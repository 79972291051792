<template>
  <el-dialog
    :visible.sync="dialogTableVisible" :title="dialogTableTitle"
    @open="openLog()"
    @close="$emit('close', false)">
    <el-table
      :data="logData.logInfo"
      :row-class-name="tableRowClassName"
      height="550"
      border
      style="width: 100%"
      stripe>
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column property="WFNode" label="流程节点" width="150" />
      <el-table-column property="RealName" label="审批人" width="150" />
      <el-table-column property="Action" label="状态" width="80" >
        <template slot-scope="scope">
          {{ scope.row.Action?scope.row.Action:'待处理' }}
        </template>
      </el-table-column>
      <el-table-column property="Log" label="审批意见" width="180" />
      <el-table-column property="ProcessTime" label="审批时间" >
        <template slot-scope="scope">
          {{ scope.row.ProcessTime.substr(0,19) }}
        </template>
      </el-table-column>
		<el-table-column property="ExpireTime" label="审核截止时间" >
		<template slot-scope="scope">
			{{ scope.row.ExpireTime ? scope.row.ExpireTime.substr(0,19) : '' }}
		</template>
		</el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getproduct } from "../request/api";
export default {
  /** 任务列表 */
  name: 'WorkFlowLog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    wfId: {
      type: [String, Number],
      default: '0'
    },
    wfName: {
      type: String,
      default: '流程日志'
    }
  },
  data() {
    return {
      logData: { WFJson: {}, logInfo: [] },
      dialogTableVisible: false,
      dialogTableTitle: '流程日志'
    }
  },
  watch: {
    dialogVisible: function(newVal, oldVal) {
		console.log(oldVal)
      this.dialogTableVisible = newVal
    }
  },
  mounted() {
  },
  methods: {
    // 打开 流程日志
    openLog() {
      this.logData = []
      this.dialogTableTitle = this.wfName || '流程日志'
      this.getLogData(this.wfId)
      //   this.dialogTableVisible = true
    //   this.$emit('update:dialogVisible', true)
    },
    // 获取 流程日志
    getLogData(id) {
		console.log(id)
		var data = {};
		data.id = id
		data.ctrl = "receipt";
		data.func = "showWorkFlowLog";
		getproduct(data).then((res) => {
			if (res.code == 200) {
				this.logData = res.data
			}
		}); 
    },
    /**
     * 流程日志 table Class
     */
    tableRowClassName({ row, rowIndex }) {
		console.log(rowIndex)
      if (row.Action == '' && row.ProcessTime == '') {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
  }
}

</script>
