<template>

	<div style="height:auto;margin-top: 94px;background: #fff;">
		<headtop :activeIndex="activeIndex" :title="titlename" :ifxianshi="false"></headtop>
		<div class='title-div'>请选择公司</div>
		<div class="companySelect" v-for="item in infoValue" :key="item.customer_name">

			<!-- <el-select v-model="customer_name" placeholder="请选择">
				<el-option v-for="item in infoValue" :key="item.customer_name" :label="item.customer_name"
					:value="item.customer_name">
				</el-option>
			</el-select> -->



			<div @click="customer_nameSearch(item.customer_name,item.customer_id)"
				style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
				<div style="width:350px">{{item.customer_name}}</div>
				<el-button type="success" style="margin-left: 20px;">选择该公司</el-button>
			</div>





		</div>



		<el-dialog :visible.sync="dialogVisible" v-show="dialogVisible" @close='closeDialog'>
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>
</template>

<script>
	import Cookies from "js-cookie";
	import config from "../request/config";
	import {
		getproduct

	} from "../request/api";
	import headtop from "../components/moneyHeader";
	export default {
		name: "Resgin",
		components: {
			headtop,
		},
		data() {
			return {
				bigShow: false,
				infoValueJia: "",
				loading: false,

				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				customer_name: "",
				account_fileArr: [],
				uploadurl: config.baseUrl + "/index.php/api/index",
				dialogImageUrl: "",
				dialogVisible: false,
				infoValue: "",
				bank_permit_file: [], //开户许可证
				business_licence_file: [], //营业执照
				business_permit_file: [], //供热/供水许可证
				customer_bar_file: [], //公司收费窗口照
				customer_brand_file: [], //公司门牌照
				customer_door_file: [], //公司门头照
				customer_inner_file: [], //公司内景照
				legal_idback_file: [], //身份证反面
				legal_idfront_file: [], //身份证正面
				logo_file: [], //客户通logo
				organization_file: [], //营业执照
				examine_file: [], //审核材料
				disabled: false,
			}
		},
		mounted() {

			this.getInfo()
		},
		methods: {
			customer_nameSearch(customer_name,customer_id) {
				this.$router.push({
					path: '/qualificationResults',
					query: {
						customer_name: customer_name,
						customer_id:customer_id
					}
				})

			},

			getInfo() {

				getproduct({
					ctrl: "payinfo",
					func: "companypayinfo",
					customer_id: this.getCookie("customerid"),
					request_type: 'pc'

				}).then((res) => {
					if (res.code == 200) {


						this.infoValue = res.data
						console.log("this.infoValue", this.infoValue)



						// :file-list="account_fileArr">
						// infoValue.data
						// i.account_file.real_url?[{url:i.account_file.real_url}]:''



					}
				});


			},








		}

	}
</script>

<style lang="scss" scoped>
	/deep/.el-form-item__label {
		line-height: 74px !important;
	}

	/deep/.el-upload--picture-card {
		line-height: 74px !important;
		width: 78px;
		height: 78px;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.title-div {
		font-size: 30px;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		margin: 0 auto;
		text-align: center;
		padding-top: 59px;
	}





	.company-info {
		display: flex;
		flex-direction: row;
		margin: 0 auto;
		margin-top: 30px;
		width: 70%;
		justify-content: space-between;

	}

	.company-info-button {
		width: 86px;
		height: 37px;
		background: #0484EF;
		border-radius: 3px;
		font-size: 15px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 11px 21px;
		/* line-height: 36px; */
	}

	.company-info-companyName {
		font-size: 17px;
		font-weight: 400;
		color: #0086F8;
		width: 40%;

	}

	.company-info-updateName {
		font-size: 17px;
		font-weight: 400;
		color: #999999;
	}

	.table-view {
		height: auto;
		width: 70%;
		border: 1px solid #CFCFCF;
		margin: 0 auto;
		margin-top: 21px;
	}

	.table-view-itemView {
		border-bottom: 1px solid #CFCFCF;
		height: 116px;
		padding-left: 69px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		// margin-right: 75px;
	}

	.table-div-item-label {
		margin-right: 59px;
		width: 200px;
	}

	.fileFormat {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 36px;
		margin-left: 17px;
		width: 340px;
	}

	.submit {
		width: 206px;
		height: 56px;
		background: #246DED;
		border-radius: 5px;
		margin: 0 auto;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 36px;
		margin-top: 113px;
		border: none;

	}

	/deep/.el-upload--picture-card i {
		margin-top: 24px;
	}

	.companySelect {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		width: 100%;
	}

	.companyClass {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;


	}

	.companyClass-div {
		width: 400px;
	}
</style>
