<template>
  <div style="margin-top: 94px; background: #f6f6f6">
    <headtop :activeIndex="activeIndex" title="热付通单位版"></headtop>
    <div class="tools-bar">
      <div class="rt"></div>
    </div>
    <div class="box">
      <div class="hd">申请开通</div>
      <div class="message" v-if="formlist.apply_message && ifedit == false" >
        拒绝原因：{{  formlist.apply_message }}
      </div>
      <el-form>
        <div class="waikuang">
          <el-row ref="form" :model="formlist">
            <el-col :span="24">
              <el-form-item label="公司名称" required>
                <div class="input">
                  <el-input
                    disabled="true"
                    style="width: 80%"
                    v-model="companyname"
                    type="text"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- <div>
          <el-row>
            <examinefile :formlist="formlist" :ifedit="ifedit"></examinefile>
          </el-row>
        </div> -->
        <div class="bottom">
          <el-tabs v-model="activeName" class="tabs" type="card">
            <el-tab-pane label="结算信息" name="first">
              <jiesuan
                :fileList="fileList"
                :formlist="formlist"
                :ifedit="ifedit"
              ></jiesuan>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 按钮 -->
        <el-form-item v-if="ifedit == false">
          <el-row class="buttonlist">
            <el-button
              type="submit"
              class="rightbutton"
              @click="submitechange"
              :disabled="ifdianji"
              >提交
            </el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <div class="copyright">Copyright©2021 -2025 热付通SASS平台</div>
  </div>
</template>

<script>
import { getproduct } from "../request/api";
import headtop from "../components/header";
import jiesuan from "../components/Hjiesuan.vue";
// import examinefile from "../components/Hexaminefile.vue";   examinefile,
import config from "../request/config";
export default {
  name: "HotPay",
  components: {
    headtop,
   
    jiesuan,
  },
  filters: {},
  data() {
    return {
      activeName: "first",
      companyname: "",
      fileList: [],
      formlist: { apply_status: 0,apply_message:"" },
      ifedit: false,
    };
  },
  created() {
    this.onCustomerRft();
    this.companyname = this.getCookie("customername");
  },
  methods: {
    onCustomerRft() {
      let data = {
        ctrl: "CustomerRft",
        func: "detail",
      };
      getproduct(data).then((res) => {
        console.log(res);
        this.formlist = res.data;
        if (this.formlist.cus_id) {
          this.formlist.contract_file_info = this.formlist.contract_file_info.map(
            (itme) => {
              return {
                url: config.baseUrl + itme.file_path,
                raw: {
                  type:
                    itme.file_path.substr(itme.file_path.lastIndexOf(".") + 1) == "pdf"
                      ? "application/pdf"
                      : "",
                },
                ...itme,
              };
            }
          );
          (this.formlist.account_file_info.url =
            config.baseUrl + this.formlist.account_file_info.file_path),
            (this.formlist.contract_file = this.formlist.contract_file.split(","));
          this.fileList = [];
          this.fileList.push(this.formlist.account_file_info);
          if (this.formlist.apply_status) {
            this.formlist.apply_status == -1
              ? (this.ifedit = false)
              : (this.ifedit = true);
          } else {
            this.ifedit = false;
          }
        } else {
          this.formlist.contract_file = [];
          this.formlist.contract_file_info = [];
        }
        console.log(this.formlist);
      });
    },
    submitechange() {
      let data = {
        ctrl: "CustomerRft",
        func: "save",
        ...this.formlist,
      };
      console.log(data,data.contract_file.length);

      // if (data.contract_file.length == 0) {
      //   this.$message({
      //     message: "请上传当前合同模版",
      //     offset: 200,
      //     type: "error",
      //   });
      //   return
      // }
      if (!data.province  && !data.city ) {
        this.$message({
          message: "请选择开户银行省市信息",
          offset: 200,
          type: "error",
        });
        return
      }
      if (!data.bank_title) {
        this.$message({
          message: "请填写开户银行详细名称",
          offset: 200,
          type: "error",
        });
        return
      }
      if (!data.bank_account) {
        this.$message({
          message: "请填写银行账号",
          offset: 200,
          type: "error",
        });
        return
      }
      if (!data.bank_name ) {
        this.$message({
          message: "请填写开户名称",
          offset: 200,
          type: "error",
        });
        return
      }
      if (!data.bank_code ) {
        this.$message({
          message: "请填写行联号",
          offset: 200,
          type: "error",
        });
        return
      }
      if (!data.account_file ) {
        this.$message({
          message: "请上传结算账户信息图片",
          offset: 200,
          type: "error",
        });
        return
      }

      data.contract_file = data.contract_file.join(",");
      data.contract_file_info = JSON.stringify(data.contract_file_info);
      data.account_file_info = JSON.stringify(data.account_file_info);
      data.cus_id = null;

      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.$router.go(0);
          window.location.reload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin: 30px auto;
  padding: 30px 40px;
  width: 75%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;

  .hd {
    padding: 12px 0;
    font-size: 20px;
    border-bottom: 1px solid #f0f1f5;
    text-align: center;
  }
}
.message{
  font-size: 14px;
  color: red;
  margin-top: 10px;
}
@import url("../assets/css/biaoge.scss");

/deep/.el-tabs--card > .el-tabs__header {
  border: none;
  margin-bottom: 30px;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-bottom: 1px solid #e4e7ed;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border: 1px solid #315bd2;
  color: #315bd2;
}

/deep/.el-form-item {
  margin: 0;
}

/deep/.el-form-item__content {
  display: flex;
  line-height: 16px;
}

/deep/.el-form-item__label {
  width: 183px;
  // border-left: 1px solid rgba(168, 188, 235, 1);
  // border-right: 1px solid rgba(168, 188, 235, 1);
  line-height: 60px;
  // background: rgba(246, 249, 255, 1);
}

/deep/.el-row {
  border-bottom: 1px solid rgba(168, 188, 235, 1);
}

.box {
  width: 74%;
  margin: 0 13%;

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid #e0e0e0;

    .navBack {
      color: #666666;
      font-size: 16px;
    }

    .navTitle {
      color: #333333;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .content {
    margin-top: 40px;

    .contentTop {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 24px;
        color: #333333;
        font-weight: 600;
      }

      .editbutton {
        border: 1px solid #315bd2;
        color: #315bd2;
      }
    }
  }
}

.waikuang {
  border: 1px solid rgba(168, 188, 235, 1);
  margin-top: 10px;
  border-bottom: none;

  // border-left: none;
  .flexlist {
    &:last-child {
      border-bottom: none;
    }

    .input {
      &:last-child {
        border-right: none;
      }
    }
  }
}

.bottom {
  margin: 30px 0;

  .tabs {
    border: none;
  }
}

.buttonlist {
  display: flex;
  justify-content: center;
  margin-bottom: 10vh;
  border: none;
  border-top: 1px solid #e3e4e7;
  padding-top: 30px;
  width: 100%;

  .leftbutton {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    color: #000;
    padding: 10px 30px;
  }

  .rightbutton {
    background: #315bd2;
    border-radius: 2px;
    color: #fff;
    padding: 10px 30px;
  }
}

.errormessage {
  width: max-content;
  margin: 0 13%;
  margin-bottom: 30px;
}

.content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 0 auto;
  margin-top: 30px;
  text-align: left;
  font-size: 20px;
  width: 100%;
  color: #f86060;
}
</style>
