<template>
	<div style="margin-top: 100px">
		<el-form :rules="rule" :model="formlist" ref="formlistRef">
			<div class="waikuang">
				<el-row ref="form">
					<el-col :span="12">
						<el-form-item label="公司名" prop="customer_id"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-select v-model="formlist.customer_id" style="width: 240px" placeholder="请选择"
									auto-complete="off">
									<!-- @change="customer_name_change" -->
									<el-option v-for="(item,index) in companylist" :key="index" :label="item.name"
										:value="item.name"></el-option>
								</el-select>
							</div>
						</el-form-item>
					</el-col>
					
					<el-col :span="12">
						<el-form-item label="金额" prop="pay_money"
						:rules="[{ required: true, message: ' ', trigger: 'blur' }]"
						>
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.pay_money" type="text"
									placeholder="请输入金额"></el-input>
							</div>
						</el-form-item>
					</el-col>
					
					<!-- <el-col :span="12">
						<el-form-item label="交费渠道" prop="pay_channel">
							<div class="input">
								<el-input style="width: 80%" v-model.number="formlist.pay_channel" maxlength="11"
									placeholder="请输入支付渠道"></el-input>
							</div>
						</el-form-item>
					</el-col> -->
				</el-row>
				<!-- <el-row>
					<el-col :span="12">
						<el-form-item label="订单号" prop="order_no">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.order_no" type="order_no"
									placeholder="请输入订单号"></el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="卡号" prop="card_no">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.card_no" placeholder="请输入卡号"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row> -->
				<!-- <el-row>
					<el-col :span="12">
						<el-form-item label="地址" prop="address">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.address" type="text" placeholder="请输入地址">
								</el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="交费时间" prop="pay_time"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
							
								<el-date-picker v-model="formlist.pay_time" type="date" placeholder="请输入交费时间">
								</el-date-picker>
							</div>
						</el-form-item>
					</el-col>
				</el-row> -->
				<!-- <el-row> -->
				<!-- 	<el-col :span="12">
						<el-form-item label="金额" prop="pay_money">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.pay_money" type="text"
									placeholder="请输入金额"></el-input>
							</div>
						</el-form-item>
					</el-col>
 -->
					<!-- <el-col :span="12">
						<el-form-item label="姓名" prop="name">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.name" type="text" placeholder="请输入金额">
								</el-input>
							</div>
						</el-form-item>
					</el-col> -->

				<!-- </el-row> -->
			<!-- 	<div class="infoClass">请下载模板，按模板内容填写，盖章后上传</div>
				<div class="infoClass"><span>下载</span>
					<el-button @click="down">下载</el-button>
				</div> -->
				<!-- <div class="infoClass"><span>上传</span>
					<el-button>上传</el-button>
				</div> -->
				<div class="uploadDiv">
					<div class="infoClass">上传</div>

					<el-upload class="upload-demo" :action="uploadurl" :on-preview="handlePreview"
						:on-remove="handleRemove" :before-remove="beforeRemove" multiple :on-exceed="handleExceed"
						:file-list="fileList" :data="filedata" :headers="fileheaders" :limit="1" :on-success="(res, file) => {handleAvatarSuccess(res,file ); } ">
						<el-button size="small" type="primary">点击上传</el-button>

					</el-upload>
				</div>



			</div>
			<!-- 按钮 -->
			<el-form-item>
				<el-row class="buttonlist">

					<el-button type="submit" class="rightbutton" :loading="ifdianji" @click="submitsh"
						:disabled="ifdianji">提交审核</el-button>
				</el-row>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	// import fapiao from "../components/fapiao";
	import config from "../../request/config";
	import Cookies from "js-cookie";
	import {
		getproduct
	} from "../../request/api";

	export default {
		name: "JinjianSubmit",
		components: {},
		data() {
			return {
				account_file: "",
				uploadurl: config.baseUrl + "/index.php/api/index",
				fileList: [],
				guid: '',
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				input: "",
				activeName: "first",
				value1: "",
				activeIndex: "1",
				onceltwoplac: "为及时取得联系，请填写手机号码",
				onceltwolable: "手机号码",
				oncelplac: "请输入第一时间可以联系到的负责人",
				oncellable: "联系人姓名",
				checkgroup: [],
				weixinchosed: false,
				weixinshchosed: false,
				zfbchosed: false,
				zfbshchosed: false,
				ylchosed: false,
				formlist: {},
				ifedit: false,
				rule: {
					wxmp_mobile: [{
						required: true,
						message: " ",
						trigger: "blur",
					}, ],
					order_no: [{
						required: true,
						message: "订单号不能为空",
						trigger: "blur",
					}, ],

					legal_id_code: [{
							required: true,
							message: "身份证号码不能为空",
							trigger: "blur",
						},
						{
							validator: this.idcard,
							trigger: ["blur", "change"]
						},

					],
					industry: [{
						required: true,
						message: "交费时间不能为空",
						trigger: "blur",
					}, ],
					email: [{
							required: true,
							message: "邮箱不能为空",
							trigger: "change"
						},
						{
							type: "email",
							message: "邮箱格式不正确",
							trigger: ["blur", "change"],
						},
					],
					bank_account: "",
					base_bank_account: "",
				},
				ifdianji: false,
				baocun: false,
				companylist: [],
				auth_file: [], //业务办理授权函
			};
		},
		created() {
			this.getcustomerinfo();
		},
		mounted() {
			this.getcustomerinfo();
		},
		methods: {

			handleAvatarSuccess(res, file) {

				if (res.code == 200) {
					console.log("必须有他啊", file)
					// item.account_file_fileinfo = "123"
					this.account_file = res.data.file_id


				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				this.$forceUpdate();
			},

			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.account_file = ""
			},
			handlePreview(file) {
				console.log("这个方法handlePreview的file", file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(`请先删除再上传`);
				console.log(files, fileList)
			},
			beforeRemove(file, fileList) {
				console.log("看看这个是什么", fileList)
				return this.$confirm(`确定移除 ${ file.name }？`);
			},

			down() {
				window.location.href = 'https://nscrm.365-job.com/public/static/关于对交易资金申请原路径退款的说明-1.doc'
			},
			//2022-06-15 增加  集团公司 则选择自己或者子公司，其他则默认自己公司
			getcustomerinfo() {
				var param = {
					func: "getcustomerinfo",
					ctrl: "payinfo",
				}
				getproduct(param).then((res) => {
					if (res.code == 200) {
						this.companylist = res.data;
					}
				});
			},
			// customer_name_change($event) {

			// 	console.log($event, this.formlist)
			// 	this.$forceUpdate()
			// },
			//保存草稿
			//提交表单
			submitsh() {
				this.$refs.formlistRef.validate((valid) => {
					if (valid) {
						if (this.ifdianji) {
							return false;
						}

						this.ifdianji = true;
						this.baocun = true;
						var data = this.formlist;
						data.func = "tjorder";
						data.ctrl = "task";
						data.type = "sass"
						data.file=this.account_file
						// data.apply_status = 1;
						console.log("json参数", JSON.stringify(data))
						getproduct(data).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: "提交成功！",
									type: "success",
									offset: 100,
								});
								setTimeout(() => {
									this.$router.push({
										name: "offlineRefundList"
									});
								}, 2000);
							} else {
								this.ifdianji = false;
								this.baocun = false;
							}
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../../assets/css/biaoge.scss");

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin-bottom: 30px;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border-bottom: 1px solid #e4e7ed;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		border: 1px solid #315bd2;
		color: #315bd2;
	}

	/deep/.el-form-item {
		margin: 0;
	}
	/deep/.el-form-item__content {
		display: flex;
		line-height: 16px;
	}
	/deep/.el-form-item__content /deep/.input{
		padding: 15px 0 0 20px;
	}
	

	/deep/.el-form-item__label {
		width: 183px;
		// border-left: 1px solid rgba(168, 188, 235, 1);
		// border-right: 1px solid rgba(168, 188, 235, 1);
		line-height: 60px;
		color:#000;
		// background: rgba(246, 249, 255, 1);
	}

	/deep/.el-row {
		border-bottom: 1px solid rgba(168, 188, 235, 1);
	}

	/deep/.el-form-item__error {
		top: 40%;
		left: 43%;
	}

	.waikuang {
		border: 1px solid rgba(168, 188, 235, 1);
		// border-bottom: none;
		// border-left: none;
		width: 70%;
		margin: 0 15%;
		min-width: 1040px;

		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.bottom {
		width: 70%;
		margin: 10vh 15% 5vh;

		.tabs {
			border: none;
		}
	}

	.buttonlist {
		display: flex;
		justify-content: center;
		margin-bottom: 10vh;
		border: none;
		// border-top: 1px solid #e3e4e7;
		padding-top: 30px;
		width: 100%;



		.rightbutton {
			background: #315bd2;
			border-radius: 2px;
			color: #fff;
			padding: 10px 30px;
		}
	}

	.listone {
		position: relative;
	}

	.errorImg {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 0;
		right: 0;
	}



	/deep/.el-form-item__label {
		line-height: 74px !important;
	}

	/deep/.el-upload--picture-card {
		line-height: 74px !important;
		width: 78px;
		height: 78px;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.fileFormat {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 36px;
		margin-left: 17px;
		width: 340px;
	}

	.infoClass {
		padding: 20px;
		font-size: 14px;
	}

	.infoClass span {
		margin-right: 20px;
	}

	.uploadDiv {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-left: 110px;
	}

	/deep/.upload-demo {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-left: 25px;
	}
</style>
