<template>
	<div style="height:auto;margin-top: 94px;padding-top:30px;background: #F6F6F6;">
		<headtop :activeIndex="activeIndex" :title="titlename" :ifxianshi="false"></headtop>

		<el-row :gutter="10" style="margin-left:0;margin-right:0 ">
			<el-col :span="8" :offset="3" style="display: flex; padding-top: 25px">
				<div class='company-div'>
					<div class="company-change">
						<div class="change-left">{{ companyname }}</div>
						<div class="change-right" @click="centerDialogVisible = true" v-if="companylist.length > 1">切换
						</div>
					</div>
				</div>
			</el-col>

			<el-col :span="8" :offset="2" class="flexright ">
				<div class="qualificationSelfInspection " @click="qualificationNav">
					<img src="../assets/product/search.png" class="search-img">
					<div class='qualificationSelfInspection-title'>
						<div>资质自查</div>
						<div>
							<span>为了确保贵公司在采暖缴费期间 </span>
							<span>收费顺利进行，请您完成资质自查</span>
						</div>
					</div>
					<img src="../assets/product/rightArrow.png" style="margin-left: 40px;">
				</div>

			</el-col>

		</el-row>
		<el-row :gutter="10" style="margin-left:0;margin-right:0 ">
			<el-col :span="12" :offset="3" style="display: flex; padding-top: 25px">
				<div>

				</div>
			</el-col>
			<el-col :span="6" :offset="3" style="display: flex; padding-top: 25px">
				<div>
					<el-button @click="associateMore">关联更多公司</el-button>
				</div>
			</el-col>

		</el-row>







		<div class="list">
			<el-row gutter="30">
				<el-col style="margin-bottom: 10px;" class="listdefaule" v-for="(item, index) in productlist" :key="index"
					:span="6">
					<div class="felxlist" style="position: relative;">
						<img class="imglogo" :src="item.icon" />
						<div class="rt">
							<p class="title">{{ item.name }}</p>
							<el-tooltip class="item" effect="light" popper-class="atooltip" :content="item.intro"
								placement="top-start">
								<div class="text">
									{{ item.intro }}
								</div>
							</el-tooltip>
							<div class="godetail"
								@click.stop="gotodetail(item.jump_type, item.url, item.istoken, item.alias, item.is_allow_auth)">
								<div class="godetailtext">
									点击进入

								</div>
							</div>
						</div>
						<el-badge v-if="item.total && item.total > 0" :max="99" :value="item.total" />
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- <el-button type="primary" class="nextbutton" @click="gonext"
      >下一步</el-button
    > -->

		<el-dialog title="选择公司" :visible.sync="centerDialogVisible" width="50%" center :show-close="false"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<div class="company-list">
				<div v-for="(item, index) in companylist" class="company-listitem" :key="index"
					@click="chosecompagy(index, item.name, item.customer_id, item.data_type)"
					:class="ischoseid == index ? 'activecompany' : ''">
					{{ item.name }}
					<img class="company-listitemchose" v-if="ischoseid == index" src="../assets/home/choseicompany.png" />

				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="company-listbuttom" @click="gotonext">下一步</el-button>
			</span>
		</el-dialog>


		<!-- :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false"-->
		<el-dialog title="关联更多公司" :visible.sync="companyDialogVisible" width="50%" center>
			<div class="companyClass">
				<div class='companyClass-div'>
					<div class="companyClass-div-label">省市区：</div>
					<el-cascader v-model="companyValue" :options="options" @change="handleChange"></el-cascader>
				</div>

				<div class='companyClass-div'>
					<div class="companyClass-div-label">公司名：</div>
					<el-select v-model="valueCompany" placeholder="请选择公司" @change="changeCompany">
						<el-option v-for="item in optionsCompany" :key="item.customer_id" :label="item.name"
							:value="item.customer_id">
						</el-option>
					</el-select>

				</div>

			</div>
			<div class="companyButton">
				<el-button @click="applyFor">申请</el-button>
			</div>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="dialogVisibleJurisdiction" width="30%" :before-close="handleClose">
			<span>此账号没有这个权限，是否申请权限</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleJurisdiction = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleJurisdictionSure">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="dialogVisibleJurisdictionXin" width="30%" :before-close="handleClose">
			<span>此账号没有这个权限，是否申请权限</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleJurisdictionXin = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleJurisdictionSureXin">确 定</el-button>
			</span>
		</el-dialog>

		<div class="copyright" style="position:fixed;bottom:0;width:100%">Copyright©2021 -2025 热付通SASS平台</div>
	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
Vue.prototype.$axios = axios
import headtop from "../components/moneyHeader";
import {
	getproduct
} from "../request/api";
export default {
	name: "Resgin",
	components: {
		headtop,
	},
	data() {
		return {
			dialogVisibleJurisdictionXin: false,
			valueCompany: '',
			options: '',
			optionsCompany: [],
			companyValue: "",
			companyDialogVisible: false,
			errorMsg: "",
			checkboxGroup1: [],
			checklable: "",
			activeIndex: "0",
			companylist: [],
			centerDialogVisible: false,
			ischoseid: -1,
			companyname: "",
			productlist: [],
			titlename: "热付通SAAS平台",
			data_type: '',
			count: '',
			infoValue: "",
			dialogVisibleJurisdiction: false,
			companyValueXin: '',
		};
	},
	beforeCreate() {
		// document
		//   .querySelector("html")
		//   .setAttribute("style", "background-color:#F6F9FF;");
	},
	beforeDestroy() {
		document.querySelector("html").removeAttribute("style");
	},
	mounted() {
		this.companylist = JSON.parse(localStorage.getItem("customerList"));
		if (this.companylist.length == 1) {

			this.ischoseid = 0;
			this.companyname = this.companylist[0].name;
			this.setCookie("customerid", this.companylist[0].customer_id);
			this.setCookie("customername", this.companylist[0].name);
		} else if (this.getCookie("customerid")) {
			this.companyname = this.getCookie("customername");
		} else {
			this.centerDialogVisible = true;
		}
		this.getCoount();
		this.getproductlist();
		this.getInfo()
		this.getCompany()


	},
	methods: {
		applyFor() {
			const data = {
				func: 'register',
				ctrl: 'openApi'
			}


			if (data.func == 'register' && data.ctrl == 'openApi') {
				axios.defaults.headers.post['source'] = 'SASS';
			}

			// if (data.func == 'loginByMobile' && data.ctrl == 'openApi') {
			// 	axios.defaults.headers.post['source'] = alias;
			// }
			if (this.optionsCompany.length == 0) {
				this.$message({
					message: "请选择省市区",
					type: 'error',
					offset: 250,
				});
				return
			}
			if (!this.companyValueXin) {
				this.$message({
					message: "请选择公司名",
					type: 'error',
					offset: 250,
				});
				return

			}

			getproduct({
				ctrl: "openApi",
				func: "register",
				cus_id: this.companyValueXin,
				name: this.getCookie("username"),
				mobile: this.getCookie("userMobile"),
			}).then((res) => {

				if (res.code == 200) {
					this.$message({
						message: "提交申请成功",
						type: 'success',
						offset: 250,
					});
					this.companyDialogVisible = false

				}
				if (res.code == 400) {
					this.errorMsg = res.error
				}
			});
		},
		handleChange(value) {
			console.log("这个是什么地址", value)
			this.optionsCompany = []
			this.valueCompany = ""
			// ctrl:Customer func:list 参数 params:{"keywords":"地址id"}
			var params = {
				"keywords": value[2]
			}

			getproduct({
				ctrl: "customer",
				func: "list",
				params: JSON.stringify(params)

			}).then((res) => {

				if (res.code == 200) {

					this.optionsCompany = res.data
				}
				if (res.code == 400) {
					this.errorMsg = res.error
				}
			});
		},
		changeCompany(value) {
			console.log("改变公司后value", value)
			this.companyValueXin = value

		},
		getCompany() {
			getproduct({
				ctrl: "area",
				func: "cascaderData",
			}).then((res) => {

				if (res.code == 200) {

					this.options = res.data
				}
				if (res.code == 400) {
					this.errorMsg = res.error
				}
			});
		},
		associateMore() {
			this.companyDialogVisible = true
		},
		dialogVisibleJurisdictionSureXin() {
			getproduct({
				ctrl: "openApi",
				func: "register",
				cus_id: this.getCookie("customerid"),
				name: this.getCookie("username"),
				mobile: this.getCookie("userMobile"),


			}).then((res) => {

				if (res.code == 200) {
					this.$message({
						message: "提交申请成功",
						type: 'success',
						offset: 250,
					});
					this.dialogVisibleJurisdictionXin = false
				}
				if (res.code == 400) {
					this.errorMsg = res.error
				}
			});
		},
		// dialogVisibleJurisdictionSure() {

		// 	getproduct({
		// 		ctrl: "openApi",
		// 		func: "register",
		// 		cus_id: this.getCookie("customerid"),
		// 		name: this.getCookie("username"),
		// 		mobile: this.getCookie("userMobile"),


		// 	}).then((res) => {

		// 		if (res.code == 200) {
		// 			this.$message({
		// 				message: "提交申请成功",
		// 				type: 'success',
		// 				offset: 250,
		// 			});
		// 			this.dialogVisibleJurisdiction = false
		// 		}
		// 		if (res.code == 400) {
		// 			this.errorMsg = res.error
		// 		}
		// 	});
		// },
		getInfo() {
			getproduct({
				ctrl: "payinfo",
				func: "getCustomerInfo_zicha",
				// customer_id: this.getCookie("customerid"),
				// request_type: 'pc'

			}).then((res) => {
				console.log("re123s", res)
				if (res.code == 200) {


					this.infoValue = res.data
					console.log("this.infoValue", this.infoValue)



					// :file-list="account_fileArr">
					// infoValue.data
					// i.account_file.real_url?[{url:i.account_file.real_url}]:''



				}
				if (res.code == 400) {
					this.errorMsg = res.error
				}
			});


		},


		qualificationNav() {
			console.log("this.count", this.count)

			if (this.count > 0) {
				console.log("this.infoValue.length", this.infoValue.length)

				if (this.infoValue.length > 1) {
					this.$router.push({
						path: '/companyPage'
					})
				}
				if (this.infoValue.length == 1) {
					this.$router.push({
						path: '/qualificationResults',
						query: {
							customer_name: this.infoValue[0].name
						}
					})
				}
				if (this.infoValue.length == 0) {
					if (this.errorMsg) {
						this.$message({
							message: this.errorMsg,
							type: 'error',
							offset: 250,
						});
					}


				}


			} else {

				this.$message({
					message: "您还未在saas平台上提交资料，请点击进件申请提交资料",
					type: 'error',
					offset: 250,
				});
			}



		},
		gotonext() {
			if (this.getCookie("customerid")) {
				this.centerDialogVisible = false;

				location.reload()


			} else {
				this.$message({
					message: "请选择公司",
					type: 'error',
					offset: 250,
				});
			}
		},
		//选择公司啊
		chosecompagy(index, name, id, data_type) {
			this.ischoseid = index;
			this.companyname = name;
			this.data_type = data_type;
			this.setCookie("customerid", id);
			this.setCookie("customername", name);
			this.setCookie("data_type", data_type);

		},
		//去详情

		gotodetail(id, url, istoken, alias, is_allow_auth) {
			console.log("掌上派工", url, is_allow_auth)
			if (is_allow_auth == 1) {
				this.loginByMobileXin(alias, url)
			}
			else {
				switch (id) {
					case 1:
						this.$router.push({
							name: url
						});
						break;
					case 2:
						if (istoken == 1) {
							url = url + "?token=" + this.getCookie("tooken");
						}
						console.log(url);
						window.open(url);
						break;
				}
			}

		},
		getCoount() {

			this.getCookie("customerid")
			var data = ""
			if (this.getCookie("customerid")) {
				data = {

					ctrl: "payinfo",
					func: "querycustomerpayinfo",
					customer_id: this.getCookie("customerid"),
				};
			} else {
				data = {

					ctrl: "payinfo",
					func: "querycustomerpayinfo",
					customer_id: this.companylist[0].customer_id,
				};
			}



			getproduct(data).then((res) => {
				if (res.code == 200) {


					this.count = res.data.count

					console.log("看看返回值", this.count)
				} else {
					console.log("看看返回值", res)
				}
			})
		},


		// loginBypayApplicationList(alias) {

		// 	console.log("没走这个是不")
		// 	var data = {
		// 		func: "loginByMobile",
		// 		ctrl: "openApi",
		// 		mobile: this.getCookie("userMobile"),
		// 		cus_id: this.getCookie("customerid"),
		// 	};
		// 	if (data.func == 'loginByMobile' && data.ctrl == 'openApi') {
		// 		axios.defaults.headers.post['source'] = alias;
		// 	}


		// 	getproduct(data).then((res) => {
		// 		if (res.code == 200) {

		// 			this.$router.push({
		// 				name: "payApplicationList",

		// 			});


		// 			console.log("看看返回值", res)
		// 		} else {
		// 			this.dialogVisibleJurisdiction = true
		// 			console.log("看看返回值", res)
		// 		}
		// 	})
		// },

		loginByMobileXin(alias, url) {
			var data = {
				func: "loginByMobile2",
				ctrl: "openApi",
				mobile: this.getCookie("userMobile"),
				cus_id: this.getCookie("customerid"),
			};
			if (data.func == 'loginByMobile2' && data.ctrl == 'openApi') {
				axios.defaults.headers.post['source'] = alias;
			}
			getproduct(data).then((res) => {
				if (res.code == 200) {
					console.log("没走这个是不",url)
					this.$router.push({
						name: url,
					});
					console.log("看看返回值", res)
				}


				else {

					this.dialogVisibleJurisdictionXin = true
					console.log("看看返回值", res)

				}
			})
		},


		// loginByMobile(alias) {
		// 	console.log("没走这个是不")
		// 	var data = {
		// 		func: "loginByMobile2",
		// 		ctrl: "openApi",
		// 		mobile: this.getCookie("userMobile"),
		// 		cus_id: this.getCookie("customerid"),
		// 	};
		// 	if (data.func == 'loginByMobile2' && data.ctrl == 'openApi') {
		// 		axios.defaults.headers.post['source'] = alias;
		// 	}
		// 	getproduct(data).then((res) => {
		// 		if (res.code == 200) {
		// 			this.$router.push({
		// 				name: "applicationList",
		// 			});
		// 			console.log("看看返回值", res)
		// 		} 


		// 		else {

		// 			this.dialogVisibleJurisdiction = true
		// 			console.log("看看返回值", res)

		// 		}
		// 	})
		// },
		//获取产品列表
		getproductlist() {
			var data = {
				func: "getList",
				ctrl: "IndexMenu",
				type: "7",
			};
			getproduct(data).then((res) => {
				// console.log(res);
				if (res.code == 200) {
					this.productlist = res.data;
				}
			});
		},
		//下一步
		gonext() {
			this.$router.push({
				name: "submit",
				params: {
					checkboxGroup: this.checkboxGroup1
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: rgba(49, 91, 210, 1);
	border-color: rgba(49, 91, 210, 1);
}

/deep/.el-checkbox {
	width: 100%;
}

/deep/.el-checkbox__input {
	position: absolute;
}

/deep/.el-checkbox__label {
	display: block;
	margin-top: 30px;
}

.biaoti {
	text-align: center;
	color: rgba(102, 102, 102, 1);
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 50px;
}

.list {
	width: 75%;
	// margin: 164px 12.5%;
	margin: 0 12.5%;
	margin-top: 164px;
	padding-bottom: 164px;
}

.felxlist {
	display: flex;
	padding: 30px 0;
	background: #ffffff;
	border-radius: 28px;
	box-shadow: 3px 3px 6px #efedef;

	.rt {
		padding: 0 30px 0 15px;
		min-height: 151px;

	}

	.imglogo {
		display: block;
		margin-left: 30px;
		width: 60px;
		height: 60px;
	}

	.title {
		margin: 0 0 12px;
		// line-height: 50px;
		font-size: 30px;
		font-weight: 700;
		color: #333333;
		height: 60px;
		padding-bottom: 10px;

	}

	.text {
		margin: 0 auto;
		line-height: 1.6;
		font-size: 14px;
		color: #999;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden; //溢出内容隐藏
		text-overflow: ellipsis; //文本溢出部分用省略号表示
		display: -webkit-box; //特别显示模式
		-webkit-line-clamp: 2; //行数
		line-clamp: 2;
		-webkit-box-orient: vertical; //盒子中内容竖直排列
		height: 47px;


	}

	.godetail {
		margin-top: 24px;
		width: 106px;
		line-height: 37px;
		text-align: center;
		background: #ffffff;
		border: 1px solid #c0c1c1;
		border-radius: 3px;

		.godetailtext {
			font-size: 14px;
			cursor: pointer;
		}
	}
}

.nextbutton {
	display: block;
	margin: auto;
	width: 350px;
	background: rgba(49, 91, 210, 1);
	color: #fff;
	font-size: 20px;
}

.company-list {
	width: 80%;
	margin: auto;

	.company-listitem {
		width: 100%;
		border: 1px solid #e3e4e7;
		margin-bottom: 24px;
		padding: 14px 20px;
		position: relative;

		.company-listitemchose {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 16px;
			height: 16px;
		}
	}

	.activecompany {
		border: 1px solid #315bd2;
	}

	.company-listbuttom {
		width: 120px;
		height: 36px;
		border-radius: 4px;
		border: 1px solid #0252cc;
		text-align: center;
		line-height: 36px;
	}
}

.company-change {
	position: relative;
	width: 400px;
	background: #ffffff;
	border-radius: 3px;
	border: 1px solid #e6e6e6;
	padding: 12px 12px 12px 80px;
	display: flex;
	// margin: 30px auto 0;
	// margin-top:30px;
	justify-content: space-between;
	font-size: 16px;

	&::before {
		content: "";
		position: absolute;
		top: 12px;
		left: 20px;
		padding-right: 20px;
		width: 21px;
		height: 21px;
		background: url(../assets/product/pro-icon_01.png) no-repeat;
		border-right: 1px solid #dedede;
	}

	.change-left {
		color: #333333;
	}

	.change-right {
		color: #0252cc;
		cursor: pointer;
	}
}

.el-badge {
	position: absolute;
	right: 15px;
	top: 15px;
}

.qualificationSelfInspection {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 350px;
	height: 103px;
	background: #FFF8E5;
	border-radius: 13px;
	padding: 26px 20px 21px 26px;
	box-sizing: border-box;

	.search-img {
		width: 50px;
		height: 50px;
	}

	.qualificationSelfInspection-title {
		margin-left: 17px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;

	}

	.qualificationSelfInspection-title div:nth-child(1) {
		font-size: 21px;
		font-weight: 400;
		color: #333333;
		line-height: 21px;
	}

	.qualificationSelfInspection-title div:nth-child(2) {
		font-size: 14px;
		font-weight: 400;
		color: #888888;
		line-height: 14px;
		height: auto;
		margin-top: 11px;
		width: 159px;
		margin-right: 34px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		span:nth-child(1) {
			margin-bottom: 3px;
		}
	}

}

.company-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.flexright {
	display: flex;
	justify-content: flex-end;
	white-space: nowrap;

}

/deep/.el-tooltip__popper /deep/.is-light {
	border: 1px solid #fff;
}

.companyClass {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.companyClass-div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.companyClass-div-label {
	margin: 30px;
}

.companyButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
</style>
