<template>
	<div>
		<el-button class="backbut" @click="goback">返回</el-button>
		<el-form ref="form" :model="form" label-width="150px">
			<div class="formtop">
				<div class="formtop-title">收件信息</div>
				<el-form-item label="收件公司名称">
					<el-input v-model="form.company" :disabled="true"></el-input>
				</el-form-item>
				<el-row type="flex">
					<el-col :span="10">
						<el-form-item label="收件人">
							<el-input v-model="form.invoice_contacts" :disabled="true">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="手机号码">
							<el-input v-model="form.invoice_mobile" maxlength="11" :disabled="true"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex">
					<el-col>
						<el-form-item label="详细地址">
							<el-input v-model="form.invoice_addr" :disabled="true" style="width: 1005px"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</div>


			<div class="formtop">
				<div class="formtop-title">快递信息</div>
				<el-row type="flex">
					<el-col :span="10">
						<el-form-item label="快递公司">
							<el-input v-model="form.kd_name" :disabled="ifedit">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="快递单号">
							<el-input v-model="form.kd_code" :disabled="ifedit"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</div>

			<div class="formtop">
				<div class="formtop-title">原因</div>
				<el-row type="flex">
					<el-col>
						<el-form-item>
							<el-input type="textarea" v-model="form.content" :disabled="ifedit" style="width: 1005px"
								maxlength="255" :autosize="{ minRows: 4}"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</div>


			<div class="formtop file">
				<div class="formtop-title">附件</div>
				<el-form-item label="变更材料" required>
					<div class="input">
						<el-upload :action="uploadurl" list-type="picture-card" accept=".jpg,.jpeg,.gif,.bmp,.png"
							:auto-upload="true" :on-remove="handleRemove" :on-progress="handleProgress"
							:on-change="handleChange" :on-exceed="exceedimg" class="uploadlist" :data="filedata"
							:headers="fileheaders" :limit="3" ref="customerdoorupload" :before-upload="beforeUpload"
							:on-success="
								(res, file) => {
									handleAvatarSuccess(
										res,
										file
									);
								}
							">
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../../assets/submit/upload.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>

						<div v-if="form.file_info != '' && ifedit !== false " style="display: flex;">
							<div v-for="(item,index) in form.file_info" :key="index" @click="chakan(item)">
								<img class="el-upload-list__item-thumbnail" :src="showurl+item.file_path" alt=""
									style="width: 76px;height: 76px;margin: 10px;" />
							</div>
						</div>

					</div>

				</el-form-item>
			</div>

		</el-form>
		<el-button style="background: #315bd2; color: #fff" @click="submit" v-if="!ifedit">提交</el-button>
		<el-dialog :visible.sync="dialogVisible">
			<img :src="dialogImageUrl" width="100%" alt="">
		</el-dialog>
	</div>
</template>
<script>
	import {
		getproduct
	} from "../../request/api";
	import Cookies from "js-cookie";
	import config from "../../request/config"
	export default {
		name: "Jinjianbillback",
		data() {
			return {
				form: {
					receipt_id: 0,
					company: '',
					invoice_contacts: '',
					invoice_mobile: '',
					invoice_addr: '',
					kd_name: '',
					kd_code: '',
					content: '',
					files: [],
					file_info: []
				},
				ifedit: false,
				ifdianji: false,
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
					serviceFileName: "附件",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				uploadurl: config.baseUrl + "/index.php/api/index",
				showurl: config.baseImgUrl,
				dialogImageUrl: '',
				dialogVisible: false
			};
		},
		beforeRouteEnter(to, from, next) {

			localStorage.setItem("routename", from.name);
			next();
		},
		created() {
			if (localStorage.getItem("routename") == "alreadybill") {
				this.getBackData()
			} else {
				this.getBackDetail()
				//backbill
				this.ifedit = true
			}

		},
		mounted() {},
		methods: {
			chakan(file) {
				if (file && file.file_path) {
					this.dialogImageUrl = this.showurl + file.file_path
					this.dialogVisible = true
				}
			},
			handleAvatarSuccess(res, res2) {
				this.form['file_info'] = res2;
				if (res.code == 200) {
					this.form['files'] = res.data.file_id;
				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				this.$forceUpdate();
			},
			getBackData() {
				var data = {
					ctrl: "receipt",
					func: "getbackData",
					receipt_id: this.$route.params.choseid,
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.form.company = res.data.name
						this.form.invoice_contacts = res.data.invoice_contacts
						this.form.invoice_mobile = res.data.invoice_mobile
						this.form.invoice_addr = res.data.invoice_addr
						this.ifedit = false
					} else {
						this.ifedit = true
					}
				});
			},
			getBackDetail() {
				var data = {
					ctrl: "receipt",
					func: "getbackDetail",
					receipt_id: this.$route.params.choseid,
					back_id: this.$route.params.back_id,
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.form = res.data
						console.log(this.form)
					}
				});
			},
			submit() {

				if (this.ifdianji) {
					return false;
				}
				this.ifdianji = true;
				var data = this.form;
				data.func = "createBackapply";
				data.ctrl = "receipt";
				data.receipt_id = this.$route.params.choseid

				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: "提交成功",
							type: "success",
							offset: 100,
						});

						this.$router.go(-1);
					}
					setTimeout(() => {
						this.ifdianji = false;
					}, 2000);
				});
			},
			goback() {
				this.$router.go(-1);
			},
			handleRemove(file, filelist) {

				this.form['file_info'] = [];
				this.form['files'] = [];
				let arr = [];
				for (var i in filelist) {
					if (filelist[i].response.code == 200) {
						arr.push(filelist[i].response.data.file_id)
						this.form['file_info'].push(filelist[i]);
					}
				}
				this.form['files'] = arr;
				this.$forceUpdate();

			},
			handleChange(file, filelist) {
				this.form['file_info'] = [];
				this.form['files'] = [];
				let arr = [];
				for (var i in filelist) {
					console.log(filelist[i].response)
					if (filelist[i].response !== undefined) {

						if (filelist[i].response.code == 200) {
							arr.push(filelist[i].response.data.file_id)
							this.form['file_info'].push(filelist[i]);
						}
					}

				}
				this.form['files'] = arr;
				this.$forceUpdate();
			},
			beforeUpload(file) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				const whiteList = ["bmp", "png", "jpeg", "jpg", "gif"];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message({
						type: "warning",
						message: "格式不正确，请重新上传",
						offset: 120,
					});
					return false;
				}
				return true
			},
			exceedimg() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.editbutton {
		border: 1px solid #315bd2;
		color: #315bd2;
	}

	.el-input {
		width: 305px;
	}

	/deep/.el-dialog__title {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 33px;
	}

	.formtop {
		background: #fff;
		padding: 20px 0;
		margin-bottom: 10px;
		min-width: 1500px;

		.formtop-title {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: rgba(0, 0, 0, 0.85);
			border-left: 3px solid #315bd2;
			padding-left: 10px;
			margin-bottom: 20px;
		}
	}

	.formbutton {
		background: #315bd2;
		border-radius: 4px;
		color: #fff;
		padding: 20px 130px;
		display: block;
		margin: auto;
		margin-top: 40px;
	}

	.toast {
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 21px;
		text-align: center;
	}

	.cancelbut {
		border: 1px solid #315bd2;
		color: #315bd2;
		width: 40%;
	}

	.confimbut {
		background: #315bd2;
		color: #fff;
		width: 40%;
	}

	.backbut {
		margin-bottom: 20px;
	}

	/deep/.el-upload--picture-card {
		background-color: transparent;
		border: none;
		width: auto;
		height: auto;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.examine-file-box {
		line-height: 39px;
		margin: 0 0 0 30px;
		cursor: pointer;
		color: #409EFF;
	}

	.file {
		.input {
			padding: 10px 20px 0;
			width: 100%;

			.text {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				margin-right: 12px;
			}

			.scimg {
				width: 26px;
				height: 26px;
				padding-top: 5px;
			}
		}
	}

	.uploadlist {
		// width: 100%;
		display: flex;
		justify-content: space-between;
	}
</style>
