/**   
 * api接口统一管理
 */
 import { post } from './http'
 import axios from 'axios';
//  import XLSX from 'xlsx';
//  import FileSaver from 'file-saver'
 
 export const getproduct = p => post('api/index', p);   //进件
 export const getCompanyList = p => post('api/index', p);   //进件
 export const rechargeSubmit = p => post('api/index', p);   //进件

 
 export function crmPayinfoExcelExportAPI(data) {
    return axios({
      url: 'api/index',
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      responseType: 'blob',
      timeout: 60000
    })
  }

/**
 * 下载excel
 */
export function downloadExcelWithResData(res) {
    let fileName = ""
    if (res.headers['content-disposition']) {
        fileName = res.headers['content-disposition'].split('filename=')[1] 
    }
    if (!fileName && res.headers['content-disposition']) {
      fileName = res.headers['content-disposition'].split('UTF-8\'\'')[1] || ""
    }
    fileName = fileName ? fileName.replace(/"/g, '') : 'file.xlsx'
    fileName = decodeURI(fileName) || ''
    downloadFileWithBuffer(res.data, fileName, 'application/vnd.ms-excel;charset=utf-8')
  }
  
  export function downloadFileWithBuffer(data, name, type) {
    var blob = new Blob([data], {
      type: type || ''
    })
    var downloadElement = document.createElement('a')
    var href = window.URL.createObjectURL(blob) // 创建下载的链接
    downloadElement.href = href
    downloadElement.download = name // 下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() // 点击下载
    document.body.removeChild(downloadElement) // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
  }
  

//   /**
//    * 导出ElTable表格
//    * @param {*} name
//    */
//   export function exportElTable(name, domId) {
//     const fix = document.querySelector('.el-table__fixed')
//     let wb
//     if (fix) {
//       wb = XLSX.utils.table_to_book(
//         document.getElementById(domId).removeChild(fix)
//       )
//       document.getElementById(domId).appendChild(fix)
//     } else {
//       wb = XLSX.utils.table_to_book(
//         document.getElementById(domId)
//       )
//     }
//     const wopts = {
//       bookType: 'xlsx',
//       bookSST: false,
//       type: 'binary'
//     }
//     const wbout = XLSX.write(wb, wopts)
  
//     FileSaver.saveAs(
//       new Blob([s2ab(wbout)], {
//         type: 'application/octet-stream;charset=utf-8'
//       }),
//       name
//     )
//   }
  
//   function s2ab(s) {
//     var cuf
//     var i
//     if (typeof ArrayBuffer !== 'undefined') {
//       cuf = new ArrayBuffer(s.length)
//       var view = new Uint8Array(cuf)
//       for (i = 0; i !== s.length; i++) {
//         view[i] = s.charCodeAt(i) & 0xff
//       }
//       return cuf
//     } else {
//       cuf = new Array(s.length)
//       for (i = 0; i !== s.length; ++i) {
//         cuf[i] = s.charCodeAt(i) & 0xff
//       }
//       return cuf
//     }
//   }