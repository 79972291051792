<template>
	<div style="height: 100%">
		<!-- <el-button class="backbut" @click="goback">返回</el-button> -->
		<el-form ref="form" :inline="true" :model="form" label-width="100px">
			<div class="formtop">
				<div class="formtop-title">筛选</div>

				<el-form-item label="申请时间">
					<el-date-picker v-model="dateValue" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="退票状态">
					<el-select v-model="form.status" style="width: 240px" placeholder="请选择" clearable>
						<el-option label="审核中" value="1"></el-option>
						<el-option label="审核成功" value="2"></el-option>
						<el-option label="审核失败" value="-1"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button class="chaxunbutton" @click="findList3">查询</el-button>
					<el-button class="clearbutton" @click="agin">重置</el-button>
				</el-form-item>
			</div>
		</el-form>
		<div class="formtop" style="padding: 20px">
			<vxe-table border row-id="id" size="small" :loading="loading3" :data="tableData3"
				:header-cell-style="{background:'#1373C2',color:'white'}">
				<!-- <vxe-table-column type="checkbox" width="60"></vxe-table-column> -->
				<vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
				<vxe-table-column field="create_time" title="申请时间" sortable></vxe-table-column>
				
				<vxe-table-column field="status" title="退票状态" sortable>
					<template #default="{ row }">
						<template>
							{{showStatus(row)}}
						</template>
					</template>
				</vxe-table-column>
				<vxe-table-column field="receipt_type" title="发票类型"></vxe-table-column>
				<vxe-table-column field="receipt_amount" title="交易金额">
					<template #default="{ row }">
						{{ parseFloat(row.receipt_amount).toFixed(2) }}
					</template>
				</vxe-table-column>
				<vxe-table-column field="kd_name" title="快递公司"></vxe-table-column>
				<vxe-table-column field="kd_code" title="快递单号"></vxe-table-column>
				<vxe-table-column title="操作" width="290">
					<template #default="{ row }">
						<template>
							<vxe-button style="background-color: #1373C2;color:#fff"
								@click="editRowEvent(row,'billback')">退票信息</vxe-button>
							<vxe-button style="background-color: #1373C2;color:#fff"
								@click="editRowEvent(row,'billdetail')">开票信息</vxe-button>
							<vxe-button style="background-color: #1373C2;color:#fff" v-if="row.wf_id>0"
								@click="showWorkFlowLog(row.wf_id)">流程日志</vxe-button>
							<!-- <vxe-button style="background-color: #1373C2;color:#fff"
								@click="editRowEvent(row,'billback')" v-if="row.status==2 && (row.back_status == 0 || row.back_status == -1)">退票</vxe-button> -->
						</template>
					</template>
				</vxe-table-column>
			</vxe-table>
		</div>
		<el-pagination :current-page.sync="tablePage3.currentPage" :page-size.sync="tablePage3.pageSize"
			:total="tablePage3.totalResult" :pager-count="5" :page-sizes="[10, 20, 30, 40, 50, 100, 500]" class="p-bar"
			background layout="prev, pager, next, sizes, total, jumper" @current-change="findList3"
			@size-change="findList3" />
		<workflowlog :dialog-visible="wfDialogVisible" :wf-id="wfId" @close="wfDialogVisible=false" />
	</div>
</template>

<script>
	import {
		getproduct
	} from "../../request/api";
	import workflowlog from '@/components/WorkFlowLog'
	export default {
		name: "JinjianNonebill",
		data() {
			return {
				dateValue: '',
				form: {},
				loading3: false,
				tableData3: [],
				tablePage3: {
					currentPage: 1,
					pageSize: 10,
					totalResult: 0,
				},
				wfId: '0',
				wfDialogVisible: false,
			};
		},
		components: {
			workflowlog
		},
		created() {
			this.findList3();
		},
		mounted() {},
		methods: {
			showWorkFlowLog(wfId) {
				this.wfId = wfId
				this.wfDialogVisible = true
				console.log(wfId)
			},
			dateChange(value) {
				console.log(value)
				if (value) {
					this.form.start_date = value[0]
					this.form.end_date = value[1]
				} else {
					this.$delete(this.form, 'start_date')
					this.$delete(this.form, 'end_date')
				}
			},
			showStatus(row) {
				const statusArr = {
					"1": "审核中",
					"2": "审核成功",
					"-1": "审核失败"
				}
				return statusArr[row.status] ? statusArr[row.status] : ''
			},
			showBackStatus(row) {
				const statusArr = {
					"0": "暂无",
					"1": "退票中",
					"2": "退票成功",
					"-1": "退票失败"
				}
				return statusArr[row.back_status] ? statusArr[row.back_status] : ''
			},
			goback() {
				this.$router.push({
					name: "product"
				});
			},
			agin() {
				this.form = {};
				this.findList3();
			},
			findList3() {
				this.loading3 = true;
				var data = {};
				if (this.form) {
					data = this.form;
				}
				data.ctrl = "receipt";
				data.func = "backindex";
				data.page = this.tablePage3.currentPage;
				data.limit = this.tablePage3.pageSize;
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.loading3 = false;
						this.tableData3 = res.data.list;
						this.tablePage3.totalResult = res.data.dataCount;
					}
				});
			},
			handlePageChange3({
				currentPage,
				pageSize
			}) {
				this.tablePage3.currentPage = currentPage;
				this.tablePage3.pageSize = pageSize;
				this.findList3();
			},
			editRowEvent(row, routename) {
				var choseid = row.receipt_id;
				var back_id = row.back_id;
				this.$router.push({
					name: routename,
					params: {
						choseid: choseid,
						back_id: back_id,
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.vxe-pager {
		background: none;
	}

	/deep/.vxe-body--column {
		height: 54px;
	}

	.el-input {
		width: 305px;
	}

	.formtop {
		background: #fff;
		padding: 20px 0;
		margin-bottom: 10px;

		// min-width: 1500px;
		.formtop-title {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: rgba(0, 0, 0, 0.85);
			border-left: 3px solid #315bd2;
			padding-left: 10px;
			margin-bottom: 20px;
		}

		.chaxunbutton {
			width: 96px;
			background: #3d7fff;
			border-radius: 3px;
			text-align: center;
			color: #fff;
		}

		.clearbutton {
			width: 96px;
			background: #f0f2f5;
			border-radius: 3px;
			border: 1px solid #d7dbe0;
			text-align: center;
			color: #000;
		}

		.fresh {
			width: 96px;
			background: #f0f2f5;
			border-radius: 3px;
			border: 1px solid #d7dbe0;
			text-align: center;
			line-height: 36px;
			font-size: 14px;
			margin-bottom: 20px;
		}

		.bill {
			width: 96px;
			background: #3d7fff;
			border-radius: 3px;
			text-align: center;
			color: #fff;
			line-height: 36px;
			margin-bottom: 20px;
			font-size: 14px;
		}
	}

	.backbut {
		margin-bottom: 20px;
	}
</style>
