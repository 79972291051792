<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item> 创建设合同模板</el-breadcrumb-item>

		</el-breadcrumb>


		<div class="managementDiv">
			<!-- v-if="false" -->
			<!-- <div class="managementDivTitleDiv">
				合同模板管理&nbsp;&nbsp;&nbsp;&nbsp;创建设合同模板
			</div> -->
			<div class="managementDiv-sonDiv">
				<div class="managementDiv-companyTitleDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">合同模板标题：</div>
					<el-input placeholder="请填写合同模板标题" v-model="TempTitle" class="managementDiv-companyTitleDiv-input">
					</el-input>

				</div>

				<div class="managementDiv-companyTitleDiv maginTopDiv" v-if="customerid != 679">
					<div class="managementDiv-companyTitleDiv-nameDiv">业务类型：
					</div>
					<el-select class="managementDiv-companyTitleDiv-input" @change="businessOptionsChange"
						v-model="BusinessType" placeholder="请选择业务类型">
						<el-option v-for="item in businessOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<!-- <el-input placeholder="请填写业务类型" v-model="BusinessType" class="managementDiv-companyTitleDiv-input">
					</el-input> -->
				</div>
				<div class="managementDiv-companyTitleDiv maginTopDiv" v-if="customerid != 679">
					<div class="managementDiv-companyTitleDiv-nameDiv">用户类型：
					</div>
					<!-- <el-input placeholder="请填写用户类型" v-model="UserType" class="managementDiv-companyTitleDiv-input">
					</el-input> -->
					<el-select class="managementDiv-companyTitleDiv-input" @change="userOptionsChange" v-model="UserType"
						placeholder="请选择用户类型">
						<el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>

				</div>


				<div class="managementDiv-companyTitleDiv maginTopDiv" v-if="customerid != 679">
					<div class="managementDiv-companyTitleDiv-nameDiv">
						标签：</div>
					<el-input placeholder="请填写标签" v-model="Labels" class="managementDiv-companyTitleDiv-input"
						style="width: 200px;">
					</el-input>
					<el-button style="margin-left: 10px;" @click="LabelsSure" type="primary">确认</el-button>


					<el-tag style="margin-left: 10px;" v-for="tag in tags" :key="tag" closable @close="handleClose(tag)">
						{{ tag }}
					</el-tag>
				</div>

				<div class="managementDiv-companyTitleDiv maginTopDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">合同模板编码：</div>
					<el-input placeholder="请填写合同模板编码" v-model="TempNo" class="managementDiv-companyTitleDiv-input">
					</el-input>
				</div>


				<div class="codeAuthenticationClass">{{ codeAuthentication }}</div>

				<div class="managementDiv-companyTitleDiv maginTopDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">上传合同模板：</div>
					<!-- https://jsonplaceholder.typicode.com/posts/ -->
					<el-upload class="upload-demo" action="" :on-preview="handlePreview" :on-remove="handleRemove"
						:before-remove="beforeRemove" multiple :limit="1" :auto-upload="false" :on-exceed="handleExceed"
						:file-list="fileList" :on-change="onChange">
						<!-- <span class="haveNoSpan">无</span> -->
						<el-button size="small" type="primary" class="uoloaderButton" style="margin-left: 0px;">
							<i class="el-icon-plus avatar-uploader-icon" style="color:#949494;margin-right: 10px;"></i>点击上传
						</el-button>

					</el-upload>


					<!-- <el-upload class="upload-demo" action="" :auto-upload="false" :on-preview="handlePreview"
						:before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed"
						:file-list="fileList" :on-success="onSuccess" :on-progress="onProgress"
						:before-upload="beforeUploadFun" :on-change="onChange" :on-remove="onRemove">
					<el-button size="small" type="primary" class="uoloaderButton" style="margin-left: 30px;">
							<i class="el-icon-plus avatar-uploader-icon"
								style="color:#949494;margin-right: 10px;"></i>点击上传
						</el-button>
					
					</el-upload> -->





				</div>
				<div slot="tip" class="el-upload__tip">*请选择有效的.pdf文件为合同的模板文件上传</div>

				<el-button class="registerButtonDiv" @click="submit">
					提交
				</el-button>
			</div>
		</div>

	</div>
</template>

<script>
// import { getproduct } from "../../request/api";

import Vue from 'vue'
// import {
// 	getCompanyList
// } from "../../request/api";
import axios from 'axios'
Vue.prototype.$axios = axios
// import {
// 	getCompanyList
// } from "../../request/api";
export default {
	data() {
		return {
			codeAuthentication: "*请保证合同模板编码的唯一性，并且其长度不能大于10位，不能包含特殊字符（如？*.”<>!）",
			fileList: [],
			customerid: "",
			TempTitle: "",
			TempNo: "",
			BusinessType: "供热缴费协议",
			UserType: "居民",
			Labels: "",
			tags: [],
			businessOptions: [
				{
					label: "供热缴费协议",
					value: "供热缴费协议"
				}, {
					label: "开栓协议",
					value: "开栓协议"
				},
				{
					label: "更名过户协议",
					value: "更名过户协议"
				},
				{
					label: "网签协议",
					value: "网签协议"
				}, {
					label: "报装协议",
					value: "报装协议"
				},
				{
					label: "面积变更协议",
					value: "面积变更协议"
				}
			],
			userOptions: [
				{
					label: "居民",
					value: "居民"
				}, {
					label: "非居民",
					value: "非居民"
				}
			],
		};
	},
	components: {},
	mounted() {

	},

	created() {
		this.customerid = this.getCookie("customerid")
		console.log("公司id", this.customerid)
	},

	methods: {
		businessOptionsChange(val) {
			console.log("选择的业务类型", val)
			this.BusinessType = val;
		},
		userOptionsChange(val) {
			console.log("选择的用户类型", val)
			this.UserType = val;
		},
		handleClose(tag) {
			this.tags.splice(this.tags.indexOf(tag), 1);
		},
		LabelsSure() {
			if (this.Labels) {
				this.tags.push(this.Labels)
				this.Labels = ""
			}


		},
		onChange(file, fileList) {
			console.log("onChange方法", file)
			console.log("onChange方法", fileList)
			this.fileList = file.raw
			console.log(this.fileList)
		},
		submit() {

			if (!this.TempTitle) {
				this.$message({
					message: "合同模板标题不能为空",
					type: 'error',
					offset: 80
				})
				return
			}
			if (!this.TempNo) {
				this.$message({
					message: "合同模板编码不能为空",
					type: 'error',
					offset: 80
				})
				return
			}
			if (this.Labels) {
				this.$message({
					message: "标签输入框里不为空，请点击【确认】按钮后再提交",
					type: 'error',
					offset: 80
				})
				return
			}



			let form = new FormData;

			form.append('func', 'CreateContractTemplate');
			// if(form.get("func")=='CreateContractTemplate'){
			// 	axios.defaults.timeout = 9999999999999999999999;
			// 	console.log("改过期时间没有啊",axios.defaults.timeout)
			// }
			form.append('ctrl', 'FadadaApi');
			form.append('file', this.fileList);
			form.append('TempNo', this.TempNo);
			form.append('TempTitle', this.TempTitle);
			form.append('BusinessType', this.BusinessType);

			form.append('UserType', this.UserType);
			form.append('Labels', this.tags.join(','));
			const customerid = this.customerid;
			if (customerid) {
				form.append('customer_id', customerid);
			}
			// console.log("form",form.get("name"))




			var that = this
			// axios.post("http://crm.odd-job.ren/index.php/api/index", form).then(function(response) {
			axios.post(axios.defaults.baseURL + '/api/index', form).then(function (response) {
				console.log("看看这个值是多少", response)
				if (response.data.code === 200) {
					console.log(response.data);
					that.$message({
						message: '合同模板创建成功',
						type: 'success',

						offset: 80
					});
					that.$router.push({
						name: "contractList"
					});

				} else {
					if (response.data.error == "上传文件后缀不允许") {
						that.$message({
							message: '请上传有效的.pdf文件做为合同的模板文件',
							type: 'error',
							offset: 80
						});
						return
					}
					that.$message({
						message: response.data.error,
						type: 'error',
						offset: 80
					});
				}
			})
		}

	},
};
</script>
<style lang='scss' scoped>
.breadcrumbClass {
	height: 39px;
	width: 100%;
	margin-top: 8px;
	border-bottom: 1px solid #F0F1F5;
}

.managementDiv {
	/* width: 677px; */
	/* height: 331px; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	margin: 0 auto;
	margin-top: 76px;
}


.managementDiv-sonDiv {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-top: 60px;


}


.managementDivTitleDiv {
	font-size: 20px;
	font-weight: bold;
	color: #0C0C0C;
	line-height: 30px;

	/* line-height: 30px; */


}

.managementDiv-companyTitleDiv {
	/* width: 677px; */
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: auto;


}

.managementDiv-companyTitleDiv-nameDiv {
	font-size: 16px;

	font-weight: bold;
	color: #000000;
	width: 120px;
	text-align: right;
}

.managementDiv-companyTitleDiv-input {
	width: 573px;
	margin-left: 0px;

}




.registerButtonDiv {
	width: 359px;
	height: 49px;
	background: linear-gradient(90deg, #0683EB, #1373C2);
	border-radius: 25px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: bold;
	color: #F6F6F6;
	/* line-height: 30px; */
	margin-top: 50px;
}



.marginLeftDiv {
	margin-left: -65px;
}

.maginTopDiv {
	margin-top: 33px;
}

.codeAuthenticationClass {
	font-size: 14px;
	font-weight: bold;
	color: #FA0000;
	margin-top: 14px;
	margin-left: 150px;

}

.haveNoSpan {
	width: 16px;
	height: 14px;
	font-size: 16px;
	font-weight: bold;
	color: #000000;
	margin-left: 35px;
	margin-right: 15px;
}

.uoloaderButton {
	width: 167px;
	height: 40px;
	background: #F5F5F5;
	border: 1px solid #CDCDCD;
	border-radius: 10px;
	font-size: 16px;
	font-weight: bold;
	color: #020202;
}

.el-upload__tip {
	font-size: 14px;
	font-weight: bold;
	color: #FA0000;
	line-height: 30px;
	margin-left: 150px;
}

/deep/.upload-demo {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

/deep/.el-upload-list .el-upload-list__item {
	margin: 0 auto;
	margin-left: 10px;
}
</style>
