<template>
	<div style="margin-top: 100px">
		<headtop :activeIndex="activeIndex" title="热付通SAAS结算信息变更"></headtop>
		<div class="box">
			<div class="nav">
				<!-- <el-button style="height: max-content; margin: auto 0" @click="goback">返回</el-button> -->
				<span></span>
				<div class="navTitle">申请详情</div>
				<div style="width: 50px"></div>
			</div>
			<el-alert v-if="formlist.apply_message && typestatus == -1" :title="formlist.apply_message" type="error"
				show-icon :closable="false">
			</el-alert>
			<div class="content">
				<div class="contentTop">
					<span>基本信息</span>
					<!-- <el-button
            size="small"
            v-if="typestatus == -1"
            class="editbutton"
            icon="el-icon-edit"
            @click="openEdit()"
            >修改</el-button
          > -->
				</div>
			</div>
			<!--  -->
			<div v-if="formlist.balance_apply_message&&formlist.balance_flag==-1" class="content-div">失败原因：{{formlist.balance_apply_message}}</div>
			<!-- 表格 -->
			<el-form>
				<div class="waikuang">
					<el-row ref="form" :model="formlist">
						<!-- <el-col :span="12">
              <el-form-item label="联系人姓名">
                <div class="input">
                  <el-input
                    disabled="true"
                    style="width: 80%"
                    v-model="formlist.contacts_name"
                    type="text"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col> -->
						<el-col :span="24">
							<el-form-item label="公司名称">
								<div class="input">
									<el-input disabled="true" style="width: 80%" v-model="formlist.customer_name"
										type="text"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<!-- v-if="ifedit == false" -->
				<div style="margin-top: 10px;" >
					<el-row>
						<bexaminefile :formlist="formlist" :formlistXin="formlistXin" :ifedit="ifedit"></bexaminefile>
					</el-row>
				</div>
				<div class="bottom">
					<el-tabs v-model="activeName" class="tabs" type="card">
						<el-tab-pane label="结算信息" name="first">
							<bjiesuan :formlist="formlist.detail" :ifedit="ifedit"></bjiesuan>
						</el-tab-pane>
						<!-- <el-tab-pane label="审核材料" name="second">
              <bexaminefile :formlist="formlist" :ifedit="ifedit"></bexaminefile>
            </el-tab-pane> -->
					</el-tabs>
				</div>
				<!-- 按钮 -->
				<el-form-item v-if="ifedit == false">
					<el-row class="buttonlist">
						<el-button type="submit" class="rightbutton" @click="submitechange" :disabled="ifdianji">提交
						</el-button>
					</el-row>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import headtop from "../components/header";
	import bjiesuan from "../components/b_jiesuan.vue";
	import bexaminefile from "../components/b_examinefile";
	import {
		getproduct
	} from "../request/api";
	export default {
		name: "RecordDetail",
		components: {
			headtop,
			bjiesuan,
			bexaminefile
		},
		data() {
			return {
				formlist: {},
				formlistXin: {},
				activeName: "first",
				activeIndex: "2",
				typestatus: "",
				ifedit: true,
				backsin: false,
				ifdianji: false,
			};
		},
		beforeRouteEnter(to, from, next) {
			localStorage.setItem("routename", from.name);
			next();
		},
		created() {
			if (localStorage.getItem("routename") == "alreadyauditing") {
				//如过是从待处理里进来的  设置返回参数
				this.backsin = true;
			}
		},
		mounted() {
			this.typestatus = this.$route.query.status;
			if (this.typestatus == 1) {
				this.ifedit = false;
			}
			this.getpage();
		},
		methods: {
			getpage() {
				var data = {
					func: "balanceDetail",
					ctrl: "payinfo",
					payinfo_id: this.$route.query.payinfoid,
				};
				if (!data.payinfo_id) {
					this.$router.go(-1);
				}
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.formlist = res.data;
						this.formlistXin = res.data

						this.formlist['balance_file'] = [];
						console.log(this.formlist);
						// this.formlistXin['balance_file'] = []
						// this.formlist['examile_file'] = [];
						// this.formlistXin['examile_file'] = [];
					}
				});
			},

			submitechange() {
				console.log("点击提交时这个是什么", this.formlistXin)
				if (this.ifdianji) {
					return false;
				}
				this.ifdianji = true;
				let data = this.formlist;
				
				// console.log("data.balance_file大", data.balance_file)
				// if (data.balance_file.length > 0) {
				// 	console.log("balance_file判断length大于0没进来吗")
					
				// } else {
				// 	console.log("balance_file判断length小于0")
				// 	this.$message({
				// 		message: "请上传结算账户变更说明",
				// 		type: "error",
				// 		offset: 100,
				// 	});
				// 	this.ifdianji = false;
				// 	return;
				// }

			
				// if (data.detail.WXLIFE || data.detail.ALILIFE) {
				// 	if (data.examile_file&&data.examile_file.length>0) {
				// 		console.log("examile_file判断length大于0没进来吗")


				// 	} else {
				// 		console.log("examile_file判断length小于0")

				// 		this.$message({
				// 			message: "请上传结算账户变更申请函",
				// 			type: "error",
				// 			offset: 100,
				// 		});
				// 		this.ifdianji = false;
				// 		return;


				// 	}
				// }

				// dataXin.examile_file = examile_file;
				// data.examile_file = examile_file
				data.func = "createApplyByBalance";
				data.ctrl = "payinfo";
				getproduct(data).then((res) => {
					console.log(res);
					console.log("请求接口这里没走吗")
					if (res.code == 200) {
						this.$message({
							message: "提交成功！",
							type: "success",
							offset: 100,
						});
						setTimeout(() => {
							this.$router.go(-1);
						}, 2000);
					} else {
						this.ifdianji = false;
					}
				}).catch((res) => {
					console.log(res);
					console.log("请求接口这里有问题了吗")
					this.ifdianji = false;

				});

			},
			goback() {
				if (this.backsin) {
					this.$router.push({
						name: "alreadyauditing"
					});
				} else {
					this.$router.go(-1);
				}

			}
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin-bottom: 30px;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border-bottom: 1px solid #e4e7ed;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		border: 1px solid #315bd2;
		color: #315bd2;
	}

	/deep/.el-form-item {
		margin: 0;
	}

	/deep/.el-form-item__content {
		display: flex;
		line-height: 16px;
	}

	/deep/.el-form-item__label {
		width: 183px;
		// border-left: 1px solid rgba(168, 188, 235, 1);
		// border-right: 1px solid rgba(168, 188, 235, 1);
		line-height: 60px;
		// background: rgba(246, 249, 255, 1);
	}

	/deep/.el-row {
		border-bottom: 1px solid rgba(168, 188, 235, 1);
	}

	.box {
		width: 74%;
		margin: 0 13%;

		.nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 18px 0;
			border-bottom: 1px solid #e0e0e0;

			.navBack {
				color: #666666;
				font-size: 16px;
			}

			.navTitle {
				color: #333333;
				font-size: 20px;
				font-weight: 600;
			}
		}

		.content {
			margin-top: 40px;

			.contentTop {
				display: flex;
				justify-content: space-between;

				span {
					font-size: 24px;
					color: #333333;
					font-weight: 600;
				}

				.editbutton {
					border: 1px solid #315bd2;
					color: #315bd2;
				}
			}
		}
	}

	.waikuang {
		border: 1px solid rgba(168, 188, 235, 1);
		margin-top: 30px;
		border-bottom: none;

		// border-left: none;
		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.bottom {
		margin: 30px 0;

		.tabs {
			border: none;
		}
	}

	.buttonlist {
		display: flex;
		justify-content: center;
		margin-bottom: 10vh;
		border: none;
		border-top: 1px solid #e3e4e7;
		padding-top: 30px;
		width: 100%;

		.leftbutton {
			background: #eeeeee;
			border: 1px solid #eeeeee;
			color: #000;
			padding: 10px 30px;
		}

		.rightbutton {
			background: #315bd2;
			border-radius: 2px;
			color: #fff;
			padding: 10px 30px;
		}
	}

	.errormessage {

		width: max-content;
		margin: 0 13%;
		margin-bottom: 30px;
	}

	.content-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: left;
		margin: 0 auto;
		margin-top: 30px;
		text-align: left;
		font-size: 20px;
		width: 100%;
		color: #F86060;
	}
</style>
