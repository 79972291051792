<template>
	<div class="pushzizhi">
		<el-row>
			<el-col :span="24">
				<el-form-item label="结算账户变更说明" required>

					<div class="input">
						<div class="text">
							<!-- 	<div>支付通道结算信息变更材料</div> -->
							<!-- <div>如果修改微信生活和支付宝生活渠道的结算账号请上传模板一和模板二</div> -->
							<!-- <div>北京光大渠道请点击模板一下载,其他渠道请点击模板二下载</div> -->
							<!-- <div>微信生活和支付宝生活请点击模板一下载,其他渠道请点击模板二下载</div> -->
							<div>
								<el-button type="text" icon="el-icon-download" @click="downloadTemplate(2)">模板下载
								</el-button>
								<!-- 	<el-button type="text" icon="el-icon-download" @click="downloadTemplate(2)">模板二下载
								</el-button> -->


							</div>
							<!-- <div>如果修改微信生活和支付宝生活渠道的结算账号请上传结算账户变更申请函和结算账户变更说明</div> -->
						</div>
						<!-- :limit="2" -->
						<el-upload :action="uploadurl" list-type="picture-card" accept="image/jpg,image/jpeg,image/png"
							:auto-upload="true" :on-remove="handleRemove" :on-progress="handleProgress"
							:on-change="handleChange" :on-exceed="exceedimg" class="uploadlist" :data="filedata"
							:headers="fileheaders" ref="customerdoorupload" :before-upload="beforeUpload"
							:on-success="(res, file) => {handleAvatarSuccess(res,file)}">

							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete" @click="deletepic">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>


							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/upload.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
							<!-- <div slot="file" slot-scope="{ file }">
                <div> {{ file.name }} </div>
              </div> -->
						</el-upload>
						<!-- <div v-if="formlist.balance_fileinfo != ''" class="balance-file-box" ref="balanceFileBox"
							@click="downloadFile(formlist.balance_fileinfo)">
							{{ formlist.balance_fileinfo?formlist.balance_fileinfo.name:'' }}
						</div> -->

						<div v-if="formlist.balance_fileinfo" @click="chakan(formlist.balance_fileinfo)">
							<img style="width: 76px; height: 76px; display: block; margin: auto"
								:src="showImg(formlist.balance_fileinfo)" />
						</div>

						<div v-show="ifedit == true" v-if="formlist.b_balance_file.length>0&&formlist.b_balance_file[0]"
							@click="chakan(formlist.b_balance_file[0])">
							<img style="width: 76px; height: 76px; display: block; margin: auto"
								:src="showImg(formlist.b_balance_file[0])" />
						</div>


					</div>
					<div class="textClass">此文件必须上传</div>

				</el-form-item>
			</el-col>




		</el-row>

		<el-row>
			<el-col :span="24">
				<el-form-item label="结算账户变更申请函" required>
					<div class="input">
						<div class="text">
							<!-- <div>支付通道结算信息变更材料</div> -->
							<!-- <div>北京光大渠道请点击模板一下载,其他渠道请点击模板二下载</div> -->
							<!-- <div>微信生活和支付宝生活请点击模板一下载,其他渠道请点击模板二下载</div> -->
							<div>
								<el-button type="text" icon="el-icon-download" @click="downloadTemplate(1)">模板下载
								</el-button>
								<!-- <el-button type="text" icon="el-icon-download" @click="downloadTemplate(2)">模板二下载
								</el-button> -->


							</div>
						</div>
						<!-- :limit="2" -->

						<el-upload :action="uploadurl" list-type="picture-card" accept="image/jpg,image/jpeg,image/png"
							:auto-upload="true" :on-remove="handleRemoveXin" :on-progress="handleProgressXin"
							:on-change="handleChangeXin" :on-exceed="exceedimgXin" class="uploadlist"
							:data="filedataXin" :headers="fileheaders" ref="customerdooruploadXin"
							:before-upload="beforeUploadXin"
							:on-success="(res, file) => {handleAvatarSuccessXin(res,file)}">

							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete" @click="deletepicXin">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>

							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/upload.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>






						</el-upload>
						<!-- <div v-if="formlist.examile_fileinfo != ''" class="balance-file-box" ref="balanceFileBox"
							@click="downloadFile(formlist.examile_fileinfo)">
							{{ formlist.examile_fileinfo?formlist.examile_fileinfo.name:'' }}
						</div> -->

						<div v-if="formlist.examile_fileinfo" @click="chakan(formlist.examile_fileinfo)">
							<img style="width: 76px; height: 76px; display: block; margin: auto"
								:src="showImg(formlist.examile_fileinfo)" />
						</div>

						<div v-show="ifedit == true" v-if="formlist.b_examile_file.length>0&&formlist.b_examile_file[0]"
							@click="chakan(formlist.b_examile_file[0])">
							<img style="width: 76px; height: 76px; display: block; margin: auto"
								:src="showImg(formlist.b_examile_file[0])" />
						</div>


					</div>
					<div class="textClass">如果您选择了微信支付缴费或者支付宝生活缴费此文件必须上传</div>
				</el-form-item>
			</el-col>




		</el-row>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>

	</div>
</template>

<script>
	import Cookies from "js-cookie";
	import config from "../request/config"

	export default {
		name: "bExaminefile",
		props: {
			formlist: Object,
			formlistXin: Object,
			ifedit: {
				default: false,
				type: Boolean,
			},
		},
		data() {
			return {
				disabled: false,
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
					serviceFileName: "结算账户变更说明",
				},
				filedataXin: {
					ctrl: "file",
					func: "save",
					type: "file",
					serviceFileName: "结算账户变更申请函",
				},



				fileheaders: {
					token: Cookies.get("tooken"),
				},
				uploadurl: config.baseUrl + "/index.php/api/index",
				dialogImageUrl: "",
				dialogVisible: false,
			};
		},
		mounted() {
			console.log(this.formlist);
			this.formlist['balance_file'] = [];
			this.formlist['examile_file'] = [];
			this.formlistXin['balance_file'] = [];
			this.formlistXin['examile_file'] = [];

		},
		methods: {
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			showImg(file) {
				if (file && file.file_path) {
					return config.baseUrl + file.file_path;
				}
			},
			chakan(file) {
				if (file && file.file_path) {
					this.dialogImageUrl = config.baseUrl + file.file_path;
					this.dialogVisible = true;
				}
			},
			handleRemove(file, filelist) {
				this.formlist['balance_file'] = filelist
				this.formlistXin['balance_file'] = filelist

			},
			handleRemoveXin(file, filelist) {
				this.formlist['examile_file'] = filelist
				this.formlistXin['examile_file'] = filelist

			},

			handleChange(file, filelist) {
				console.log(file, filelist)
				// this.formlist['balance_file'] = filelist
				// this.formlistXin['balance_file'] = filelist
			},
			handleChangeXin(file, filelist) {
				console.log(file, filelist)
				// this.formlist['examile_file'] = filelist
				// this.formlistXin['examile_file'] = filelist
			},
			beforeUpload(file) {
				console.log(file)
				// var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
				// const extension = testmsg === 'doc'
				// const extension2 = testmsg === 'docx'
				// if (!extension && !extension2) {
				// 	this.$message({
				// 		type: "warning",
				// 		message: "上传文件只能是 doc、docx格式!",
				// 		offset: 120,
				// 	});
				// }
				// return extension || extension2
				var img = file.name.substring(file.name.lastIndexOf('.') + 1)
				const suffix = img === 'jpg'
				const suffix2 = img === 'png'
				const suffix3 = img === 'jpeg'

				if (!suffix && !suffix2 && !suffix3) {
					this.$message.error("只能上传图片！");
					return false
				}
				// const isLt10M = file.size / 1024 / 1024 < 2
				// if (!isLt10M) {
					
					
				// 	this.$message({
				// 		type: "error",
				// 		message: "上传文件大小不能超过 2MB!",
				// 		offset: 120,
				// 	});
				// 	return false;
				// }
				
				
				// 可以限制图片的大小

				return suffix || suffix2 || suffix3


			},
			deletepic() {
				this.formlist['balance_file'] = ''
				this.formlistXin['balance_file'] = ''
				this.$refs['customerdoorupload'].clearFiles()
			},
			deletepicXin() {
				this.formlist['examile_file'] = ''
				this.formlistXin['examile_file'] = ''

				this.$refs['customerdooruploadXin'].clearFiles()
			},


			beforeUploadXin(file) {
				// console.log(file)
				// var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
				// const extension = testmsg === 'doc'
				// const extension2 = testmsg === 'docx'
				// if (!extension && !extension2) {
				// 	this.$message({
				// 		type: "warning",
				// 		message: "上传文件只能是 doc、docx格式!",
				// 		offset: 120,
				// 	});
				// }
				// return extension || extension2
				var img = file.name.substring(file.name.lastIndexOf('.') + 1)
				const suffix = img === 'jpg'
				const suffix2 = img === 'png'
				const suffix3 = img === 'jpeg'

				if (!suffix && !suffix2 && !suffix3) {
					this.$message.error("只能上传图片！");
					return false
				}
				const isLt10M = file.size / 1024 / 1024 < 2
				if (!isLt10M) {
					
					
					this.$message({
						type: "error",
						message: "上传文件大小不能超过 2MB!",
						offset: 120,
					});
					return false;
				}
				
				
				// 可以限制图片的大小

				return suffix || suffix2 || suffix3
			},


			downloadTemplate(type) {
				const link = document.createElement('a')
				link.style.display = 'none'
				console.log("看看type是几",type)
				if (type == 1) {
					link.href = config.baseUrl + '/public/static/审核材料模板/结算账户变更申请函_北京光大渠道（北软版）.doc'
				}
				if (type == 2) {
					link.href = config.baseUrl + '/public/static/审核材料模板/结算账户变更说明.doc'
				}
				if (type == 3) {
					link.href = config.baseUrl + '/public/static/审核材料模板/结算账户图片模板.docx'
				}
				document.body.appendChild(link)
				link.click()
			},

			handleProgress() {
				// this.$refs.examineFileBox.innerHTML = ""
			},
			handleProgressXin() {

			},
			handleAvatarSuccess(res, file) {
				console.log("咋进来这里的handleAvatarSuccess的res", res)
				console.log("咋进来这里的handleAvatarSuccess的file", file)
				if (res.code == 200) {
					// this.formlist['balance_file'] = res.data.file_id;
					let arr = [];
					this.formlist['balance_file'] = []
					arr.push(res.data.file_id);
					this.formlist['balance_file'] = arr;
					this.formlistXin['balance_file'] = arr;


					console.log("这个是什么", this.formlist['balance_file'])

					// this.formlistXin=this.formlist

				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				this.$forceUpdate();
			},
			handleAvatarSuccessXin(res, file) {
				console.log("咋进来这里的handleAvatarSuccessXin的res", res)
				console.log("咋进来这里的handleAvatarSuccessXin的file", file)
				if (res.code == 200) {
					// this.formlist['balance_file'] = res.data.file_id;
					let arr = [];
					this.formlist['examile_file'] = []
					arr.push(res.data.file_id);
					this.formlist['examile_file'] = arr;
					this.formlistXin['examile_file'] = arr;




					// this.formlistXin=this.formlist

				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				this.$forceUpdate();
			},
			exceedimg() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});
			},
			exceedimgXin() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	/deep/.el-form-item__label {
		line-height: 74px !important;
		width: 250px !important;
	}

	/deep/.el-upload--picture-card {
		background-color: transparent;
		border: none;
		width: auto;
		height: auto;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.examine-file-box {
		line-height: 39px;
		margin: 0 0 0 30px;
		cursor: pointer;
		color: #409EFF;
	}

	.pushzizhi {
		// min-width: 1040px;
		border: 1px solid rgba(168, 188, 235, 1);
		border-bottom: none;

		// border-left: none;
		.input {
			padding: 10px 20px 0;
			width: 100%;

			.text {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				margin-right: 12px;
			}

			.scimg {
				width: 26px;
				height: 26px;
				padding-top: 5px;
			}
		}
	}

	.uploadlist {
		// width: 100%;
		display: flex;
		justify-content: space-between;
	}

	/deep/.el-form-item__content {
		margin-top: 10px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.textClass{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 250px;
	}
</style>
