<template>
	<div class="zizhipage">
		<el-row>
			<el-col :span="12">
				<el-form-item label="营业执照注册号" required>
					<div class="input">
						<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.business_licence_code"
							placeholder="请输入"></el-input>
					</div>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="营业执照有效期" required>
					<div class="input" style="align-items: center;">
						<el-date-picker :disabled="check_business_licence_valid_check()"
							v-model="formlist.business_licence_valid" value-format="yyyy-MM-dd" type="date"
							placeholder="选择日期">
						</el-date-picker>
						<el-checkbox v-model="formlist.business_licence_valid_check" style="margin-left: 10px;">长期有效
						</el-checkbox>
					</div>
				</el-form-item>
			</el-col>
		</el-row>

		<el-row>
			<el-col :span="16">
				<el-form-item label="营业执照经营内容" required>
					<div class="input" style="width: 100%">
						<el-input :disabled="ifedit" style="width: 100%" v-model="formlist.business_licence_cont"
							type="text" placeholder="请输入，与企业工商营业执照上一致"></el-input>
					</div>
				</el-form-item>
			</el-col>
		</el-row>

		<el-row>
			<el-col :span="12">
				<el-form-item label="营业执照注册资金" required>
					<div class="input">
						<el-input :disabled="ifedit" max="9999999999" style="width: 80%"
							v-model="formlist.business_licence_fund" type="number" placeholder="请输入"></el-input>
					</div>
				</el-form-item>
			</el-col>
		</el-row>

		<!-- <el-row>
			<el-col :span="12">
				<el-form-item label="组织机构代码" required>
					<div class="input">
						<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.organization_code" 
						type="text"
							placeholder="如三证合一，不需要填写。" @input="changeMemberId"></el-input>
					</div>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="组织机构代码证有效期" required>
					<div class="input" style="align-items: center;">
						<el-date-picker :disabled="check_organization_valid_check()"
							v-model="formlist.organization_valid" type="date" placeholder="选择日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
						<el-checkbox v-model="formlist.organization_valid_check" style="margin-left: 10px;">长期有效
						</el-checkbox>
						
					</div>
				</el-form-item>
				
				
				
			</el-col>
		</el-row> -->

	
	</div>
</template>

<script>
	export default {
		name: "JinjianZizhi",
		props: {
			formlist: Object,
			ifedit: {
				default: false,
				type: Boolean
			}
		},
		data() {
			return {
				input: "",
				value1: "",
			};
		},
		mounted() {},
		methods: {

			changeMemberId(val) {
				console.log(val)
				this.formlist.organization_code = val.replace(/[^\w_]/g, '')
			},

			check_business_licence_valid_check() {
				if (this.ifedit === true) {
					return true;
				}
				return this.formlist.business_licence_valid_check;
			},
			check_organization_valid_check() {
				if (this.ifedit === true) {
					return true;
				}
				return this.formlist.organization_valid_check;
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	.zizhipage {
		border: 1px solid rgba(168, 188, 235, 1);
		border-bottom: none;

		// border-left: none;
		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}
	}
</style>
