<template>
	<div class="zizhipage">

		<el-row>
			<el-col :span="16" style="padding-bottom: 20px;" >
				<el-form-item label="企业支付宝账号" required>
					<div class="input" style="width: 100%">
						<el-input :disabled="ifedit" style="width: 100%" v-model="formlist.ali_account"
							type="text" placeholder="请输入企业支付宝账号"></el-input>
					</div>
				</el-form-item>
				<div class="pagetext" >申请企业支付宝账号操作指南： <span @click="toopen" >https://opendocs.alipay.com/b/065tsj?pathHash=522959e3</span></div>
			</el-col>
		</el-row>

	</div>
</template>

<script>
	export default {
		name: "zfbInfo",
		props: {
			formlist: Object,
			ifedit: {
				default: false,
				type: Boolean
			}
		},
		data() {
			return {
				input: "",
				value1: "",
			};
		},
		mounted() {},
		methods: {
			toopen(){
				window.open('https://opendocs.alipay.com/b/065tsj?pathHash=522959e3')
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	.zizhipage {
		border: 1px solid rgba(168, 188, 235, 1);
		border-bottom: none;
		position: relative;
		// border-left: none;
		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}
	}
	.pagetext{
		position: absolute;
		bottom: 10px;
		left: 20px;
		font-size: 12px;
		display: flex;
		color: red;
		span{
			cursor: pointer;
		}
	}
</style>
