<template>
    <div class="corp">
        <div class="corp_nav">
            <div class="corp_nav_left">
                <div class="title"><span style="color:red">*</span> 银联商户信息变更单</div>
                <div class="text">
                    <el-button type="text" icon="el-icon-download" @click="downloadTemplate(2)">模板下载
                    </el-button>
                    <el-upload :action="uploadurl" list-type="picture-card" accept="image/jpg,image/jpeg,image/png"
                        :auto-upload="true" :on-remove="handleRemove" :on-progress="handleProgress"
                        :on-change="handleChange" :on-exceed="exceedimg" class="uploadlist" :data="filedata"
                        :headers="fileheaders" ref="customerdoorupload" :before-upload="beforeUploadXin"
                        :on-success="(res, file) => { handleAvatarSuccess(res, file) }">

                        <div slot="file" slot-scope="{ file }">
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span v-if="!disabled" class="el-upload-list__item-delete" @click="deletepic(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>

                        <div class="el-upload__text">
                            <img class="scimg" src="../assets/submit/upload.png" />
                            <p style="font-size: 12px; margin: 0">上传</p>
                        </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </div>
            </div>
            <div class="textClass">此文件必须上传</div>
        </div>


        <div class="corp_nav">
            <div class="corp_nav_left">
                <div class="title"><span style="color:red">*</span> 变更表——商户盖章即可</div>
                <div class="text">
                    <el-button type="text" icon="el-icon-download" @click="downloadTemplate(1)">模板下载
                    </el-button>
                    <el-upload 
                    :action="uploadurl" list-type="picture-card" accept="image/jpg,image/jpeg,image/png"
                        :auto-upload="true" :on-remove="handleRemoveXin" :on-progress="handleProgressXin"
                        :on-change="handleChangeXin" :on-exceed="exceedimgXin" class="uploadlist" :data="filedataXin"
                        :headers="fileheaders" ref="customerdooruploadXin" :before-upload="beforeUploadXin"
                        :on-success="(res, file) => { handleAvatarSuccessXin(res, file) }">

                        <div slot="file" slot-scope="{ file }">
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span v-if="!disabled" class="el-upload-list__item-delete" @click="deletepicXin(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>

                        <div class="el-upload__text">
                            <img class="scimg" src="../assets/submit/upload.png" />
                            <p style="font-size: 12px; margin: 0">上传</p>
                        </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </div>
            </div>
            <div class="textClass">此文件必须上传</div>
        </div>



    </div>
</template>

<script>
import Cookies from "js-cookie";
import config from "../request/config"
export default {
    name: "corpFile",
    data() {
        return {
            disabled: false,
            filedata: {
                ctrl: "file",
                func: "save",
                type: "file",
                serviceFileName: "银联商户信息变更单",
            },
            filedataXin: {
                ctrl: "file",
                func: "save",
                type: "file",
                serviceFileName: "变更表——商户盖章即可",
            },
            fileheaders: {
                token: Cookies.get("tooken"),
            },
            uploadurl: config.baseUrl + "/index.php/api/index",
            dialogImageUrl: "",
            dialogVisible: false,
        }
    },
    mounted() {
    },
    methods: {
        handleAvatarSuccess(res) {
            if (res.code == 200) {
                console.log(res.data.file_id);
                this.$emit("chinaumsFile",res.data.file_id)
            }
            if (res.code == 401) {
                setTimeout(function () {
                    this.$router.push({
                        name: "login"
                    });
                }, 2000);
            }
        },
        handleAvatarSuccessXin(res) {
            if (res.code == 200) {
                console.log(res.data.file_id);
                this.$emit("ysepayFile",res.data.file_id)
            }
            if (res.code == 401) {
                setTimeout(function () {
                    this.$router.push({
                        name: "login"
                    });
                }, 2000);
            }
        },
        downloadTemplate(type) {
            const link = document.createElement('a')
            link.style.display = 'none'
            console.log("看看type是几", type)
            if (type == 1) {
                link.href = config.baseUrl + '/public/static/file/变更表--商户盖章即可.docx'
            }
            if (type == 2) {
                link.href = config.baseUrl + '/public/static/file/银盛商户信息变更单.docx'
            }
            document.body.appendChild(link)
            link.click()
        },
        chakan(file) {
            if (file && file.file_path) {
                this.dialogImageUrl = config.baseUrl + file.file_path;
                this.dialogVisible = true;
            }
        },
        handleRemove(file, filelist) {
            this.formlist['balance_file'] = filelist
            this.formlistXin['balance_file'] = filelist

        },
        handleRemoveXin(file, filelist) {
            this.formlist['examile_file'] = filelist
            this.formlistXin['examile_file'] = filelist

        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        deletepic(file) {
            let uploadFiles = this.$refs.customerdoorupload.uploadFiles
            console.log(file, uploadFiles);

            for (var i = 0; i < uploadFiles.length; i++) {
                if (uploadFiles[i]['url'] == file.url) {
                    uploadFiles.splice(i, 1)
                }
            }
            this.hideUpload = false
        },
        deletepicXin(file, fileList) {
            console.log(file, fileList);

            let uploadFiles = this.$refs.customerdooruploadXin.uploadFiles
            for (var i = 0; i < uploadFiles.length; i++) {
                if (uploadFiles[i]['url'] == file.url) {
                    uploadFiles.splice(i, 1)
                }
            }
            this.hideUpload = false
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/.el-upload--picture-card {
    /*  background-color: transparent; */
    border: none;
    width: 90px;
    height: 90px;
}

/deep/.el-upload__text {
    line-height: 1;
}

/deep/.el-upload-list--picture-card{
   display: flex;
   align-items: center;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 70px;
    height: 70px;
    margin: 10px ;
}

.corp {
    .corp_nav {
        border: 1px solid rgba(168, 188, 235, 1);
        border-bottom: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 400;

        .corp_nav_left {
            display: flex;
            align-items: center;
        }

        .title {
            width: 200px;
            padding: 0 40px 0 80px;
        }

        .text {
            display: flex;
            align-items: center;
            color: #999999;
            margin-right: 12px;
        }

        .scimg {
            width: 26px;
            height: 26px;
            padding-top: 20px;
        }

        .uploadlist {
            // width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .textClass {
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 160px;
        }
    }
}
</style>