
/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import { Message } from 'element-ui';
import Cookies from 'js-cookie';
import QS from 'qs';


// 环境的切换
// axios.defaults.baseURL = '/api';
//axios.defaults.baseURL = 'http://crm.odd-job.ren/index.php/'//测试
 axios.defaults.baseURL = 'https://nscrm.365-job.com/index.php/'//正式
// axios.defaults.baseURL = 'https://nscrm.odd-job.net/index.php/'//正式2022-2-23注释



// 请求超时时间
axios.defaults.timeout = 90000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = Cookies.get('tooken');
        if (token) {
            // config.params = { 'token': token } //如果要求携带在参数中
            config.headers.token = token; //如果要求携带在请求头中
        }
        return config;
    },
    error => {
        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        /***** 接收到异常响应的处理开始 *****/
        if (error && error.response) {
            // 1.公共错误处理
            // 2.根据响应码具体处理
            switch (error.response.status) {
                case 400:
                    error.message = '错误请求'
                    break;
                case 401:
                    error.message = '未授权，请重新登录'
                    break;
                case 403:
                    error.message = '拒绝访问'
                    break;
                case 404:
                    error.message = '请求错误,未找到该资源'
                    window.location.href = "/NotFound"
                    break;
                case 405:
                    error.message = '请求方法未允许'
                    break;
                case 408:
                    error.message = '请求超时'
                    break;
                case 500:
                    error.message = '服务器端出错'
                    break;
                case 501:
                    error.message = '网络未实现'
                    break;
                case 502:
                    error.message = '网络错误'
                    break;
                case 503:
                    error.message = '服务不可用'
                    break;
                case 504:
                    error.message = '网络超时'
                    break;
                case 505:
                    error.message = 'http版本不支持该请求'
                    break;
                default:
                    error.message = `连接错误${error.response.status}`
            }
        } else {
            // 超时处理
            if (JSON.stringify(error).includes('timeout')) {
                Message.error('服务器响应超时，请刷新当前页')
            }
            error.message = '连接服务器失败'
        }

        // Message.()
        Message({
            showClose: true,
            duration:2000,
            type: 'error',
            message: error.message,
            offset:250
          })
        /***** 处理结束 *****/
        //如果不需要错误处理，以上的处理过程都可省略
        return Promise.resolve(error.response)
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        const customerid = Cookies.get('customerid');
        if (customerid) {
            params.customer_id = customerid //如果要求携带在参数中
        }
        axios.post(url, QS.stringify(params))
            .then(res => {
                if (res.data.code != 200&&res.data.code != 202) {
                    Message({
                        message:res.data.error,
                        offset:250,
                        type:'error'
                    })
                    if (res.data.code == 401) {
                        Cookies.remove("tooken");
                        Cookies.remove("customerList");
                        Cookies.remove("username");
                        Cookies.remove("headimg");
                        Cookies.remove('customerid');
                        Cookies.remove('code');
                    }
                }
                resolve(res.data);

            })
            .catch(err => {
                reject(err.data)
            })
    });
}
