<template>
  <div>
    <!-- <el-button class="backbut" @click="goback">返回</el-button> -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="进件流程" name="first"></el-tab-pane>
      <el-tab-pane label="开票申请流程" name="second"></el-tab-pane>
    </el-tabs>
	
    <el-form ref="form" :inline="true" :model="form">
      <div class="formtop">
        <div class="formtop-title">筛选</div>
          <el-form-item label="客户名称">
            <el-input
              v-model="form.name"
              style="width: 150px"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item label="申请支付方式" v-if="activeName == 'first'">
            <el-input
              v-model="form.type"
              style="width: 150px"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item label="申请人">
            <el-input
              v-model="form.type"
              style="width: 150px"
              placeholder="请输入关键字"
            ></el-input>
            </el-form-item>
            <el-form-item label="申请日期">
              <el-date-picker
                v-model="dateValue"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="form.status"
                style="width: 120px"
                placeholder="请选择"
                clearable
              >
                <el-option label="审核中" value="1"></el-option>
                <el-option label="审核成功" value="2"></el-option>
                <el-option label="审核失败" value="-1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button class="chaxunbutton" @click="getpage(activetabname);"
                >查询</el-button
              >
              <el-button class="clearbutton" @click="agin"
                >重置</el-button
              >
            </el-form-item>
      </div>
    </el-form>
	
    <vxe-table border :data="tableData" :loading="loading3" :header-cell-style="{background:'#1373C2',color:'white'}"
          height="600">
      <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
      <vxe-table-column
        :field="item.columnIndex"
        :title="item.name"
        show-overflow
        v-for="(item,index) in listheadarray"
        :key="index"
      ></vxe-table-column>
       <vxe-table-column title="操作" width="180">
         <template #default="{ row }">
           <template>
             <vxe-button @click="editRowEvent(row)" style="background-color: #1373C2;color:#fff">查看</vxe-button>
             <vxe-button style="background-color: #1373C2;color:#fff" v-if="row[wfId_key]>0" @click="showWorkFlowLog(row[wfId_key])">流程日志</vxe-button>
             <!-- <vxe-button style="background-color: #1373C2;color:#fff" v-if="activeName == 'second' && row[wfId_key]>0" @click="showWorkFlowLog(row[wfId_key])">流程日志</vxe-button> -->
           </template>
         </template>
       </vxe-table-column>
    </vxe-table>
    <vxe-pager
      background
      size="small"
      :loading="loading3"
      :current-page="tablePage3.currentPage"
      :page-size="tablePage3.pageSize"
      :total="tablePage3.totalResult"
      :page-sizes="[
        10,
        25,
        50,
        100,
      ]"
      layout="sizes, prev, pager, next"
      align="center"
      @page-change="handlePageChange3"
    >
    </vxe-pager>
    <workflowlog :dialog-visible="wfDialogVisible" :wf-id="wfId" @close="wfDialogVisible=false"/>
  </div>
</template>

<script>
import { getproduct } from "../../request/api";
import workflowlog from '@/components/WorkFlowLog'
export default {
  data() {
    return {
      form: {},
      dateValue:'',
      tableData: [],
      listheadarray: [],
      activeName: "first",
      tablePage3: {
        currentPage: 1,
        pageSize:10,
        totalResult: 0,
      },
      activetabname:"进件流程",
      key:false,
      wfId_key:false,
      wfId: '0',
      wfDialogVisible: false,
      btnwidth:120
    };
  },
  components:{
    workflowlog
  },
  mounted() {
    if(this.$route.query.status == 1){
         this.activeName = 'second';
    }
    this.getpage("进件流程");
  },
  methods: {
    showWorkFlowLog(wfId) {
      this.wfId = wfId
      this.wfDialogVisible = true
      console.log(wfId)
    },
    dateChange(value){
        console.log(value)
        if(value){
          this.form.start_date = value[0]
          this.form.end_date = value[1]
        }else{
          this.$delete(this.form,'start_date')
          this.$delete(this.form,'end_date')
        }
    },
    agin() {
        this.form = {};
    },
    handleClick(tab) {
      this.activetabname=tab.label
      this.btnwidth = this.btnwidth == 120 ? 180 : 120;
      this.tablePage3.currentPage = 1;
      this.agin();
      this.getpage(tab.label);
	
    },
    goback() {
      this.$router.push({ name: "product" });
    },
    getpage(lable) {
      // var data = {
      //   ctrl: "indexExamine",
      //   func: "completelist",
      //   wfname: lable,
      //   page: this.tablePage3.currentPage,
      //   pageSize: this.tablePage3.pageSize
      // };
      this.loading3 = true;
      var data = {};
      if (this.form) {
        data = this.form;
      }
      data.ctrl = "indexExamine";
      data.func = "completelist";
      data.wfname = lable;
      data.page = this.tablePage3.currentPage;
      data.pageSize = this.tablePage3.pageSize;
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.loading3 = false;
          // var list= res.data.list
          this.listheadarray = res.data.column;
          // this.tablePage3.totalResult = list.length;
          // this.tableData = list.slice(
          //   (this.tablePage3.currentPage - 1) * this.tablePage3.pageSize,
          //   this.tablePage3.currentPage * this.tablePage3.pageSize
          // );
          this.tablePage3.totalResult = res.data.dataCount;
          this.tableData = res.data.list
          this.key = res.data.key
          this.wfId_key = res.data.wfId_key
        }
      });
    },
    handlePageChange3({ currentPage, pageSize }) {
      this.tablePage3.currentPage = currentPage;
      this.tablePage3.pageSize = pageSize;
      this.getpage(this.activetabname);
    },
	editRowEvent(row) {
		var id = row[this.key];
		
		if(this.activeName=='first'){
			this.$router.push({
				name: "recordDetail",
				query: { payinfoid: id, status: 1 },
			});
			return;
		}
		
		if(this.activeName=='second'){
			this.$router.push({ name: "billdetail", params: { choseid: id } });
			return;
		}
	},
  },
};
</script>

<style lang="scss" scoped>
.backbut {
  margin-bottom: 20px;
}
.el-input {
  width: 305px;
}
.formtop {
  background: #fff;
  padding: 20px 0;
  margin-bottom: 10px;
  }
  .formtop-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-left: 3px solid #315bd2;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .chaxunbutton {
    width: 96px;
    background: #3d7fff;
    border-radius: 3px;
    text-align: center;
    color: #fff;
  }
  .clearbutton {
    width: 96px;
    background: #f0f2f5;
    border-radius: 3px;
    border: 1px solid #d7dbe0;
    text-align: center;
    color: #000;
  }
</style>