<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>账户管理</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="managementDiv" v-if="registerStatus">
			<!-- v-if="false" -->
			<!-- <div class="managementDivTitleDiv">
				账户管理
			</div> -->
			<div class="managementDiv-sonDiv">
				<div class="managementDiv-companyTitleDiv maginTopDiv" style="margin-bottom: 20px;"
					v-if="statusError||statusData">
					<div class="statusDiv">
						<span class="managementDiv-companyTitleDiv-nameDiv" style="margin-left:30px;">状态：</span>

						<span style="margin-left: 35px;color:red" v-if="statusError">{{statusError}}</span>

						<span style="margin-left: 35px;color:green"
							v-if="statusData.verifiedStatusDesc=='审核通过'">{{statusData.verifiedStatusDesc}}</span>
						<span style="margin-left: 35px;color:blue"
							v-if="statusData.verifiedStatusDesc!='审核通过'">{{statusData.verifiedStatusDesc}}</span>
						<el-button style="margin-left: 35px;" v-if="isShowContinue" type="primary" size="small" @click="continueCer()">
							继续认证
						</el-button>
						<span style="margin-left: 35px;color:red"
							v-if="isShowContinue">（可点击继续认证完成剩余步骤）</span>
					</div>
				</div>
				<div class="managementDiv-companyTitleDiv maginTopDiv" style="margin-bottom: 20px;" v-else>
					<div class="managementDiv-companyTitleDiv-nameDiv" style="margin-left:30px;">状态：</div>
					<!-- <el-input placeholder="请填写注册公司名称" disabled="disabled" v-model="customername"
						class="managementDiv-companyTitleDiv-input"></el-input> -->
					<span style="margin-left: 35px;color:red">认证已通过</span>
				</div>
				<div class="manssssssagementDiv-companyTitleDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">公司名称：</div>
					<el-input placeholder="请填写公司名称" v-model="customername" disabled="disabled"
						class="managementDiv-companyTitleDiv-input"></el-input>
				</div>
				<div class="managementDiv-registerStatusDiv">
					<div class="managementDiv-registerStatusDiv-statusDiv">
						注册状态：
					</div>
					<div class="managementDiv-registerStatusDiv-statusDivTwo">
						<span>已注册</span>
						<span @click="registerStatusFalse">重新认证</span>
					</div>
				</div>
				<!-- <div class="registerButtonDiv">
					注册
				</div> -->
			</div>
		</div>
		<div class="managementDivTwoDiv" v-else>
			<!-- <div class="managementDivTitleDiv">
				账户管理
			</div> -->
			<div class="managementDiv-sonDivTwoDiv">
				<!-- 	<div class="managementDiv-companyTitleDiv maginTopDiv" v-if="statusError||statusData">
					<div class="managementDiv-companyTitleDiv-nameDiv" style="margin-left:65px">状态：</div>
					<span style="margin-left: 35px;color:red">{{statusError}}{{statusData}}</span>
				</div>
				<div class="managementDiv-companyTitleDiv maginTopDiv" v-else>
					<div class="managementDiv-companyTitleDiv-nameDiv" style="margin-left:65px">状态：</div>
					<span style="margin-left: 35px;color:red">认证已通过</span>
				</div> -->

				<div class="statusDiv">
					<span class="managementDiv-companyTitleDiv-nameDiv" style="margin-left:60px;">状态：</span>

					<span style="margin-left: 35px;color:red" v-if="statusError">{{statusError}}</span>

					<span style="margin-left: 35px;color:green"
						v-if="statusData.verifiedStatusDesc=='审核通过'">{{statusData.verifiedStatusDesc}}</span>

					<span style="margin-left: 35px;color:blue"
						v-if="statusData.verifiedStatusDesc!='审核通过'">{{statusData.verifiedStatusDesc}}</span>
					<el-button style="margin-left: 35px;" v-if="isShowContinue" type="primary" size="small" @click="continueCer()">
						继续认证
					</el-button>
					<span style="margin-left: 15px;color:red;font-size:14px"
							v-if="isShowContinue">（可点击继续认证完成剩余步骤）</span>
				</div>
				<div class="managementDiv-companyTitleDiv maginTopDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">注册公司名称：</div>
					<el-input placeholder="请填写注册公司名称" disabled="disabled" v-model="customername"
						class="managementDiv-companyTitleDiv-input"></el-input>
				</div>
				<div class="managementDiv-companyTitleDiv marginLeftDiv maginTopDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">企业实名认证套餐类型：</div>
					<!-- <el-input placeholder="辽宁北软测试" class="managementDiv-companyTitleDiv-input"></el-input> -->
					<el-select class="managementDiv-companyTitleDiv-input" v-model="VerifiedWayValue"
						@change="optionsChange" :disabled="registerStatusDisabled">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<!-- CompanyPrincipalTypeKey -->
				<div class="managementDiv-companyTitleDiv maginTopDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">企业认证类型：</div>
					<!-- <el-input placeholder="辽宁北软测试" class="managementDiv-companyTitleDiv-input"></el-input> -->
					<el-select v-model="VerifiedWayValueTwo" class="managementDiv-companyTitleDiv-input"
						@change="optionsTwoChange" :disabled="registerStatusDisabled">
						<el-option v-for="item in optionsTwo" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<el-button class="registerButtonDiv" @click="registerButtonDivFun" v-if="registerButtonDivFunShow">
					<p v-if="isShowContinue">重新认证</p>
					<p v-else>开始认证</p>
				</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	// import { getproduct } from "../../request/api";
	import {
		getCompanyList
	} from "../../request/api";
	export default {

		data() {
			return {
				// :key="item.value" :label="item.label" :value="item.value"
				isShowContinue:false,
				continueUrl :'',
				registerStatus: false,
				registerButtonDivFunShow: false,
				registerStatusDisabled: false,
				optionsTwo: [{
					label: "法人",
					value: "1"
				}, {
					label: "代理人",
					value: "2"
				}],
				VerifiedWayValue: "",
				VerifiedWayValueTwo: "",
				CompanyPrincipalType: "",
				VerifiedWay: "",
				customername: "",
				statusError: "",
				statusData: "",
				VerifiedWayKey: "",
				CompanyPrincipalTypeKey: "",
				options: [{
						label: "标准方案(对公打款+纸质审核)",
						value: "0"
					}, {
						label: "对公打款",
						value: "1"
					},
					{
						label: "纸质审核",
						value: "2"
					}
				]
			};
		},
		components: {},
		mounted() {
			var data = {
				func: "QueryEnterpriseAuthenticationStatus",
				ctrl: "FadadaApi",
				customer_id: this.customerid,
			};
			getCompanyList(data).then((res) => {
				// res.data =""
				// res.error =""
				// res.error = '审核不通过'
				if (res.code == 200) {
					console.log("看看错误返回值", res)
					this.statusData = res.data
					if (res.data.verifiedStatusDesc == "审核通过") {
						this.statusData.verifiedStatusDesc = '审核通过'
						this.registerStatus = true //账号已经认证
						this.registerButtonDivFunShow = false
						this.registerStatusDisabled = true
					} else if (res.data.errmsg == "账户未认证") {
						this.statusData.verifiedStatusDesc = '账户未认证'
						this.registerStatus = false //账号未认证
						this.registerButtonDivFunShow = true
						this.registerStatusDisabled = false

					} else if (res.data.errmsg == "审核不通过") {
						this.statusData.verifiedStatusDesc = '审核不通过'
						this.registerStatus = true //账号未认证
						this.registerButtonDivFunShow = true
						this.registerStatusDisabled = false

					} else {
						this.statusData.verifiedStatusDesc = res.data.errmsg
						this.registerStatus = false
						this.registerButtonDivFunShow = true
						this.registerStatusDisabled = true
					}


				} else if (res.code == 400) {
					console.log("看看错误返回值", res)
					this.registerStatus = true
					this.statusError = res.error
					if (res.data.verifiedStatusDesc == "审核通过") {

						this.statusData.verifiedStatusDesc = '审核通过'
						this.registerStatus = true //账号已经认证
						this.registerButtonDivFunShow = false
						this.registerStatusDisabled = true

					} else if (res.data.errmsg == "账户未认证") {
						console.log("没走到这里吗账号未认证")
						this.statusData.verifiedStatusDesc = '账户未认证'
						this.registerStatus = false //账号未认证
						this.registerButtonDivFunShow = true
						this.registerStatusDisabled = false

					} else if (res.data.errmsg == "审核不通过") {
						this.statusData.verifiedStatusDesc = '审核不通过'
						this.registerStatus = true //账号未认证
						this.registerButtonDivFunShow = true
						this.registerStatusDisabled = false

					} else {
						this.statusData.verifiedStatusDesc = res.data.errmsg
						this.registerStatus = false
						this.registerButtonDivFunShow = false
						this.registerStatusDisabled = true
					}

				}

				// 继续认证按钮是否显示
				if(res.data.errobj.url){
					this.isShowContinue = true;
					this.continueUrl = res.data.errobj.url;
					this.VerifiedWay = res.data.errobj.VerifiedWay;
					this.VerifiedWayValue = this.options[this.VerifiedWay].label

					this.CompanyPrincipalType = res.data.errobj.CompanyPrincipalType;
					this.VerifiedWayValueTwo = this.optionsTwo[this.CompanyPrincipalType-1].label;
				}
			})
		},
		created() {
			this.customername = this.getCookie("customername")
			this.customerid = this.getCookie("customerid")

		},
		methods: {
			continueCer() {
				// 继续认证，跳页
				if(this.continueUrl){
					// window.open(this.continueUrl);
					window.location.href = this.continueUrl;
				}
			},
			registerStatusFalse() {
				this.registerStatus = false
				this.registerButtonDivFunShow = true
				this.registerStatusDisabled = false
			},
			optionsChange(val) {
				// 企业实名认证套餐类型
				console.log("选择的值", val)
				this.VerifiedWay = val
				// this.VerifiedWayKey = this.options.indexOf(val)
				// console.log("key", this.options.indexOf(val));
				// this.VerifiedWayValue = this.options[this.VerifiedWayKey].label
				// console.log("VerifiedWayValue", this.options[this.VerifiedWayKey].label);
				// 企业实名认证套餐类型
			},
			optionsTwoChange(val) {
				console.log("two选择的值", val)
				//企业认证类型
				this.CompanyPrincipalType = val
				// this.CompanyPrincipalTypeKey = this.optionsTwo.indexOf(val)
				// this.VerifiedWayValueTwo = this.optionsTwo[this.CompanyPrincipalTypeKey].label
				//企业认证类型
			},
			registerButtonDivFun() {
				if (!this.VerifiedWay) {
					this.$message({
						message: '企业实名认证套餐类型不能为空',
						type: 'error'
					});
					return
				}

				if (!this.CompanyPrincipalType) {
					this.$message({
						message: '企业认证类型不能为空',
						type: 'error'
					});
					return
				}

				var data = {
					func: "EnterpriseRegisteAndAuthentication",
					ctrl: "FadadaApi",
					customer_id: this.customerid,
					CompanyPrincipalType: this.CompanyPrincipalType,
					VerifiedWay: this.VerifiedWay,
					ReturnUrl: "http://customer.odd-job.ren/#/company/notCertified"
				};
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// // this.ifused = res.data;
						// this.list=res.data
						// this.$message({
						// 	message: '认证成功',
						// 	type: 'success'
						// });
						// this.registerStatus=true
						window.location.href = res.data.url
						console.log("看看返回值", res)



					}
					// } else {
					// 	this.$message({
					// 		message: '认证失败',
					// 		type: 'error'
					// 	});
					// }
				})
			}
		},
	};
</script>
<style lang='scss' scoped>
	.breadcrumbClass {
		height: 39px;
		width: 100%;
		margin-top: 8px;
		border-bottom: 1px solid #F0F1F5;
	}

	.managementDiv {
		/* width: 677px; */
		/* height: 331px; */
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		margin: 0 auto;
		margin-top: 76px;
	}

	.managementDivTwoDiv {
		/* width: 773px; */
		/* height: 331px; */
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		margin: 0 auto;
		margin-top: 76px;
	}

	.managementDiv-sonDiv {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-top: 60px;
	}

	.managementDivTitleDiv {
		font-size: 20px;
		font-weight: bold;
		color: #0C0C0C;
		line-height: 30px;
		/* line-height: 30px; */
	}

	.managementDiv-companyTitleDiv {
		/* width: 677px; */
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: auto;
	}

	.managementDiv-companyTitleDiv-nameDiv {
		font-size: 16px;
		font-weight: bold;
		color: #000000;
	}

	.managementDiv-companyTitleDiv-input {
		width: 573px;
		margin-left: 35px;
	}

	.managementDiv-registerStatusDiv {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		width: 677px;
		height: 40px;
		margin-top: 30px;
	}

	.managementDiv-registerStatusDiv-statusDiv {
		font-size: 16px;
		font-weight: bold;
		color: #000000;
	}

	.managementDiv-registerStatusDiv-statusDivTwo {}

	.registerButtonDiv {
		width: 359px;
		height: 49px;
		background: linear-gradient(90deg, #0683EB, #1373C2);
		border-radius: 25px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: bold;
		color: #F6F6F6;
		/* line-height: 30px; */
		margin-top: 101px;
	}

	.managementDiv-registerStatusDiv-statusDivTwo span:nth-child(1) {
		font-size: 16px;
		font-weight: bold;
		color: #000000;
		margin-left: 35px;
	}

	.managementDiv-registerStatusDiv-statusDivTwo span:nth-child(2) {
		font-size: 16px;
		font-weight: bold;
		color: #0683EA;
		margin-left: 10px;
	}

	.managementDiv-sonDivTwoDiv {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-top: 60px;
	}

	.marginLeftDiv {
		margin-left: -65px;
	}

	.maginTopDiv {
		margin-top: 33px;
	}

	.statusDiv {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.manssssssagementDiv-companyTitleDiv {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
</style>
