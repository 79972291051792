<template>

	<div style="height:auto;margin-top: 94px;background: #fff;">
		<headtop :activeIndex="activeIndex" :title="titlename" :ifxianshi="false"></headtop>

		<div class='title-div'>更新法人相关信息</div>
		<div class='content-div'>
			<!-- <div>1.缺少<span>供热/供水许可证、法人身份证（正面）、法人身份证（反面）、营业执照</span>四项资质 </div>
			<div>2.<span>组织结构代码证</span>不合规范</div> -->
			<!-- <div v-if="dataValue.apply_status==2">审核成功</div> -->
			<!-- <div v-if="dataValue.apply_status==1">审核中</div>
			<div v-if="dataValue.apply_status==0">未申请</div>
			<div v-if="dataValue.apply_status==-1">审核失败</div>
			<div v-if="dataValue.apply_status==-1"><span>{{status.apply_message}}</span></div> -->


			<div v-if="status.apply_status==1">有未完成的申请正在审核中</div>
			<!-- <div v-if="dataValue.apply_status==0">未申请</div> -->
			<!-- <div v-if="dataValue.apply_status==-1">审核失败</div> -->
			<div v-if="status.apply_status==-1">审核失败：<span>{{status.apply_message}}</span></div>
		</div>



		<el-form :rules="rule" :model="dataValue" ref="formlistRef">
			
			
			<div class="table-div">
				<el-form-item prop="legal_id_code" >
					<div class='table-div-item'>
						<div class='table-div-item-label'>法人身份证号码</div>
						<el-input placeholder="请输入法人身份证号码"  v-model="dataValue.legal_id_code"></el-input>
					</div>
				</el-form-item>
				
				<div class='table-div-item'>
					<div class='table-div-item-label'>法人身份证姓名</div>
					<el-input placeholder="请输入法人身份证姓名" v-model="dataValue.legal_name"></el-input>
				</div>
				<div class='table-div-item'>
					<div class='table-div-item-label'>法人证件有效期</div>
					<el-date-picker v-model="dataValue.legal_id_valid" type="date" placeholder="选择日期"
						format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
					<el-checkbox v-model="dataValue.legal_id_valid_check" class='checkbox'>长期有效</el-checkbox>
				
				
				</div>
				<div class='table-div-item'>
					<div class='table-div-item-label'>法人身份证人像面照片</div>
					
					<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
						:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'法人身份证人像面照片')}"
						:on-success=" (res, file) => { handleAvatarSuccess( 'legal_idfront_file','legal_idfront_fileinfo', res,file ); }"
						:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
						ref="legal_idfront_file" :file-list="legal_idfront_fileinfo">
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span class="el-upload-list__item-delete"
									@click="deletepic('legal_idfront_file','legal_idfront_file')">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>

					</el-upload>
					<div class="fileFormat">原件手机拍照或者彩色扫描,扫描件需要加盖公司公章<br/>文件格式为bmp、png、jpeg、jpg或gif</div>
				</div>


				<div class='table-div-item'>
					<div class='table-div-item-label'>法人身份证国徽面照片</div>
					
					<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
						:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'法人身份证国徽面照片')}"
						:on-success=" (res, file) => { handleAvatarSuccess( 'legal_idback_file','legal_idback_fileinfo', res,file ); }"
						:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
						ref="legal_idback_file" :file-list="legal_idback_file">
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span class="el-upload-list__item-delete"
									@click="deletepic('legal_idback_file','legal_idback_file')">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>

					</el-upload>
					<div class="fileFormat">原件手机拍照或者彩色扫描,扫描件需要加盖公司公章<br/>文件格式为bmp、png、jpeg、jpg或gif</div>
				</div>


				


				<div class='table-div-item'>
					<div class='table-div-item-label'>
						营业执照
					</div>
					
					<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
						:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'营业执照')}"
						:on-success=" (res, file) => { handleAvatarSuccess( 'business_licence_file','business_licence_fileinfo', res,file ); }"
						:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
						ref="business_licence_file" :file-list="business_licence_fileinfo">
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span class="el-upload-list__item-delete"
									@click="deletepic('business_licence_file','business_licence_file')">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>

					</el-upload>
					<div class="fileFormat">原件手机拍照或者彩色扫描，且确保最新年检章信息，字迹清晰。<br/>文件格式为bmp、png、jpeg、jpg或gif</div>
					
				</div>


				<div class='table-div-item'>
					<div class='table-div-item-label'>
						开户许可证</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
						:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'开户许可证')}"
						:on-success=" (res, file) => { handleAvatarSuccess( 'bank_permit_file','bank_permit_fileinfo', res,file ); }"
						:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
						ref="bank_permit_file" :file-list="bank_permit_fileinfo">
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span class="el-upload-list__item-delete"
									@click="deletepic('bank_permit_file','bank_permit_file')">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>

					</el-upload>
					<div class="fileFormat">原件手机拍照或者彩色扫描，且确保最新年检章信息。<br/>文件格式为bmp、png、jpeg、jpg或gif</div>
					
				</div>

			</div>
		</el-form>
		<!-- v-if='dataValue.apply_status!=1' -->
		<el-button class='submit' @click="submitFun" :disabled="disabled" v-if="status.apply_status!=1"
			:loading="loading">提交</el-button>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>


	</div>


</template>

<script>
	import Cookies from "js-cookie";
	import config from "../request/config";
	import {
		getproduct,
		// submitMain
	} from "../request/api";


	import headtop from "../components/moneyHeader";

	export default {
		name: "Resgin",
		components: {
			headtop,
		},
		data() {
			return {
				customer_id: "",
				disabled: false,
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				rule: {


					legal_id_code: [{
							required: true,
							message: "身份证号码不能为空",
							trigger: "blur",
						},
						{
							validator: this.idcard,
							trigger: ["blur", "change"]
						},

					],

				},


				status: "",
				loading: false,
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				uploadurl: config.baseUrl + "/index.php/api/index",
				dialogImageUrl: "",
				dialogVisible: false,
				value1: "",
				dataValue: "",
				legal_idfront_fileinfo: [], //身份证正面
				legal_idback_file: [], //身份证反面
				business_licence_fileinfo: [], //营业执照
				bank_permit_fileinfo: [], //开户许可证
			}
		},
		mounted() {

			this.getStatus()
			console.log("截止不对是不", this.getCookie("customerid_xin"))




			getproduct({
				ctrl: "payinfo",
				func: "personInfo",
				real_customer_id: this.getCookie("customerid_xin")
			}).then((res) => {
				if (res.code == 200) {
					console.log(res)

					this.dataValue = res.data
					if (this.dataValue.legal_idfront_fileinfo.file_path_thumb) {
						this.legal_idfront_fileinfo.push({
							'url': config.baseUrl + this.dataValue.legal_idfront_fileinfo.file_path_thumb
						})
					}
					if (this.dataValue.legal_idback_fileinfo.file_path_thumb) {
						this.legal_idback_file.push({
							'url': config.baseUrl + this.dataValue.legal_idback_fileinfo.file_path_thumb
						})
					}
					if (this.dataValue.business_licence_fileinfo.file_path_thumb) {
						this.business_licence_fileinfo.push({
							'url': config.baseUrl + this.dataValue.business_licence_fileinfo
								.file_path_thumb
						})
					}
					if (this.dataValue.bank_permit_fileinfo.file_path_thumb) {
						this.bank_permit_fileinfo.push({
							'url': config.baseUrl + this.dataValue.bank_permit_fileinfo.file_path_thumb
						})
					}

				}
			});
		},
		methods: {
			idcard(rule, value, callback) {
				// console.log("rule", rule)
				// console.log("value", value)
				// console.log("callback", callback)

				// 法人身份证号码legal_id_code



				if (!value) {
					if (rule.field == "legal_id_code") {
						return callback(new Error("身份证号码不能为空"));
					} else {
						return;
					}
				} else if (!/^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/.test(value) && !
					/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
						value)) {
					callback(new Error('身份证号不正确！'));
				} else {
					callback()
				}
				//}, 1000);
			},
			getStatus() {
				getproduct({
					ctrl: "payinfo",
					func: "personInfo_status",
					real_customer_id: this.getCookie("customerid_xin")
				}).then((res) => {
					if (res.code == 200) {
						this.status = res.data
					}
				});
			},
			showImg(file) {
				if (file && file.file_path) {
					return config.baseUrl + file.file_path;
				}
			},
			deletepic(ref, info) {
				this.dataValue[info] = ''
				this.$refs[ref].clearFiles()
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleAvatarSuccess(type, typetwo, res) {
				this.dataValue[typetwo] = "";
				// :on-success=" (res, file) => { handleAvatarSuccess( 'legal_idfront_file','legal_idfront_fileinfo', res,file ); }"
				if (res.code == 200) {
					console.log("为啥上传图片不对", res.data)
					this.dataValue[type] = res.data;

				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				this.$forceUpdate();
			},
			exceedimg() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});
			},
			submitFun() {

				this.$refs.formlistRef.validate((valid) => {

					if (valid) {
						this.loading = true
						console.log("为啥是看", this.dataValue)
						getproduct({
							ctrl: "payinfo",
							func: "personInfo_edit",
							real_customer_id: this.getCookie("customerid_xin"),
							legal_idfront_file: this.dataValue['legal_idfront_file'], //法人身份证人像面
							legal_idback_file: this.dataValue['legal_idback_file'], //法人身份证国徽面
							business_licence_file: this.dataValue['business_licence_file'], //营业执照
							legal_name: this.dataValue.legal_name, //法人身份证姓名
							legal_id_code: this.dataValue.legal_id_code, //法人身份证号
							base_id: this.dataValue.base_id, //自查法人接口返回值里有
							bank_permit_file: this.dataValue['bank_permit_file'], //开户许可证
							legal_id_valid_check: this.dataValue.legal_id_valid_check, //是否长期有效  true/false
							legal_id_valid: this.dataValue.legal_id_valid //身份证有效期



						}).then((res) => {
							if (res.code == 200) {
								this.disabled = true
								console.log(res)
								this.loading = false
								this.$message({
									type: "success",
									message: "提交成功",
									offset: 120,
								});
								this.getStatus()

							} else {
								this.disabled = false
								this.loading = false
							}
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				})

			},
			beforeUpload(file, fileName) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				const whiteList = ["bmp", "png", "jpeg", "jpg", "gif"];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message({
						type: "warning",
						message: "格式不正确，请重新上传",
						offset: 120,
					});
					return false;
				}
				
				const isLt10M = file.size / 1024 / 1024 < 2
				if (!isLt10M) {
					
					
					this.$message({
						type: "error",
						message: "上传文件大小不能超过 2MB!",
						offset: 120,
					});
					return false;
				}
				
				
				

				//   const isLt2M = file.size / 1024 / 1024 < 2;

				//   if (!isLt2M) {
				//     this.$message({
				//       type: "warning",
				//       message: "上传文件大小不能超过 2MB",
				//       offset: 120,
				//     });
				//     return false;
				//   }

				// 对应文件名称
				this.filedata['serviceFileName'] = fileName

				return true;
			}
		}

	}
</script>

<style lang="scss" scoped>
	/deep/.el-form-item__label {
		line-height: 74px !important;
	}

	/deep/.el-upload--picture-card {
		line-height: 74px !important;
		width: 78px;
		height: 78px;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.title-div {
		font-size: 30px;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		margin: 0 auto;
		text-align: center;
		padding-top: 59px;
	}

	.table-div {
		border: 1px solid #CFCFCF;
		margin: 0 auto;
		margin-top: 30px;
		width: 70%;
		margin-bottom: 149px;
	}

	.submit {
		width: 206px;
		height: 56px;
		background: #246DED;
		border-radius: 5px;
		margin: 0 auto;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 36px;
		border: none;
	}

	.table-div-item {
		border-bottom: 1px solid #CFCFCF;
		height: 116px;
		padding-left: 69px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-right: 75px;
		width:800px;

	}

	.table-div-item-label {
		margin-right: 75px;
		width: 200px;
	}

	/deep/ .el-input {
		width: auto;
	}

	.checkbox {
		margin-left: 46px;
	}

	.content-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: left;
		margin: 0 auto;
		margin-top: 49px;
		text-align: left;
		font-size: 20px;
		width: 70%;
		color: #F86060;
	}


	/deep/.el-upload--picture-card i {
		margin-top: 24px;
	}

	/deep/.el-form-item__error {
		top: 40%;
		left: 66%;
		width: 200px;
	}
	.fileFormat {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 36px;
		margin-left: 17px;
		width: 340px;
	}
	/deep/.el-input__inner{
		width: 250px;
	}
</style>
