<template>
	<div class="headertwo">
		<el-row :gutter="10">
			<el-col :span="8" style="display: flex; padding-top: 15px">
				<img src="../../assets/index/header1.png" class="logoimg" />
				<div class="title">热付通SAAS审核平台</div>
			</el-col>
			<el-col :span="8" :offset="8" class="flexright">
				<div class="userlogin">
					<span class="moneyDiv" @click="shouyeFun">首页</span>
					<el-avatar size="large" :src="circleUrl"></el-avatar>
					<span class="username">{{ username }}</span>
					<div class="xian"></div>
					<span class="userexit" @click="removecokie">退出</span>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		name: "headertwo",
		props: {
			activeIndex: String,
			ifxianshi: {
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				username: "用户",
			};
		},
		mounted() {
			if (this.getCookie("headimg")) {
				this.circleUrl = this.getCookie("headimg");
			}
			if (this.getCookie("username")) {
				this.username = this.getCookie("username");
			}
		},
		methods: {
			shouyeFun(){
				this.$router.push({
					path: "/product"
				});
			},
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
				if (key == "1") {
					this.$router.push({
						name: "home"
					});
				} else {
					this.$router.push({
						name: "records"
					});
				}
			},
			//退出登录
			removecokie() {
				this.removeCookie("tooken");
				this.removeCookie("customerList");
				this.removeCookie("username");
				this.removeCookie("headimg");
				this.removeCookie('customerid');
				this.removeCookie('code');
				this.$router.push({
					name: 'login'
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-menu.el-menu--horizontal {
		border: none;
	}

	/deep/.el-menu--horizontal>.el-menu-item {
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		margin: 0 20px;
		padding: 0;
	}

	.el-menu-demo {
		display: flex;
	}

	.headertwo {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: #ffffff;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
		z-index: 999999;
		height: 60px;
		padding: 0 20px;
	}

	.logoimg {
		width: 200px;
		height: 36px;
	}

	.title {
		font-size: 16px;
		font-weight: 500;
		color: #354E99;
		margin-left: 10px;
		padding-bottom: 3px;
		line-height: 36px;
		white-space: nowrap;
		font-weight: 600;
	}

	.flexright {
		display: flex;
		justify-content: flex-end;
		white-space: nowrap;

		.userlogin {
			display: flex;
			padding-top: 12px;
			margin-left: 15%;

			.username {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 37px;
				margin-left: 12px;
			}

			.xian {
				width: 1px;
				height: 20px;
				background: #d8d8d8;
				margin: 10px 12px;
			}

			.userexit {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 37px;
			}
		}
	}
	.moneyDiv {
		// margin-left: 17.9px;
		margin-right: 19px;
		line-height: 40px;
		font-size: 14px;
		cursor: pointer;
	
		// border-right: 1px solid #F6F6F6;
		// line-height: 14px;
		// padding-right: 17.9px;
	}
</style>
