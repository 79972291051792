<template>
	<div>
		<div class="table-div">
			<div class="inputDiv">
				<!-- 	<el-input v-model="inputName" placeholder="请输入项目名称" class="inputClass"></el-input>
				<el-input v-model="inputNum" placeholder="请输入项目编号" class="inputClass"></el-input> -->
				<!-- 	<el-date-picker v-model="valueTime" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" @change="pickerChage" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
				</el-date-picker> -->

				<div style="width: 50%;display: flex;flex-direction: row;justify-content: flex-start;
align-items: center;">
				<el-date-picker v-model="valueTime[0]" type="date" placeholder="开始日期" @change="pickerChage"
					format="yyyy-MM-dd" value-format="yyyy-MM-dd">
				</el-date-picker>
				<div style="margin:0 20px">至</div>
				<el-date-picker v-model="valueTime[1]" type="date" placeholder="结束日期" @change="pickerChage"
					format="yyyy-MM-dd" value-format="yyyy-MM-dd">
				</el-date-picker>
				</div>

				<el-select v-model="value" placeholder="请选择公司" @change="changeSelect">
					<el-option v-for="item in tableDataArray" :key="item.C_ItemNum" :label="item.C_ItemName"
						:value="item.C_ItemNum">
					</el-option>
				</el-select>
				<el-button @click="exportExcel">导出</el-button>

			</div>

			<el-table border :data="tableData" id="table" ref="table" :loading="loading3"
				:header-cell-style="{background:'#1373C2',color:'white'}" style="width: 100%;">
				<!-- show-summary -->
				<!-- :summary-method="totalOutPrice" -->
				<el-table-column prop="C_DateRange" label="日期"></el-table-column>
				<el-table-column prop="C_ItemNum" label="项目编号"></el-table-column>
				<el-table-column prop="C_ItemName" label="项目名称"></el-table-column>
				<el-table-column prop="C_ItemType" label="项目类别"></el-table-column>
				<el-table-column prop="I_CntSum" label="本期交易笔数(笔)"></el-table-column>
				<el-table-column prop="M_PaymentSum" label="本期交易金额(元)"></el-table-column>
				<el-table-column prop="M_ShouXuFeiSum" label="本期手续费总额(元)"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import config from "../../request/config"
	import QS from 'qs';
	import axios from 'axios'
	import Vue from 'vue'
	Vue.prototype.$axios = axios
	// import FileSaver from "file-saver"
	// import * as XLSX from 'xlsx'
	import {
		getproduct
	} from "../../request/api.js";
	export default {
		name: "Resgin",

		data() {
			return {
				mainArray: [],
				inputName: "",
				inputNum: "",
				valueTime: [
					// new Date().getFullYear() + '-' + new Date().getMonth() + '-' + 1,
					// new Date().getFullYear() + '-' + (new Date().getMonth() + 1) +
					// '-' + 1
				],
				tableData: [],
				selectValue: '',
				tableDataArray: [],
				is_excel: 0,
			}
		},

		watch: {

			immediate: true,
			async handler() {
				//await this.$nextTick(); 根据实际选择延迟调用
				const tds = document.querySelectorAll('#table .el-table__footer-wrapper tr>td');
				tds[0].colSpan = 4;
				tds[0].style.textAlign = 'center'
				tds[1].style.display = 'none'
				tds[2].style.display = 'none'
				tds[3].style.display = 'none'
			}
		},
		mounted() {
			// if (new Date().getMonth() > 8) {
			// 	console.log("没进来吗大于9")
			// 	this.valueTime = [
			// 		new Date().getFullYear() + '-' + new Date().getMonth() + '-' + 1,
			// 		new Date().getFullYear() + '-' + (new Date().getMonth() + 1) +
			// 		'-' + 1
			// 	]
			// } else {
			// 	console.log("没进来吗小于9")
			// 	this.valueTime = [
			// 		new Date().getFullYear() + '-' + ('0' + new Date().getMonth() + 1) + '-',
			// 		new Date().getFullYear() + '-' + ('0' + new Date().getMonth() + 1) +
			// 		'-' + 1
			// 	]
			// }
			this.billFun()
			this.mainFun()
			this.getdatatime()
			console.log("什么", this.valueTime)
		},
		methods: {


			changeSelect(e) {
				console.log("那个跟你说", e)

				for (var i = 0; i < this.tableDataArray.length; i++) {
					if (e == this.tableDataArray[i].C_ItemNum) {
						this.selectValue = this.tableDataArray[i].C_ItemName
						if (this.tableDataArray[i].C_ItemName == '全部') {
							this.selectValue = ""
						}
					}

				}


				// this.selectValue = e
				// this.billFun()
				var data = {
					func: "queryWebGetPayInfo_SYRQ",
					ctrl: "Apiv",
					is_excel: this.is_excel,
					StartDate: this.valueTime[0],
					ItemName: this.selectValue,
					EndDate: this.valueTime[1],
				};
				this.tableData = []
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.tableData = res.data
						if (this.is_excel == 1) {
							console.log("123")
							if (res.msg == '') {
								return
							}

						} else {
							if (res.msg == '') {
								return
							}
						}

					} else {
						console.log("123123")
					}
				})
			},
			getdatatime() { //默认显示今天

			},
			mainFun() {
				var data = {
					func: "WebGetItemNumName_SYRQ",
					ctrl: "Apiv",

				};

				this.tableDataArray = []

				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.tableDataArray = res.data


						this.tableDataArray.unshift({
							C_ItemName: "全部",
							C_ItemNum: ""
						})



						console.log("this.tableDataArray", this.tableDataArray)

					} else {
						console.log("123123")
					}
				})
			},
			// totalOutPrice(param) {
			// 	const {
			// 		columns,
			// 		data
			// 	} = param
			// 	const sums = []
			// 	columns.forEach((column, index) => {
			// 		if (index === 0) {
			// 			sums[index] = '合计';
			// 			return;
			// 		}
			// 		const values = data.map(item => Number(item[column.property]));
			// 		if (column.property != 'C_Month' && column.property != 'C_ItemNum' && column.property !=
			// 			'C_ItemName' &&
			// 			column.property != 'C_ItemType'
			// 		) {
			// 			sums[index] = values.reduce((prev, curr) => {
			// 				const value = Number(curr);
			// 				if (!isNaN(value)) {
			// 					return prev + curr;
			// 				} else {
			// 					return prev;
			// 				}
			// 			}, 0);
			// 			sums[index];
			// 		}
			// 	});
			// 	return sums
			// },
			pickerChage(e) {
				console.log("选择边框", e)
				// this.valueTime=e.value
				this.billFun()
			},

			exportExcel() {
				var data = {
					is_excel: 1,
					StartDate: this.valueTime[0],
					ItemName: this.selectValue,
					EndDate: this.valueTime[1],
				};
				window.location.href = config.baseUrl + '/index.php/api/Apiv/queryWebGetPayInfo_SYRQ?' + QS.stringify(data)
			},
			billFun() {
				console.log("这个是神，", this.selectValue)
				var data = {
					func: "queryWebGetPayInfo_SYRQ",
					ctrl: "Apiv",
					is_excel: this.is_excel,
					StartDate: this.valueTime[0],
					ItemName: this.selectValue,
					EndDate: this.valueTime[1],

				};


				this.tableData = []
				getproduct(data).then((res) => {



					if (res.code == 200) {
						this.tableData = res.data
						console.log("这个是什么11", this.tableData)
						if (res.msg == '') {
							return
						}


					} else {
						console.log("123123")
					}
				})
			}
		}

	}
</script>

<style>
	.table-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 100px;
		padding: 40px;
		box-sizing: border-box;
	}

	#table {
		margin: 0 auto;
	}

	.table-name-div {
		margin-bottom: 20px;
	}

	.inputClass {
		width: 300px;
	}

	.inputDiv {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
		width: 100%;
	}
</style>
