import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../page/login.vue';
import product from '../page/product.vue';
import home from '../page/home.vue';
import records from '../page/records.vue';
import recordDetail from '../page/recordDetail.vue';
import submit from '../page/submit.vue';
import Cookies from 'js-cookie';
import bill from '../page/bill.vue';
import alreadybill from '../components/bill/alreadybill.vue';
import nonebill from '../components/bill/nonebill.vue';
import billdetail from '../components/bill/billdetail.vue';
import auditing from '../page/auditing.vue';
import waitauditing from '../components/auditing/waitauditing.vue';
import alreadyauditing from '../components/auditing/alreadyauditing.vue';
import ingauditing from '../components/auditing/ingauditing.vue';
import examine from '../components/auditing/examine.vue';
import hotPayPass from '../page/hotPayPass.vue';
import balance from '../page/balance.vue';
import balanceDetail from '../page/balanceDetail.vue';
import corpChange from '../page/corpChange.vue';
import receipt from '../page/receipt.vue';
import receiptDetail from '../page/receiptDetail.vue';
import notCertified from '../components/company/notCertified.vue';
import createContract from '../components/company/createContract.vue';
import company from '../page/company.vue';
import OfficialUpload from '../components/company/OfficialUpload.vue';
import contractList from '../components/company/contractList.vue';
import officialList from '../components/company/officialList.vue';
import consumptionDetails from '../components/company/consumptionDetails.vue';
import detailedTwo from '../components/company/detailedTwo.vue';
// import moneyHeader from '../components/company/moneyHeader.vue';
import detailed from '../page/detailed.vue';
import recharge from '../page/recharge.vue';
import receiptbase from '../page/receiptbase.vue';
import billback from '../components/bill/billback.vue';
import backbill from '../components/bill/backbill.vue';
import transfer from '../page/transfer.vue';
import dispatch from '../page/dispatch.vue';
import thermal from '../page/thermal.vue';


import applicationList from '../components/dispatch/applicationList.vue';
import success from '../components/dispatch/success.vue';
import refuse from '../components/dispatch/refuse.vue';
import slide from '../components/dispatch/slide.vue';

import ThermalApplicationList from '../components/thermal/applicationList.vue';
import Thermalsuccess from '../components/thermal/success.vue';
import Thermalrefuse from '../components/thermal/refuse.vue';
import Thermalslide from '../components/thermal/slide.vue';

import palmAudit from '../page/palmAudit.vue';


import applicationListXin from '../components/palmAudit/applicationListXin.vue';
import successXin from '../components/palmAudit/successXin.vue';
import refuseXin from '../components/palmAudit/refuseXin.vue';
import slideXin from '../components/palmAudit/slideXin.vue';


import goCollectTreasure from '../page/goCollectTreasure.vue';

import goApplicationListXin from '../components/goCollectTreasure/goApplicationListXin.vue';
import goSuccessXin from '../components/goCollectTreasure/goSuccessXin.vue';
import goRefuseXin from '../components/goCollectTreasure/goRefuseXin.vue';
import goSlideXin from '../components/goCollectTreasure/goSlideXin.vue';


import paymentAgent from '../page/paymentAgent.vue';
import payApplicationList from '../components/paymentAgent/payApplicationList.vue';
import payRefuse from '../components/paymentAgent/payRefuse.vue';
import paySlide from '../components/paymentAgent/paySlide.vue';
import paySuccess from '../components/paymentAgent/paySuccess.vue';
import qualificationResults from "../page/qualificationResults.vue";
import updatePersonInfo from "../page/updatePersonInfo.vue";
import companyPage from "../page/companyPage.vue";
import billQuery from "../page/billQuery.vue";
import billQueryTable from '../components/billQuery/billQueryTable.vue';

import offlineRefund from "../page/offlineRefund.vue";
import offlineRefundApply from '../components/offlineRefund/offlineRefundApply.vue';
import offlineRefundList from '../components/offlineRefund/offlineRefundList.vue';

// import billQuerySlide from '../components/billQuery/billQuerySlide.vue';


Vue.use(VueRouter)

const routes = [{
		path: '/receiptbase',
		name: 'receiptbase',
		component: receiptbase
	},

	{
		path: '/receipt',
		name: 'receipt',
		component: receipt
	},
	{
		path: '/receiptDetail',
		name: 'receiptDetail',
		component: receiptDetail
	},
	{
		path: '/hotPayPass',
		name: 'hotPayPass',
		component: hotPayPass,
	},
	{
		path: '/balance',
		name: 'balance',
		component: balance
	},
	{
		path: '/balanceDetail',
		name: 'balanceDetail',
		component: balanceDetail
	},
	{
		path: '/corpChange',
		name: 'corpChange',
		component: corpChange
	},
	{
		path: '/',
		name: 'login',
		component: login
	},
	{
		path: '/product',
		name: 'product',
		component: product
	},
	{
		path: '/home',
		name: 'home',
		component: home
	},
	{
		path: '/records',
		name: 'records',
		component: records
	},
	{
		path: '/recordDetail',
		name: 'recordDetail',
		component: recordDetail
	},
	{
		path: '/submit',
		name: 'submit',
		component: submit
	},
	{
		path: "/qualificationResults",
		name: "qualificationResults",
		component: qualificationResults,
	},
	{
		path: "/companyPage",
		name: "companyPage",
		component: companyPage,
	},


	{
		path: "/updatePersonInfo",
		name: "updatePersonInfo",
		component: updatePersonInfo,
	},

	{
		path: '/bill',
		name: 'bill',
		component: bill,
		children: [{
				path: "nonebill",
				name: "nonebill",
				component: nonebill
			},
			{
				path: "alreadybill",
				name: "alreadybill",
				component: alreadybill
			},
			{
				path: "billdetail",
				name: "billdetail",
				component: billdetail
			},
			{
				path: 'billback',
				name: 'billback',
				component: billback
			},
			{
				path: 'backbill',
				name: 'backbill',
				component: backbill
			},
		]
	},
	{
		path: '/transfer',
		name: 'transfer',
		component: transfer
	},

	{
		path: '/recharge',
		name: 'recharge',
		component: recharge
	},
	{
		path: '/detailed',
		name: 'detailed',
		component: detailed
	},
	{
		path: "/offlineRefund",
		name: "offlineRefund",
		component: offlineRefund,
		children: [


			{
				path: "offlineRefundApply",
				name: "offlineRefundApply",
				component: offlineRefundApply
			},
			{
				path: "offlineRefundList",
				name: "offlineRefundList",
				component: offlineRefundList
			},

		]
	},

	{
		path: "/billQuery",
		name: "billQuery",
		component: billQuery,
		children: [


			{
				path: "billQueryTable",
				name: "billQueryTable",
				component: billQueryTable
			},

		]
	},

	{
		path: '/company',
		name: 'company',
		component: company,
		children: [

			{
				path: "detailedTwo",
				name: "detailedTwo",
				component: detailedTwo
			},
			{
				path: "notCertified",
				name: "notCertified",
				component: notCertified
			},
			{
				path: "createContract",
				name: "createContract",
				component: createContract
			},
			{
				path: "OfficialUpload",
				name: "OfficialUpload",
				component: OfficialUpload
			},
			{
				path: "contractList",
				name: "contractList",
				component: contractList
			},
			{
				path: "officialList",
				name: "officialList",
				component: officialList
			},

			// {
			// 	path: 'recharge',
			// 	name: 'recharge',
			// 	component: recharge
			// },
			{
				path: 'consumptionDetails',
				name: 'consumptionDetails',
				component: consumptionDetails
			},
		]
	},
	{
		path: '/auditing',
		name: 'auditing',
		component: auditing,
		children: [{
				path: "waitauditing",
				name: "waitauditing",
				component: waitauditing
			},
			{
				path: "alreadyauditing",
				name: "alreadyauditing",
				component: alreadyauditing
			},
			{
				path: "ingauditing",
				name: "ingauditing",
				component: ingauditing
				// component: examine
			},
			{
				path: "examine",
				name: "examine",
				component: examine
			},

		]
	},

	{
		path: '/paymentAgent',
		name: 'paymentAgent',
		component: paymentAgent,
		children: [{
				path: "payApplicationList",
				name: "payApplicationList",
				component: payApplicationList
			},
			{
				path: "payRefuse",
				name: "payRefuse",
				component: payRefuse
			},
			{
				path: "paySlide",
				name: "paySlide",
				component: paySlide
			},

			{
				path: "paySuccess",
				name: "paySuccess",
				component: paySuccess
			}
		]

	},
	

	
{
		path: '/goCollectTreasure',
		name: 'goCollectTreasure',
		component: goCollectTreasure,
		children: [{
				path: "goApplicationListXin",
				name: "goApplicationListXin",
				component: goApplicationListXin
			},
			{
				path: "goSuccessXin",
				name: "goSuccessXin",
				component: goSuccessXin
			},
			{
				path: "goRefuseXin",
				name: "goRefuseXin",
				component: goRefuseXin
			},

			{
				path: "goSlideXin",
				name: "goSlideXin",
				component: goSlideXin
			}
		],
	},
	{
		path: '/palmAudit',
		name: 'palmAudit',
		component: palmAudit,
		children: [{
				path: "applicationListXin",
				name: "applicationListXin",
				component: applicationListXin
			},
			{
				path: "successXin",
				name: "successXin",
				component: successXin
			},
			{
				path: "refuseXin",
				name: "refuseXin",
				component: refuseXin
			},

			{
				path: "slideXin",
				name: "slideXin",
				component: slideXin
			}
		],
	},
	{
		path: '/dispatch',
		name: 'dispatch',
		component: dispatch,
		children: [{
				path: "applicationList",
				name: "applicationList",
				component: applicationList
			},
			{
				path: "success",
				name: "success",
				component: success
			},
			{
				path: "refuse",
				name: "refuse",
				component: refuse
			},

			{
				path: "slide",
				name: "slide",
				component: slide
			},


		]
	}
	,
	{
		path: '/the',
		name: 'the',
		component: thermal,
		children: [{
				path: "thermal",
				name: "thermal",
				component: ThermalApplicationList
			},
			{
				path: "thesuccess",
				name: "thesuccess",
				component: Thermalsuccess
			},
			{
				path: "therefuse",
				name: "therefuse",
				component: Thermalrefuse
			},

			{
				path: "theslide",
				name: "theslide",
				component: Thermalslide
			},


		]
	}
]

const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	if (to.name !== 'login' && Cookies.get('tooken') == undefined) next({
		name: 'login'
	})
	// 用户验证身份
	else if (to.path == '/login' || to.name == 'login') {
		if (Cookies.get('tooken') != undefined) {
			if (from.name == null) {
				next({
					name: 'product'
				})
			} else {
				next({
					name: from.name
				})
			}

		} else next()
	} else next()

})

export default router
