<template>
	<div>
		<el-container>
			<el-header>
				<headtwo></headtwo>
			</el-header>
			<el-container>
				<el-aside width="200px">
					<silde style="height: 100%"></silde>
				</el-aside>
				<el-main class="mainheight">
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	import headtwo from "../components/auditing/headtwo.vue";
	import silde from "../components/billQuery/billQuerySlide.vue";
	export default {
		components: {
			headtwo,
			silde
		},
		name: "JinjianBill",
		data() {
			return {
				isshow: 1,
			};
		},

		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	/deep/body {
		background: #f7f8f9 !important;
	}
</style>
