<template>
	<div class="headertop">
		<el-row :gutter="10">
			<el-col :span="8" :offset="3" style="display: flex; padding-top: 25px">
				<img src="../assets/index/logo.png" class="logoimg" />
				<div class="title">{{title}}</div>
			</el-col>
			<el-col :span="8" :offset="2" class="flexright">
				<div class="userlogin">
					<div class="moneyDiv" @click="shouyeFun">首页</div>

					<div class="moneyDiv" @click="receipt(1)">开票信息</div>

					<div class="moneyDiv" @click="receipt(2)" v-if="data_type=='银行'">收件信息</div>

					<!-- <img src="../assets/detaIled/money.png" class="moneyImg" @click="moneyImgFun" /> -->
					<div class="moneyDiv" @click="moneyImgFun">充值</div>
					<!-- <img src="../assets/detaIled/detailed.png" class="detailedImg" @click="detailedImgFun" /> -->
					<div class="detailedDiv" @click="detailedImgFun">明细</div>

					<!-- <img src="../assets/detaIled/balance.png" class="balanceImg" /> -->
					<div class="balanceDiv">
						<div>余额：￥</div>
						<div>{{money}}</div><!-- 元 -->
					</div>

					<!-- <img src="../assets/detaIled/person.png" class="usernameImg" /> -->
					<div class="detailedDiv" @click="TransferFun">划账</div>
					<div class="usernameDiv">{{ username }}</div>
					<!-- <img src="../assets/detaIled/outLogin.png" class="outLoginImg" @click="removecokie"/> -->
					<div @click="removecokie" class="outLoginDiv">退出</div>


					<!-- <el-avatar size="large" :src="circleUrl"></el-avatar> -->
					<!--  <span class="username">{{ username }}</span>
          <div class="xian"></div>
          <span class="userexit" @click="removecokie">退出</span> -->
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		getCompanyList
	} from "../request/api";
	export default {
		name: "headertop",
		props: {
			activeIndex: String,
			ifxianshi: {
				type: Boolean,
				default: true
			},
			title: {
				type: String,
				default: '热付通SAAS进件申请平台'
			}
		},
		data() {
			return {
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				username: "用户",
				money: "",
				data_type:''
			};
		},
		created() {
			this.serve_id = this.getCookie("customerid")
			if (this.serve_id) {

				this.getCompanyListFun()

			}
		},
		mounted() {
			if (this.getCookie("headimg")) {
				this.circleUrl = this.getCookie("headimg");
			}
			if (this.getCookie("username")) {
				this.username = this.getCookie("username");
			}
			if (this.getCookie("data_type")) {
				this.data_type = this.getCookie("data_type");
				// console.log('this.data_type',this.data_type)
			}
		},
		methods: {
			receipt(type) {
				if (type == 1) {
					//开票信息
					this.$router.push({
						path: "/receiptbase",
						query: {
							type: 1
						}
					});
				}
				if (type == 2) {
					//银行收货信息
					this.$router.push({
						path: "/receiptbase",
						query: {
							type: 2
						}
					});
				}

			},
			receipt_back() {
				this.$router.push({
					path: "/receiptbase"
				});
			},
			shouyeFun() {
				this.$router.push({
					path: "/product"
				});
			},
			TransferFun(){
				this.$router.push({
					path: "/transfer"
				});
			},

			detailedImgFun() {
				// this.$router.push({
				// 	name: "detailed"
				// });
				this.$router.push({
					path: "/detailed"
				});

			},
			moneyImgFun() {
				// this.$router.push({
				// 	name: "recharge"
				// });
				this.$router.push({
					path: "/recharge"
				});

			},

			// getCompanyListFun() {

			// 	var data = {
			// 		func: "QueryAccountInfo",
			// 		ctrl: "FadadaApi",
			// 		customer_id: this.customerid,

			// 	};
			// 	console.log("看看传递的数据", data)
			// 	getCompanyList(data).then((res) => {
			// 		if (res.code == 200) {
			// 			// this.ifused = res.data;

			// 			this.money = res.data.M_Balance
			// 			if (!this.money) {
			// 				this.money = 0
			// 			}

			// 			console.log("看看返回值", this.companyList)
			// 		} else {
			// 			console.log("看看错误返回值", res)
			// 		}
			// 	})

			// },

			getCompanyListFun() {
				var data = {
					func: "balance",
					ctrl: "customerBalance",
					// customer_id: "",
					customer_id: this.serve_id,
				};
				// console.log("传递的参数", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;
						// console.log("看看返回值", res.data)
						// window.location.href = res.data.pay_url
						this.money = res.data
						this.dialogVisible = false

					} else {
						// console.log("看看错误返回值", res)
					}
				})
			},



			//退出登录
			removecokie() {
				this.removeCookie("tooken");
				this.removeCookie("customerList");
				this.removeCookie("username");
				this.removeCookie("headimg");
				this.removeCookie('customerid');
				this.removeCookie('code');
				this.$router.push({
					name: 'login'
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-menu.el-menu--horizontal {
		border: none;
	}

	/deep/.el-menu--horizontal>.el-menu-item {
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		margin: 0 20px;
		padding: 0;
	}

	.el-menu-demo {
		display: flex;
	}

	.headertop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: url(../assets/index/header_bg.jpg) no-repeat;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
		z-index: 999999;
		height: 94px;
		background-size: 100%;
		// height: 60px;
	}

	.logoimg {
		display: block;
		width: 127px;
		height: 42px;
	}

	.title {
		font-size: 24px;
		font-weight: 500;
		color: white;
		margin-left: 24px;
		border-left: 1px solid #266DED;
		padding-left: 24px;
		line-height: 42px;
		white-space: nowrap;
	}

	.flexright {
		display: flex;
		justify-content: flex-end;
		white-space: nowrap;

		.userlogin {
			display: flex;
			padding-top: 39px;
			margin-left: 15%;

			font-size: 14px;
			font-weight: bold;
			color: #FFFFFF;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			// line-height: 30px;
			.username {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 40px;
				margin-left: 12px;
			}

			.xian {
				width: 1px;
				height: 20px;
				background: #266DED;
				margin: 10px 12px;
			}

			.userexit {
				position: relative;
				padding-left: 28px;
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 40px;
				cursor: pointer;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -9px;
					width: 17px;
					height: 18px;
					background: url(../assets/index/logout.png) no-repeat;
				}
			}
		}
	}

	.detailedImg {
		width: 16px;
		height: 16px;

	}

	.detailedDiv {
		// margin-left: 10px;
		margin-right: 19px;
		// border-right: 1px solid #F6F6F6;
		line-height: 14px;
		font-size: 14px;
		cursor: pointer;
		// width:50px;
		// padding-right: 17.9px;
	}

	.balanceImg {
		width: 16px;
		height: 14px;
	}

	.balanceDiv {
		// margin-left: 10px;
		// margin-right: 17.9px;
		// line-height: 14px;

		// border-right: 1px solid #F6F6F6;
		// line-height: 14px;
		// padding-right: 17.9px;
		margin-right: 19px;
		display: flex;
		font-size: 14px;
		flex-direction: row;
		justify-content: center;
		margin-top: 1px;
		align-items: center;
		line-height: 14px;


	}

	.moneyImg {
		width: 17.9px;
		height: 17.9px;
	}

	.moneyDiv {
		// margin-left: 17.9px;
		margin-right: 19px;
		line-height: 14px;
		font-size: 14px;
		cursor: pointer;

		// border-right: 1px solid #F6F6F6;
		// line-height: 14px;
		// padding-right: 17.9px;
	}

	.usernameImg {
		width: 17.9px;
		height: 17.9px;
	}

	.usernameDiv {
		// margin-left: 17.9px;
		margin-right: 19px;
		line-height: 14px;
		font-size: 14px;
		// border-right: 1px solid #F6F6F6;
		// line-height: 14px;
		// padding-right: 17.9px;
	}

	.outLoginImg {
		width: 16px;
		height: 17.9px;
	}

	.outLoginDiv {
		line-height: 14px;
		font-size: 14px;
		cursor: pointer;
		// margin-left: 17.9px;
		// margin-right: 17.9px;
		// line-height: 14px;
		// padding-right: 17.9px;
	}

	.balanceDiv div {
		color: #F9C803;
		line-height: 14px;
		font-size: 14px;
		// margin: 0 10px;
	}
</style>
