<template>
	<el-dialog title="地址列表" :visible.sync="outerVisible" @close="$emit('close', false)" @open="getpage()">

		<div style="display: flex;justify-content: end;padding-bottom: 20px;">
			<el-button type="default" @click="act('','add')">新增</el-button>
		</div>
		<el-table ref="multipleTable" :data="list"  border style="width: 100%" stripe
			@selection-change="handleSelectionChange" v-loading="loading">
			<el-table-column type="selection" width="55">
			</el-table-column>
			<!-- <el-table-column type="index" label="序号" width="50" /> -->
			<el-table-column property="invoice_contacts" label="收货人名称" />
			<el-table-column property="invoice_mobile" label="手机号码" />
			<el-table-column property="invoice_addr" label="详细地址" />
			<el-table-column property="create_time" label="创建时间" />
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button type="primary" size="small" @click="act(scope.row,'edit')">
						编辑
					</el-button>
					<el-button type="primary" size="small" @click="act(scope.row,'del')">
						删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="pagination" layout="total, sizes, prev, pager, next, jumper"
			:current-page="pagination.index" :page-sizes="[5]" :page-size="pagination.size"
			:total="pagination.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background>
		</el-pagination>


		<el-dialog width="30%" title="编辑地址" :visible.sync="innerVisible" append-to-body>
			<el-form :model="formdata">
				<el-form-item label="收货人名称">
					<el-input v-model="formdata.invoice_contacts" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="手机号码">
					<el-input v-model="formdata.invoice_mobile" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="详细地址">
					<el-input v-model="formdata.invoice_addr" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="innerVisible = false">取 消</el-button>
				<el-button type="primary" @click="save" :disabled="ifdianji">确 定</el-button>
			</div>
		</el-dialog>

		<div slot="footer" class="dialog-footer">
			<el-button @click="outerVisible = false">取 消</el-button>
			<el-button type="primary" @click="choose" :disabled="ifdianji">选 择</el-button>
		</div>

	</el-dialog>

</template>

<script>
	import {
		getproduct
	} from "../request/api";
	export default {
		name: "addressdialo",
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			type: {
				type: Number,
				default: 1
			},
		},
		watch: {
			dialogVisible: function(newVal, oldVal) {
				console.log(oldVal)
				this.outerVisible = newVal
			}
		},
		data() {
			return {
				outerVisible: false,
				innerVisible: false,
				list: [],
				pagination: {
					index: 1,
					size: 5,
					total: 0,
				},
				selection: [],
				formdata: {},
				ifdianji: false,
				loading: false,
			};
		},
		methods: {
			handleSelectionChange(val) {
				this.selection = val;
				// if(val.length>1){
				// 	this.$refs.multipleTable.clearSelection();
				// }else{
				// 	this.selection = val;
				// }
			},
			choose() {
				if (this.selection.length > 1) {
					this.$message({
						message: "不可同时选中多个收货地址！",
						type: "error",
						offset: 100,
					});
				} else {
					this.$emit('choose', this.selection[0])
					this.outerVisible = false
				}
			},
			save() {
				if (this.ifdianji) {
					return false;
				}
				this.ifdianji = true;
				var data = this.formdata;
				data.func = "address_save";
				data.ctrl = "ReceiptAddress";
				data.type = this.type
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: "提交成功！",
							type: "success",
							offset: 100,
						});
						this.innerVisible = false
					}
					setTimeout(() => {
						this.getpage()
					}, 500);
					setTimeout(() => {
						this.ifdianji = false;
					}, 2000);

				});
			},
			act(row, type) {
				if (type == 'edit') {
					this.formdata = {};
					this.formdata = row;
					this.innerVisible = true;
				}
				if (type == 'add') {
					this.formdata = {};
					this.innerVisible = true;
				}
				if (type == 'del') {
					var data = {
						func: "address_del",
						ctrl: "ReceiptAddress",
						id: row.cras_id
					};
					getproduct(data).then((res) => {
						if (res.code == 200) {
							this.$message({
								message: "操作成功！",
								type: "success",
								offset: 100,
							});
						}
						setTimeout(() => {
							this.getpage()
						}, 500);
					});
				}
			},
			handleCurrentChange(val) {
				this.pagination.index = val;
				this.getpage();
			},
			handleSizeChange(val) {
				this.pagination.size = val;
				this.getpage();
			},
			getpage() {
				this.loading = true
				var data = {
					func: "addresslist",
					ctrl: "ReceiptAddress",
					page: this.pagination.index,
					limit: this.pagination.size,
					type:this.type
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.list = res.data.list;
						this.pagination.total = res.data.dataCount;
					}
					this.loading = false
				}).catch(() => {
					this.loading = false
				});
			},
		}
	}
</script>

<style>
</style>
