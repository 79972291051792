<template>
  <div class="pushzizhi">
    <el-row>
      <el-col :span="24">
        <el-form-item label="进件盖章材料" required>
          <div class="input">
            <div class="text" >
              <div>申请 微信生活缴费，银联云闪付，支付宝生活缴费 所需要的盖章材料</div>
              <div>如有多个文件上传时，请打包后再进行上传操作</div>
              <div><el-button type="text" icon="el-icon-download" @click="downloadTemplate()">模板下载</el-button></div>
            </div>
            <el-upload
              :action="uploadurl"
              list-type="text"
              :auto-upload="true"
              :on-progress = "handleProgress"
              :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    res,
                    file
                  );
                }
              "
              :on-exceed="exceedimg"
              class="uploadlist"
              :data="filedata"
              :headers="fileheaders"
              :limit="1"
              ref="customerdoorupload"
            >
              <div class="el-upload__text" v-if="ifedit == false">
                <img class="scimg" src="../assets/submit/upload.png" />
                <p style="font-size: 12px; margin: 0">上传</p>
              </div>
              <!-- <div slot="file" slot-scope="{ file }">
                <div> {{ file.name }} </div>
              </div> -->
            </el-upload>
             <div
                v-if="formlist.examine_fileinfo != ''"
                class = "examine-file-box"
                ref="examineFileBox"
                @click="downloadFile(formlist.examine_fileinfo)"
             >
                {{ formlist.examine_fileinfo?formlist.examine_fileinfo.name:'' }}
          </div>
          </div>
         
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import config from "../request/config"

export default {
  name: "JinjianExaminefile",
  props: {
    formlist: Object,
    ifedit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      disabled: false,
      filedata: {
        ctrl: "file",
        func: "save",
        type: "file",
        serviceFileName: "进件盖章材料",
      },
      fileheaders: {
        token: Cookies.get("tooken"),
      },
      uploadurl: config.baseUrl+"/index.php/api/index",
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  mounted() {
    console.log(this.formlist);
  },
  methods: {
    downloadFile(fileinfo){
      const link = document.createElement('a')
    //   link.setAttribute('download', fileinfo.name) // 指定下载后的文件名，防跳转
      link.style.display = 'none'
      link.href = config.baseUrl+fileinfo.file_path
      link.download = fileinfo.name
      document.body.appendChild(link)
      link.click()
    },
    downloadTemplate(){
        const link = document.createElement('a')
      // link.setAttribute('download', row.c_ImageName) // 指定下载后的文件名，防跳转
      link.style.display = 'none'
      link.href = config.baseUrl+'/public/static/进件需要盖章的材料.rar'
      document.body.appendChild(link)
      link.click()
    },
    deletepic(ref,info){
       this.formlist[info]=''
       this.$refs[ref].clearFiles()
    },
    showImg(path){
      return config.baseUrl + path;
    },
    chakan(file) {
      this.dialogImageUrl = config.baseUrl + file;
      this.dialogVisible = true;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleProgress(){
        // this.$refs.examineFileBox.innerHTML = ""
    },
    handleAvatarSuccess(res) {
      if (res.code == 200) {
          this.formlist['examine_file'] = res.data.file_id;
      }
      if (res.code == 401) {
        setTimeout(function () {
          this.$router.push({ name: "login" });
        }, 2000);
      }
      this.$forceUpdate();
    },
    exceedimg() {
      this.$message({
        type: "warning",
        message: "请先删除，再上传",
        offset: 120,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../assets/css/biaoge.scss");
/deep/.el-form-item__label {
  line-height: 74px !important;
}
/deep/.el-upload--picture-card {
  background-color: transparent;
  border: none;
  width: auto;
  height: auto;
}
/deep/.el-upload__text {
  line-height: 1;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 78px;
  height: 78px;
}
.examine-file-box{
    line-height: 39px;
    margin: 0 0 0 30px;
    cursor:pointer;
    color: #409EFF;
}
.pushzizhi {
    min-width: 1040px;
  border: 1px solid rgba(168, 188, 235, 1);
  border-bottom: none;
  // border-left: none;
  .input {
    padding: 10px 20px 0;
    width: 100%;
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      margin-right: 12px;
    }
    .scimg {
      width: 26px;
      height: 26px;
      padding-top: 5px;
    }
  }
}
.uploadlist {
  // width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>