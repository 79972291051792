<template>
  <div>
    <!-- <el-button class="backbut" @click="goback">返回</el-button> -->
    <vxe-table border :data="tableData" :header-cell-style="{background:'#1373C2',color:'white'}">
      <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
      <vxe-table-column
        :field="index"
        :title="item.name"
        v-for="(item, index) in listheadarray"
        :key="index"
      ></vxe-table-column>
      <vxe-table-column title="操作" width="100px" align="center">
        <template #default="{ row }">
          <vxe-button  style="background-color: #1373C2;color:#fff" @click="detail(row)">处理</vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import { getproduct } from "../../request/api";
export default {
  data() {
    return {
      tableData: [],
      listheadarray: [],
    };
  },
  mounted() {
    this.getpage();
  },
  methods: {
    goback() {
      this.$router.push({ name: "product" });
    },
    detail(row) {
      this.$router.push({
        name:'ingauditing',
        query:{
          wfid:row.I_WFID,
          wfnode:row.C_WFNode,
          viewname:row.C_ViewName
        }
      })
    },
    getpage() {
      var data = {
        ctrl: "indexExamine",
        func: "index",
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.listheadarray = res.data.column;
          this.tableData = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.backbut {
  margin-bottom: 20px;
}
.vxe-body--row   /deep/  td{
  border:  1px solid red;
}
</style>