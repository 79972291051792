<template>
	<div class="wrapper">
		<img src="../assets/login/leftLogo.png" class='leftLogo'>
		<!-- <div class="hd">搭建以客户为中心的智能服务平台<br />赋能热企 连接你我</div> -->
		<img src="../assets/login/bigBack.png" class='bigBack-img' />

		<div class="md">
			<div class="md-small">

				<img class="headerlogo" src="../assets/login/login_logo.png" />
				<div class="customerCenter">以客户为中心的智能服务平台</div>

				<div class="tabs-bar">
					<span :class="{ active: !saoma }" @click="
            saoma = false;
            login = false;
          ">密码登录</span>
					<span :class="{ active: saoma }" @click="
            saoma = true;
            login = false;
          ">扫码登录</span>
				</div>
				<img class="erweima" v-if="login" src="../assets/index/erweima.jpg"
					style="width:195px;height:203px;margin:0 auto" />
				<!-- <wxlogin
          appid="wx29b5db0c94f329f6 "
          :scope="snsapilogin"
          :redirect_uri="redirecturl"
           v-if="!login && saoma"
        >
        </wxlogin> -->
				<div class="erweima" v-show="!login && saoma" ref="qrCodeUrl"></div>
				<p class="toast" v-if="!login && saoma">请扫码登录</p>
				<p class="toast" v-if="login">扫码关注公众号进行注册</p>

				<el-form label-position="top" label-width="40px" v-model="formLabelAlign" v-if="!login && !saoma"
					class="formlist">
					<el-form-item>
						<el-input class="forminput username" v-model="formLabelAlign.mobile" placeholder="请输入用户名"
							maxlength="11" @keyup.enter.native="submitForm"></el-input>
					</el-form-item>
					<el-form-item style="margin-bottom: 0px;">
						<el-input class="forminput pwd" type="password" v-model="formLabelAlign.password"
							placeholder="请输入密码" @keyup.enter.native="submitForm" show-password></el-input>
					</el-form-item>

					<el-form-item>
						<div class='password-text-div'>
							<div>
								<el-checkbox v-model="checked">记住密码</el-checkbox>

							</div>
							<div>
								记住密码?

							</div>
						</div>

					</el-form-item>

					<el-form-item>
						<el-button type="primary" class='login-class' @click="submitForm">登录</el-button>
					</el-form-item>
				</el-form>
				<p class="footer" v-if="!login">
					<!-- 没有账号？ -->
					<span @click="login = !login">注册账号</span>
				</p>
				<!-- 有账号？ -->
				<p class="footer" v-if="login">
					<span @click="login = !login">点击登录</span>
				</p>
			</div>

			<div class="connectYouAndMe-div">
				<img src='../assets/login/connectYouAndMe.png' class='connectYouAndMe' />
			</div>

		</div>
		<div class="bd">
			版权所有：辽宁北软技术开发有限公司&nbsp;&nbsp;&nbsp;&nbsp;服务热线：400-024-2181&nbsp;&nbsp;&nbsp;&nbsp;技术支持：辽宁北软&nbsp;&nbsp;|&nbsp;&nbsp;热付通
		</div>
	</div>
</template>

<script>
	import {
		getproduct
	} from "../request/api";
	import QRCode from "qrcodejs2";
	export default {
		name: "index",
		data() {
			return {
				login: false,
				formLabelAlign: {
					mobile: "",
					password: "",
				},
				checked: false,
				saoma: false,
				redirecturl: "http://192.168.110.196:8080/home",
				snsapilogin: "snsapi_login",
				wait: "",
			};
		},
		watch: {
			saoma() {
				if (this.saoma == true) {
					this.$nextTick(() => {
						this.creatQrCode();
					});
				} else {
					clearInterval(this.wait);
				}
			},
		},
		mounted() {
			this.randomNumber();
			document.addEventListener('mousewheel', function(e) {
				e = e || window.event;
				if ((e.wheelDelta && event.ctrlKey) || e.detail) {
					event.preventDefault();
				}
			}, {
				capture: false,
				passive: false
			});
			document.addEventListener('keydown', function(event) {
				if ((event.ctrlKey === true || event.metaKey === true) &&
					(event.keyCode === 61 || event.keyCode === 107 ||
						event.keyCode === 173 || event.keyCode === 109 ||
						event.keyCode === 187 || event.keyCode === 189)) {
					event.preventDefault();
				}
			}, false);

		},
		beforeDestroy() {
			clearInterval(this.wait);
		},
		destroyed() {
			clearInterval(this.wait);
		},
		methods: {
			//二维码生成
			creatQrCode() {
				var text =
					this.$commourl.httpurl +
					"/api/scanopenid?code=" +
					this.getCookie("code");
				console.log("text", text);
				this.$refs.qrCodeUrl.innerHTML = "";
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: text,
					width: 184,
					height: 184,
					colorDark: "#000000",
					colorLight: "#ffffff",
					correctLevel: QRCode.CorrectLevel.H,
				});
				var that = this;
				this.wait = setInterval(function() {
					that.waitlogin();
				}, 2000);
				console.log(qrcode);
			},
			waitlogin() {
				var data = {
					func: "wxScanlogin",
					ctrl: "IndexLogin",
					code: this.getCookie("code"),
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.setCookie("tooken", res.data.token);
						// this.setCookie(
						//   "customerList",
						//   JSON.stringify(res.data.info.customerList)
						// );
						var aa = JSON.stringify(res.data.info.customerList);
						localStorage.setItem("customerList", aa);
						this.setCookie("username", res.data.info.name);
						this.setCookie("headimg", res.data.info.headimg);
						this.setCookie("userMobile", res.data.info.mobile);
						clearInterval(this.wait);
						this.$message({
							message: "登录成功",
							type: "success",
						});
						setTimeout(() => {
							this.$router.push({
								name: "product"
							});
						}, 2000);
					}
				});
			},
			//随机数生成
			randomNumber() {
				var dataTime = new Date().getTime();
				var count = 3000;
				var num = Math.floor(Math.random() * count);
				var randomNumber = "G" + dataTime + "" + num;
				console.log(randomNumber);
				this.setCookie("code", randomNumber);
				return randomNumber;
			},
			//账户密码登录
			submitForm() {
				if (this.formLabelAlign.mobile == "") {
					this.$message.error("请输入用户名");
					return;
				}
				if (this.formLabelAlign.password == "") {
					this.$message.error("请输入密码");
					return;
				}
				var data = {
					func: "aplogin",
					ctrl: "IndexLogin",
					mobile: this.formLabelAlign.mobile,
					password: this.formLabelAlign.password,
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						console.log("看看登录后返回什么", res)
						
						var aa = JSON.stringify(res.data.info.customerList);
						this.setCookie("tooken", res.data.token);
						this.setCookie("contacts_id", res.data.info.contacts_id);
						
					
						
						localStorage.setItem("customerList", aa);
						this.setCookie("username", res.data.info.name);
						this.setCookie("headimg", res.data.info.headimg);

						this.setCookie("userMobile", this.formLabelAlign.mobile);

						// debugger
						this.$message({
							message: "登录成功",
							type: "success",
						});
						setTimeout(() => {
							this.$router.push({
								name: "product"
							});
						}, 2000);
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-button {
		margin-top: 10px;
		padding: 13px 0;
		width: 100%;
		font-size: 16px;
		background: #2285ed;
	}

	.headerlogo {
		display: block;
		margin: 0 auto;
		width: 282px;
		height: 33px;
	}

	.erweima {
		width: 184px;
		height: 184px;
		display: block;
		margin: auto;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.toast {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		text-align: center;
		margin-top: 0;
	}

	.footer {
		font-size: 14px;
		font-weight: 400;
		color: #666;
		text-align: center;


		span {
			color: #4B8FD6;
			cursor: pointer;
			font-weight: bold;
		}
	}

	.wrapper {
		// background-image: url('../assets/login/bigBack.png');
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding-right: 250px;
		box-sizing: border-box;

		// .hd {
		//   padding-top: 120px;
		//   width: 100%;
		//   height: 408px;
		//   line-height: 1.6;
		//   font-size: 26px;
		//   text-align: center;
		//   color: white;
		// background: #003497 url(../assets/login/login-hd_bg.jpg) no-repeat center;
		//   box-sizing: border-box;
		// }
		.md {
			// margin: -110px auto 0;
			padding: 48px 0;
			// width: 740px;
			width: 450px !important;
			min-height: 450px;
			// background-color: rgba(255, 255, 255, 0.4);
			padding: 30px;


			box-sizing: border-box;
			// border-radius: 50px;
			background: url(../assets/login/roundedRectangle.png) center;
			background-repeat: no-repeat;
			border-top-left-radius: 60px;
			border-top-right-radius: 60px;
			position: relative;
			top: -50px;



			.tabs-bar {
				margin: 0 auto 24px;
				// width: 348px;
				line-height: 42px;
				border-bottom: 1px solid #dedede;
				color: #6E7276;

				span {
					position: relative;
					padding: 12px 15px;
					font-size: 18px;
					cursor: pointer;
					font-weight: bold;

					&.active {
						color: #4B8FD6;
					}

					&.active::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: 1px;
						width: 100%;
						height: 1px;
						background-color: #4B8FD6;
						z-index: 1;
					}
				}
			}

			.formlist {
				margin: 0 auto;
				// width: 348px;

				.forminput {
					position: relative;

					/deep/ .el-input__inner {
						padding-left: 50px;
					}

					&::before {
						content: "";
						position: absolute;
						top: 10px;
						left: 15px;
					}

					&.username::before {
						width: 20px;
						height: 21px;
						background: url(../assets/login/login-icon_01.png) no-repeat;
					}

					&.pwd::before {
						width: 19px;
						height: 21px;
						background: url(../assets/login/login-icon_02.png) no-repeat;
					}
				}
			}
		}

		.bd {
			position: fixed;
			// left: 75%;
			bottom: 50px;
			// margin-left: -370px;
			// width: 740px;
			font-size: 12px;
			text-align: center;
			color: #fff;
			left: 50%;
			margin-left: -221px;


		}
	}

	.customerCenter {
		color: #4B8FD6;
		font-size: 16px;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 50px;
		font-weight: bold;

	}

	.md-small {
		background-color: #FFFEFF;
		border-radius: 30px;
		width: 100%;
		padding: 60px 50px 30px 50px;
		box-sizing: border-box;

	}

	.connectYouAndMe-div {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		margin-top: 20px;

		img {
			height: 50px;
		}

	}

	.password-text-div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		color: #4B8FD6;
		font-weight: bold;

		/deep/.el-checkbox__label {
			color: #4B8FD6;
			font-weight: bold;
		}

	}

	.login-class {
		border-radius: 20px;
		background-color: #5195F7;
	}

	.bigBack-img {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
	}

	.leftLogo {
		position: absolute;
		z-index: 999;
		top: 50px;
		left: 100px;
	}
</style>
