<template>
  <div style="height: 100%">
    <!-- <el-button class="backbut" @click="goback">返回</el-button> -->
    <el-form ref="form" :inline="true" :model="form" label-width="150px">
      <div class="formtop">
        <div class="formtop-title">筛选</div>
          <el-form-item label="所属银行">
            <el-select
              v-model="form.bank_name"
              style="width: 240px"
              placeholder="请选择"
              @change = "findList3"
            > 	
              <el-option label="全部" value=""></el-option>
              <el-option label="中国光大银行北京分行" value="中国光大银行北京分行"></el-option>
              <el-option label="中国光大银行股份有限公司石家庄分行" value="中国光大银行股份有限公司石家庄分行"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式">
            <el-select
              v-model="form.pay_type"
              style="width: 240px"
              placeholder="请选择"
              @change = "findList3"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="支付宝生活缴费" value="支付宝生活缴费"></el-option>
              <el-option label="微信生活缴费" value="微信生活缴费"></el-option>
               <el-option label="微信公众号缴费" value="微信公众号缴费"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择公司" v-if="customerid==1229">
            <el-select
              v-model="form.extend"
              style="width: 240px"
              placeholder="请选择"
              @change = "findList3"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="沈阳燃气" value="00"></el-option>
              <el-option label="沈阳沈南" value="21"></el-option>
              <el-option label="沈阳沈西" value="41"></el-option>
              <el-option label="沈阳新北" value="80"></el-option>
              <el-option label="沈阳沈法" value="91"></el-option>
              <el-option label="抚顺沈抚" value="93"></el-option>
              <el-option label="沈阳康平" value="94"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="缴费开始日期">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.start_date"
              value-format="yyyy-MM-dd"
              style="width: 240px"
              :picker-options="pickerOptions"
              @change = "findList3"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="缴费结束日期">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.end_date"
              value-format="yyyy-MM-dd"
              style="width: 240px"
              :picker-options="pickerOptions2"
              @change = "findList3"
            ></el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="交易金额(元)大于">
            <el-input
              v-model="form.gt_payment"
              style="width: 240px"
            ></el-input>
          </el-form-item> -->
          <!-- <el-form-item>
            <el-button class="chaxunbutton" @click="find">查询</el-button>
            <el-button class="clearbutton" @click="agin">重置</el-button>
          </el-form-item> -->
      </div>
    </el-form>
    <div class="formtop" style="padding: 20px">
      <el-row type="flex" justify="space-between">
        <el-col :span="18">
             <el-button class="fresh"  @click="refshtable">刷新</el-button>
             <el-button class="bill" type="primary" @click="applyBillNew" :disabled="applyBillNewStatus">申请开票</el-button>
             <div :style="{display:'inline-block',marginLeft: '20px',lineHeight:'50px', color:'red', textAlign:'center', fontWeight:'bolder'}">可申请上月之前的发票，无法申请当月产生的发票</div>
          <!-- <div class="fresh" @click="refshtable">刷新</div>
          <div class="bill" @click="applyBillNew">申请开票</div> -->
        </el-col>
        <el-col :span="6" >
          <div :style="{lineHeight:'50px', color:'#666', textAlign:'right', fontWeight:'bolder'}">手续费合计：{{shouxufeiSum.toFixed(6)}}</div>
        </el-col>
      </el-row>
      <vxe-table
        border
        row-id="receipt_detail_id"
        size="small"
        :loading="loading3"
        :data="tableData3"
        :checkbox-config="{reserve:false, range:true}"
        ref="xtable"
        :header-cell-style="{background:'#1373C2',color:'white'}"
      >
        <!-- <vxe-table-column type="checkbox" width="60"></vxe-table-column> -->
        <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
        <vxe-table-column
          field="customer_name"
          title="客户名称"
        ></vxe-table-column>
        <vxe-table-column
          field="bank_name"
          title="银行名称"
          sortable
        ></vxe-table-column>
        <vxe-table-column
          field="batch"
          title="缴费日期"
          sortable
        ></vxe-table-column>
        <vxe-table-column
          field="pay_type"
          title="支付方式"
          sortable
        ></vxe-table-column>
        <vxe-table-column field="extend" title="结算标识"></vxe-table-column>
        <vxe-table-column
          field="feilv"
          title="费率(千分位‰)"
        ></vxe-table-column>
        <vxe-table-column field="shouxufei" title="手续费">
          <!-- <template #default="{ row }">
            {{ parseFloat(row.shouxufei).toFixed(2) }}
          </template> -->
        </vxe-table-column>
        <vxe-table-column field="cnt" title="交易笔数"></vxe-table-column>
        <vxe-table-column field="payment" title="交易金额"></vxe-table-column>
        <vxe-table-column field="remark" title="备注"></vxe-table-column>
        <vxe-table-column
          field="create_time"
          title="创建时间"
        ></vxe-table-column>
      </vxe-table>
    </div>
    <el-pagination
        :current-page.sync="tablePage3.currentPage"
        :page-size.sync="tablePage3.pageSize"
        :total="tablePage3.totalResult"
        :pager-count="5"
        :page-sizes="[10, 20, 30, 40, 50, 100, 500]"
        class="p-bar"
        background
        layout="prev, pager, next, sizes, total, jumper"
        @current-change = "findList3"
        @size-change = "findList3"
      />
    <!-- <vxe-pager
      background
      size="small"
      :loading="loading3"
      :current-page.sync="tablePage3.currentPage"
      :page-size.sync="tablePage3.pageSize"
      :total="tablePage3.totalResult"
      :page-sizes="[
        10,
        20,
        100,
        { label: '大量数据', value: 1000 },
        { label: '全量数据', value: -1 },
      ]"
      :layouts="['PrevPage', 'Number', 'NextPage', 'FullJump', 'PageCount']"
      align="center"
      @page-change="findList3"
    >
    </vxe-pager> -->
  </div>
</template>

<script>
import { getproduct } from "../../request/api";
import Cookies from 'js-cookie';
export default {
  name: "JinjianNonebill",
  data() {
    return {
      customerid:'',
      form: {
        bank_name: '',
        pay_type: '',
        start_date:'',
        end_date:'',
        extend:''
      },
      loading3: false,
      tableData3: [],
      shouxufeiSum: 0,
      tablePage3: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      applyBillNewStatus:false,
      choseid: [],
      //半年筛选条件
      // pickerOptions:{    //日期禁选项
      //   disabledDate:(time) => {
      //     if(this.form.end_date){
      //         let data = new Date(this.form.end_date);
      //         let halftime = 365/2*24*3600*1000;
      //         let restime =data.getTime()-halftime
      //         if(time.getTime()<restime || time.getTime()>data.getTime()){
      //           return true;
      //         }else{
      //           return false;
      //         }
      //     }else{
      //       return false;
      //     }
          
      //   }
      // },
      // pickerOptions2:{    //日期禁选项
      //   disabledDate:(time) => {
      //     let d = new Date();
      //     d.setDate(0);
      //     d.setHours(0);
      //     d.setSeconds(0);
      //     d.setMinutes(0);
      //     if(time.getTime() > d.getTime()){
      //         return true;
      //     }
           
      //     if(this.form.start_date){
      //         let data = new Date(this.form.start_date);
      //         let halftime = 365/2*24*3600*1000;
      //         let restime =data.getTime()+halftime
      //         if(time.getTime()>restime || time.getTime()<data.getTime()){
      //           return true;
      //         }else{
      //           return false;
      //         }
      //     }else{
      //       return false;
      //     }
          
      //   }
      // }
       
      pickerOptions2:{    
        disabledDate:(time) => {
          let d = new Date();
          //本月不让选中筛选条件
          // d.setDate(0);
          // d.setHours(0);
          // d.setSeconds(0);
          // d.setMinutes(0);
          return time.getTime() > d.getTime();
        }
      },
      pickerOptions:{
        disabledDate:(time) => {
          let d = new Date();
          //本月不让选中筛选条件
          // d.setDate(0);
          // d.setHours(0);
          // d.setSeconds(0);
          // d.setMinutes(0);
          return time.getTime() > d.getTime();
        }
      }
    };
  },
  created() {
    this.customerid = Cookies.get('customerid');
    this.findList3();
  },
  mounted() {},
  methods: {
    /**
     * 监听时间选择
     */
    changeDate(){
      if(this.form.start_date && this.form.end_date){
        this.findList3()
      }
    },
    /**
     * 申请开票 2021-08-10
     */
    applyBillNew(){
      if(!this.form.start_date){
        this.$message({
          message: '请选择开始日期',
          type: 'warning',
          offset:100
        });
        return false
      }
      if(!this.form.end_date){
        this.$message({
          message: '请选择结束日期',
          type: 'warning',
          offset:100
        });
        return false
      }
      if(this.tableData3.length <= 0){
        this.$message({
          message: '当前时间段内没有未开票的缴费数据',
          type: 'warning',
          offset:100
        });
        return false
      }

      this.$router.push({
        name: "billdetail",
        // params: { chosearray: this.choseid },
        query:{ 
          bank_name: this.form.bank_name,
          pay_type: this.form.pay_type,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          extend:this.form.extend,
        }
      });
    },
    /**
     * 申请开票( 原逻辑 )
     */
    applybill() {
      var that = this;
      var table = that.$refs.xtable;
      var insertRecords = table.getCheckboxRecords(true);
      if(insertRecords.length==0){
          this.$message({
          message: '请选择需要开票的明细',
          type: 'warning',
          offset:100
        });
        return
      }
      this.choseid = []
      // let minDate = '2030-01-01';
      // let maxDate = '1970-01-01';
      insertRecords.forEach(function (element) {
        // minDate = new Date(minDate).getTime() > new Date(element.batch).getTime()?element.batch:minDate
        // maxDate = new Date(maxDate).getTime() < new Date(element.batch).getTime()?element.batch:maxDate
        that.choseid.push(element.receipt_detail_id);
      });
      // const daysCount = this.getDaysBetween(minDate,maxDate)
      // console.log(minDate,maxDate)
      // console.log(daysCount)
      // console.log(insertRecords.length)
      // if(daysCount+1 != insertRecords.length){
      //   this.$message({
      //     message: '选择开票的明细必须是连续的日期，不能有间隔',
      //     type: 'warning',
      //     offset:100
      //   });
      //   return
      // }

      var data = {};
      data.ctrl = "receiptDetail";
      data.func = "checkSelect";
      data.pay_type = this.form.pay_type;
      data.receipt_detail_id = that.choseid;
      getproduct(data).then((res) => {
        if (res.code == 200) {
          that.$router.push({
            name: "billdetail",
            params: { chosearray: that.choseid },
            query:{ id: that.choseid }
          });
        }
      })
    },
    goback() {
      this.$router.push({ name: "product" });
    },
    agin() {
      this.form = {
        bank_name: '中国光大银行北京分行'
      };
    this.findList3();
    },
    find() {
      this.findList3();
    },
    refshtable() {
      this.findList3();
    },
    findList3() {
      let d = new Date();
      d.setDate(1);
      d.setHours(0);
      d.setSeconds(0);
      d.setMinutes(0);
      this.applyBillNewStatus  = false;
      if(this.form.end_date){
           let data = new Date(this.form.end_date);
          if(data.getTime() > d.getTime()){
              this.applyBillNewStatus  = true;
          }
      }
      if(this.form.start_date){
          let data = new Date(this.form.start_date);
          if(data.getTime() > d.getTime()){
              this.applyBillNewStatus  = true;
          }
      }
      
      
      this.loading3 = true;
      var data = {};
      if (this.form) {
        data = this.form;
      }
      data.status = '0';
      data.ctrl = "receiptDetail";
      data.func = "index";
      data.page = this.tablePage3.currentPage;
      data.limit = this.tablePage3.pageSize;
      // data.status='0'
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.tableData3 = res.data.list;
          this.tablePage3.totalResult = res.data.dataCount;
          this.shouxufeiSum = res.data.shouxufeiSum;
          this.loading3 = false;
          // const list = res.data.list;
          // this.loading3 = false;
          // this.tablePage3.totalResult = list.length;
          // this.tableData3 = list.slice(
          //   (this.tablePage3.currentPage - 1) * this.tablePage3.pageSize,
          //   this.tablePage3.currentPage * this.tablePage3.pageSize
          // );
        }
      });
    },
    handlePageChange3({ currentPage, pageSize }) {
      this.tablePage3.currentPage = currentPage;
      this.tablePage3.pageSize = pageSize;
      this.findList3();
    },
    getDaysBetween(dateString1,dateString2){
      var  startDate = Date.parse(dateString1);
      var  endDate = Date.parse(dateString2);
      var days=(endDate - startDate)/(1*24*60*60*1000);
      // alert(days);
      return  days;
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.vxe-pager {
  background: none;
}
/deep/.vxe-body--column {
  height: 54px;
}
.el-input {
  width: 305px;
}
.formtop {
  background: #fff;
  padding: 20px 0;
  margin-bottom: 10px;
  // min-width: 1500px;
  .formtop-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-left: 3px solid #315bd2;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .chaxunbutton {
    width: 96px;
    background: #3d7fff;
    border-radius: 3px;
    text-align: center;
    color: #fff;
  }
  .clearbutton {
    width: 96px;
    background: #f0f2f5;
    border-radius: 3px;
    border: 1px solid #d7dbe0;
    text-align: center;
    color: #000;
  }
  .fresh {
    // width: 96px;
    // background: #f0f2f5;
    // border-radius: 3px;
    // border: 1px solid #d7dbe0;
    // text-align: center;
    // line-height: 36px;
    // font-size: 14px;
    margin-bottom: 20px;
    // cursor: pointer;
  }
  .bill {
    // width: 96px;
    // background: #3d7fff;
    // border-radius: 3px;
    // text-align: center;
    // color: #fff;
    // line-height: 36px;
    margin-bottom: 20px;
    // font-size: 14px;
    // cursor: pointer;
  }
}
.backbut {
  margin-bottom: 20px;
}
</style>