<template>

	<div style="height:auto;margin-top: 94px;background: #fff;">
		<headtop :activeIndex="activeIndex" :title="titlename" :ifxianshi="false"></headtop>
		<div class='title-div'>资质自查结果</div>
		<div class="companySelect">
			<!-- <div>公司选择：</div>
			<el-select v-model="customer_name" placeholder="请选择">
				<el-option v-for="item in infoValue" :key="item.customer_name" :label="item.customer_name"
					:value="item.customer_name">
				</el-option>
			</el-select>

			<el-button type="primary" @click="customer_nameSearch">搜索</el-button> -->
		</div>
		<div class='content-div' v-show="bigShow">
			<!-- <div>1.缺少<span>供热/供水许可证、法人身份证（正面）、法人身份证（反面）、营业执照</span>四项资质 </div>
			<div>2.<span>组织机构代码证</span>不合规范</div> -->
			<!-- 	<div v-if="infoValue.apply_status==2">审核成功</div>
			<div v-if="infoValue.apply_status==1">审核中</div>
			<div v-if="infoValue.apply_status==0">未申请</div>
			<div v-if="infoValue.apply_status==-1">审核失败</div>
			<div v-if="infoValue.apply_status==-1"><span>{{status.apply_message}}</span></div> -->

			<!-- <div v-if="infoValueJia.apply_status==1">有未完成的申请正在审核中</div>
			<! <div v-if="dataValue.apply_status==0">未申请</div> -->
			<!-- <div v-if="dataValue.apply_status==-1">审核失败</div> -->
			<!-- <div v-if="infoValueJia.apply_status==-1">审核失败：<span>{{infoValueJia.apply_message}}</span></div> -->
			<!-- 	<div v-if="infoValueJia.status==100">贵公司尚未在客户通SAAS平台注册或已注册但未提交公司资料，请登录客户通SAAS平台提交公司资料。</div>
			<div v-if="infoValueJia.status==101">贵公司进件信息正在审核中</div>
			<div v-if="infoValueJia.status==102">贵公司自查信息正在审核中</div>
			<div v-if="infoValueJia.status==-100">审核失败原因{{infoValueJia.error}}</div> -->
			<div v-if="infoValueJia.status==200&&infoValueJia.defect_str">
				需补充的资质图片有：{{infoValueJia.defect_str}}
			</div>
			<div v-else>{{infoValueJia.error}}</div>
			<!-- 审核成功 -->
		</div>
		<div class='company-info' v-if="bigShow&&infoValueJia.status!=100">
			<div class='company-info-companyName'>公司名称：{{infoValueJia.customer_name}} 法人代表：{{infoValueJia.person}}</div>
			<div class="company-info-updateName">请再次核对贵公司的法人信息，如有变化，请及时更新法人相关资料信息
				<buttona class='company-info-button'
					v-show="bigShow&&infoValueJia.status!=100&&infoValueJia.status!=101&&infoValueJia.status!=102"
					@click="gotoUpdate">去更新</buttona>
			</div>
		</div>
		<div class='table-view' v-show="bigShow&&infoValueJia.status!=100">
			<div class="table-view-itemView">
				<div class='table-div-item-label'>
					供热/供水许可证</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'供热/供水许可证')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'business_permit_file','business_permit_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="business_permit_file" :file-list="business_permit_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('business_permit_file','business_permit_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">
					原件手机拍照或者彩色扫描，且确保最新年检章信息。<br />可用政府相关部门发的收费文件代替，也可用非当月且最近三个月给用户开具的采暖费发票代替,电子发票需打印并盖公章<br />文件格式为bmp、png、jpeg、jpg或gif，
				</div>
			</div>
			<div class="table-view-itemView">
				<div class='table-div-item-label'>法人身份证（正面）</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'法人身份证（正面）')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'legal_idfront_file','legal_idfront_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="legal_idfront_file" :file-list="legal_idfront_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('legal_idfront_file','legal_idfront_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">原件手机拍照或者彩色扫描,扫描件需要加盖公司公章<br />文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>

			<div class="table-view-itemView">
				<div class='table-div-item-label'>法人身份证（反面）</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'法人身份证（反面）')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'legal_idback_file','legal_idback_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="legal_idback_file" :file-list="legal_idback_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('legal_idback_file','legal_idback_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">原件手机拍照或者彩色扫描,扫描件需要加盖公司公章<br />文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>
			<div class="table-view-itemView">
				<div class='table-div-item-label'>营业执照</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'营业执照')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'business_licence_file','business_licence_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="business_licence_file" :file-list="business_licence_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('business_licence_file','business_licence_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">原件手机拍照或者彩色扫描，且确保最新年检章信息，字迹清晰。<br />文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>
			<!-- <div class="table-view-itemView">
				<div class='table-div-item-label'>组织机构代码证</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'组织机构代码证')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'organization_file','organization_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="organization_file" :file-list="organization_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('organization_file','organization_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">原件手机拍照或者彩色扫描，且确保最新年检章信息。文件格式为bmp、png、jpeg、jpg或gif</div>
			</div> -->

			<div class="table-view-itemView">
				<div class='table-div-item-label'>开户许可证</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'开户许可证')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'bank_permit_file','bank_permit_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="bank_permit_file" :file-list="bank_permit_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('bank_permit_file','bank_permit_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">原件手机拍照或者彩色扫描，且确保最新年检章信息。<br />文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>

			<div class="table-view-itemView">
				<div class='table-div-item-label'>公司Logo图片</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'公司Logo图片')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'logo_file','logo_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="logo_file" :file-list="logo_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete" @click="deletepic('logo_file','logo_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>

			<div class="table-view-itemView">
				<div class='table-div-item-label'>公司门牌照</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'公司门牌照')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'customer_brand_file','customer_brand_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="customer_brand_file" :file-list="customer_brand_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('customer_brand_file','customer_brand_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">公司门和公司牌子的合影，公司全称或简称字迹要清晰<br />文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>

			<div class="table-view-itemView">
				<div class='table-div-item-label'>公司门头照</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'公司门头照')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'customer_door_file','customer_door_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="customer_door_file" :file-list="customer_door_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('customer_door_file','customer_door_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">公司门和公司牌子的合影，公司全称或简称字迹要清晰<br />文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>


			<div class="table-view-itemView">
				<div class='table-div-item-label'>公司内景照</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'公司内景照')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'customer_inner_file','customer_inner_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="customer_inner_file" :file-list="customer_inner_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('customer_inner_file','customer_inner_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">公司楼内经营场所照片,且实景拍摄,不可用历史照片,避免人员入镜<br />文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>




			<div class="table-view-itemView">
				<div class='table-div-item-label'>公司收费窗口照</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'公司收费窗口照')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'customer_bar_file','customer_bar_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="customer_bar_file" :file-list="customer_bar_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepic('customer_bar_file','customer_bar_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">
					公司收费窗口全景，且实景拍摄避免人员入镜<br />
					文件格式为bmp、png、jpeg、jpg或gif
				</div>
			</div>



			<div class="table-view-itemView">
				<div class='table-div-item-label'>审核材料</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'审核材料')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'examine_file','examine_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="examine_file" :file-list="examine_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete" @click="deletepic('examine_file','examine_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">文件格式为bmp、png、jpeg、jpg或gif</div>
			</div>



			<div class="table-view-itemView">
				<div class='table-div-item-label'>业务办理授权函</div>
				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,'业务办理授权函')}"
					:on-success=" (res, file) => { handleAvatarSuccess( 'auth_file','auth_file', res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					ref="auth_file" :file-list="auth_file">
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete" @click="deletepic('auth_file','auth_file')">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>

				</el-upload>
				<div class="fileFormat">
					公司公章需清晰，请上传扫描件<br />
					文件格式为bmp、png、jpeg、jpg或gif</div>
				<div class="el-upload__text flexClass" @click="download">
					<!-- <img class="scimg" src="../assets/submit/push.png" /> -->
					<i class="el-icon-document scimg chengImg"></i>
					<p style="font-size: 16px; margin: 0;">模板下载</p>
				</div>

			</div>





			<div class='table-view-itemView' v-for="(i,index) in infoValueJia.data" :key="i.file_id">
				<div class='table-div-item-label'>
					{{i.name}}
				</div>


				<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
					:auto-upload="true" :before-upload="(file) => {return beforeUpload(file,i.name)}"
					:on-success=" (res, file) => { handleAvatarSuccessForFun( 'account_file'+index,index, res,file ); }"
					:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
					:ref="'account_file'+index" :file-list="[account_fileArr[index]]">

					<!-- i.account_file.real_url?[{url:i.account_file.real_url}]:'' -->
					<!-- account_fileArr -->
					<div slot="file" slot-scope="{ file }">
						<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
						<span class="el-upload-list__item-actions">
							<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
								<i class="el-icon-zoom-in"></i>
							</span>
							<span class="el-upload-list__item-delete"
								@click="deletepicForFun('account_file'+index,index,file)">
								<i class="el-icon-delete"></i>
							</span>
						</span>
					</div>
					<i class="el-icon-plus"></i>
				</el-upload>
				<div class='example'>
					示例
					<img :src="baseUrl+'/public/static/img/example.png'"
						@click="chakanXin('/public/static/img/example.png')" />
				</div>
			</div>

		</div>
		<!-- v-if="infoValue.apply_status!=1" -->
		<el-button class='submit'
			v-show="bigShow&&infoValueJia.status!=100&&infoValueJia.status!=101&&infoValueJia.status!=102"
			@click="submitFun" :loading="loading" v-if="infoValue.apply_status!=1">
			提交</el-button>

		<!-- <el-dialog :visible.sync="dialogVisible" v-show="dialogVisible" @close='dialogVisible=false'>
				<img width="100%" :src="dialogImageUrl" alt="" />
			</el-dialog> -->
		<!-- <el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="" />
			</el-dialog> -->
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>

	</div>
</template>

<script>
	import Cookies from "js-cookie";
	import config from "../request/config";
	import {
		getproduct

	} from "../request/api";
	import headtop from "../components/moneyHeader";
	export default {
		name: "Resgin",
		components: {
			headtop,
		},
		data() {
			return {
				bigShow: false,
				infoValueJia: "",
				loading: false,

				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				customer_name: "",
				account_fileArr: [],
				baseUrl: config.baseUrl,
				uploadurl: config.baseUrl + "/index.php/api/index",
				dialogImageUrl: "",
				dialogVisible: false,
				infoValue: "",
				bank_permit_file: [], //开户许可证
				business_licence_file: [], //营业执照
				business_permit_file: [], //供热/供水许可证
				customer_bar_file: [], //公司收费窗口照
				customer_brand_file: [], //公司门牌照
				customer_door_file: [], //公司门头照
				customer_inner_file: [], //公司内景照
				legal_idback_file: [], //身份证反面
				legal_idfront_file: [], //身份证正面
				logo_file: [], //客户通logo
				organization_file: [], //营业执照
				examine_file: [], //审核材料
				auth_file: [], //业务办理授权函
				disabled: false,

			}
		},
		mounted() {
			console.log("揍他")
			this.getInfo()


		},
		methods: {
			download() {
				window.open(config.baseUrl + '/public/static/业务办理授权函模板.docx')
			},
			chakanXin(file) {
				this.dialogImageUrl = config.baseUrl + file;
				this.dialogVisible = true;
			},


			submitFun() {
				console.log("为啥是看", this.infoValueJia)
				this.loading = true
				if(this.$route.query.customer_id){
					getproduct({
						base_id: this.infoValueJia.base_id,
						data: JSON.stringify(this.infoValueJia),
						ctrl: 'payinfo',
						func: 'companypayinfo_edit',
						customer_id: this.infoValueJia.customer_id,
						// customer_id: this.$route.query.customer_id,
						customercompany_id:this.$route.query.customer_id
					
					}).then((res) => {
						if (res.code == 200) {
							console.log(res)
					
							this.infoValue = res.data
							this.$message({
								type: "success",
								message: "提交成功",
								offset: 120,
							});
							this.loading = false
							this.disabled = true
					
							// this.getStatus()
					
							this.getInfo()
						} else {
							this.disabled = false
							this.loading = false
						}
					});
				}else{
					getproduct({
						base_id: this.infoValueJia.base_id,
						data: JSON.stringify(this.infoValueJia),
						ctrl: 'payinfo',
						func: 'companypayinfo_edit',
						customer_id: this.infoValueJia.customer_id,
						// customer_id: this.$route.query.customer_id,
						customercompany_id:this.infoValueJia.customer_id
					
					}).then((res) => {
						if (res.code == 200) {
							console.log(res)
					
							this.infoValue = res.data
							this.$message({
								type: "success",
								message: "提交成功",
								offset: 120,
							});
							this.loading = false
							this.disabled = true
					
							// this.getStatus()
					
							this.getInfo()
						} else {
							this.disabled = false
							this.loading = false
						}
					});
				}
				

			},
			getInfo() {

				getproduct({
					ctrl: "payinfo",
					func: "companypayinfo",
					// customer_id: this.getCookie("customerid"),
					customer_id: this.$route.query.customer_id,
					request_type: 'pc'

				}).then((res) => {
					if (res.code == 200) {


						this.infoValue = res.data
						console.log("this.infoValue", this.infoValue)


						this.customer_name = this.$route.query.customer_name
						console.log("this.customer_name", this.customer_name)
						console.log("this.infoValue23123", this.infoValue)
						for (var i = 0; i < this.infoValue.length; i++) {
							console.log("没进来吗阿萨德")
							if (this.customer_name == this.infoValue[i].customer_name) {

								this.infoValueJia = this.infoValue[i]
								console.log("123123", this.infoValueJia)
								this.bigShow = true


								this.bank_permit_file = []; //开户许可证
								this.business_licence_file = []; //营业执照
								this.business_permit_file = []; //供热/供水许可证
								this.customer_bar_file = []; //公司收费窗口照
								this.customer_brand_file = []; //公司门牌照
								this.customer_door_file = []; //公司门头照
								this.customer_inner_file = []; //公司内景照
								this.legal_idback_file = []; //身份证反面
								this.legal_idfront_file = []; //身份证正面
								this.logo_file = []; //客户通logo
								this.organization_file = []; //营业执照
								this.examine_file = []; //审核材料
								this.auth_file = []; //业务办理授权函



								//开户许可证
								if (this.infoValueJia.bank_permit_file.real_url) {
									this.bank_permit_file.push({
										'url': this.infoValueJia.bank_permit_file.real_url,
									})
								}

								//业务办理授权函
								if (this.infoValueJia.auth_file.real_url) {
									this.auth_file.push({
										'url': this.infoValueJia.auth_file.real_url
									})
								}



								if (this.infoValueJia.business_licence_file.real_url) {
									//营业执照
									this.business_licence_file.push({
										'url': this.infoValueJia.business_licence_file
											.real_url
									})
								}
								//供热/供水许可证
								if (this.infoValueJia.business_permit_file.real_url) {
									this.business_permit_file.push({
										'url': this.infoValueJia.business_permit_file.real_url
									})
								}
								//公司收费窗口照
								if (this.infoValueJia.customer_bar_file.real_url) {
									this.customer_bar_file.push({
										'url': this.infoValueJia.customer_bar_file.real_url
									})
								}

								//公司门牌照
								if (this.infoValueJia.customer_brand_file.real_url) {
									this.customer_brand_file.push({
										'url': this.infoValueJia.customer_brand_file.real_url
									})
								}
								//公司门头照
								if (this.infoValueJia.customer_door_file.real_url) {
									this.customer_door_file.push({
										'url': this.infoValueJia.customer_door_file.real_url
									})
								}
								//公司内景照
								if (this.infoValueJia.customer_inner_file.real_url) {
									this.customer_inner_file.push({
										'url': this.infoValueJia.customer_inner_file.real_url
									})
								}

								//身份证反面
								if (this.infoValueJia.legal_idback_file.real_url) {
									this.legal_idback_file.push({
										'url': this.infoValueJia.legal_idback_file.real_url
									})
								}

								//身份证正面
								if (this.infoValueJia.legal_idfront_file.real_url) {
									this.legal_idfront_file.push({
										'url': this.infoValueJia.legal_idfront_file.real_url
									})
								}
								//客户通logo
								if (this.infoValueJia.logo_file.real_url) {
									this.logo_file.push({
										'url': this.infoValueJia.logo_file.real_url
									})
								}
								//营业执照
								if (this.infoValueJia.organization_file.real_url) {
									this.organization_file.push({
										'url': this.infoValueJia.organization_file.real_url
									})
								}
								//审核材料
								if (this.infoValueJia.examine_file.real_url) {
									// config.baseUrl +
									this.examine_file.push({
										'url': this.infoValueJia.examine_file.real_url
									})
								}

								console.log("这个是什么", this.infoValueJia.data)
								for (var j = 0; j < this.infoValueJia.data.length; j++) {
									if (this.infoValueJia.data[j].account_file.real_url) {
										this.account_fileArr[j] = {
											url: this.infoValueJia.data[j].account_file.real_url
										}
										console.log(j, this.infoValueJia.data[j].account_file.real_url)
									}
								}




							}
						}



						// :file-list="account_fileArr">
						// infoValue.data
						// i.account_file.real_url?[{url:i.account_file.real_url}]:''



					}
				});


			},

			gotoUpdate() {
				this.$router.push('/updatePersonInfo')
				console.log("这个是什么customer_id", this.infoValueJia.customer_id)
				this.setCookie("customerid_xin", this.infoValueJia.customer_id)
				// this.$router.push({
				// 	path: '/updatePersonInfo',
				// 	query: {
				// 		customer_id: this.infoValueJia.customer_id
				// 	}
				// })
			},
			showImg(file) {
				if (file && file.file_path) {
					return config.baseUrl + file.file_path;
				}
			},
			// @click="deletepic('bank_permit_file','bank_permit_file')"
			deletepic(ref, info) {
				console.log(ref, info)
				console.log("this.infoValue", this.infoValueJia)
				console.log("this.infoValue.info", this.infoValueJia.info)
				console.log("this.$refs[ref]", this.$refs[ref])
				this.infoValueJia.info = []
				this.$refs[ref].clearFiles()
				if (ref == 'bank_permit_file') {

					this.bank_permit_file = []
					console.log("没进来吗", this.bank_permit_file)

				}
			},
			deletepicForFun(ref, info, file) {
				// this.dataValue[info] = ''
				// this.$refs[ref].clearFiles()
				console.log("看看这个动态图的", ref, info)

				console.log("看看￥refs", this.$refs[ref])
				// this.infoValue.info = ''
				// this.account_fileArr[info].url=""
				// this.account_fileArr[j] = {
				// 	url: this.infoValueJia.data[j].account_file.real_url
				// }
				this.account_fileArr[info] = ''
				for (var i = 0; i < this.infoValueJia.data; i++) {
					// this.infoValueJia.data[info] = ''
					// this.infoValueJia.data[info].account_file.real_url=''
					this.infoValueJia.data[info] = ''

					console.log("file是什么", file)
				}
				console.log("this.infoValueJia.data[info] 这个是什么", this.infoValueJia.data[info])

				this.$refs[ref][0].clearFiles()
			},
			handlePictureCardPreview(file) {

				console.log(file)

				this.dialogImageUrl = file.url;
				this.dialogVisible = true;

			},
			//handleAvatarSuccessForFun( 'account_file'+index,index, res,file );
			handleAvatarSuccessForFun(type, typetwo, res) {
				this.infoValueJia.typetwo = "";

				// for (var i = 0; i < this.infoValue.data; i++) {
				// 	this.infoValue.data[typetwo] = ''
				// }
				// :on-success=" (res, file) => { handleAvatarSuccess( 'legal_idfront_file','legal_idfront_fileinfo', res,file ); }"
				console.log("type是撒", type)
				console.log("this.infoValueJia.data[type]是撒", this.infoValueJia.data[typetwo])
				if (res.code == 200) {
					this.infoValueJia.data[typetwo].account_file = res.data;
					console.log("为啥上传图片不对", this.infoValueJia.data[typetwo].account_file.file_id)

				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				// this.$forceUpdate();
			},
			handleAvatarSuccess(type, typetwo, res) {
				console.log(type, typetwo, res)
				this.infoValueJia[typetwo] = "";
				// :on-success=" (res, file) => { handleAvatarSuccess( 'legal_idfront_file','legal_idfront_fileinfo', res,file ); }"
				if (res.code == 200) {
					console.log("为啥上传图片不对", res.data)
					this.infoValueJia[type] = res.data;
					console.log("this.infoValueJia[type]", this.infoValueJia[type])
				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				// this.$forceUpdate();
			},
			exceedimg() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});
			},

			beforeUpload(file, fileName) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				const whiteList = ["bmp", "png", "jpeg", "jpg", "gif"];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message({
						type: "warning",
						message: "格式不正确，请重新上传",
						offset: 120,
					});
					return false;
				}


				// const isLt10M = file.size / 1024 / 1024 < 2
				// if (!isLt10M) {


				// 	this.$message({
				// 		type: "error",
				// 		message: "上传文件大小不能超过 2MB!",
				// 		offset: 120,
				// 	});
				// 	return false;
				// }




				//   const isLt2M = file.size / 1024 / 1024 < 2;

				//   if (!isLt2M) {
				//     this.$message({
				//       type: "warning",
				//       message: "上传文件大小不能超过 2MB",
				//       offset: 120,
				//     });
				//     return false;
				//   }

				// 对应文件名称
				this.filedata['serviceFileName'] = fileName

				return true;
			}

		}

	}
</script>

<style lang="scss" scoped>
	/deep/.el-form-item__label {
		line-height: 74px !important;
	}

	/deep/.el-upload--picture-card {
		line-height: 74px !important;
		width: 78px;
		height: 78px;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.title-div {
		font-size: 30px;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		margin: 0 auto;
		text-align: center;
		padding-top: 59px;
	}

	.content-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: left;
		margin: 0 auto;
		margin-top: 49px;
		text-align: left;
		font-size: 20px;
		width: 70%;
		color: #F86060;
	}

	.content-div span {
		color: #F86060
	}



	.company-info {
		display: flex;
		flex-direction: row;
		margin: 0 auto;
		margin-top: 30px;
		width: 70%;
		justify-content: space-between;

	}

	.company-info-button {
		width: 86px;
		height: 37px;
		background: #0484EF;
		border-radius: 3px;
		font-size: 15px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 11px 21px;
		/* line-height: 36px; */
	}

	.company-info-companyName {
		font-size: 17px;
		font-weight: 400;
		color: #0086F8;
		width: 40%;

	}

	.company-info-updateName {
		font-size: 17px;
		font-weight: 400;
		color: #999999;
	}

	.table-view {
		height: auto;
		width: 70%;
		border: 1px solid #CFCFCF;
		margin: 0 auto;
		margin-top: 21px;
	}

	.table-view-itemView {
		border-bottom: 1px solid #CFCFCF;
		height: 220px;
		padding-left: 69px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		// margin-right: 75px;
	}

	.table-div-item-label {
		margin-right: 59px;
		width: 200px;
	}

	.fileFormat {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 36px;
		margin-left: 17px;
		width: 340px;
	}

	.submit {
		width: 206px;
		height: 56px;
		background: #246DED;
		border-radius: 5px;
		margin: 0 auto;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 36px;
		margin-top: 113px;
		border: none;

	}

	/deep/.el-upload--picture-card i {
		margin-top: 24px;
	}

	.companySelect {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	.flexClass {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		// top:-15px;
	}

	.chengImg {
		font-size: 40px;
		color: #315BD2;
		margin-bottom: 10px;
	}

	.example {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-left: 200px;
	}

	.example img {
		width: 100px;
		height: 100px;
		margin-left: 20px;
	}
</style>
