<template>
  <div style="100%">
    <el-menu
      :default-active="act"
      class="vertical-demo"
      background-color="#000000"
      text-color="#fff"
      router
      active-text-color="#fff"
    >
      <el-menu-item
        :index="item.route"
        v-for="(item, index) in slidearray"
        :key="index"
      >
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "JinjianSilde",
  data() {
    return {
      slidearray: [
        {
          route: "examine",
          name: "待审核",
        },
        {
          route: "alreadyauditing",
          name: "已审核",
        },
      ],
      act: "",
    };
  },
  watch: {
    $route(to){
      this.act=to.name
  }
  },
  mounted() {
    this.act = this.$route.name;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.vertical-demo {
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 200px;
}
.vertical-demo .el-menu-item.is-active {
  background: #315bd2 !important;
}
.vertical-demo .el-submenu__title.is-active {
  background: #315bd2 !important;
}
</style>