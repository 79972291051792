<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>企业公章管理</el-breadcrumb-item>
			<!-- <el-breadcrumb-item>上传公章图片</el-breadcrumb-item> -->
		</el-breadcrumb>
		<!-- <div class="titleDiv">
			企业公章管理&nbsp;&nbsp;&nbsp;&nbsp;上传公章图片
		</div> -->
		<div class="createLookDiv" @click="OfficialUpload">
			<div>
				<img src="../../assets/company/upload.png">
				<span>上传企业公章</span>
			</div>
		</div>
		<el-table :data="list" class="elTable" style="width: 100%">
			<el-table-column label="公章名称">
				<template slot-scope="scope">
					<!-- <i class="el-icon-time"></i> -->
					<span style="margin-left: 10px">{{ scope.row.name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="是否默认">
				<template slot-scope="scope">
					<!-- <el-popover trigger="hover" placement="top"> -->
					<!-- <p>姓名: {{ scope.row.name }}</p>
						<p>住址: {{ scope.row.address }}</p> -->
					<div slot="reference" class="name-wrapper">
						<!-- {{ scope.row.isDefault }} -->
						<span v-if="scope.row.isDefault">是</span>
						<span v-else>否</span>

					</div>
					<!-- </el-popover> -->
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button size="mini" class="buttonOne" @click="handleEdit(scope.$index, scope.row)">设置默认公章
					</el-button>
					<el-button size="mini" class="buttonTwo" type="danger"
						@click="handleDelete(scope.$index, scope.row)">删除</el-button>

					<!-- <el-button size="mini" class="buttonTwo" type="danger" @click="handleExit(scope.$index, scope.row)">
						替换</el-button>
 -->
				</template>

			</el-table-column>
		</el-table>
		<div class="blockDiv">


		</div>


		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>您确定要删除该公章吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleSure">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getCompanyList
	} from "../../request/api";
	export default {
		data() {
			return {
				list: [],
				dialogVisible:false,
				currentPage1: 5,
				currentPage2: 5,
				currentPage3: 5,
				currentPage4: 4,
				customerid: "",
				row:"",
			}
		},
		created() {
			this.customerid = this.getCookie("customerid")
			this.getCompanyListFun()

		},
		methods: {
			dialogVisibleSure(){
				this.dialogVisible=false
				
				var data = {
					func: "DeleteEnterpriseSeal",
					ctrl: "FadadaApi",
					customer_id: this.customerid,
					SealName: this.row.name
				
				};
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;
				
						this.$message({
							message: '公章删除成功',
							type: 'success'
						});
				
						this.getCompanyListFun()
				
					}
					// } else {
					// 	console.log("看看错误返回值", res)
					// 	this.$message({
					// 		message: res.error,
					// 		type: 'error'
					// 	});	
					// }
				})
				
			},
			OfficialUpload() {
				// this.$router.push({path:'/company/OfficialUpload',query:{setid:123456}})
				this.$router.push({
					name: "OfficialUpload"
				});

			},

			getCompanyListFun() {
				// setInterval(function() {
				var data = {
					func: "QueryEnterpriseSeal",
					ctrl: "FadadaApi",
					customer_id: this.customerid,

				};
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;

						this.list = res.data
						if (!this.money) {
							this.money = 0
						}

						console.log("看看返回值", this.companyList)
					} else {
						console.log("看看错误返回值", res)
					}
				})
				// }), 1000)
			},

			handleExit(index, row) {
				this.$router.push({
					name: "OfficialUpload",
					params: {

						row: row
					}
				});
			},

			handleEdit(index, row) {
				console.log(index, row);

				var data = {
					func: "SetEnterpriseDefauSeal",
					ctrl: "FadadaApi",
					customer_id: this.customerid,
					SealName: row.name

				};
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;

						this.getCompanyListFun()
						this.$message({
							message: '默认公章设置成功',
							type: 'success'
						});

					} else {
						console.log("看看错误返回值", res)
						this.$message({
							message: '默认公章设置失败',
							type: 'error'
						});
					}
				})


			},
			handleDelete(index, row) {
				console.log(index, row);
				this.dialogVisible=true
				this.row=row
				

			},


		}
	}
</script>

<style lang="scss" scoped>
	.titleDiv {
		font-size: 20px;
		font-weight: bold;
		color: #0C0C0C;
		margin: 0 auto;
		margin-top: 30px;
		text-align: center;
	}

	.createLookDiv {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-right: 24px;
		font-size: 14px;
		font-weight: bold;
		color: #F19944;
		margin-top: 20px;
	}

	.createLookDiv div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.createLookDiv div:nth-child(1) {
		border: none;
		/* padding-right: 15px; */
	}

	.createLookDiv div:nth-child(2) {
		padding-left: 15px;
	}

	.createLookDiv div img {
		width: 24px;
		height: 19px;
		margin-right: 10px;
	}

	.el-table td.el-table__cell,
	.el-table th.el-table__cell.is-leaf {
		text-align: center;
	}

	.el-table th.el-table__cell.is-leaf {
		background-color: #1373C2;
		font-size: 16px;
		font-weight: bold;
		color: #F6F6F6;
		line-height: 30px;
	}

	.elTable {
		margin-top: 17px;
	}

	.el-table td.el-table__cell {
		font-weight: bold;
	}

	.blockDiv {
		margin-top: 32px;
	}

	.blockDiv .el-pagination {
		margin: 0 auto;
		text-align: center;
	}

	.buttonOne {

		height: 30px;
		border: 1px solid #1373C2;
		border-radius: 5px;
		font-size: 14px;
		font-weight: bold;
		color: #1373C2;

	}

	.buttonTwo {

		height: 30px;
		background: #FFFFFF;
		border: 1px solid #FB0000;
		border-radius: 5px;
		font-size: 14px;
		font-weight: bold;
		color: #FF0000;
	}

	.breadcrumbClass {
		height: 39px;
		width: 100%;
		margin-top: 8px;
		border-bottom: 1px solid #F0F1F5;
	}

	/deep/.el-table td,
	/deep/.el-table th {
		text-align: center;
	}

	/deep/.el-table th {
		background-color: #1373C2;
		color: #fff;
	}
</style>
