<template>
  <div v-loading="loading2">
    <!-- <el-button class="backbut" @click="goback">返回</el-button> -->
    <el-button class="backbut" @click="refresh">刷新</el-button>
    <el-button class="backbut" @click="getSelectEvent">批量审核</el-button>
    <vxe-table border :data="tableData" :header-cell-style="{background:'#1373C2',color:'white'}" :loading="loading" ref="xTable">
      <vxe-column type="checkbox" width="60"></vxe-column>
      <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
      <vxe-table-column
        field="C_Title" 
        title="业务名称"
      ></vxe-table-column>
      <vxe-table-column
        field="D_ArriveTime" 
        title="到达时间"
      ></vxe-table-column>
      <vxe-table-column
        field="C_WFNode" 
        title="当前节点"
      ></vxe-table-column>
      <vxe-table-column
        field="C_PrevNode" 
        title="上级节点"
      ></vxe-table-column>
      <vxe-table-column
        field="D_ArriveTime" 
        title="上级节点时间"
      ></vxe-table-column>
      <vxe-table-column title="操作" align="left" fixed="right" width="260">
        <template #default="{ row }">
          <el-button
            style="background-color: #1373C2"
            type="primary"
            @click="shenhe(row)"
            >处理</el-button
          >
          <el-button
            style="background-color: #1373C2"
            type="primary"
            v-if="row.C_WFNode == '客户汇款验证'"
            @click="req_img(row)"
            >查看账户验证信息</el-button
          >
          <!-- <el-button
            style="background-color: #315bd2"
            type="primary"
            @click="lookat(row)"
            >查看</el-button
          > -->
        </template>
      </vxe-table-column>
    </vxe-table>
    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="tablePage3.currentPage"
      :page-size="tablePage3.pageSize"
      :total="tablePage3.totalResult"
      :page-sizes="[
        10,
        25,
        50,
        100,
      ]"
      layout="sizes, prev, pager, next"
      align="center"
      @page-change="handlePageChange3"
    >
    </vxe-pager>
    <el-dialog title="流程处理" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="流程处理" name="first">
          <e-row type="flex">
            <el-col :span="2"
              ><div style="line-height: 40px">常用意见</div></el-col
            >
            <el-col :span="8">
              <el-select v-model="selectvalue" clearable placeholder="请选择" @change="selectChanged">
                <el-option
                  v-for="item in yjoptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </e-row>

          <el-input
            type="textarea"
            :rows="7"
            placeholder="请输入内容"
            v-model="textarea"
            style="margin-top: 20px"
          >
          </el-input>

          <el-row type="flex" style="margin-top: 20px">
            <el-col :span="3" v-for="(item, index) in buttonlist" :key="index">
              <el-button
                style="width: 90%"
                type="primary"
                v-if="item.isVisible == 1"
                @click="buttonaction(item.name)"
                >{{ item.name }}</el-button
              >
              <el-select
                v-if="item.isVisible == 1 && item.name == '回退'"
                v-model="selectvalue"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in htoptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog title="查看" :visible.sync="dialogtwo">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClickaa">
        <!-- <el-tab-pane label="流程日志" name="first">
          <vxe-table
            border
            ref="liuchengtable"
            :data="tableDatatwo"
            height="500"
          >
            <vxe-table-column
              field="WFNode"
              title="节点名称"
            ></vxe-table-column>
            <vxe-table-column
              field="RealName"
              title="办理人"
            ></vxe-table-column>
            <vxe-table-column field="Action" title="处理"></vxe-table-column>
            <vxe-table-column
              field="ProcessTime"
              title="处理时间"
            ></vxe-table-column>
            <vxe-table-column
              field="address"
              title="处理意见"
              show-overflow
            ></vxe-table-column>
          </vxe-table>
        </el-tab-pane> -->
        <el-tab-pane label="附件" name="two">
          <vxe-table border ref="fujiantable" :data="fileList">
            <vxe-table-column
              field="附件类型"
              title="附件类型"
              width="140"
            ></vxe-table-column>
            <vxe-table-column
              field="附件名称"
              title="附件名称"
              width="140"
            ></vxe-table-column>
            <vxe-table-column
              field="增加时间"
              title="增加时间"
              width="140"
            ></vxe-table-column>
            <vxe-table-column title="操作" width="200">
              <template #default="{ row }">
                <el-button
                  style="background-color: #315bd2"
                  type="primary"
                  @click="download(row)"
                  >下载</el-button
                >
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getproduct } from "../../request/api";
export default {
  data() {
    return {
      tableData: [],
      listheadarray: [],
      dialogFormVisible: false,
      htoptions: [],
      buttonlist: [],
      activeName: "two",
      textarea: "",
      selectvalue: "",
      yjoptions: [],
      chosetodoId: "",
      chosealltodoId: "",//批量
      dialogtwo: false,
      tableDatatwo: [],
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      loading:false,
      loading2:false,
      tablePage3: {
        currentPage: 1,
        pageSize:10,
        totalResult: 0,
      },
    };
  },
  components: {},
  mounted() {
    this.getpage();
  },
  methods: {
    refresh(){
        this.getpage();
    },
    req_img(row){
      let that = this;
      var data = {
        ctrl: "indexExamine",
        func: "reqimg",
        wf_id: row.I_InstanceID,
      };
      getproduct(data).then((res) => {
        if (res.code == 200 && res.data != 0) {
               that.chakan(res.data); 
        }
      });
    },
    chakan(file) {
      if(file){
        this.dialogImageUrl = file;
        this.dialogVisible = true;
      }
    },
    download(row) {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = row.c_ImageName || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src =  row.real_url;
    },
    handleClickaa() {
      this.$refs.liuchengtable.reloadData(this.tableDatatwo);
      this.$refs.fujiantable.reloadData(this.fileList);
    },
    // buttonaction(name) {
    //   switch (name) {
    //     case "同意":
    //       this.agreen("同意");
    //       break;
    //   }
    // },
    agreen(name) {
      var data = {
        ctrl: "indexExamine",
        func: "examinedata",
        todoId: this.chosetodoId,
        action: name,
        mind: this.textarea,
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功！",
            offset: 100,
          });
        }
      });
    },
    remit(todoId) {
      var data = {
        ctrl: "indexExamine",
        func: "examinePost",
        todoId: todoId,
        action: '同意',
        mind: '同意',
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.getpage();
          this.$message({
            type: 'success',
            message: '处理成功!',
            offset: 100,
          });
        }
      });
    },
    shenhe(row) {
      // if(row.C_WFName == '进件流程' && ['开始'].includes(row.C_WFNode)){
      //   this.$router.push({ name: "recordDetail", query: { TodoID: row.I_TodoID, InstanceID: row.I_InstanceID,status: -1} });
      // } else 
      if(['商户汇款', '客户汇款验证'].includes(row.C_WFNode)) {
      // if(row.C_WFNode=='热付通平台审核') {
        this.$confirm('银行的汇款验证是否已经汇款完毕', '提示', {
          confirmButtonText: '已汇款',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.remit(row.I_TodoID)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      } else{
        window.open(row.open_url)
      }
      // this.chosetodoId = row.I_TodoID;
      // var data = {
      //   ctrl: "indexExamine",
      //   func: "examinedata",
      //   WFNode: row.C_WFNode,
      //   InstanceID: row.I_InstanceID,
      // };
      // getproduct(data).then((res) => {
      //   if (res.code == 200) {
      //     console.log(res);
      //     this.buttonlist = res.data.SystemActions;
      //     this.yjoptions = res.data.NeedMindList;
      //     this.htoptions = res.data.BackNodeList;
      //     this.dialogFormVisible = true;
      //   }
      // });
    },
    lookat(row) {
      console.log(row);
      var data = {
        ctrl: "indexExamine",
        func: "workflowlog",
        instanceID: row.I_InstanceID,
        // instanceID: 459,
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.tableDatatwo = res.data.logInfo;
          this.fileList = res.data.fileList;
          this.dialogtwo = true;
        }
      });
    },
    goback() {
      this.$router.push({ name: "product" });
    },
    getpage() {
      this.loading = true;
      var data = {
        ctrl: "indexExamine",
        func: "dataList",
        // wfid: this.$route.query.wfid,
        // wfnode: this.$route.query.wfnode,
        // viewname: this.$route.query.viewname,
        page:this.tablePage3.currentPage,
        limit:this.tablePage3.pageSize,
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.listheadarray = res.data.column;
          this.tableData = res.data.list;
          this.tablePage3.totalResult = res.data.dataCount;
        }
        this.loading = false;
      }).catch((err)=>{
        console.log(err)
          this.loading = false;
      })
    },
    handlePageChange3({ currentPage, pageSize }) {
      this.tablePage3.currentPage = currentPage;
      this.tablePage3.pageSize = pageSize;
      this.getpage();
    },
    selectChanged(value){
      this.textarea = value;
    },
    getSelectEvent () {
      let selectRecords = this.$refs.xTable.getCheckboxRecords()
      console.log(selectRecords)
      if(selectRecords.length == 0){
          this.$message({
            type: 'warning',
            message: '请选择待处理的信息'
          }); 
             return;
      }
      this.chosealltodoId = selectRecords.map(item => {
         return item.I_TodoID;
      })
      var data = {
        ctrl: "indexExamine",
        func: "examinedata",
        WFNode: selectRecords[0].C_WFNode,
        InstanceID: selectRecords[0].I_InstanceID,
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.buttonlist = res.data.SystemActions;
          this.yjoptions = res.data.NeedMindList;
          this.htoptions = res.data.BackNodeList;
          this.dialogFormVisible = true;
          this.activeName = 'first';
        }
      });
    },
    buttonaction(name) {
       this.loading2=true;
        var data = {
          ctrl: "indexExamine",
          func: "examineallPost",
          todoId: this.chosealltodoId,
          action: name,
          mind: this.textarea,
        };
        getproduct(data).then((res) => {
          this.loading2=false;
          if (res.code == 200) {
            this.dialogFormVisible = false;
            this.getpage();
            this.$message({
              type: "success",
              message: res.data,
              offset: 100,
            });
          }
        }).catch(()=>{
           this.loading2=false;
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.backbut {
  margin-bottom: 20px;
}
</style>