<template>
  <div>
    <el-container>
      <el-header>
        <headtwo></headtwo>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <slide style="height: 100%"></slide>
        </el-aside>
        <el-main class="mainheight">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import headtwo from "../components/auditing/headtwo.vue";
import slide from "../components/goCollectTreasure/goSlideXin.vue";
export default {
  components: { headtwo, slide },
  name: "goCollectTreasure",
  data() {
    return {
      isshow: 1,
    };
  },
 
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/body {
  background: #f7f8f9 !important;
}
.test{
	width:10px;
}
</style>