<template>
  <div>
    <el-container>
      <el-header>
        <headtwo></headtwo>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <silde style="height: 100%"></silde>
        </el-aside>
        <el-main class="mainheight">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import headtwo from "../components/bill/headtwo.vue";
import silde from "../components/bill/silde.vue";
export default {
  components: { headtwo, silde },
  name: "JinjianBill",
  data() {
    return {
      isshow: 1,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/body {
  background: #f7f8f9 !important;
}
.el-main {
  background: #f7f8f9 !important;
  box-sizing: border-box;
}
</style>