<template>
	<div style="100%">
		<el-menu :default-active="act" class="vertical-demo" background-color="#000000" text-color="#fff" router
			active-text-color="#fff">
			<el-menu-item :index="item.route" v-for="(item, index) in slidearray" :key="index" >
				<span slot="title">{{ item.name }}</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	export default {
		name: "company",
		data() {
			return {
				slidearray: [
					// {
					// 	route: "",
					// 	name: "首页",
					// },
					{
						route: "notCertified",
						name: "账号管理",
					},
					// {
					// 	route: "createContract",
					// 	name: "合同模板管理",
					// },
					// {
					// 	route: "OfficialUpload",
					// 	name: "企业公章上传",
					// },

					{
						route: "contractList",
						name: "合同模板管理",
					},
					{
						route: "officialList",
						name: "企业公章管理",
					},
					// {
					// 	route: "recharge",
					// 	name: "充值",
					// },
					{
						route: "detailedTwo",
						name: "充值明细",
					},
					
					{
						route: "consumptionDetails",
						name: "消费明细",
					}
					// ,
					// 	{
					// 	route: "applicationList",
					// 	name: "掌上派工",
					// },
					

				],
				act: "",
			};
		},
		watch: {
			$route(to) {
				this.act = to.name
			}
		},
		mounted() {
			this.act = this.$route.name;
		},
		methods: {
		
		},
	};
</script>

<style lang="scss" scoped>
	.vertical-demo {
		height: 100%;
		padding: 20px 0;
		box-sizing: border-box;
		position: fixed;
		top: 60px;
		left: 0;
		bottom: 0;
		width: 200px;
	}

	.vertical-demo .el-menu-item.is-active {
		background: #315bd2 !important;
	}

	.vertical-demo .el-submenu__title.is-active {
		background: #315bd2 !important;
	}
</style>
