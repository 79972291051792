<template>
  <div style="margin-top: 94px;background:#F6F6F6">
    <headtop :activeIndex="activeIndex" title="热付通SAAS开票信息变更"></headtop>

    <div class="tools-bar">
      <el-button class="backbut" @click="backpage">返回</el-button>
      <!-- <el-button type="default" @click="backpage">返回</el-button> -->
      <div class="rt">
        <!-- <el-button
          type="default"
          icon="el-icon-s-promotion"
          @click="$router.push({ name: 'home' })"
          >提交申请</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-s-custom"
          @click="$router.push({ name: 'records' })"
          >申请记录</el-button
        > -->
      </div>
    </div>
    <div class="box">
      <div class="hd">
        <!-- 申请记录表 -->
      </div>
      <el-table header-row-class-name="table-header" :data="list" style="width: 100%">
        <el-table-column prop="date" label="名称">
          <template slot-scope="scope">
            {{ scope.row.titleList.join("、") }}
          </template>
        </el-table-column>
        <el-table-column label="结算状态" width="120">
          <template slot-scope="scope">
            <i class="badge" :class="{
              gray: scope.row.balance_flag == -1,
              red: scope.row.balance_flag == 1,
              green: scope.row.balance_flag == 2,
              blue: scope.row.balance_flag == 0,
            }"></i>
            {{ scope.row.balance_flag | statusFilter }}
          </template>
        </el-table-column>
        <el-table-column label="开票状态" width="120">
          <template slot-scope="scope">
            <i class="badge" :class="{
              gray: scope.row.receipt_flag == -1,
              red: scope.row.receipt_flag == 1,
              green: scope.row.receipt_flag == 2,
              blue: scope.row.receipt_flag == 0,
            }"></i>
            {{ scope.row.receipt_flag | statusFilter }}
          </template>
        </el-table-column>
        <el-table-column label="执照状态" width="120">
          <template slot-scope="scope">
            <i class="badge" :class="{
              gray: scope.row.licence_flag == -1,
              red: scope.row.licence_flag == 1,
              green: scope.row.licence_flag == 2,
              blue: scope.row.licence_flag == 0,
            }"></i>
            {{ scope.row.licence_flag | statusFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="进件时间">
          <template slot-scope="scope">
            <span v-if="scope.row.receipt_flag == 0">{{ scope.row.create_time }}</span>
            <span v-else-if="scope.row.receipt_flag == 1">{{ scope.row.receipt_create_time }}</span>
            <span v-else>{{ scope.row.receipt_complete_time }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="460">
          <template slot-scope="scope">
            <!--  :disabled="scope.row.apply_status == 0 ? true : false" -->
            <el-button type="primary" size="small" @click="gotodetailC(scope.row.apply_status, scope.row.payinfo_id)">
              查看详情
            </el-button>
            <!--  v-if="scope.row.receipt_flag != 1" -->
            <el-button type="primary" size="small" :disabled="scope.row.balance_flag == 1 ? true : false"
              @click="gotodetail0(scope.row.payinfo_id, 1)">
              结算信息编辑
            </el-button>
            <el-button type="primary" size="small" :disabled="scope.row.receipt_flag == 1 ? true : false"
              @click="gotodetail(scope.row.payinfo_id, 1)">
              开票信息编辑
            </el-button>
            <el-button type="primary" size="small" :disabled="scope.row.licence_flag == 1 ? true : false"
              @click="goCorp(scope.row.titleList, scope.row.payinfo_id)">
              营业执照变更
            </el-button>
            <!-- <el-button v-if="scope.row.wf_id>0" type="primary" size="small" @click="showWorkFlowLog(scope.row.wf_id)">流程日志</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" layout="total, sizes, prev, pager, next, jumper" :current-page="pagination.index"
        :page-sizes="[10, 20, 30, 40]" :page-size="pagination.size" :total="pagination.total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" background>
      </el-pagination>
    </div>
    <!-- <div v-if="list.length == 0" class="nonelist">
      暂无任何数据
    </div> -->
    <workflowlog :dialog-visible="wfDialogVisible" :wf-id="wfId" @close="wfDialogVisible = false" />
    <div class="copyright">Copyright©2021 -2025 热付通SASS平台</div>
  </div>
</template>

<script>
import { getproduct } from "../request/api";
import headtop from "../components/header";
import workflowlog from '@/components/WorkFlowLog'
export default {
  name: "Records",
  components: {
    headtop,
    workflowlog
  },
  filters: {
    statusFilter: function (value) {
      switch (value) {
        case -1:
          value = "变更失败";
          break;
        case 1:
          value = "变更中";
          break;
        case 2:
          value = "变更成功";
          break;
        case 0:
          value = "正常";
          break;
        default:
          value = "";
          break;
      }
      return value;
    },
  },
  data() {
    return {
      list: [],
      activeIndex: "2",
      pagination: {
        index: 1,
        size: 10,
        total: 0,
      },
      wfId: '0',
      wfDialogVisible: false,
    };
  },
  mounted() {
    this.getpage();
  },
  methods: {
    backpage() {
      this.$router.push({ name: "product" });
    },
    getpage() {
      var data = {
        func: "applytable",
        ctrl: "payinfo",
        page: this.pagination.index,
        limit: this.pagination.size,
        apply_status: 2,

        /*        type: 'receipt' */
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list;
          this.pagination.total = res.data.dataCount;
        }
      });
    },
    showWorkFlowLog(wfId) {
      this.wfId = wfId
      this.wfDialogVisible = true
      console.log(wfId)
    },
    goCorp(e, payinfo_id) {
      console.log(e)

      this.$router.push({
        name: "corpChange",
        query: { list: JSON.stringify(e), payinfo_id: payinfo_id },
      });
    },
    gotodetailC(status, id) {
      this.$router.push({
        name: "recordDetail",
        query: {
          payinfoid: id,
          status: status
        },
      });
    },
    gotodetail0(id, status) {
      this.$router.push({
        name: "balanceDetail",
        query: { payinfoid: id, status: status },
      });
    },
    gotodetail(id, status) {
      this.$router.push({
        name: "receiptDetail",
        query: { payinfoid: id, status: status },
      });
    },
    handleCurrentChange(val) {
      this.pagination.index = val;
      this.getpage();
    },
    handleSizeChange(val) {
      this.pagination.size = val;
      this.getpage();
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin: 30px auto;
  padding: 30px 40px;
  width: 75%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;

  .hd {
    padding: 12px 0;
    font-size: 20px;
    border-bottom: 1px solid #f0f1f5;
  }
}

/deep/ .el-table .table-header {
  position: relative;
  font-size: 18px;
  line-height: 1.8;
  color: #333;

  .cell {
    padding-left: 16px;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 5px solid #333;
      border-bottom: 5px solid transparent;
    }
  }
}

.badge {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.red {
    background-color: #f00000;
  }

  &.green {
    background-color: #04b377;
  }

  &.gray {
    background-color: #ccc;
  }

  &.blue {
    background-color: #abcdef;
  }
}

.nonelist {
  text-align: center;
  width: 100%;
  margin-top: 300px;
  font-size: 30px;
  color: #333;
}

.tools-bar {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 42px;
  width: 75%;

  /deep/ .el-button {
    padding: 20px;
    font-size: 18px;
    border-radius: 5px;

    &.el-button--default {
      background-color: #e1e1e1;
    }

    &.el-button--primary {
      background-color: #246fec;
    }
  }
}

.pagination {
  margin-top: 30px;
  text-align: center;
}
</style>
