<template>
	<div>
		<el-button class="backbut" @click="goback">返回</el-button>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>掌上稽查APP注册用户审批</el-breadcrumb-item>
			<el-input class='inputClass' placeholder="请输入手机号或姓名" v-model="inputValue" @input="getCompanyList"></el-input>
		</el-breadcrumb>
		<div class="button-div">
			<el-button class="backbut" @click="importContacts">导入联系人</el-button>
			<el-button class="backbut" @click="addContacts" style="margin-right: 20px;">添加联系人</el-button>
			<!-- <el-button class="backbut" @click="importTemplate" style="margin-right: 20px;">下载模板</el-button> -->
			<!-- <el-link type="primary" :href=" config.baseUrl + '/public/static/审核材料模板/结算账户图片模板.docx'">下载模板</el-link> -->
			<!-- link.href = config.baseUrl + '/public/static/审核材料模板/结算账户图片模板.docx' -->
			<el-link type="primary" :href="url+ '/public/static/导入模板维修工.xls'" >下载模板</el-link>
		</div>
		<el-table :data="list" class="elTable" style="width: 100%">
			<el-table-column label="联系人姓名">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.name }}</span>
				</template>
			</el-table-column>

			<el-table-column label="手机号">
				<template slot-scope="scope">

					<span style="margin-left: 10px">{{ scope.row.mobile }}</span>
				</template>
			</el-table-column>
			<el-table-column label="职务">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.post }}</span>
				</template>
			</el-table-column>

			<el-table-column label="公司名">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.enterprise }}</span>

				</template>
			</el-table-column>
			<el-table-column label="审核状态">
				<template slot-scope="scope">
					<span style="margin-left: 10px" v-if="scope.row.examine == 1">待审核</span>
					<span style="margin-left: 10px" v-if="scope.row.examine == 2">审核成功 </span>
					<span style="margin-left: 10px" v-if="scope.row.examine == -1">审核失败 </span>

				</template>
			</el-table-column>

			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button v-if="scope.row.examine == 1" size="mini" class="buttonOne"
						@click="adopt(scope.$index, scope.row)">通过
					</el-button>
					<el-button v-if="scope.row.examine == 1" size="mini" class="buttonTwo" type="danger"
						@click="refuse(scope.$index, scope.row)">拒绝
					</el-button>
				</template>

			</el-table-column>
		</el-table>
		<div class="blockDiv">

			<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[100, 200, 300, 400]" :page-size="100"
				layout="total, sizes, prev, pager, next, jumper" :total="400">
			</el-pagination> -->
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>请填写拒绝原因（必填）</span>
			<el-input v-model="msgInput" class="msgInput-class"></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleSure">确 定</el-button>
			</span>
		</el-dialog>


		<el-dialog title="导入联系人" :visible.sync="dialogVisibleImportContacts" width="30%" :before-close="handleClose">

			<el-row>
				<el-col :span="12">

					<div class="input pushzizhi">
						<!-- link.href = config.baseUrl + '/public/static/审核材料模板/结算账户图片模板.docx' -->

						<el-upload class="upload-demo" :action="uploadurl" :on-preview="handlePreview"
							accept=".pdf, .doc, .docx, .xls, .xlsx" :auto-upload="true" :before-upload="(file) => {
							return beforeUpload(file)
							}" :on-success="
							(res, file) => {
								changeyyy(res);
							}
							" :on-exceed="exceedimg" :data="filedata" :headers="fileheaders" :limit="1" ref="permitupload" :on-remove="
							(file, fileList) => {
								changeyyy(res);
							}
							" :file-list="fileList">
							<!-- <div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="@/assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div> -->
							<el-button slot="trigger" size="small" type="primary">选取文件</el-button>

						</el-upload>





					</div>


				</el-col>
			</el-row>


			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleImportContacts = false">取 消</el-button>
				<!-- <el-button type="primary" @click="dialogVisibleImportContactsSure">确 定</el-button> -->
			</span>
		</el-dialog>


		<el-dialog title="添加联系人" :visible.sync="adddialogVisible" width="50%" :before-close="handleClose">



			<div class="nameDiv">
				<div class="name-label">姓名：</div>
				<el-input v-model="nameValue" class="msgInput-class"></el-input>
			</div>
			<div class="nameDiv">
				<div class="name-label">手机号：</div>
				<el-input v-model="mobileValue" class="msgInput-class"></el-input>
			</div>
			<div class="nameDiv">
				<div class="name-label">职务：</div>
				<el-input v-model="postValue" class="msgInput-class"></el-input>
			</div>
			<div class="nameDiv">
				<div class="name-label">密码：</div>
				<el-input v-model="passwordValue" class="msgInput-class"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="adddialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="adddialogVisibleSure">确 定</el-button>
			</span>
		</el-dialog>


	</div>
</template>

<script>
	import {
		getCompanyList
	} from "../../request/api";
	import Vue from 'vue'
	import Cookies from "js-cookie";
	import config from "../../request/config"

	import axios from 'axios'
	Vue.prototype.$axios = axios
	export default {
		name: "dispatch",
		data() {
			return {
				filedata: {
					// ctrl: "file",
					// func: "save",
					// type: "file",
					ctrl: "Contacts",
					func: "importConServe",
					type: "file",
				},
				nameValue: "",
				mobileValue: "",
				postValue: "",
				passwordValue: "",
				adddialogVisible: false,
				formlist: [],
				fileList: [],
				fileheaders: {
					token: Cookies.get("tooken"),
					source: "ZSJC",
				},
				uploadurl: config.baseUrl + "/index.php/api/index",
				url:config.baseUrl,
				dialogVisible: false,
				currentPage1: 5,
				currentPage2: 5,
				currentPage3: 5,
				currentPage4: 4,
				list: [],
				rowValue: "",
				msgInput: "",
				dialogVisibleImportContacts: false,
				inputValue:'',
			}
		},
		created() {
			this.customerid = this.getCookie("customerid")
			console.log("寄哪里")
			console.log("没走这个方法吗")
			this.getCompanyList()

		},

		methods: {
			importTemplate() {
				window.open(config.baseUrl + '/public/static/导入模板.xls')
			},
			adddialogVisibleSure() {
				

				var data = {
					func: "register",
					ctrl: "openApi",
					name: this.nameValue,
					mobile: this.mobileValue,
					post: this.postValue,
					password: this.passwordValue,
					cus_id: Cookies.get('customerid')

				};
				if (!this.nameValue) {
					this.$message({
						type: "error",
						message: "姓名不能为空",
						offset: 120,
					});
					return false;
				}
				if (!this.mobileValue) {
					this.$message({
						type: "error",
						message: "手机号不能为空",
						offset: 120,
					});
					return false;
				}

				var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
				if (!phonereg.test(this.mobileValue)) {
					this.$message({
						type: "error",
						message: "请填写正确手机号",
						offset: 120,
					});

					return false
				}
				if(!this.passwordValue){
					this.$message({
						type: "error",
						message: "请填写密码",
						offset: 120,
					});
					
					return false
				}
				
				
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;
						this.adddialogVisible = false
						this.getCompanyList()




						console.log("看看返回值", res)
					} else {
						console.log("看看返回值", res)
					}
				})





			},
			addContacts() {
				this.adddialogVisible = true
			},
			dialogVisibleImportContactsSure() {
				var data = {
					func: "importConServe",
					ctrl: "Contacts",
					// cus_id: this.getCookie("customerid"),
					file: this.formlist[0]

				};
				if (data.func == 'examinelist' && data.ctrl == 'openApi') {
					axios.defaults.headers.post['source'] = 'ZSJC';
				}


				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;



						console.log("看看返回值", res)
					} else {
						console.log("看看返回值", res)
					}
				})
			},

			exceedimg() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});

			},
			deletepic(ref, info) {
				this.formlist[info] = ''
				this.$refs[ref].clearFiles()
			},

			download() {
				window.open(config.baseUrl + '/public/static/审核材料模板/结算账户图片模板.docx')
			},
			// changeyyy(res.data.file_id, value, 'account_file');
			// changeyyy('', value, 'account_file');


			changeyyy(res) {
				if (res.code == 200) {
					this.$message({
						type: "success",
						message: "导入成功",
						offset: 120,
					});
					return false;
				} else {
					this.$message({
						type: "error",
						message: res.error,
						offset: 120,
					});
					return false;
				}

			},



			beforeUpload(file) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				const whiteList = ["pdf", "doc", "docx", "xls", "xlsx"];

				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message({
						type: "warning",
						message: "格式不正确，请重新上传",
						offset: 120,
					});
					return false;
				}

				// const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isLt2M) {
				// 	this.$message({
				// 		type: "warning",
				// 		message: "上传文件大小不能超过 2MB",
				// 		offset: 120,
				// 	});
				// 	return false;
				// }

				// 对应文件名称
				this.filedata['customer_id'] = Cookies.get('customerid')

				return true;
			},
			handlePreview(file) {
				console.log("看看handlePreview这个方法的file是什么", file)
				// if (file && file.file_path) {
				if (file && file.response.data.url) {
					// this.dialogImageUrl = config.baseUrl + file.file_path;
					this.dialogImageUrl = file.response.data.url
					// this.dialogVisible = true;
				}
			},

			importContacts() {
				this.dialogVisibleImportContacts = true




			},
			goback() {
				this.$router.push({
					name: "product"
				});
			},
			createFun() {
				this.$router.push({
					name: "createContract"
				});
			},
			getCompanyList() {
				console.log("没走这个方法吗")
				var data = {
					func: "examinelist",
					ctrl: "openApi",
					cus_id: this.getCookie("customerid"),
					status: 1,
					words:this.inputValue
				};
				if (data.func == 'examinelist' && data.ctrl == 'openApi') {
					axios.defaults.headers.post['source'] = 'ZSJC';
				}


				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;
						this.list = res.data


						console.log("看看返回值", res)
					} else {
						console.log("看看返回值", res)
					}
				})
			},
			adopt(index, row) {
				console.log(index, row);
				var data = {
					func: "examine",
					ctrl: "openApi",
					examine: 1,
					contacts_id: row.contacts_id
					// customer_id: this.customerid,
				};
				// if (data.func == 'examinelist' && data.ctrl == 'openApi') {
				// 	axios.defaults.headers.post['source'] = 'ZSPG';
				// }


				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: "通过成功",
							type: 'success'
						});

						this.getCompanyList()
						console.log("看看返回值", res)
					} else {
						console.log("看看返回值", res)
					}
				})

			},

			dialogVisibleSure() {
				console.log("拒绝", this.row)
				var data = {
					func: "examine",
					ctrl: "openApi",
					contacts_id: this.rowValue.contacts_id,
					examine: 2,
					msg: this.msgInput


				};
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: "拒绝成功",
							type: 'success'
						});
						this.getCompanyList()


						console.log("看看返回值", res)
						this.dialogVisible = false
					} else {
						this.$message({
							message: "拒绝失败",
							type: 'error'
						});
						this.dialogVisible = false
						this.getCompanyList()
						console.log("看看返回值", res)
					}
				})
			},

			refuse(index, row) {
				this.msgInput = ""

				console.log(index, row);
				this.dialogVisible = true

				this.rowValue = row



			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			}

		}
	}
</script>

<style scoped>
	.titleDiv {
		font-size: 20px;
		font-weight: bold;
		color: #0C0C0C;
		margin: 0 auto;
		margin-top: 30px;
		text-align: center;
	}

	.createLookDiv {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-right: 24px;
		font-size: 14px;
		font-weight: bold;
		color: #F19944;
		margin-top: 20px;
	}

	.createLookDiv div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.createLookDiv div:nth-child(1) {
		/* border-right: 1px solid #c1c1c1; */
		padding-right: 15px;
	}

	.createLookDiv div:nth-child(2) {
		padding-left: 15px;
	}

	.createLookDiv div img {
		width: 26px;
		height: 20px;
		margin-right: 10px;
	}

	.el-table td.el-table__cell,
	.el-table th.el-table__cell.is-leaf {
		text-align: center;
	}

	.el-table th.el-table__cell.is-leaf {
		background-color: #1373C2;
		font-size: 16px;
		font-weight: bold;
		color: #F6F6F6;
		line-height: 30px;
	}

	.elTable {
		margin-top: 17px;
	}

	.el-table td.el-table__cell {
		font-weight: bold;
	}

	.blockDiv {
		margin-top: 32px;
	}

	.blockDiv .el-pagination {
		margin: 0 auto;
		text-align: center;
	}

	.buttonOne {

		height: 30px;
		border: 1px solid #1373C2;
		border-radius: 5px;
		font-size: 14px;
		font-weight: bold;
		color: #1373C2;

	}

	.buttonTwo {

		height: 30px;
		background: #FFFFFF;
		border: 1px solid #FB0000;
		border-radius: 5px;
		font-size: 14px;
		font-weight: bold;
		color: #FF0000;
	}

	.breadcrumbClass {
		height: 39px;
		width: 100%;
		margin-top: 8px;
		border-bottom: 1px solid #F0F1F5;
		padding-bottom: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	/deep/.el-table td,
	/deep/.el-table th {
		text-align: center;
	}

	/deep/.el-table th {
		background-color: #1373C2;
		color: #fff;
	}

	.msgInput-class {
		margin-top: 20px;

	}

	.backbut {
		margin-bottom: 20px;
	}

	.nameDiv {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.name-label {
		width: 100px;
	}

	.button-div {
		margin: 20px 0;
	}
	.inputClass {
		width: 40vh;
		margin-left:3vh ;
	}
	
</style>
