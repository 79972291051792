<template>
	<div class="headertop">
		<el-row :gutter="10" style="border:none;height: 94px;">


			<el-col :span="8" :offset="3" style="display: flex; padding-top: 26px">

				<img src="../assets/index/logo.png" class="logoimg" />
				<div class="title">{{title}}</div>
			</el-col>
			<!-- <el-col :span="8" :offset="2" class="flexright"> -->
			<el-col :span="8" :offset="2" class="flexright">
				<div class="userlogin">
					<span class="moneyDiv" @click="shouyeFun">首页</span>
					<el-avatar size="large" :src="circleUrl"></el-avatar>
					<span class="username">{{ username }}</span>
					<div class="xian"></div>
					<span class="userexit" @click="removecokie">退出</span>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		name: "headertop",
		props: {
			activeIndex: String,
			ifxianshi: {
				type: Boolean,
				default: true
			},
			title: {
				type: String,
				default: '热付通SAAS进件申请平台'
			}
		},
		data() {
			return {
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				username: "用户",
			};
		},
		mounted() {
			if (this.getCookie("headimg")) {
				this.circleUrl = this.getCookie("headimg");
			}
			if (this.getCookie("username")) {
				this.username = this.getCookie("username");
			}
		},
		methods: {
			
			shouyeFun() {
				this.$router.push({
					path: "/product"
				});
			},
			//退出登录
			removecokie() {
				this.removeCookie("tooken");
				this.removeCookie("customerList");
				this.removeCookie("username");
				this.removeCookie("headimg");
				this.removeCookie('customerid');
				this.removeCookie('code');
				this.$router.push({
					name: 'login'
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-menu.el-menu--horizontal {
		border: none;
	}

	/deep/.el-menu--horizontal>.el-menu-item {
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		margin: 0 20px;
		padding: 0;
	}

	.el-menu-demo {
		display: flex;
	}

	.headertop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 94px;
		background-size: 100% 100%;
		background: url(../assets/index/header_bg.jpg) no-repeat;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
		z-index: 999999;

	}

	.logoimg {
		display: block;
		width: 127px;
		height: 42px;
	}

	.title {
		font-size: 24px;
		font-weight: 500;
		color: white;
		margin-left: 24px;
		border-left: 1px solid #266DED;
		padding-left: 24px;
		line-height: 42px;
		white-space: nowrap;
	}

	.flexright {
		display: flex;
		justify-content: flex-end;
		white-space: nowrap;

		.userlogin {
			display: flex;
			padding-top: 27px;
			margin-left: 15%;

			.username {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 40px;
				margin-left: 12px;
			}

			.xian {
				width: 1px;
				height: 20px;
				background: #266DED;
				margin: 10px 12px;
			}

			.userexit {
				position: relative;
				padding-left: 28px;
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 40px;
				cursor: pointer;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -9px;
					width: 17px;
					height: 18px;
					background: url(../assets/index/logout.png) no-repeat;
				}
			}
		}
	}

	.moneyDiv {

		margin-right: 19px;
		line-height: 40px;
		font-size: 14px;
		cursor: pointer;


	}
	/deep/.el-row{
		border: none;
	}
</style>
