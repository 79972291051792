<template>
	<div style="margin-top: 100px">
		<headtop :activeIndex="activeIndex" title="热付通SAAS开票基本信息"></headtop>
		<div class="box" v-if="type == 1">
			<div class="nav">
				<span></span>
				<div style="width: 50px"></div>
			</div>
			<div class="content">
				<div class="contentTop">
					<span>基本信息</span>
					<el-button size="small" class="editbutton" icon="el-icon-edit" @click="openEdit()">修改</el-button>
				</div>
			</div>
			<!-- 表格 -->
			<el-form>

				<div class="waikuang">
					<el-row ref="form" :model="formlist">
						<el-col :span="10">
							<el-form-item label="开户行">
								<div class="input">
									<el-input v-model="formlist.bank_name" :disabled="ifedit">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item label="发票抬头">
								<div class="input">
									<el-input v-model="formlist.invoice_title" :disabled="ifedit">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row type="flex">
						<el-col :span="10">
							<el-form-item label="银行账号">
								<div class="input">
									<el-input v-model="formlist.bank_account" :disabled="ifedit">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item label="开票地址+电话">
								<div class="input">
									<el-input v-model="formlist.company_address" :disabled="ifedit">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row type="flex">
						<el-col :span="10">
							<el-form-item label="纳税人识别号">
								<div class="input">
									<el-input v-model="formlist.business_licence_code" :disabled="ifedit"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item label="发票类型">
								<div class="input">
									<el-select v-model="formlist.receipt_type" :disabled="ifedit" style="width: 240px"
										placeholder="请选择">
										<el-option label="普通发票" value="普通发票"></el-option>
										<el-option label="增值税专用发票" value="增值税专用发票"></el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<div class="bottom">
					<div class="content">
						<div class="contentTop">
							<span>收货人信息</span>
							<el-button size="small" class="editbutton" icon="el-icon-edit" @click="address()">地址管理
							</el-button>
						</div>
					</div>
					<div class="waikuang">
						<el-row type="flex">
							<el-col :span="10">
								<el-form-item label="收货人名称">
									<div class="input">
										<el-input v-model="formlist.invoice_contacts" :disabled="ifedit">
										</el-input>
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<el-form-item label="手机号码">
									<div class="input">
										<el-input v-model="formlist.invoice_mobile" maxlength="11" :disabled="ifedit">
										</el-input>
									</div>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row type="flex">
							<el-col :span="10">
								<el-form-item label="详细地址">
									<div class="input">
										<el-input v-model="formlist.invoice_addr" :disabled="ifedit"></el-input>
									</div>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>

				<!-- 按钮 -->
				<el-form-item v-if="ifedit == false">
					<el-row class="buttonlist">
						<el-button type="submit" class="rightbutton" @click="submitechange" :disabled="ifdianji">提交修改
						</el-button>
					</el-row>
				</el-form-item>
			</el-form>
		</div>


		<div class="box" v-if="type == 2">
			<div class="nav">
				<span></span>
				<div style="width: 50px"></div>
			</div>
			<div class="content">
				<div class="contentTop">
					<span>收件信息</span>
					<div>
						<el-button size="small" class="editbutton" icon="el-icon-edit" @click="openEdit()">修改</el-button>
						<el-button size="small" class="editbutton" icon="el-icon-edit" @click="address()">地址管理</el-button>
					</div>
				</div>
			</div>
			<!-- 表格 -->
			<el-form>


				<div class="waikuang">
					
					<el-row type="flex">
						<el-col >
							<el-form-item label="收件公司名称">
								<div class="input">
									<el-input v-model="formlist.name" disabled="true">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row type="flex">
						<el-col >
							<el-form-item label="收件人">
								<div class="input">
									<el-input v-model="formlist.invoice_contacts" :disabled="ifedit">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row type="flex">
						<el-col >
							<el-form-item label="收件人电话">
								<div class="input">
									<el-input v-model="formlist.invoice_mobile" maxlength="11" :disabled="ifedit">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row type="flex">
						<el-col >
							<el-form-item label="收件地址">
								<div class="input">
									<el-input v-model="formlist.invoice_addr" :disabled="ifedit"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>

				<!-- 按钮 -->
				<el-form-item v-if="ifedit == false">
					<el-row class="buttonlist">
						<el-button type="submit" class="rightbutton" @click="submitechange" :disabled="ifdianji">提交修改
						</el-button>
					</el-row>
				</el-form-item>
			</el-form>
		</div>

		<addressdialo :dialog-visible="DialogVisible" :type="type" @close="DialogVisible=false" @choose="choose" />


	</div>
</template>

<script>
	import headtop from "../components/header";
	import addressdialo from "../components/addressdialo";
	import {
		getproduct
	} from "../request/api";
	export default {
		name: "receiptbase",
		components: {
			headtop,
			addressdialo
		},
		data() {
			return {
				formlist: {},
				ifedit: true,
				ifdianji: false,
				DialogVisible: false,
				type: ''
			};
		},
		mounted() {
			this.type = this.$route.query.type ?? '';
			this.getpage();
		},
		methods: {
			getpage() {
				var data = {
					func: "receiptbase",
					ctrl: "ReceiptAddress",
					type:this.type
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.formlist = res.data;
						console.log(this.formlist);
					}
				});
			},
			submitechange() {
				if (this.ifdianji) {
					return false;
				}
				this.ifdianji = true;
				var data = this.formlist;
				data.func = "receiptbase_save";
				data.ctrl = "ReceiptAddress";
				data.type = this.type
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: "修改成功！",
							type: "success",
							offset: 100,
						});

					}
					setTimeout(() => {
						this.ifdianji = false;
					}, 2000);

				});
			},
			openEdit() {
				if (this.ifedit) {
					this.ifedit = false
				} else {
					this.getpage();
					this.ifedit = true
				}

			},
			choose(data) {
				if (!this.ifedit) {
					this.formlist.invoice_addr = data.invoice_addr
					this.formlist.invoice_contacts = data.invoice_contacts
					this.formlist.invoice_mobile = data.invoice_mobile
				}
			},
			address() {
				this.DialogVisible = true
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin-bottom: 30px;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border-bottom: 1px solid #e4e7ed;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		border: 1px solid #315bd2;
		color: #315bd2;
	}

	/deep/.el-form-item {
		margin: 0;
	}

	/deep/.el-form-item__content {
		display: flex;
		line-height: 16px;
	}

	/deep/.el-form-item__label {
		width: 183px;
		// border-left: 1px solid rgba(168, 188, 235, 1);
		// border-right: 1px solid rgba(168, 188, 235, 1);
		line-height: 60px;
		// background: rgba(246, 249, 255, 1);
	}

	/deep/.el-row {
		border-bottom: 1px solid rgba(168, 188, 235, 1);
	}

	.box {
		width: 74%;
		margin: 0 13%;

		.nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 18px 0;
			border-bottom: 1px solid #e0e0e0;

			.navBack {
				color: #666666;
				font-size: 16px;
			}

			.navTitle {
				color: #333333;
				font-size: 20px;
				font-weight: 600;
			}
		}

		.content {
			margin-top: 40px;

			.contentTop {
				display: flex;
				justify-content: space-between;

				span {
					font-size: 24px;
					color: #333333;
					font-weight: 600;
				}

				.editbutton {
					border: 1px solid #315bd2;
					color: #315bd2;
				}
			}
		}
	}

	.waikuang {
		border: 1px solid rgba(168, 188, 235, 1);
		margin-top: 30px;
		border-bottom: none;

		// border-left: none;
		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.bottom {
		margin: 30px 0;

		.tabs {
			border: none;
		}
	}

	.buttonlist {
		display: flex;
		justify-content: center;
		margin-bottom: 10vh;
		border: none;
		border-top: 1px solid #e3e4e7;
		padding-top: 30px;
		width: 100%;

		.leftbutton {
			background: #eeeeee;
			border: 1px solid #eeeeee;
			color: #000;
			padding: 10px 30px;
		}

		.rightbutton {
			background: #315bd2;
			border-radius: 2px;
			color: #fff;
			padding: 10px 30px;
		}
	}

	.errormessage {

		width: max-content;
		margin: 0 13%;
		margin-bottom: 30px;
	}
</style>
