<template>
	<div>

		<!-- 如果微信没有选中 -->
		<!-- v-if="value == 'WXPAY'" -->
		<div v-show="weixinchosed">
			<!-- publicItem.publicIndex -->
			<!-- WXPAYitem, WXPAYindex -->
			<div class="zizhipage" v-for="(publicItem, publicIndex) in formlist" :key="publicIndex">
				<div class="zizhipage-title">
					公众号 {{ publicIndex + 1 }}
					<div v-if="publicIndex != 0" style="cursor: pointer" @click="deleteindex('WXPAY', publicIndex)">
						删除
					</div>
				</div>

				<el-row style="border: none">
					<el-col :span="12">
						<el-form-item required label="微信公众号名称">
							<div class="input">
								<el-input :disabled="ifedit" @input="
                      changeyyy(
                        publicIndex,
                        publicItem.bank_account,
                        listindex,
                        'bank_account'
                      )
                    " style="width: 80%" v-model="publicItem.wxmp_name" placeholder="请输入微信公众号名称"></el-input>
							</div>




						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item required label="微信公众号账号
">
							<div class="input">
								<el-input :disabled="ifedit" @input="
                      changeyyy(
                        publicIndex,
                        publicItem.wxmp_account,
                        listindex,
                        'wxmp_account'
                      )
                    " style="width: 80%" v-model="publicItem.wxmp_account" placeholder="请输入微信公众号账号"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="border: none">
					<el-col :span="12">



						<el-form-item required label="微信公众号密码
">
							<div class="input">
								<el-input :disabled="ifedit" @input="
                      changeyyy(
                        publicIndex,
                        publicItem.wxmp_pwd,
                        listindex,
                        'wxmp_pwd'
                      )
                    " style="width: 80%" v-model="publicItem.wxmp_pwd" placeholder="请输入微信公众号密码
"></el-input>
							</div>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item required label="微信管理员姓名

						">
							<div class="input">
								<el-input :disabled="ifedit"
									@input="changeyyy( publicIndex,publicItem.wxmp_contacts,listindex,'wxmp_contacts' ) "
									style="width: 80%" v-model="publicItem.wxmp_contacts" placeholder="请输入微信管理员姓名
"></el-input>
							</div>
						</el-form-item>
					</el-col>


				</el-row>
				<el-row style="border: none">
					<el-col :span="12">
						<el-form-item required label="微信管理员电话
					">
							<div class="input">
								<el-input :disabled="ifedit"
									@input=" changeyyy(publicIndex,publicItem.wxmp_mobile,listindex,'wxmp_mobile' )"
									style="width: 80%" v-model="publicItem.wxmp_mobile" placeholder="请输入微信管理员电话
					"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- v-if="value == 'WXPAY' && ifedit == false" v-show="weixinchosed" -->

		<div class="title" v-if="ifedit == false" @click="addylzh">
			新增公众号
		</div>



		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>

</template>

<script>
	import Cookies from "js-cookie";

	import config from "../request/config";
	export default {
		name: "JinjianJiesuan",
		props: {
			zfbchosed: Boolean,
			zfbshchosed: Boolean,
			weixinshchosed: Boolean,
			weixinchosed: Boolean,
			ylchosed: Boolean,
			formlist: Object,
			checkgroup: Array,
			ifedit: {
				default: false,
				type: Boolean,
			},
			rules: Object,
		},
		data() {
			return {
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				input: "",
				uploadurl: config.baseUrl + "/index.php/api/index",
				num: 2,
				proptions: [],
				cityoptions: [],
				value: "",
				weixinchecked: true,
				zfbchecked: true,
				weixinshchecked: true,
				zfbshchecked: true,
				ylchecked: true,
				dialogVisible: false,
				dialogImageUrl: "",
			};
		},
		mounted() {
			console.log("看看传值是什么this.formlist", this.formlist)
			// if(!this.formlist){
			// 	console.log("没进来是不")
			// 	this.formlist=[]
			
			// 	this.formlist=this.formlist.push({
			// 			wxmp_name: "", //微信公众号名称
			// 			wxmp_account: "", //微信公众号账号
			// 			wxmp_pwd: "", //微信公众号密码
			// 			wxmp_contacts: "", //微信管理员姓名
			// 			wxmp_mobile: "", //微信管理员电话
			// 		})
			// 		console.log("这个是什么",this.formlist)
				
			// }
		},
		methods: {

			deleteindex(name, index) {
				this.formlist.splice(index, 1);
				this.$forceUpdate();
			},

			// changeyyy(
			// 	publicIndex,
			// 	publicItem.bank_account,
			// 	listindex,
			// 	'bank_account'
			// )


			changeyyy(listindex, value, index, type) {
				var aa = {
					ALIPAY: this.zfbchecked,
					YSF: this.ylchecked,
					ALILIFE: this.zfbshchecked,
					WXLIFE: this.weixinshchecked,
					WXPAY: this.weixinchecked,
				};

				if (index == 0) {
					var obj = this.formlist;
					for (var i in obj) {
						for (var k = 0; k < obj[i].length; k++) {
							if (listindex == k) {
								// if (listindex == 0) {
								if (aa[i] == true) {
									obj[i][k][type] = value;
								}
							}
						}
					}
					this.formlist = obj;
				}
				this.$forceUpdate();
			},

			addylzh() {
				var list = {
					wxmp_name: "", //微信公众号名称
					wxmp_account: "", //微信公众号账号
					wxmp_pwd: "", //微信公众号密码
					wxmp_contacts: "", //微信管理员姓名
					wxmp_mobile: "" //微信管理员电话
				};
				this.formlist.push(list);
				this.$forceUpdate();
				console.log("这个为啥和我想的不一样", this.formlist)
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	.zizhipage {
		border: 1px solid rgba(168, 188, 235, 1);
		margin-bottom: 20px;

		// border-left: none;
		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}

		.zizhipage-title {
			background: #f6f9ff;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			color: #315bd2;
			padding: 10px 20px;
			display: flex;
			justify-content: space-between;
		}

		.zizhipage-titletwo {
			width: 124px;
			height: 40px;
			border: 1px solid #e3e4e7;
			color: #333333;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			line-height: 40px;
			text-align: center;
			margin: 20px;
		}

		.zizhipage-border {
			width: 96%;
			border: 1px solid #e3e4e7;
			display: block;
			margin: 0 auto;
			margin-top: 10px;
		}
	}

	.title {
		width: 183px;
		padding: 17px 0;
		background: #f6f9ff;
		border: 1px solid #a8bceb;
		text-align: center;
		color: #315bd2;
		margin: 30px 0;
		font-size: 14px;
		cursor: pointer;

		&:first-child {
			margin-top: 0;
		}
	}

	.xlselect {
		width: 120px;
		margin-right: 20px;
	}

	.pushzizhi {
		min-width: 900px;
		border-bottom: none;
		// border-left: none;

		/deep/.el-form-item__label {
			line-height: 74px !important;
		}

		/deep/.el-upload--picture-card {
			background-color: transparent;
			border: none;
			width: auto;
			height: auto;
		}

		/deep/.el-upload__text {
			line-height: 1;
		}

		/deep/.el-upload-list--picture-card .el-upload-list__item {
			width: 78px;
			height: 78px;
		}

		.input {
			padding: 10px 20px 0;
			width: 520px;
			align-items: center;

			.text {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				margin-right: 12px;
			}

			.scimg {
				width: 26px;
				height: 26px;
				padding-top: 5px;
			}
		}
	}
</style>
