import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import wxlogin from 'vue-wxlogin';
import Cookies from 'js-cookie';
import common from './request/common.vue'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'


Vue.use(VXETable)
Vue.component('my-component', {
  components: {
    wxlogin
  }
});
Vue.prototype.setCookie = function (name, cvalue) {
  return  Cookies.set(name, cvalue)
}

Vue.prototype.getCookie = function (name) {
  return  Cookies.get(name)
}

Vue.prototype.removeCookie = function (name) {
  return  Cookies.remove(name)
}

Vue.prototype.$commourl = common//挂载到Vue实例上面
Vue.config.silent = true

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
