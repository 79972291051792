<template>
  <div>
    <el-button class="backbut" @click="goback">返回</el-button>
    <el-button class="editbutton" @click="openEdit()" v-if="form.status == -1"
      >修改</el-button
    >
    <el-form ref="form" :model="form" label-width="150px">
      <div class="formtop">
        <div class="formtop-title">发票信息</div>
        <el-form-item label="客户名称">
          <el-input v-model="customerName" :disabled="true"></el-input>
        </el-form-item>
        <el-row type="flex">
          <el-col :span="10">
            <el-form-item label="开始日期">
              <el-date-picker
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                v-model="form.start_date"
                style="width: 240px"
                :disabled="dataflag"
                @change="findList4"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="结束日期">
              <el-date-picker
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                v-model="form.end_date"
                style="width: 240px"
                :disabled="dataflag"
                @change="findList4"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10">
            <!-- <el-form-item label="开票日期">
              <el-date-picker
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                v-model="form.receipt_date"
                style="width: 240px"
                :disabled="ifedit"
              ></el-date-picker>
            </el-form-item> -->
            <el-form-item label="发票金额(元)">
              <el-input
                v-model="form.receipt_amount"
                :disabled="ifedit"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="发票类型">
              <el-select
                v-model="form.receipt_type"
                :disabled="ifedit"
                style="width: 240px"
                placeholder="请选择"
              >
                <el-option label="普通发票" value="普通发票"></el-option>
                <el-option label="增值税专用发票" value="增值税专用发票"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="formtop">
        <div class="formtop-title">开票信息</div>
        <el-row type="flex">
          <el-col :span="10">
            <el-form-item label="开户行">
              <el-input
                v-model="form.bank_name"
                :disabled="ifedit"
                style="width: 305px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10">
            <el-form-item label="发票抬头">
              <el-input
                v-model="form.invoice_title"
                :disabled="ifedit"
                style="width: 305px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="银行账号">
              <el-input v-model="form.bank_account" :disabled="ifedit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10">
            <el-form-item label="开票地址+电话">
              <el-input
                v-model="form.company_address"
                style="width: 305px"
                :disabled="ifedit"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="纳税人识别号">
              <el-input
                v-model="form.business_licence_code"
                :disabled="ifedit"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="formtop">
        <div class="formtop-title">收货人信息</div>
        <el-row type="flex">
          <el-col :span="10">
            <el-form-item label="收货人名称">
              <el-input
                v-model="form.invoice_contacts"
                :disabled="ifedit"
                style="width: 305px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="手机号码">
              <el-input
                v-model="form.invoice_mobile"
                maxlength="11"
                :disabled="ifedit"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col>
            <el-form-item label="详细地址">
              <el-input
                v-model="form.invoice_addr"
                :disabled="ifedit"
                style="width: 840px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col>
            <el-form-item label="邮箱">
              <el-input
                v-model="form.invoice_email"
                :disabled="ifedit"
                style="width: 840px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="formtop">
        <div class="formtop-title">备注</div>
        <el-row type="flex">
          <el-col>
            <el-form-item>
              <el-input
                type="textarea"
                v-model="form.remark"
                :disabled="ifedit"
                style="width: 840px"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="formtop">
        <div class="formtop-title">发票明细</div>
        <vxe-table
          border
          row-id="id"
          size="small"
          :loading="loading3"
          :data="tableData3"
          ref="xtable"
          @cell-click="getSelectEvent(true)"
          @checkbox-all="selectAllEvent"
        >
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
          <vxe-table-column field="customer_name" title="客户名称"></vxe-table-column>
          <vxe-table-column
            field="bank_name"
            title="银行名称"
            sortable
          ></vxe-table-column>
          <vxe-table-column field="batch" title="日期" sortable></vxe-table-column>
          <vxe-table-column field="pay_type" title="支付方式" sortable></vxe-table-column>
          <vxe-table-column field="extend" title="结算标识"></vxe-table-column>
          <vxe-table-column field="feilv" title="费率(千分位‰)"></vxe-table-column>
          <vxe-table-column field="shouxufei" title="手续费">
            <!-- <template #default="{ row }">
              {{ parseFloat(row.shouxufei).toFixed(2) }}
            </template> -->
          </vxe-table-column>
          <vxe-table-column field="cnt" title="交易笔数"></vxe-table-column>
          <vxe-table-column field="payment" title="交易金额"></vxe-table-column>
          <vxe-table-column field="remark" title="备注"></vxe-table-column>
          <vxe-table-column field="create_time" title="创建时间"></vxe-table-column>
        </vxe-table>
      </div>
    </el-form>
    <el-button style="background: #315bd2; color: #fff" @click="submit" v-if="!ifedit"
      >提交</el-button
    >
  </div>
</template>
<script>
import { getproduct } from "../../request/api";
export default {
  name: "JinjianNonebill",
  data() {
    return {
      customerName: this.getCookie("customername"),
      form: {
        receipt_amount: 0,
        receipt_date: "",
        bank_name: "",
        invoice_title: "",
        bank_account: "",
        company_address: "",
        business_licence_code: "",
        invoice_contacts: "",
        invoice_mobile: "",
        invoice_addr: "",
        invoice_email: "",
        remark: "",
      },
      receiptDetailIdList: [],
      receiptDetailIdList_new: [],
      dialogVisible: false,
      loading3: false,
      tableData3: [],
      ifedit: false,
      backsin: false,
      dataflag: true, //重新编辑时 可以选择时间
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.name == "alreadybill" ||
      from.name == "alreadyauditing" ||
      from.name == "backbill"
    ) {
      // this.filelist();
      localStorage.setItem("routename", from.name);
    } else {
      localStorage.setItem("routename", "submitbill");
      // this.findList3();
    }

    next();
  },
  created() {
    if (localStorage.getItem("routename") == "submitbill") {
      this.form.receipt_date = this.getToDay();
      this.findList3();
      // 查询上一次提交的数据
      this.getlastData();
    } else {
      if (localStorage.getItem("routename") == "alreadyauditing") {
        //如过是从待处理里进来的  设置返回参数
        this.backsin = true;
      }
      this.ifedit = true;
      this.filelist();
    }
  },
  mounted() {},
  methods: {
    openEdit() {
      this.ifedit = false;
      this.dataflag = false;
      this.$refs.xtable.setAllCheckboxRow(true);
    },
    getToDay() {
      let time = new Date();
      let inJob = time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();
      return inJob;
    },
    getlastData() {
      var data = {
        ctrl: "receipt",
        func: "lastData",
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.form.bank_name = res.data.bank_name;
          this.form.invoice_title = res.data.invoice_title;
          this.form.bank_account = res.data.bank_account;
          this.form.company_address = res.data.company_address;
          this.form.business_licence_code = res.data.business_licence_code;
          this.form.invoice_contacts = res.data.invoice_contacts;
          this.form.invoice_mobile = res.data.invoice_mobile;
          this.form.invoice_addr = res.data.invoice_addr;
          this.form.remark = res.data.remark;
        }
      });
    },
    filelist() {
      var data = {
        ctrl: "receipt",
        func: "detail",
        receipt_id: this.$route.params.choseid,
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          const list = res.data.deatilList;
          this.loading3 = false;
          this.tableData3 = list;
          this.form = res.data;
        }
      });
    },
    selectAllEvent() {
      this.getSelectEvent();
    },
    //2021-12-20  修改
    //这样处理： 增加一个全部选项，然后默认全部，提交的时候，如果选中的记录里面包含两种不同的银行，就不让提交；
    getSelectEvent(flag = true) {
      let selectRecords = this.$refs.xtable.getCheckboxRecords();
      // this.receiptDetailIdList_new = selectRecords.map(item => {
      // 	return item.receipt_detail_id;
      // })
      let paymentSum = 0;
      this.receiptDetailIdList_new = [];
      for (var i = 0, len = selectRecords.length; i < len; i++) {
        paymentSum = this.numberAdd(paymentSum, selectRecords[i].shouxufei);
        this.receiptDetailIdList_new.push(selectRecords[i].receipt_detail_id);
      }
      if (flag == true) {
        this.form.receipt_amount = paymentSum;
      } else {
        return paymentSum;
      }
    },
    submit() {
      this.getSelectEvent(false);
      // if(this.receiptDetailIdList.length <= 0){
      //   this.$message({
      //     message: '当前时间段内没有未开票的缴费数据',
      //     type: 'warning',
      //     offset:100
      //   });
      //   return false
      // }
      let selectRecords = this.$refs.xtable.getCheckboxRecords();
      if (this.receiptDetailIdList_new.length <= 0) {
        this.$message({
          message: "请选择待开票的数据",
          type: "warning",
          offset: 100,
        });
        return false;
      }
      console.log(selectRecords)
      if (this.form.invoice_email=="") {
        this.$message({
          message: "请填写收货人邮箱",
          type: "warning",
          offset: 100,
        });
        return false;
      }
      console.log(this.receiptDetailIdList_new)
      var data = {};
      data = this.form;
      data.ctrl = "receipt";
      data.func = "createapply";
      // data.receipt_detail_id = this.$route.params.chosearray;
      // data.receipt_detail_id = this.$route.query.id;
      // data.receipt_detail_id = this.receiptDetailIdList;
      data.receipt_detail_id = this.receiptDetailIdList_new;
      //post 数据量太大
      data.deatilList = "";
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
            offset: 100,
          });
          // this.$router.push({ name: "nonebill" });
          this.$router.go(-1);
        }
      });
    },
    goback() {
      if (this.backsin) {
        this.$router.push({
          name: "alreadyauditing",
          query: { status: 1 },
        });
      } else {
        this.$router.go(-1);
      }
    },
    //编辑时时间选择使用
    findList4() {
      console.log("aaa");
      this.loading3 = true;
      var data = {};
      data.bank_name = this.$route.query.bank_name;
      data.pay_type = this.$route.query.pay_type;
      data.start_date = this.form.start_date;
      data.end_date = this.form.end_date;
      data.extend = this.$route.query.extend;
      data.status = 0;
      data.ctrl = "receiptDetail";
      data.func = "index";
      data.pageType = "all";
      getproduct(data).then((res) => {
        if (res.code == 200) {
          const list = res.data.list;
          this.loading3 = false;
          this.tableData3 = list;
        }
      });
    },
    findList3() {
      this.loading3 = true;

      this.form.start_date = this.$route.query.start_date;
      this.form.end_date = this.$route.query.end_date;
      var data = {};
      // var chosearray = this.$route.params.chosearray;
      // var chosearray = this.$route.query.id;
      // if (chosearray) {
      //   data.receipt_detail_id = chosearray;
      // }
      data.bank_name = this.$route.query.bank_name;
      data.pay_type = this.$route.query.pay_type;
      data.start_date = this.$route.query.start_date;
      data.end_date = this.$route.query.end_date;
      data.extend = this.$route.query.extend;
      data.status = 0;
      data.ctrl = "receiptDetail";
      data.func = "index";
      data.pageType = "all";
      getproduct(data).then((res) => {
        if (res.code == 200) {
          const list = res.data.list;
          this.loading3 = false;
          if (list.length <= 0) {
            this.$message({
              message: "当前时间段内没有未开票的缴费数据",
              type: "warning",
              offset: 100,
            });
            this.$router.go(-1);
          }
          this.tableData3 = list;
          this.form.receipt_amount = this.getSumPayment(list);
          // this.screenDate(list)
          // this.form.bank_name = list[0].bank_name
        }
      });
    },
    /** 计算业务开始日期 和 结束日期 */
    screenDate(dataList) {
      for (var i = 0, len = dataList.length; i < len; i++) {
        this.form.start_date =
          !this.form.start_date ||
          new Date(this.form.start_date).getTime() > new Date(dataList[i].batch).getTime()
            ? dataList[i].batch
            : this.form.start_date;
        this.form.end_date =
          !this.form.end_date ||
          new Date(this.form.end_date).getTime() < new Date(dataList[i].batch).getTime()
            ? dataList[i].batch
            : this.form.end_date;
      }
    },
    getSumPayment(list) {
      let paymentSum = 0;
      for (var i = 0, len = list.length; i < len; i++) {
        paymentSum = this.numberAdd(paymentSum, list[i].shouxufei);
        // paymentSum = this.numberAdd(paymentSum, parseFloat(list[i].shouxufei).toFixed(2))
        this.receiptDetailIdList.push(list[i].receipt_detail_id);
      }
      return paymentSum;
    },
    numberAdd(num1, num2) {
      if (parseFloat(num1).toString() == "NaN" || parseFloat(num2).toString() == "NaN")
        return false;
      var r1 = 0,
        r2 = 0;
      try {
        r1 = num1.toString().split(".")[1].length;
      } catch (e) {
        //
      }
      try {
        r2 = num2.toString().split(".")[1].length;
      } catch (e) {
        //
      }
      var n = Math.pow(10, Math.max(r1, r2));
      return (num1 * n + num2 * n) / n;
    },
  },
};
</script>

<style lang="scss" scoped>
.editbutton {
  border: 1px solid #315bd2;
  color: #315bd2;
}
.el-input {
  width: 305px;
}
/deep/.el-dialog__title {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
}
.formtop {
  background: #fff;
  padding: 20px 0;
  margin-bottom: 10px;
  min-width: 1500px;
  .formtop-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-left: 3px solid #315bd2;
    padding-left: 10px;
    margin-bottom: 20px;
  }
}

.formbutton {
  background: #315bd2;
  border-radius: 4px;
  color: #fff;
  padding: 20px 130px;
  display: block;
  margin: auto;
  margin-top: 40px;
}

.toast {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 21px;
  text-align: center;
}

.cancelbut {
  border: 1px solid #315bd2;
  color: #315bd2;
  width: 40%;
}
.confimbut {
  background: #315bd2;
  color: #fff;
  width: 40%;
}
.backbut {
  margin-bottom: 20px;
}
</style>
