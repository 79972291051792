var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"94px","background":"#F6F6F6"}},[_c('headtop',{attrs:{"activeIndex":_vm.activeIndex,"title":"热付通SAAS结算信息变更"}}),_vm._m(0),_c('div',{staticClass:"box"},[_c('div',{staticClass:"hd"}),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"header-row-class-name":"table-header","data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"date","label":"名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.titleList.join("、"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"badge",class:{
                gray: scope.row.balance_flag == -1,
                red: scope.row.balance_flag == 1,
                green: scope.row.balance_flag == 2,
                blue: scope.row.balance_flag == 0,
              }}),_vm._v(" "+_vm._s(_vm._f("statusFilter")(scope.row.balance_flag))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"create_time","label":"变更时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.balance_flag == 0)?_c('span',[_vm._v(_vm._s(scope.row.create_time))]):(scope.row.balance_flag == 1)?_c('span',[_vm._v(_vm._s(scope.row.balance_create_time))]):_c('span',[_vm._v(_vm._s(scope.row.balance_complete_time))])]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.gotodetail(scope.row.payinfo_id,0)}}},[_vm._v(" 查看详情 ")]),(scope.row.balance_flag != 1)?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.gotodetail(scope.row.payinfo_id,1)}}},[_vm._v(" 编辑 ")]):_vm._e()]}}])})],1),_c('el-pagination',{staticClass:"pagination",attrs:{"layout":"total, sizes, prev, pager, next, jumper","current-page":_vm.pagination.index,"page-sizes":[10, 20, 30, 40],"page-size":_vm.pagination.size,"total":_vm.pagination.total,"background":""},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('workflowlog',{attrs:{"dialog-visible":_vm.wfDialogVisible,"wf-id":_vm.wfId},on:{"close":function($event){_vm.wfDialogVisible=false}}}),_c('div',{staticClass:"copyright"},[_vm._v("Copyright©2021 -2025 热付通SASS平台")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools-bar"},[_c('div',{staticClass:"rt"})])
}]

export { render, staticRenderFns }