<template>
	<div class="pushzizhi">
		<el-row>
			<el-col :span="12">
				<el-form-item required label="供热/供水许可证">
					<div class="input">
						<div class="text" >
							<!-- v-if="formlist.business_permit_file == null ||formlist.business_permit_file == 0" -->
							原件手机拍照或者彩色扫描，且确保最新年检章信息。<br/>可用政府相关部门发的收费文件代替，也可用非当月且最近三个月给用户开具的采暖费发票代替,电子发票需打印并盖公章<br/>文件格式为bmp、png、jpeg、jpg或gif，
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'供热/供水许可证')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'business_permit_file',
                    'business_permit_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="permitupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('permitupload','business_permit_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.business_permit_fileinfo != ''"
						@click="chakan(formlist.business_permit_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.business_permit_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item required label="公司logo图片">
					<div class="input">
						<!-- v-if="formlist.logo_file == null || formlist.logo_file == 0" -->
						<div class="text" >
							文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'公司logo图片')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess('logo_file', 'logo_fileinfo', res, file);
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="logofileupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('logofileupload','logo_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.logo_fileinfo != ''" @click="chakan(formlist.logo_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.logo_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<el-form-item required label="法人身份证(正面)">
					<div class="input">
						<!-- v-if="formlist.legal_idfront_file == null || formlist.legal_idfront_file == 0" -->
						<div class="text" >
							原件手机拍照或者彩色扫描,扫描件需要加盖公司公章<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'法人身份证(正面)')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'legal_idfront_file',
                    'legal_idfront_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="idfrontupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('idfrontupload','legal_idfront_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.legal_idfront_fileinfo != ''" @click="chakan(formlist.legal_idfront_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.legal_idfront_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item required label="法人身份证(反面)">
					<div class="input">
						<div class="text" >
							<!-- v-if=" formlist.legal_idback_file == null ||formlist.legal_idback_file == 0" -->
							原件手机拍照或者彩色扫描,扫描件需要加盖公司公章<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'法人身份证(反面)')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'legal_idback_file',
                    'legal_idback_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="idbackupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('idbackupload','legal_idback_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.legal_idback_fileinfo != ''" @click="chakan(formlist.legal_idback_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.legal_idback_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<el-form-item required label="营业执照">
					<div class="input">
						<!-- v-if=" formlist.business_licence_file == null ||formlist.business_licence_file == 0" -->
						<div class="text" >
							原件手机拍照或者彩色扫描，且确保最新年检章信息，字迹清晰。<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'营业执照')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'business_licence_file',
                    'business_licence_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="licenceupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('licenceupload','business_licence_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.business_licence_fileinfo != ''"
						@click="chakan(formlist.business_licence_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.business_licence_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
			<!-- <el-col :span="12">
				<el-form-item required label="组织机构代码证">
					<div class="input">
						<div class="text" >
							原件手机拍照或者彩色扫描，且确保最新年检章信息。文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'组织机构代码证')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'organization_file',
                    'organization_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="organizationupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('organizationupload','organization_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.organization_fileinfo != ''" @click="chakan(formlist.organization_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.organization_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col> -->
			<el-col :span="12">
				<el-form-item required label="业务办理授权函">
					<div class="input">
						<!-- v-if=" formlist.auth_file == null || formlist.auth_file == 0" -->
						<div class="text" >
							公司公章需清晰，需上传扫描件<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => { return beforeUpload(file, '业务办理授权函') }"
							:on-success="(res, file) => {handleAvatarSuccess( 'auth_file','auth_fileinfo', res,file );}"
							:on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="customerbarupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('customerbarupload','auth_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
						<!-- v-if="ifedit == false" -->
						<div class="el-upload__text flexClass"  @click="download">
							<!-- <img class="scimg" src="../assets/submit/push.png" /> -->
							<i class="el-icon-document scimg chengImg" ></i>
							<p style="font-size: 11px; margin: 0;">模板下载</p>
						</div>
					</div>
					<div v-if="formlist.auth_fileinfo != ''" @click="chakan(formlist.auth_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto"
							:src="showImg(formlist.auth_fileinfo)" />
					</div>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<el-form-item required label="开户许可证">
					<div class="input">
						<!-- v-if="formlist.bank_permit_file == null || formlist.bank_permit_file == 0" -->
						<div class="text" >
							原件手机拍照或者彩色扫描，且确保最新年检章信息。<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'开户许可证')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'bank_permit_file',
                    'bank_permit_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="bankpermitupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('bankpermitupload','bank_permit_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.bank_permit_fileinfo != ''" @click="chakan(formlist.bank_permit_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.bank_permit_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item required label="公司门牌照">
					<div class="input">
						<!-- v-if=" formlist.customer_brand_file == null ||formlist.customer_brand_file == 0" -->
						<div class="text" >
							公司门和公司牌子的合影，公司全称或简称字迹要清晰<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'公司门牌照')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'customer_brand_file',
                    'customer_brand_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="customerbrandupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('customerbrandupload','customer_brand_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.customer_brand_fileinfo != ''"
						@click="chakan(formlist.customer_brand_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.customer_brand_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<el-form-item required label="公司门头照">
					<div class="input">
						<!-- v-if="formlist.customer_door_file == null ||formlist.customer_door_file == 0" -->
						<div class="text" >
							公司门和公司牌子的合影，公司全称或简称字迹要清晰<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'公司门头照')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'customer_door_file',
                    'customer_door_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="customerdoorupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('customerdoorupload','customer_door_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.customer_door_fileinfo != ''" @click="chakan(formlist.customer_door_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.customer_door_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item required label="公司内景照">
					<div class="input">
						<!-- v-if=" formlist.customer_inner_file == null ||formlist.customer_inner_file == 0" -->
						<div class="text" >
							公司楼内经营场所照片,且实景拍摄,不可用历史照片,避免人员入镜<br/>文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file,'公司内景照')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'customer_inner_file',
                    'customer_inner_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="customerinnerupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('customerinnerupload','customer_inner_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.customer_inner_fileinfo != ''"
						@click="chakan(formlist.customer_inner_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.customer_inner_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<el-form-item required label="公司收费窗口照">
					<div class="input">
						<!-- v-if="formlist.customer_bar_file == null || formlist.customer_bar_file == 0" -->
						<div class="text" >
							公司收费窗口全景，且实景拍摄避免人员入镜<br/>
							文件格式为bmp、png、jpeg、jpg或gif
						</div>
						<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif" list-type="picture-card"
							:auto-upload="true" :before-upload="(file) => {
                return beforeUpload(file, '公司收费窗口照')
              }" :on-success="
                (res, file) => {
                  handleAvatarSuccess(
                    'customer_bar_file',
                    'customer_bar_fileinfo',
                    res,
                    file
                  );
                }
              " :on-exceed="exceedimg" class="uploadlist" :data="filedata" :headers="fileheaders" :limit="1"
							ref="customerbarupload">
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="deletepic('customerbarupload','customer_bar_file')">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="el-upload__text" v-if="ifedit == false">
								<img class="scimg" src="../assets/submit/push.png" />
								<p style="font-size: 12px; margin: 0">上传</p>
							</div>
						</el-upload>
					</div>
					<div v-if="formlist.customer_bar_fileinfo != ''" @click="chakan(formlist.customer_bar_fileinfo)">
						<img style="width: 76px; height: 76px; display: block; margin: auto" :src="
                showImg(formlist.customer_bar_fileinfo)
              " />
					</div>
				</el-form-item>
			</el-col>







			
		</el-row>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>
</template>

<script>
	import Cookies from "js-cookie";
	import config from "../request/config"

	export default {
		name: "JinjianPushzizhi",
		props: {
			formlist: Object,
			ifedit: {
				default: false,
				type: Boolean,
			},
		},
		data() {
			return {
				disabled: false,
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				uploadurl: config.baseUrl + "/index.php/api/index",
				dialogImageUrl: "",
				dialogVisible: false,
			};
		},
		mounted() {
			console.log(this.formlist);
		},
		methods: {
			download(){
				window.open(config.baseUrl+'/public/static/业务办理授权函模板.docx')
			},
			deletepic(ref, info) {
				this.formlist[info] = ''
				this.$refs[ref].clearFiles()
			},
			showImg(file) {
				if (file && file.file_path) {
					return config.baseUrl + file.file_path;
				}
			},
			chakan(file) {
				if (file && file.file_path) {
					this.dialogImageUrl = config.baseUrl + file.file_path;
					this.dialogVisible = true;
				}
			},
			handleRemove(file) {
				console.log(file);
				// this.$refs.permitupload.clearFiles();
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleAvatarSuccess(type, typetwo, res) {
				this.formlist[typetwo] = "";
				if (res.code == 200) {
					this.formlist[type] = res.data.file_id;
				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				this.$forceUpdate();
			},
			exceedimg() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});
			},

			beforeUpload(file, fileName) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				const whiteList = ["bmp", "png", "jpeg", "jpg", "gif"];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message({
						type: "warning",
						message: "格式不正确，请重新上传",
						offset: 120,
					});
					return false;
				}
				const isLt10M = file.size / 1024 / 1024 < 2
				if (!isLt10M) {
					
					
					this.$message({
						type: "error",
						message: "上传文件大小不能超过 2MB!",
						offset: 120,
					});
					return false;
				}

				//   const isLt2M = file.size / 1024 / 1024 < 2;

				//   if (!isLt2M) {
				//     this.$message({
				//       type: "warning",
				//       message: "上传文件大小不能超过 2MB",
				//       offset: 120,
				//     });
				//     return false;
				//   }

				// 对应文件名称
				this.filedata['serviceFileName'] = fileName

				return true;
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	/deep/.el-form-item__label {
		line-height: 74px !important;
	}

	/deep/.el-upload--picture-card {
		background-color: transparent;
		border: none;
		width: auto;
		height: auto;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.pushzizhi {
		min-width: 900px;
		border: 1px solid rgba(168, 188, 235, 1);
		border-bottom: none;

		// border-left: none;
		.input {
			padding: 10px 20px 0;
			width: 520px;
			align-items: center;

			.text {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				margin-right: 12px;
			}

			.scimg {
				width: 26px;
				height: 26px;
				padding-top: 5px;
			}
		}
	}

	.uploadlist {
		// width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.flexClass{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		top:-15px;
		width:100px;
	}
	.chengImg{
		font-size: 23px;color:#315BD2;
	}
	
</style>
