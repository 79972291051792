<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item> 上传企业公章</el-breadcrumb-item>

		</el-breadcrumb>


		<div class="managementDiv">
			<!-- v-if="false" -->
			<!-- <div class="managementDivTitleDiv">
				企业公章管理&nbsp;&nbsp;&nbsp;&nbsp;上传公章图片
			</div> -->
			<div class="managementDiv-sonDiv">
				<div class="managementDiv-companyTitleDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">公司名称：</div>
					<el-input placeholder="请填写公司名称" v-model="customername" disabled="disabled"
						class="managementDiv-companyTitleDiv-input"></el-input>
				</div>

				<div class="managementDiv-companyTitleDiv maginTopDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">公章名称：</div>
					<el-input placeholder="请填写公章名称" v-model="SealName" class="managementDiv-companyTitleDiv-input">
					</el-input>
				</div>
				<div class="managementDiv-companyTitleDiv maginTopDiv marginLeftDiv">
					<div class="managementDiv-companyTitleDiv-nameDiv">上传公章图片：</div>
					<el-upload class="upload-demo" action="" :auto-upload="false" :on-preview="handlePreview"
						:before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed"
						:file-list="fileList" :on-success="onSuccess" :on-progress="onProgress"
						:before-upload="beforeUploadFun" :on-change="onChange" :on-remove="onRemove">
						<!-- <span class="haveNoSpan">无</span> -->
						<el-button size="small" type="primary" class="uoloaderButton" style="margin-left: 30px;">
							<i class="el-icon-plus avatar-uploader-icon"
								style="color:#949494;margin-right: 10px;"></i>点击上传
						</el-button>

					</el-upload>

				</div>
				<div slot="tip" class="el-upload__tip">*支持jpg、bmp、jpeg,但建议上传.png格式的图片，并且图片的大小应当限制在20K以内 </div>

				<el-button class="registerButtonDiv" @click="submit">
					提交
				</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	import Vue from 'vue'
	// import {
	// 	getCompanyList
	// } from "../../request/api";
	import axios from 'axios'
	Vue.prototype.$axios = axios
	// import { getproduct } from "../../request/api";
	export default {
		data() {
			return {

				fileList: [],
				customername: "",
				customerid: "",
				SealName: "",
			};
		},
		components: {},
		mounted() {

		},

		created() {

			this.customerid = this.getCookie("customerid")
			this.customername = this.getCookie("customername")
			if (!this.$route.params.row.name) {
				console.log("不变")
			} else {
				this.SealName = this.$route.params.row.name
			}
			console.log("看看值", this.$route.params.row)
		},

		methods: {



			onChange(file, fileList) {
				console.log("onChange方法", file)
				console.log("onChange方法", fileList)
				this.fileList = file.raw
				console.log(this.fileList)




			},
			onRemove(file, fileList) {
				this.fileList = []

				console.log("onRemove方法", file)
				console.log("onRemove方法", fileList)
			},
			handlePreview(file) {
				console.log("handlePreview方法", file)

			},
			onProgress(event, file, fileList) {
				console.log("onProgress方法", event)
				console.log("onProgress方法", file)
				console.log("onProgress方法", fileList)
			},
			beforeUploadFun(file) {
				console.log("beforeUploadFun方法", file)
			},


			// beforeRemove() {

			// },
			// handleExceed() {

			// },
			onSuccess(response, file, fileList) {
				// console.log("成功一", response)
				// console.log("成功二", file)
				console.log("成功三", fileList)
			},
			submit() {


				if (!this.customername) {
					this.$message({
						message: "公司名称不能为空",
						type: 'error'
					})
					return
				}

				if (!this.SealName) {
					this.$message({
						message: "公章名称不能为空",
						type: 'error'
					})
					return
				}


				if (this.fileList.length == 0) {
					this.$message({
						message: "公章图片不能为空",
						type: 'error'
					})
					return
				}


				console.log("图片列表", this.fileList)

				var that = this
				if (this.$route.params.row) {

					let form = new FormData;
					form.append('SealName', this.SealName);
					form.append('func', 'ReplaceEnterpriseSeal');
					form.append('ctrl', 'FadadaApi');
					form.append('img', this.fileList);
					const customerid = this.customerid;
					if (customerid) {
						form.append('customer_id', customerid);
					}
					console.log("看看传递的数据", axios.defaults.baseURL);

					// axios.post("http://crm.odd-job.ren/index.php/api/index", form).then(function(response) {
						axios.post(axios.defaults.baseURL+'/api/index', form).then(function(response) {
						
						if (response.status === 200) {
							console.log(response.data);

							that.$message({
								message: '企业公章上传成功',
								type: 'success'
							});
						} else {
							that.$message({
								message: response.error,
								type: 'error'
							});
						}
					})

				} else {
					let form = new FormData;
					form.append('SealName', this.SealName);
					form.append('func', 'NewEnterpriseSeal');
					form.append('ctrl', 'FadadaApi');
					form.append('img', this.fileList);
					const customerid = this.customerid;
					if (customerid) {
						form.append('customer_id', customerid);
					}

					// axios.post("http://crm.odd-job.ren/index.php/api/index", form).then(function(response) {
						axios.post(axios.defaults.baseURL+'/api/index', form).then(function(response) {
						console.log("看看看返回值", response)
						if (response.status === 200) {
							console.log(response.data);

							that.$message({
								message: '企业公章上传成功',
								type: 'success'
							});
							that.$router.push({
								path: "officialList"
							});
							
						} else {
							that.$message({
								message: response.error,
								type: 'error'
							});
						}


					})
				}

			},


		},
	};
</script>
<style lang='scss' scoped>
	.breadcrumbClass {
		height: 39px;
		width: 100%;
		margin-top: 8px;
		border-bottom: 1px solid #F0F1F5;
	}

	.managementDiv {
		/* width: 677px; */
		/* height: 331px; */
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		margin: 0 auto;
		margin-top: 76px;
	}


	.managementDiv-sonDiv {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-top: 60px;


	}


	.managementDivTitleDiv {
		font-size: 20px;
		font-weight: bold;
		color: #0C0C0C;
		line-height: 30px;

		/* line-height: 30px; */


	}

	.managementDiv-companyTitleDiv {
		/* width: 677px; */
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: auto;


	}

	.managementDiv-companyTitleDiv-nameDiv {
		font-size: 16px;

		font-weight: bold;
		color: #000000;
	}

	.managementDiv-companyTitleDiv-input {
		width: 573px;
		margin-left: 35px;

	}




	.registerButtonDiv {
		width: 359px;
		height: 49px;
		background: linear-gradient(90deg, #0683EB, #1373C2);
		border-radius: 25px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: bold;
		color: #F6F6F6;
		/* line-height: 30px; */
		margin-top: 101px;
	}

	.marginLeftDiv {
		margin-left: -30px;
	}

	.maginTopDiv {
		margin-top: 33px;
	}

	.haveNoSpan {
		width: 16px;
		height: 14px;
		font-size: 16px;
		font-weight: bold;
		color: #000000;
		margin-left: 35px;
		margin-right: 15px;
	}

	.uoloaderButton {
		width: 167px;
		height: 40px;
		background: #F5F5F5;
		border: 1px solid #CDCDCD;
		border-radius: 10px;
		font-size: 16px;
		font-weight: bold;
		color: #020202;
	}

	.el-upload__tip {
		font-size: 14px;
		font-weight: bold;
		color: #FA0000;
		line-height: 30px;
		margin-left: 150px;
	}
	/deep/.upload-demo {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
	
	/deep/.el-upload-list .el-upload-list__item {
		margin: 0 auto;
		margin-left: 10px;
	}
</style>
