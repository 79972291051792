<template>
	<div>
		<el-button class="backbut" @click="goback">返回</el-button>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>掌上派工APP注册用户审批</el-breadcrumb-item>
			<el-input class='inputClass' placeholder="请输入手机号或姓名" v-model="inputValue"
				@input="getCompanyList"></el-input>
			<el-button style="margin-left: 20px;" @click="exportbtn">导出</el-button>
			<el-button v-if="delShow" style="margin-left: 20px;" @click="delexportbtn">批量删除</el-button>
		</el-breadcrumb>

		<el-table :data="list" class="elTable"  @selection-change="handleSelectionChange"
		style="width: 100%">
			<el-table-column      type="selection"      width="55"></el-table-column>
			<el-table-column label="联系人姓名 11">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.name }}</span>
				</template>
			</el-table-column>

			<el-table-column label="手机号">
				<template slot-scope="scope">

					<span style="margin-left: 10px">{{ scope.row.mobile }}</span>
				</template>
			</el-table-column>
			<el-table-column label="身份类型">
				<template slot-scope="scope">
					<span style="margin-left: 10px" v-if="scope.row.idtype == 'OPERATOR'">话务员</span>
					<span style="margin-left: 10px" v-else>维修工</span>
				</template>
			</el-table-column>
			<el-table-column label="部门">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.department }}</span>
				</template>
			</el-table-column>
			<el-table-column label="职务">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.post }}</span>
				</template>
			</el-table-column>
			<el-table-column label="公司名">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.enterprise }}</span>

				</template>
			</el-table-column>
			<el-table-column label="审核状态">
				<template slot-scope="scope">
					<span style="margin-left: 10px" v-if="scope.row.examine == 1">待审核</span>
					<span style="margin-left: 10px" v-if="scope.row.examine == 2">审核成功 </span>
					<span style="margin-left: 10px" v-if="scope.row.examine == -1">审核失败 </span>

				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button size="mini" class="buttonOne" @click="del(scope.$index, scope.row)">删除
					</el-button>
					<el-button size="mini" class="buttonOne" @click="reset(scope.$index, scope.row)">重置密码
					</el-button>

				</template>

			</el-table-column>


		</el-table>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>您确定删除此条数据吗?</span>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleSure">确 定</el-button>

			</span>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="dialogVisibleReset" width="30%" :before-close="handleClose">
			<span>请输入重置的密码</span>
			<el-input type='text' v-model="newPassword" />

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleReset = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleResetSure">确 定</el-button>

			</span>
		</el-dialog>

	</div>
</template>

<script>
import {
	getCompanyList,downloadExcelWithResData,crmPayinfoExcelExportAPI
} from "../../request/api";
import Vue from 'vue'
import axios from 'axios'
Vue.prototype.$axios = axios
export default {
	name: "dispatch",
	data() {
		return {
			dialogVisible: false,
			currentPage1: 5,
			currentPage2: 5,
			currentPage3: 5,
			currentPage4: 4,
			list: [],
			rowValue: "",
			msgInput: "",
			dialogVisibleReset: false,
			indexReset: '',
			rowReset: '',
			newPassword: '',
			passwordPhone: '',
			inputValue: '',
			delShow:false,
			delList:[],
		}
	},
	created() {
		this.customerid = this.getCookie("customerid")

	},
	mounted() {
		this.getCompanyList()
	},
	methods: {
		del(index, row) {
			this.dialogVisible = true
			this.index = index
			this.row = row
		},
		reset(index, row) {
			this.dialogVisibleReset = true
			this.indexReset = index
			this.rowReset = row
			this.passwordPhone = row.mobile
		},
		exportbtn() {
			var data = {
				func: "excelExport",
				ctrl: "openApi",
				cus_id: this.getCookie("customerid"),
				status: 2,
				customer_id:this.getCookie("customerid"),
			};
			crmPayinfoExcelExportAPI(data).then((res) => {
				console.log(res)
				downloadExcelWithResData(res)
			})
		},
		dialogVisibleSure() {
			var data = {
				func: "delserve",
				ctrl: "openApi",
				contacts_id: this.row.contacts_id
			};
			if (data.func == 'delserve' && data.ctrl == 'openApi') {
				axios.defaults.headers.post['source'] = 'ZSPG';
			}
			getCompanyList(data).then((res) => {

				if (res.code == 200) {
					this.dialogVisible = false
					this.$message({
						message: "删除成功",
						type: 'success'
					});


					this.getCompanyList()
					console.log("看看返回值", res)
				} else {
					console.log("看看返回值", res)
				}
			})
		},
		goback() {
			this.$router.push({
				name: "product"
			});
		},
		createFun() {
			this.$router.push({
				name: "createContract"
			});
		},
		dialogVisibleResetSure() {
			var data = {
				func: "updatepwd",
				ctrl: "openApi",
				password: this.newPassword,
				mobile: this.passwordPhone
			};
			if (data.func == 'delserve' && data.ctrl == 'openApi') {
				axios.defaults.headers.post['source'] = 'ZSPG';
			}
			getCompanyList(data).then((res) => {

				if (res.code == 200) {
					this.dialogVisibleReset = false
					this.$message({
						message: "重置密码成功",
						type: 'success'
					});
					this.newPassword = ""

					this.getCompanyList()
					console.log("看看返回值", res)
				} else {
					console.log("看看返回值", res)
				}
			})
		},
		handleSelectionChange(selection){
			console.log(selection)
			this.delList = selection
			if(this.delList.length == 0){
				this.delShow = false
			}else{
				this.delShow = true

			}
		},
		delexportbtn(){
			if(this.delList.length == 0){
				return false
			}
			console.log(this.delList)
			let arr = this.delList.map((item)=>{
				return item.contacts_id
			})
			var data = {
				func: "delserves",
				ctrl: "openApi",
				contacts_ids: arr.join(",") ,
				customer_id:this.getCookie("customerid"),
			};
			if (data.func == 'delserves' && data.ctrl == 'openApi') {
				axios.defaults.headers.post['source'] = 'ZSPG';
			}
			getCompanyList(data).then((res) => {
				
if (res.code == 200) {
	this.$message({
		message: "删除成功",
		type: 'success',
		offset:80
	});
	this.getCompanyList()
	console.log("看看返回值", res)
} else {
	console.log("看看返回值", res)
}
			})
		},
		getCompanyList() {
			var data = {
				func: "examinelist",
				ctrl: "openApi",
				cus_id: this.getCookie("customerid"),
				status: 2,
				words: this.inputValue
			};
			if (data.func == 'examinelist' && data.ctrl == 'openApi') {
				axios.defaults.headers.post['source'] = 'ZSPG';
			}
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					// this.ifused = res.data;
					this.list = res.data


					console.log("看看返回值", res)
				} else {
					console.log("看看返回值", res)
				}
			})
		},
	}
}
</script>

<style scoped>
.titleDiv {
	font-size: 20px;
	font-weight: bold;
	color: #0C0C0C;
	margin: 0 auto;
	margin-top: 30px;
	text-align: center;
}

.createLookDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-right: 24px;
	font-size: 14px;
	font-weight: bold;
	color: #F19944;
	margin-top: 20px;
}

.createLookDiv div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.createLookDiv div:nth-child(1) {
	/* border-right: 1px solid #c1c1c1; */
	padding-right: 15px;
}

.createLookDiv div:nth-child(2) {
	padding-left: 15px;
}

.createLookDiv div img {
	width: 26px;
	height: 20px;
	margin-right: 10px;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
	text-align: center;
}

.el-table th.el-table__cell.is-leaf {
	background-color: #1373C2;
	font-size: 16px;
	font-weight: bold;
	color: #F6F6F6;
	line-height: 30px;
}

.elTable {
	margin-top: 17px;
}

.el-table td.el-table__cell {
	font-weight: bold;
}

.blockDiv {
	margin-top: 32px;
}

.blockDiv .el-pagination {
	margin: 0 auto;
	text-align: center;
}

.buttonOne {

	height: 30px;
	border: 1px solid #1373C2;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	color: #1373C2;

}

.buttonTwo {

	height: 30px;
	background: #FFFFFF;
	border: 1px solid #FB0000;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	color: #FF0000;
}

.breadcrumbClass {
	height: 39px;
	width: 100%;
	margin-top: 8px;
	border-bottom: 1px solid #F0F1F5;
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

::v-deep .el-table td,
::v-deep .el-table th {
	text-align: center;
}

::v-deep .el-table th {
	background-color: #1373C2;
	color: #fff;
}

.msgInput-class {
	margin-top: 20px;

}

.backbut {
	margin-bottom: 20px;
}

.inputClass {
	width: 40vh;
	margin-left: 3vh;
}
</style>
