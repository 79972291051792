<template>
	<div>
		<div class='titleDiv'>收款账户是指热企收采暖的对公账户</div>
		<div v-for="(name, value, listindex) in formlist" :key="listindex">
			<div style="display: flex" v-if="value == 'YSF'" v-show="ylchosed">
				<div class="title" style="margin-top: 30px">银联收款账户资料</div>
				<el-checkbox v-model="ylchecked" label="银联" style="margin: auto 11px" v-if="listindex != 0"
					@change="changezzz(listindex, 'ylchecked', 'YSF')">使用相同的账户信息</el-checkbox>
			</div>

			<div v-if="value == 'YSF'" v-show="ylchosed">
				<div class="zizhipage" v-for="(YSFitem, YSFindex) in formlist.YSF" :key="YSFindex">
					<div class="zizhipage-title">
						银联账号 {{ YSFindex + 1 }}
						<div v-if="YSFindex != 0" style="cursor: pointer" @click="deleteindex('YSF', YSFindex)">
							删除
						</div>
					</div>
					<div class="zizhipage-titletwo">收款账户信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="开户银行省市信息">
								<div class="input">
									<el-select :disabled="ifedit" v-model="YSFitem.province" class="xlselect"
										placeholder="省份" @change="
                      changeyyy(
                        YSFindex,
                        YSFitem.province,
                        listindex,
                        'province'
                      )
                    " @visible-change="changecity(YSFitem.province)">
										<el-option v-for="item in proptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
									<el-select :disabled="ifedit" v-model="YSFitem.city" class="xlselect"
										placeholder="城市" @change="
                      changeyyy(YSFindex, YSFitem.city, listindex, 'city')
                    ">
										<el-option v-for="item in cityoptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户银行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.bank_title,
                        listindex,
                        'bank_title'
                      )
                    " style="width: 80%" v-model="YSFitem.bank_title" placeholder="请输入(如：中国招商银行辽宁省沈阳市奉天支行)"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="银行账号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.bank_account,
                        listindex,
                        'bank_account'
                      )
                    " style="width: 80%" v-model="YSFitem.bank_account" placeholder="请输入公司对公银行账号信息"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.bank_name,
                        listindex,
                        'bank_name'
                      )
                    " style="width: 80%" v-model="YSFitem.bank_name" placeholder="需与营业执照公司名称保持一致。"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="行联号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.bank_code,
                        listindex,
                        'bank_code'
                      )
                    " style="width: 80%" v-model="YSFitem.bank_code" placeholder="请输入行联号"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>


						<el-col :span="12">
							<el-form-item required label="银联收款账户信息图片" class="pushzizhi">
								<div class="input">
									<div class="text"
										v-if="YSFitem.account_file==null||YSFitem.account_file==0||YSFitem.account_file==''">
										需有银行章，可提供印鉴卡、银行电子回单、开户许可证等，文件格式为bmp、png、jpeg、jpg或gif

									</div>
									<img style="width: 76px; height: 76px; display: block;"
										v-if="YSFitem.account_file_fileinfo&&YSFitem.account_file_fileinfo.length!=0"
										@click="chakan(YSFitem.account_file_fileinfo)"
										:src="showImg(YSFitem.account_file_fileinfo)" />
									<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif"
										list-type="picture-card" :before-upload="
                      (file) => {
                        return beforeUpload(file, '银联收款账户信息图片'+YSFindex);
                      }
                    " :on-success="
                      (res, file) => {
                        handleAvatarSuccess(
                          YSFitem, YSFindex,res,file,'YSF' ); } " :on-exceed=" exceedimg" class="uploadlist"
										:data="filedata" :headers="fileheaders" :limit="1"
										:ref="'permituploadYSFindex' + YSFindex">
										<div slot="file" slot-scope="{ file }">
											<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
											<span class="el-upload-list__item-actions">
												<span class="el-upload-list__item-preview"
													@click="handlePictureCardPreview(file)">
													<i class="el-icon-zoom-in"></i>
												</span>

												<span v-if="!disabled" class="el-upload-list__item-delete" @click="
                            deletepic(
                              'permituploadYSFindex' + YSFindex,
                              'business_permit_file',YSFitem, YSFindex,'YSF'
                            )
                          ">
													<i class="el-icon-delete"></i>
												</span>
											</span>
										</div>
										<div class="el-upload__text" v-if="ifedit == false">
											<img class="scimg" src="../assets/submit/push.png" />
											<p style="font-size: 12px; margin: 0">上传</p>
										</div>
									</el-upload>
									
								</div>
								<div class='example'>
									示例
									<img :src="baseUrl+'/public/static/img/example.png'" @click="chakanXin('/public/static/img/example.png')"/>
									</div>
								

							</el-form-item>
							
								
							
							
							
						</el-col>
						
					</el-row>
					
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">发票邮寄信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="发票抬头">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.invoice_title,
                        listindex,
                        'invoice_title'
                      )
                    " style="width: 80%" v-model="YSFitem.invoice_title" placeholder="需与营业执照上保持一致"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="邮编">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.invoice_postal,
                        listindex,
                        'invoice_postal'
                      )
                    " style="width: 80%" v-model="YSFitem.invoice_postal" placeholder="输入邮编"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件联系人">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.invoice_contacts,
                        listindex,
                        'invoice_contacts'
                      )
                    " style="width: 80%" v-model="YSFitem.invoice_contacts" placeholder="输入收件联系人"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="收件联系电话">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.invoice_mobile,
                        listindex,
                        'invoice_mobile'
                      )
                    " style="width: 80%" v-model="YSFitem.invoice_mobile" placeholder="输入收件联系电话"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件详细地址">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.invoice_addr,
                        listindex,
                        'invoice_addr'
                      )
                    " style="width: 80%" v-model="YSFitem.invoice_addr" placeholder="请输入收件详细地址"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">开票信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="纳税人识别号">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="YSFitem.taxpayer_id"
										@input="
                      changeyyy(
                        YSFindex,
                        YSFitem.taxpayer_id,
                        listindex,
                        'taxpayer_id'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="企业注册地址">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="YSFitem.company_address"
										@input="
                      changeyyy(
                        YSFindex,
                        YSFitem.company_address,
                        listindex,
                        'company_address'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="企业电话">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.company_tel,
                        listindex,
                        'company_tel'
                      )
                    " style="width: 80%" v-model="YSFitem.company_tel" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="基本开户行账号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.base_bank_account,
                        listindex,
                        'base_bank_account'
                      )
                    " style="width: 80%" v-model="YSFitem.base_bank_account" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="基本开户行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        YSFindex,
                        YSFitem.base_bank_title,
                        listindex,
                        'base_bank_title'
                      )
                    " style="width: 80%" v-model="YSFitem.base_bank_title" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开票类型">
								<div class="input">
									<el-select :disabled="ifedit" v-model="YSFitem.billing_type" placeholder="请选择开票类型"
										@change="
                      changeyyy(
                        YSFindex,
                        YSFitem.billing_type,
                        listindex,
                        'billing_type'
                      )
                    ">
										<el-option label="专用发票" value="专用发票" />
										<el-option label="电子发票（普票）" value="电子发票（普票）" />
									</el-select>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</div>

			<div class="title" v-if="value == 'YSF' && ifedit == false" v-show="ylchosed" @click="addylzh('YSF')">
				新增银联收款账户
			</div>

			<div style="display: flex" v-if="value == 'WXPAY'" v-show="weixinchosed">
				<div class="title" style="margin-top: 30px">微信收款账户资料</div>
				<el-checkbox v-model="weixinchecked" label="微信" style="margin: auto 11px" v-if="listindex != 0"
					@change="changezzz(listindex, 'weixinchecked', 'WXPAY')">使用相同的账户信息</el-checkbox>
			</div>
			<!-- 如果微信没有选中 -->
			<div v-if="value == 'WXPAY'" v-show="weixinchosed">
				<div class="zizhipage" v-for="(WXPAYitem, WXPAYindex) in formlist.WXPAY" :key="WXPAYindex">
					<div class="zizhipage-title">
						微信收款账户 {{ WXPAYindex + 1 }}
						<div v-if="WXPAYindex != 0" style="cursor: pointer" @click="deleteindex('WXPAY', WXPAYindex)">
							删除
						</div>
					</div>
					<div class="zizhipage-titletwo">收款账户信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="开户银行省市信息">
								<div class="input">
									<el-select :disabled="ifedit" v-model="WXPAYitem.province" class="xlselect"
										placeholder="省份" @change="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.province,
                        listindex,
                        'province'
                      )
                    " @visible-change="changecity(WXPAYitem.province)">
										<el-option v-for="item in proptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
									<el-select :disabled="ifedit" v-model="WXPAYitem.city" class="xlselect"
										placeholder="城市" @change="
                      changeyyy(WXPAYindex, WXPAYitem.city, listindex, 'city')
                    ">
										<el-option v-for="item in cityoptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户银行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.bank_title,
                        listindex,
                        'bank_title'
                      )
                    " style="width: 80%" v-model="WXPAYitem.bank_title" placeholder="请输入(如：中国招商银行辽宁省沈阳市奉天支行)">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="银行账号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.bank_account,
                        listindex,
                        'bank_account'
                      )
                    " style="width: 80%" v-model="WXPAYitem.bank_account" placeholder="请输入公司对公银行账号信息"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.bank_name,
                        listindex,
                        'bank_name'
                      )
                    " style="width: 80%" v-model="WXPAYitem.bank_name" placeholder="需与营业执照公司名称保持一致。"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="行联号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.bank_code,
                        listindex,
                        'bank_code'
                      )
                    " style="width: 80%" v-model="WXPAYitem.bank_code" placeholder="请输入行联号"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="微信收款账户信息图片" class="pushzizhi">
								<div class="input">
									<div class="text"
										v-if="(WXPAYitem.account_file==null||WXPAYitem.account_file==0)&&!WXPAYitem.account_file_fileinfo">
										需有银行章，可提供印鉴卡、银行电子回单、开户许可证等，文件格式为bmp、png、jpeg、jpg或gif
									</div>
									<img style="width: 76px; height: 76px; display: block;"
										v-if="WXPAYitem.account_file_fileinfo&&WXPAYitem.account_file_fileinfo.length!=0"
										@click="chakan(WXPAYitem.account_file_fileinfo)"
										:src="showImg(WXPAYitem.account_file_fileinfo)" />
									<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif"
										list-type="picture-card" :before-upload="
                      (file) => {
                        return beforeUpload(file, '微信收款账户信息图片'+WXPAYindex);
                      }
                    " :on-success="
                      (res, file) => {
                        
                        handleAvatarSuccess(
                          WXPAYitem, WXPAYindex,
                           res,file,'WXPAY' ); } " :on-exceed=" exceedimg" class="uploadlist" :data="filedata"
										:headers="fileheaders" :limit="1" :ref="'permituploadWXPAYindex' + WXPAYindex">
										<div slot="file" slot-scope="{ file }">
											<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
											<span class="el-upload-list__item-actions">
												<span class="el-upload-list__item-preview"
													@click="handlePictureCardPreview(file)">
													<i class="el-icon-zoom-in"></i>
												</span>


												<span v-if="!disabled" class="el-upload-list__item-delete"
													@click="
                            deletepic(
                              'permituploadWXPAYindex' + WXPAYindex,'business_permit_file', WXPAYitem, WXPAYindex,'WXPAY' ) ">
													<i class="el-icon-delete"></i>
												</span>
											</span>
										</div>
										<div class="el-upload__text" v-if="ifedit == false">
											<img class="scimg" src="../assets/submit/push.png" />
											<p style="font-size: 12px; margin: 0">上传</p>
										</div>
									</el-upload>
								</div>
								<div class='example'>
									示例
									<img :src="baseUrl+'/public/static/img/example.png'" @click="chakanXin('/public/static/img/example.png')"/>
									</div>
							</el-form-item>
							
														
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">发票邮寄信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="发票抬头">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.invoice_title"
										@input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.invoice_title,
                        listindex,
                        'invoice_title'
                      )
                    " placeholder="需与营业执照上保持一致"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="邮编">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.invoice_postal"
										@input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.invoice_postal,
                        listindex,
                        'invoice_postal'
                      )
                    " placeholder="输入邮编"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件联系人">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.invoice_contacts"
										placeholder="输入收件联系人" @input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.invoice_contacts,
                        listindex,
                        'invoice_contacts'
                      )
                    "></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="收件联系电话">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.invoice_mobile"
										@input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.invoice_mobile,
                        listindex,
                        'invoice_mobile'
                      )
                    " placeholder="输入收件联系电话"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件详细地址">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.invoice_addr"
										@input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.invoice_addr,
                        listindex,
                        'invoice_addr'
                      )
                    " placeholder="请输入收件详细地址"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">开票信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="纳税人识别号">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.taxpayer_id"
										@input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.taxpayer_id,
                        listindex,
                        'taxpayer_id'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="企业注册地址">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" @input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.company_address,
                        listindex,
                        'company_address'
                      )
                    " v-model="WXPAYitem.company_address" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="企业电话">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.company_tel"
										@input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.company_tel,
                        listindex,
                        'company_tel'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="基本开户行账号">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%"
										v-model="WXPAYitem.base_bank_account" @input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.base_bank_account,
                        listindex,
                        'base_bank_account'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="基本开户行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXPAYitem.base_bank_title"
										@input="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.base_bank_title,
                        listindex,
                        'base_bank_title'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开票类型">
								<div class="input">
									<el-select :disabled="ifedit" v-model="WXPAYitem.billing_type" placeholder="请选择开票类型"
										@change="
                      changeyyy(
                        WXPAYindex,
                        WXPAYitem.billing_type,
                        listindex,
                        'billing_type'
                      )
                    ">
										<el-option label="专用发票" value="专用发票" />
										<el-option label="电子发票（普票）" value="电子发票（普票）" />
									</el-select>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</div>

			<div class="title" v-if="value == 'WXPAY' && ifedit == false" v-show="weixinchosed"
				@click="addylzh('WXPAY')">
				新增微信收款账户
			</div>

			<div style="display: flex" v-if="value == 'WXLIFE'" v-show="weixinshchosed">
				<div class="title" style="margin-top: 30px">微信生活收款账户资料</div>
				<el-checkbox v-model="weixinshchecked" label="微信生活" style="margin: auto 11px" v-if="listindex != 0"
					@change="changezzz(listindex, 'weixinshchecked', 'WXLIFE')">使用相同的账户信息</el-checkbox>
			</div>
			<!-- 如果微信生活没有选中 -->
			<div v-if="value == 'WXLIFE'" v-show="weixinshchosed">
				<div class="zizhipage" v-for="(WXLIFEitem, WXLIFEindex) in formlist.WXLIFE" :key="WXLIFEindex">
					<div class="zizhipage-title">
						微信生活收款账户 {{ WXLIFEindex + 1 }}
						<div v-if="WXLIFEindex != 0" style="cursor: pointer"
							@click="deleteindex('WXLIFE', WXLIFEindex)">
							删除
						</div>
					</div>
					<div class="zizhipage-titletwo">收款账户信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="开户银行省市信息">
								<div class="input">
									<el-select :disabled="ifedit" v-model="WXLIFEitem.province" class="xlselect"
										placeholder="省份" @change="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.province,
                        listindex,
                        'province'
                      )
                    " @visible-change="changecity(WXLIFEitem.province)">
										<el-option v-for="item in proptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
									<el-select :disabled="ifedit" v-model="WXLIFEitem.city" class="xlselect"
										placeholder="城市" @change="
                      changeyyy(WXLIFEindex, WXLIFEitem.city, listindex, 'city')
                    ">
										<el-option v-for="item in cityoptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户银行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.bank_title"
										placeholder="请输入(如：中国招商银行辽宁省沈阳市奉天支行)" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.bank_title,
                        listindex,
                        'bank_title'
                      )
                    "></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="银行账号">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.bank_account"
										placeholder="请输入公司对公银行账号信息" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.bank_account,
                        listindex,
                        'bank_account'
                      )
                    "></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户名称">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.bank_name"
										placeholder="需与营业执照公司名称保持一致。" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.bank_name,
                        listindex,
                        'bank_name'
                      )
                    "></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="行联号">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.bank_code"
										placeholder="请输入行联号" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.bank_code,
                        listindex,
                        'bank_code'
                      )
                    "></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">

							<el-form-item required label="微信生活收款账户信息图片" class="pushzizhi">
								<div class="input">
									<div class="text"
										v-if="(WXLIFEitem.account_file==null||WXLIFEitem.account_file==0)&&!WXLIFEitem.account_file_fileinfo">
										需有银行章，可提供印鉴卡、银行电子回单、开户许可证等，文件格式为bmp、png、jpeg、jpg或gif
									</div>
									<img style="width: 76px; height: 76px; display: block;"
										v-if="WXLIFEitem.account_file_fileinfo&&WXLIFEitem.account_file_fileinfo.length!=0"
										@click="chakan(WXLIFEitem.account_file_fileinfo)"
										:src="showImg(WXLIFEitem.account_file_fileinfo)" />

									<!-- :auto-upload="true" -->
									<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif"
										list-type="picture-card" :before-upload="
                      (file) => {
                        return beforeUpload(file, '微信生活收款账户信息图片'+WXLIFEindex);
                      }
                    " :on-success="
                      (res, file) => {
                        handleAvatarSuccess(
                          WXLIFEitem, WXLIFEindex,
                          res,file,'WXLIFE' ); } " :on-exceed=" exceedimg" class="uploadlist" :data="filedata"
										:headers="fileheaders" :limit="1"
										:ref="'permituploadWXLIFEindex' + WXLIFEindex">
										<div slot="file" slot-scope="{ file }">
											<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
											<span class="el-upload-list__item-actions">
												<span class="el-upload-list__item-preview"
													@click="handlePictureCardPreview(file)">
													<i class="el-icon-zoom-in"></i>
												</span>
												<span v-if="!disabled" class="el-upload-list__item-delete"
													@click="
                            deletepic('permituploadWXLIFEindex' + WXLIFEindex,'business_permit_file', WXPAYitem, WXPAYindex,'WXLIFE' ) ">
													<i class="el-icon-delete"></i>
												</span>
											</span>
										</div>
										<div class="el-upload__text" v-if="ifedit == false">
											<img class="scimg" src="../assets/submit/push.png" />
											<p style="font-size: 12px; margin: 0">上传</p>
										</div>
									</el-upload>
								</div>
								<div class='example'>
									示例
									<img :src="baseUrl+'/public/static/img/example.png'" @click="chakanXin('/public/static/img/example.png')"/>
									</div>

							</el-form-item>
							
														
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">发票邮寄信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="发票抬头">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.invoice_title"
										placeholder="需与营业执照上保持一致" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.invoice_title,
                        listindex,
                        'invoice_title'
                      )
                    "></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="邮编">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.invoice_postal"
										@input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.invoice_postal,
                        listindex,
                        'invoice_postal'
                      )
                    " placeholder="输入邮编"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件联系人">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%"
										v-model="WXLIFEitem.invoice_contacts" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.invoice_contacts,
                        listindex,
                        'invoice_contacts'
                      )
                    " placeholder="输入收件联系人"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="收件联系电话">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.invoice_mobile"
										placeholder="输入收件联系电话" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.invoice_mobile,
                        listindex,
                        'invoice_mobile'
                      )
                    "></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件详细地址">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.invoice_addr"
										@input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.invoice_addr,
                        listindex,
                        'invoice_addr'
                      )
                    " placeholder="请输入收件详细地址"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">开票信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="纳税人识别号">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.taxpayer_id"
										@input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.taxpayer_id,
                        listindex,
                        'taxpayer_id'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="企业注册地址">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.company_address"
										@input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.company_address,
                        listindex,
                        'company_address'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="企业电话">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.company_tel"
										@input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.company_tel,
                        listindex,
                        'company_tel'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="基本开户行账号">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" @input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.base_bank_account,
                        listindex,
                        'base_bank_account'
                      )
                    " v-model="WXLIFEitem.base_bank_account" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="基本开户行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="WXLIFEitem.base_bank_title"
										@input="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.base_bank_title,
                        listindex,
                        'base_bank_title'
                      )
                    " placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开票类型">
								<div class="input">
									<el-select :disabled="ifedit" v-model="WXLIFEitem.billing_type"
										placeholder="请选择开票类型" @change="
                      changeyyy(
                        WXLIFEindex,
                        WXLIFEitem.billing_type,
                        listindex,
                        'billing_type'
                      )
                    ">
										<el-option label="专用发票" value="专用发票" />
										<el-option label="电子发票（普票）" value="电子发票（普票）" />
									</el-select>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</div>

			<div class="title" v-if="value == 'WXLIFE' && ifedit == false" v-show="weixinshchosed"
				@click="addylzh('WXLIFE')">
				新增微信生活收款账户
			</div>

			<div style="display: flex" v-if="value == 'ALIPAY'" v-show="zfbchosed">
				<div class="title" style="margin-top: 30px">支付宝收款账户资料</div>
				<el-checkbox v-model="zfbchecked" v-if="listindex != 0" label="支付宝" style="margin: auto 11px"
					@change="changezzz(listindex, 'zfbchecked', 'ALIPAY')">使用相同的账户信息</el-checkbox>
			</div>
			<!-- 如果支付宝没有选中 -->
			<div v-if="value == 'ALIPAY'" v-show="zfbchosed">
				<div class="zizhipage" v-for="(ALIPAYitem, ALIPAYindex) in formlist.ALIPAY" :key="ALIPAYindex">
					<div class="zizhipage-title">
						支付宝收款账户 {{ ALIPAYindex + 1 }}
						<div v-if="ALIPAYindex != 0" style="cursor: pointer"
							@click="deleteindex('ALIPAY', ALIPAYindex)">
							删除
						</div>
					</div>
					<div class="zizhipage-titletwo">收款账户信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="开户银行省市信息">
								<div class="input">
									<el-select :disabled="ifedit" v-model="ALIPAYitem.province" class="xlselect"
										placeholder="省份" @change="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.province,
                        listindex,
                        'province'
                      )
                    " @visible-change="changecity(ALIPAYitem.province)">
										<el-option v-for="item in proptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
									<el-select :disabled="ifedit" v-model="ALIPAYitem.city" class="xlselect"
										placeholder="城市" @change="
                      changeyyy(ALIPAYindex, ALIPAYitem.city, listindex, 'city')
                    ">
										<el-option v-for="item in cityoptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户银行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.bank_title,
                        listindex,
                        'bank_title'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.bank_title" placeholder="请输入(如：中国招商银行辽宁省沈阳市奉天支行)">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="银行账号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.bank_account,
                        listindex,
                        'bank_account'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.bank_account" placeholder="请输入公司对公银行账号信息"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.bank_name,
                        listindex,
                        'bank_name'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.bank_name" placeholder="需与营业执照公司名称保持一致。"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="行联号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.bank_code,
                        listindex,
                        'bank_code'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.bank_code" placeholder="请输入行联号"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="支付宝收款账户信息图片" class="pushzizhi">
								<div class="input">
									<div class="text"
										v-if="(ALIPAYitem.account_file==null||ALIPAYitem.account_file==0)&&!ALIPAYitem.account_file_fileinfo">
										需有银行章，可提供印鉴卡、银行电子回单、开户许可证等，文件格式为bmp、png、jpeg、jpg或gif
									</div>
									<img style="width: 76px; height: 76px; display: block;"
										v-if="ALIPAYitem.account_file_fileinfo&&ALIPAYitem.account_file_fileinfo.length!=0"
										@click="chakan(ALIPAYitem.account_file_fileinfo)"
										:src="showImg(ALIPAYitem.account_file_fileinfo)" />

									<!-- :auto-upload="true" -->
									<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif"
										list-type="picture-card" :before-upload="
                      (file) => {
                        return beforeUpload(file, '支付宝收款账户信息图片'+ALIPAYindex);
                      }
                    " :on-success="
                      (res, file) => {
                        handleAvatarSuccess(
                          ALIPAYitem, ALIPAYindex,
                         res,file, 'ALIPAY' ); } " :on-exceed=" exceedimg" class="uploadlist" :data="filedata"
										:headers="fileheaders" :limit="1"
										:ref="'permituploadALIPAYindex' + ALIPAYindex">
										<div slot="file" slot-scope="{ file }">
											<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
											<span class="el-upload-list__item-actions">
												<span class="el-upload-list__item-preview"
													@click="handlePictureCardPreview(file)">
													<i class="el-icon-zoom-in"></i>
												</span>
												<span v-if="!disabled" class="el-upload-list__item-delete" @click="
                            deletepic(
                              'permituploadALIPAYindex' + ALIPAYindex,
                              'business_permit_file', ALIPAYitem, ALIPAYindex,'ALIPAY'
                            )
                          ">
													<i class="el-icon-delete"></i>
												</span>
											</span>
										</div>
										<div class="el-upload__text" v-if="ifedit == false">
											<img class="scimg" src="../assets/submit/push.png" />
											<p style="font-size: 12px; margin: 0">上传</p>
										</div>
									</el-upload>
								</div>
								<div class='example'>
									示例
									<img :src="baseUrl+'/public/static/img/example.png'" @click="chakanXin('/public/static/img/example.png')"/>
									</div>

							</el-form-item>
							
														
						</el-col>
						
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">发票邮寄信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="发票抬头">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="ALIPAYitem.invoice_title"
										@input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.invoice_title,
                        listindex,
                        'invoice_title'
                      )
                    " placeholder="需与营业执照上保持一致"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="邮编">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.invoice_postal,
                        listindex,
                        'invoice_postal'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.invoice_postal" placeholder="输入邮编"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件联系人">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.invoice_contacts,
                        listindex,
                        'invoice_contacts'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.invoice_contacts" placeholder="输入收件联系人"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="收件联系电话">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.invoice_mobile,
                        listindex,
                        'invoice_mobile'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.invoice_mobile" placeholder="输入收件联系电话"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件详细地址">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.invoice_addr,
                        listindex,
                        'invoice_addr'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.invoice_addr" placeholder="请输入收件详细地址"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">开票信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="纳税人识别号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.taxpayer_id,
                        listindex,
                        'taxpayer_id'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.taxpayer_id" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="企业注册地址">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.company_address,
                        listindex,
                        'company_address'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.company_address" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="企业电话">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.company_tel,
                        listindex,
                        'company_tel'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.company_tel" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="基本开户行账号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.base_bank_account,
                        listindex,
                        'base_bank_account'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.base_bank_account" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="基本开户行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.base_bank_title,
                        listindex,
                        'base_bank_title'
                      )
                    " style="width: 80%" v-model="ALIPAYitem.base_bank_title" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开票类型">
								<div class="input">
									<el-select :disabled="ifedit" v-model="ALIPAYitem.billing_type"
										placeholder="请选择开票类型" @change="
                      changeyyy(
                        ALIPAYindex,
                        ALIPAYitem.billing_type,
                        listindex,
                        'billing_type'
                      )
                    ">
										<el-option label="专用发票" value="专用发票" />
										<el-option label="电子发票（普票）" value="电子发票（普票）" />
									</el-select>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</div>

			<div class="title" v-if="value == 'ALIPAY' && ifedit == false" v-show="zfbchosed"
				@click="addylzh('ALIPAY')">
				新增支付宝收款账户
			</div>

			<div style="display: flex" v-if="value == 'ALILIFE'" v-show="zfbshchosed">
				<div class="title" style="margin-top: 30px">支付宝生活收款账户资料</div>
				<el-checkbox v-model="zfbshchecked" label="支付宝生活" v-if="listindex != 0" style="margin: auto 11px"
					@change="changezzz(listindex, 'zfbshchecked', 'ALILIFE')">使用相同的账户信息</el-checkbox>
			</div>
			<!-- 如果支付宝生活没有选中 -->
			<div v-if="value == 'ALILIFE'" v-show="zfbshchosed">
				<div class="zizhipage" v-for="(ALILIFEitem, ALILIFEindex) in formlist.ALILIFE" :key="ALILIFEindex">
					<div class="zizhipage-title">
						支付宝生活收款账户 {{ ALILIFEindex + 1 }}
						<div v-if="ALILIFEindex != 0" style="cursor: pointer"
							@click="deleteindex('ALILIFE', ALILIFEindex)">
							删除
						</div>
					</div>
					<div class="zizhipage-titletwo">收款账户信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="开户银行省市信息">
								<div class="input">
									<el-select :disabled="ifedit" v-model="ALILIFEitem.province" class="xlselect"
										placeholder="省份" @change="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.province,
                        listindex,
                        'province'
                      )
                    " @visible-change="changecity(ALILIFEitem.province)">
										<el-option v-for="item in proptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
									<el-select :disabled="ifedit" v-model="ALILIFEitem.city" class="xlselect"
										placeholder="城市" @change="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.city,
                        listindex,
                        'city'
                      )
                    ">
										<el-option v-for="item in cityoptions" :key="item.name" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户银行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.bank_title,
                        listindex,
                        'bank_title'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.bank_title" placeholder="请输入(如：中国招商银行辽宁省沈阳市奉天支行)">
									</el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="银行账号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.bank_account,
                        listindex,
                        'bank_account'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.bank_account" placeholder="请输入公司对公银行账号信息"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开户名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.bank_name,
                        listindex,
                        'bank_name'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.bank_name" placeholder="需与营业执照公司名称保持一致。"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="行联号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.bank_code,
                        listindex,
                        'bank_code'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.bank_code" placeholder="请输入行联号"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="支付宝生活收款账户信息图片" class="pushzizhi">
								<div class="input">
									<div class="text"
										v-if="(ALILIFEitem.account_file==null||ALILIFEitem.account_file==0)&&!ALILIFEitem.account_file_fileinfo">
										需有银行章，可提供印鉴卡、银行电子回单、开户许可证等，文件格式为bmp、png、jpeg、jpg或gif
									</div>
									<img style="width: 76px; height: 76px; display: block;"
										v-if="ALILIFEitem.account_file_fileinfo&&ALILIFEitem.account_file_fileinfo.length!=0"
										@click="chakan(ALILIFEitem.account_file_fileinfo)"
										:src="showImg(ALILIFEitem.account_file_fileinfo)" />

									<!-- :auto-upload="true" -->
									<el-upload :action="uploadurl" accept=".bmp, .png, .jpeg, .jpg, .gif"
										list-type="picture-card" :before-upload="
                      (file) => {
                        return beforeUpload(file, '支付宝生活收款账户信息图片'+ALILIFEindex);
                      }
                    " :on-success="
                      (res, file) => {
                        handleAvatarSuccess(
                          ALILIFEitem, ALILIFEindex,
                          res,file,'ALILIFE' ); } " :on-exceed=" exceedimg" class="uploadlist" :data="filedata"
										:headers="fileheaders" :limit="1"
										:ref="'permituploadALILIFEindex' + ALILIFEindex">
										<div slot="file" slot-scope="{ file }">
											<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
											<span class="el-upload-list__item-actions">
												<span class="el-upload-list__item-preview"
													@click="handlePictureCardPreview(file)">
													<i class="el-icon-zoom-in"></i>
												</span>
												<span v-if="!disabled" class="el-upload-list__item-delete" @click="
                            deletepic(
                              'permituploadALILIFEindex' + ALILIFEindex,
                              'business_permit_file',ALILIFEitem, ALILIFEindex,'ALILIFE'
                            )
                          ">
													<i class="el-icon-delete"></i>
												</span>
											</span>
										</div>
										<div class="el-upload__text" v-if="ifedit == false">
											<img class="scimg" src="../assets/submit/push.png" />
											<p style="font-size: 12px; margin: 0">上传</p>
										</div>
									</el-upload>
								</div>
								<div class='example'>
									示例
									<img :src="baseUrl+'/public/static/img/example.png'" @click="chakanXin('/public/static/img/example.png')"/>
									</div>

							</el-form-item>
							
														
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">发票邮寄信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="发票抬头">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.invoice_title,
                        listindex,
                        'invoice_title'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.invoice_title" placeholder="需与营业执照上保持一致"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="邮编">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.invoice_postal,
                        listindex,
                        'invoice_postal'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.invoice_postal" placeholder="输入邮编"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件联系人">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.invoice_contacts,
                        listindex,
                        'invoice_contacts'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.invoice_contacts" placeholder="输入收件联系人"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="收件联系电话">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.invoice_mobile,
                        listindex,
                        'invoice_mobile'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.invoice_mobile" placeholder="输入收件联系电话"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="收件详细地址">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.invoice_addr,
                        listindex,
                        'invoice_addr'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.invoice_addr" placeholder="请输入收件详细地址"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<div class="zizhipage-border"></div>
					<div class="zizhipage-titletwo">开票信息</div>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="纳税人识别号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.taxpayer_id,
                        listindex,
                        'taxpayer_id'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.taxpayer_id" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="企业注册地址">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.company_address,
                        listindex,
                        'company_address'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.company_address" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="border: none">
						<el-col :span="12">
							<el-form-item required label="企业电话">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.company_tel,
                        listindex,
                        'company_tel'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.company_tel" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="基本开户行账号">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.base_bank_account,
                        listindex,
                        'base_bank_account'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.base_bank_account" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item required label="基本开户行详细名称">
								<div class="input">
									<el-input :disabled="ifedit" @input="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.base_bank_title,
                        listindex,
                        'base_bank_title'
                      )
                    " style="width: 80%" v-model="ALILIFEitem.base_bank_title" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item required label="开票类型">
								<div class="input">
									<el-select :disabled="ifedit" v-model="ALILIFEitem.billing_type"
										placeholder="请选择开票类型" @change="
                      changeyyy(
                        ALILIFEindex,
                        ALILIFEitem.billing_type,
                        listindex,
                        'billing_type'
                      )
                    ">
										<el-option label="专用发票" value="专用发票" />
										<el-option label="电子发票（普票）" value="电子发票（普票）" />
									</el-select>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</div>

			<div class="title" v-if="value == 'ALILIFE' && ifedit == false" v-show="zfbshchosed"
				@click="addylzh('ALILIFE')">
				新增支付宝生活收款账户
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>

</template>

<script>
	import Cookies from "js-cookie";
	import {
		getproduct
	} from "../request/api";
	import config from "../request/config";
	export default {
		name: "JinjianJiesuan",
		props: {
			zfbchosed: Boolean,
			zfbshchosed: Boolean,
			weixinshchosed: Boolean,
			weixinchosed: Boolean,
			ylchosed: Boolean,
			formlist: Object,
			checkgroup: Array,
			ifedit: {
				default: false,
				type: Boolean,
			},
			rules: Object,
		},
		data() {
			return {
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				input: "",
				baseUrl: config.baseUrl,
				uploadurl: config.baseUrl + "/index.php/api/index",
				num: 2,
				proptions: [],
				cityoptions: [],
				value: "",
				weixinchecked: true,
				zfbchecked: true,
				weixinshchecked: true,
				zfbshchecked: true,
				ylchecked: true,
				dialogVisible: false,
				dialogImageUrl: "",
			};
		},
		mounted() {
			this.getcity();
		},
		methods: {
			chakanXin(file){
				this.dialogImageUrl = config.baseUrl + file;
				this.dialogVisible = true;
			},
			
			chakan(file) {
				console.log("看看查看方法的file",file)
				if (file && file.file_path) {
					this.dialogImageUrl = config.baseUrl + file.file_path;
					this.dialogVisible = true;
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			random(min, max) {
				return Math.floor(Math.random() * (max - min)) + min;
			},
			handleAvatarSuccess(item, index, res, file, pay) {
				console.log("res是什么", res)
				console.log("item是什么", item)
				if (res.code == 200) {
					item.account_file_fileinfo = ''
					// item.account_file_fileinfo = "123"
					item.account_file = res.data.file_id
					console.log("所以大哥你是什么", item.account_file)
					console.log(item, index, res, file, pay, 'aaaaa')
					// this.formlist.detail[pay][index]['account_file'] = res.data.file_id
					console.log("看看加完值得item是什么", item)
				}
				if (res.code == 401) {
					setTimeout(function() {
						this.$router.push({
							name: "login"
						});
					}, 2000);
				}
				this.$forceUpdate();
			},
			deletepic(ref, info, item, index, pay) {
				console.log("删除", ref);
				console.log("为啥删除报错this.$refs", this.$refs);
				console.log("为啥删除报错this.$refs[ref]", this.$refs[ref]);
				console.log("为啥删除报错this.$refs.ref", this.$refs.ref);
				console.log("为啥删除报错item", item);
				// this.formlist[info] = "";
				// this.$refs.ref.clearFiles();
				var that = this

				this.$nextTick(function() {
					item.account_file_fileinfo = ""

					that.$refs[ref][0].clearFiles();

				})
				console.log(index)
				console.log(pay)
				// console.log("this.formlist.detail[pay][index]['account_file']", this.formlist.detail[pay][index][
				// 	'account_file'
				// ])
				// this.formlist.detail[pay][index]['account_file'] = ''

				//  this.$refs.ref.clearFiles()
			},
			exceedimg() {
				this.$message({
					type: "warning",
					message: "请先删除，再上传",
					offset: 120,
				});
			},


			beforeUpload(file, fileName) {
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				const whiteList = ["bmp", "png", "jpeg", "jpg", "gif"];
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.$message({
						type: "warning",
						message: "格式不正确，请重新上传",
						offset: 120,
					});
					return false;
				}
				console.log("file.size ", file.size)
				// const isLt10M = file.size / 1024 / 1024 < 2
				// if (!isLt10M) {


				// 	this.$message({
				// 		type: "error",
				// 		message: "上传文件大小不能超过 2MB!",
				// 		offset: 120,
				// 	});
				// 	return false;
				// }




				// 对应文件名称
				this.filedata['serviceFileName'] = fileName

				return true;
			},

			showImg(file) {
				if (file && file.file_path) {
					return config.baseUrl + file.file_path;
				}
			},
			deleteindex(name, index) {
				this.formlist[name].splice(index, 1);
				this.$forceUpdate();
			},
			getcity() {
				var data = {
					func: "provinceData",
					ctrl: "area",
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.proptions = res.data;
					}
				});
			},
			changecity(index) {
				console.log(index);
				for (var i = 0; i < this.proptions.length; i++) {
					if (this.proptions[i].name == index) {
						this.cityoptions = this.proptions[i].cityList;
					}
				}
				this.$forceUpdate();
				console.log(this.cityoptions);
			},
			changeyyy(listindex, value, index, type) {
				// var aa = {
				//   ALIPAY: this.zfbchosed,
				//   YSF: this.ylchosed,
				//   ALILIFE: this.zfbshchosed,
				//   WXLIFE: this.weixinshchosed,
				//   WXPAY: this.weixinchosed,
				// };

				var aa = {
					ALIPAY: this.zfbchecked,
					YSF: this.ylchecked,
					ALILIFE: this.zfbshchecked,
					WXLIFE: this.weixinshchecked,
					WXPAY: this.weixinchecked,
				};

				if (index == 0) {
					var obj = this.formlist;
					for (var i in obj) {
						for (var k = 0; k < obj[i].length; k++) {
							if (listindex == k) {
								// if (listindex == 0) {
								if (aa[i] == true) {
									obj[i][k][type] = value;
								}
							}
						}
					}
					this.formlist = obj;
				}
				this.$forceUpdate();
			},
			changezzz(listindex, checkname, objkey) {
				if (listindex > 0 && eval("this." + checkname)) {
					let obj = Object.values(this.formlist)[0];
					this.formlist[objkey] = [];
					for (var i in obj) {
						this.formlist[objkey][i] = new Object();
						for (var k in obj[i]) {
							if (k == "payment_type") {
								this.formlist[objkey][i][k] = objkey;
							} else {
								this.formlist[objkey][i][k] = obj[i][k];
							}
						}
					}
					this.$forceUpdate();
				}
				return;
			},
			addylzh(type) {
				var list = {
					payment_type: type,
					province: "",
					city: "",
					bank_title: "",
					bank_account: "",
					bank_name: "",
					bank_code: "",
					taxpayer_id: "",
					company_address: "",
					company_tel: "",
					base_bank_account: "",
					base_bank_title: "",
					invoice_title: "",
					invoice_postal: "",
					invoice_contacts: "",
					invoice_mobile: "",
					invoice_addr: "",
				};

				this.formlist[type].push(list);
				this.$forceUpdate();
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	.zizhipage {
		border: 1px solid rgba(168, 188, 235, 1);
		border-bottom: none;
		margin-bottom: 20px;

		// border-left: none;
		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}

		.zizhipage-title {
			background: #f6f9ff;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			color: #315bd2;
			padding: 10px 20px;
			display: flex;
			justify-content: space-between;
		}

		.zizhipage-titletwo {
			width: 124px;
			height: 40px;
			border: 1px solid #e3e4e7;
			color: #333333;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			line-height: 40px;
			text-align: center;
			margin: 20px;
		}

		.zizhipage-border {
			width: 96%;
			border: 1px solid #e3e4e7;
			display: block;
			margin: 0 auto;
			margin-top: 10px;
		}
	}

	.title {
		width: 183px;
		padding: 17px 0;
		background: #f6f9ff;
		border: 1px solid #a8bceb;
		text-align: center;
		color: #315bd2;
		margin: 30px 0;
		font-size: 14px;
		cursor: pointer;

		&:first-child {
			margin-top: 0;
		}
	}

	.xlselect {
		width: 120px;
		margin-right: 20px;
	}

	.pushzizhi {
		min-width: 900px;
		border-bottom: none;
		// border-left: none;

		/deep/.el-form-item__label {
			line-height: 74px !important;
			width: 210px;
		}

		/deep/.el-upload--picture-card {
			background-color: transparent;
			border: none;
			width: auto;
			height: auto;
		}

		/deep/.el-upload__text {
			line-height: 1;
		}

		/deep/.el-upload-list--picture-card .el-upload-list__item {
			width: 78px;
			height: 78px;
		}

		.input {
			padding: 10px 20px 0;
			width: 520px;
			align-items: center;

			.text {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				margin-right: 12px;
			}

			.scimg {
				width: 26px;
				height: 26px;
				padding-top: 5px;
			}
		}
	}
	.example{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
	.example img{
		width: 100px;
		height: 100px;
		margin-left: 20px;
	}
	.titleDiv{
		color:red;
	}
</style>
