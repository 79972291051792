<template>
	<div >
		<!-- <headtop :activeIndex="activeIndex" :title="titlename" :ifxianshi="false"></headtop> -->
		<!-- height:calc(100vh - 94px - 30px);margin-top: 94px;background: #F6F6F6; -->
		
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>充值明细</el-breadcrumb-item>
			<!-- <el-breadcrumb-item>上传公章图片</el-breadcrumb-item> -->
		</el-breadcrumb>
		<div class="mainDiv">
			<!-- <div class="mainDiv-titleDiv">充值明细</div> -->
			<el-table :data="companyList" class="elTable" style="width: 100%">
				<el-table-column label="公司名称">
					<template slot-scope="scope">
						<!-- <i class="el-icon-time"></i> -->
						<span style="margin-left: 10px">{{ scope.row.CompanyName }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作前余额(元)">
					<template slot-scope="scope">
						<!-- <i class="el-icon-time"></i> -->
						<span style="margin-left: 10px">{{ scope.row.OldBalance }}</span>
					</template>
				</el-table-column>
				<!-- 公司名称
				之前余额old
				充值金额amout
				充值后金额new
				充值时间 -->
				<el-table-column label="入账余额(元)">
					<template slot-scope="scope">
						<!-- <i class="el-icon-time"></i> -->
						<span style="margin-left: 10px">{{ scope.row.Amount }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作后余额(元)">
					<template slot-scope="scope">
						<!-- <i class="el-icon-time"></i> -->
						<span style="margin-left: 10px">{{ scope.row.NewBalance }}</span>
					</template>
				</el-table-column>
				<el-table-column label="充值时间">
					<template slot-scope="scope">
						<!-- <i class="el-icon-time"></i> -->
						<span style="margin-left: 10px">{{ scope.row.RechargeTime }}</span>
					</template>
				</el-table-column>

			</el-table>

			<div class="paginationDiv">
				<el-pagination  @current-change="handleCurrentChange" page-size="5"
					layout="total,prev, pager, next, jumper" :total="dataCount">
				</el-pagination>
				<!-- :current-page="currentPage4" :page-sizes="[5, 10, 15, 20]" -->
				<!-- <div class="backDiv">返回</div> -->
			</div>
		</div>
		<div class="copyright" style="position:fixed;bottom:0;width:90vw">Copyright©2021 -2025 热付通SASS平台</div>
	</div>
</template>

<script>
	// import headtop from "../moneyHeader.vue";
	// import {
	// 	getproduct
	// } from "../request/api";

	import {
		getCompanyList
	} from "../../request/api.js";


	export default {
		name: "Resgin",
		// components: {
		// 	headtop,
		// },
		data() {
			return {
				customerid: "",
				companyList: [],
				checkboxGroup1: [],
				checklable: "",
				activeIndex: "0",
				companylist: [],
				centerDialogVisible: false,
				ischoseid: -1,
				currentPage1: 5,
				currentPage2: 5,
				currentPage3: 5,
				currentPage4: 4,
				titlename: "热付通SAAS平台",
				pageSize:1,
				dataCount:""
			};
		},
		beforeCreate() {
			// document
			// 	.querySelector("html")
			// 	.setAttribute("style", "background-color:#F6F9FF;");
		},
		beforeDestroy() {
			document.querySelector("html").removeAttribute("style");
		},

		created() {
			this.getCompanyListFun()
			this.customerid = this.getCookie("customerid")
		},
		mounted() {
			this.companylist = JSON.parse(localStorage.getItem("customerList"));
			if (this.companylist.length == 1) {
				this.ischoseid = 0;

				this.setCookie("customerid", this.companylist[0].customer_id);
				this.setCookie("customername", this.companylist[0].name);
			} else if (this.getCookie("customerid")) {
				console.log(4444444);

			} else {
				this.centerDialogVisible = true;
			}

		},
		methods: {
			handleCurrentChange(e){
				this.pageSize=e
				this.getCompanyListFun()
			},
			getCompanyListFun() {
				// setInterval(function() {
				var data = {
					func: "QueryRechargeDetails",
					ctrl: "FadadaApi",
					customer_id: this.customerid,
					CurrPage: this.pageSize,
					PageSize: 5
				};
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;
						this.companyList=[]
						this.dataCount=res.data.count
						this.companyList = res.data.details
						console.log("看看返回值", this.companyList)
					} else {
						console.log("看看错误返回值", res)
					}
				})
				// }), 1000)
			},

			gotonext() {
				if (this.getCookie("customerid")) {
					this.centerDialogVisible = false;
				} else {
					this.$message({
						message: "请选择公司",
						type: "warning",
						offset: 100,
					});
				}
			},
			//选择公司啊
			chosecompagy(index, name, id) {
				this.ischoseid = index;

				this.setCookie("customerid", id);
				this.setCookie("customername", name);
			},




			//下一步
			gonext() {
				this.$router.push({
					name: "submit",
					params: {
						checkboxGroup: this.checkboxGroup1
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: rgba(49, 91, 210, 1);
		border-color: rgba(49, 91, 210, 1);
	}

	/deep/.el-checkbox {
		width: 100%;
	}

	/deep/.el-checkbox__input {
		position: absolute;
	}

	/deep/.el-checkbox__label {
		display: block;
		margin-top: 30px;
	}

	.biaoti {
		text-align: center;
		color: rgba(102, 102, 102, 1);
		font-weight: 400;
		margin-top: 0;
		margin-bottom: 50px;
	}

	.list {
		width: 75%;
		margin: 164px 12.5%;
	}

	.nextbutton {
		display: block;
		margin: auto;
		width: 350px;
		background: rgba(49, 91, 210, 1);
		color: #fff;
		font-size: 20px;
	}


	.el-badge {
		position: absolute;
		right: 15px;
		top: 15px;
	}

	.mainDiv {
		width: 1428px;
		// height: 814px;
		height: auto;
		background-color: #fff !important;
		margin: 0 auto;
		padding-bottom: 57px;
		box-sizing: border-box;
	}

	.mainDiv-titleDiv {
		font-size: 17.9px;
		font-weight: bold;
		color: #525252;
		line-height: 30px;
		padding: 38px 0px 20px 40px;
		box-sizing: border-box;
	}

	.elTable {
		margin-top: 17px;
	}

	.el-table td.el-table__cell {
		font-weight: bold;
	}

	.paginationDiv {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-left: 33px;
		padding-right: 47px;
		box-sizing: border-box;
		margin-top: 40px;
	}

	
	.breadcrumbClass {
		height: 39px;
		width: 100%;
		margin-top: 8px;
		border-bottom: 1px solid #F0F1F5;
	}
	html{
		background-color: #fff;
	}
	
	/deep/.el-table td,/deep/.el-table th{
		text-align: center;
	}
	/deep/.el-table th{
		background-color:#1373C2 ;
		color:#fff;
	}
</style>
