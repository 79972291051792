<template>
  <div>
    <div class="zizhipage"  >
      <el-row style="border: none">
        <el-col :span="12">
          <el-form-item required label="开户银行省市信息">
            <div class="input">
              <el-select
                :disabled="ifedit"
                v-model="formlist.province"
                class="xlselect"
                placeholder="省份"
                @visible-change="changecity(formlist.province)"
              >
                <el-option
                  v-for="pitem in proptions"
                  :key="pitem.name"
                  :label="pitem.name"
                  :value="pitem.name"
                >
                </el-option>
              </el-select>
              <el-select
                :disabled="ifedit"
                v-model="formlist.city"
                class="xlselect"
                placeholder="城市"
              >
                <el-option
                  v-for="citem in cityoptions"
                  :key="citem.name"
                  :label="citem.name"
                  :value="citem.name"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item required label="开户银行详细名称">
            <div class="input">
              <el-input
                :disabled="ifedit"
                style="width: 80%"
                v-model="formlist.bank_title"
                placeholder="请输入(如：中国招商银行辽宁省沈阳市奉天支行)"
              ></el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="border: none">
        <el-col :span="12">
          <el-form-item required label="银行账号">
            <div class="input">
              <el-input
                :disabled="ifedit"
                style="width: 80%"
                v-model="formlist.bank_account"
                placeholder="请输入公司对公银行账号信息"
              ></el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item required label="开户名称">
            <div class="input">
              <el-input
                :disabled="ifedit"
                style="width: 80%"
                v-model="formlist.bank_name"
                placeholder="需与营业执照公司名称保持一致。"
              ></el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="border: none">
        <el-col :span="12">
          <el-form-item required label="行联号">
            <div class="input">
              <el-input
                :disabled="ifedit"
                style="width: 80%"
                v-model="formlist.bank_code"
                placeholder="请输入行联号"
              ></el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item required label="结算账户信息图片">
            <div class="input pushzizhi">
              <el-upload
                class="upload-demo"
                :action="uploadurl"
                :on-preview="handlePreview"
                :disabled="ifedit"
                accept=".bmp, .png, .jpeg, .jpg, .gif"
                :auto-upload="true"
                :before-upload="
                  (file) => {
                    return beforeUpload(file, '结算账户信息图片');
                  }
                "
                :on-success="
                  (res, file) => {
                    changeyyy(res.data.file_id,  'account_file');
                  }
                "
                :on-exceed="exceedimg"
                :data="filedata"
                :headers="fileheaders"
                :limit="1"
                ref="permitupload"
                :on-remove="
                  (file, fileList) => {
                    changeyyy('', 'account_file');
                  }
                "
                :file-list="fileList"
                list-type="picture-card"
              >
                <div class="el-upload__text" v-if="!ifedit">
                  <img class="scimg" src="../assets/submit/push.png" />
                  <p style="font-size: 12px; margin: 0">上传</p>
                </div>
              </el-upload>

              <!-- <div
                v-if="ifedit"
                @click="chakan(formlist.account_file_info)"
                style="margin-left: 20px"
              >
                <img
                  style="width: 76px; height: 76px; display: block; margin: auto"
                  :src="showImg(formlist.account_file_info)"
                />
              </div> -->
              <div class="example">
                示例
                <img
                  :src="baseUrl + '/public/static/img/example.png'"
                  @click="chakanXin('/public/static/img/example.png')"
                />
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="zizhipage-border"></div>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getproduct } from "../request/api";
import Cookies from "js-cookie";
import config from "../request/config";
export default {
  name: "BJiesuan",
  props: {
    formlist: Object,
    fileList: Object,
    ifedit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      baseUrl: config.baseUrl,
      proptions: [],
      cityoptions: [],
      filedata: {
        ctrl: "file",
        func: "save",
        type: "file",
      },
      fileheaders: {
        token: Cookies.get("tooken"),
      },
      uploadurl: config.baseUrl + "/index.php/api/index",
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  mounted() {
    this.getcity();
    console.log(this.formlist)
  },

  methods: {
    chakanXin(file) {
      this.dialogImageUrl = config.baseUrl + file;
      this.dialogVisible = true;
    },

    chakan(file) {
      if (file) {
        this.dialogImageUrl = config.baseUrl + file.file_path;
        this.dialogVisible = true;
        console.log("图片是什么this.dialogImageUrl", this.dialogImageUrl);
      }
    },
    download() {
      window.open(config.baseUrl + "/public/static/审核材料模板/结算账户图片模板.docx");
    },
    showImg(file) {
      if (file && file.file_path) {
        return config.baseUrl + file.file_path;
      }
    },
    changeyyy( value,  field) {
      // console.log( value, field)
      this.formlist[field] = value;
      if (field == "account_file" && value > 0) {
        this.formlist["account_file_info"] = "";
      }
    },
    beforeUpload(file, fileName) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["bmp", "png", "jpeg", "jpg", "gif"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          type: "warning",
          message: "格式不正确，请重新上传",
          offset: 120,
        });
        return false;
      }

      // 对应文件名称
      this.filedata["serviceFileName"] = fileName;

      return true;
    },
    handlePreview(file) {
      console.log(file);
      // if (file && file.response.data.url) {
      
      //   this.dialogImageUrl = file.response.data.url;
      //   this.dialogVisible = true;
      // }else 
       if (file && file.url){
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    },
    exceedimg() {
      this.$message({
        type: "warning",
        message: "请先删除，再上传",
        offset: 120,
      });
    },
    openEdit(value, item) {
      var obj = this.formlist;
      for (var i in obj) {
        if (i == value) {
          console.log(obj[i]);
          for (var k = 0; k < obj[i].length; k++) {
            if (obj[i][k]["payinfo_detail_id"] == item.payinfo_detail_id) {
              console.log(item.payinfo_detail_id);
              obj[i][k]["if_edit"] = !obj[i][k]["if_edit"];
            }
          }
        }
      }
      this.formlist = obj;
      this.$forceUpdate();
    },
    payment_type_text(value) {
      switch (value) {
        case "WXPAY":
          value = "微信";
          break;
        case "ALIPAY":
          value = "支付宝";
          break;
        case "YSF":
          value = "银联";
          break;
        case "WXLIFE":
          value = "微信生活";
          break;
        case "ALILIFE":
          value = "支付宝生活";
          break;
        default:
          value = "";
          break;
      }
      return value;
    },
    getcity() {
      var data = {
        func: "provinceData",
        ctrl: "area",
      };
      getproduct(data).then((res) => {
        if (res.code == 200) {
          this.proptions = res.data;
        }
      });
    },
    changecity(index) {
      for (var i = 0; i < this.proptions.length; i++) {
        if (this.proptions[i].name == index) {
          this.cityoptions = this.proptions[i].cityList;
        }
      }
      this.$forceUpdate();
      console.log(this.cityoptions);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../assets/css/biaoge.scss");

.zizhipage {
  border: 1px solid rgba(168, 188, 235, 1);
  border-bottom: none;
  margin-bottom: 20px;
  .flexlist {
    &:last-child {
      border-bottom: none;
    }

    .input {
      &:last-child {
        border-right: none;
      }
    }
  }

  .zizhipage-title {
    background: #f6f9ff;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #315bd2;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }

  .zizhipage-titletwo {
    width: 124px;
    height: 40px;
    border: 1px solid #e3e4e7;
    color: #333333;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    line-height: 40px;
    text-align: center;
    margin: 20px;
  }

  .zizhipage-border {
    width: 96%;
    border: 1px solid #e3e4e7;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }
}

.title {
  width: 183px;
  padding: 17px 0;
  background: #f6f9ff;
  border: 1px solid #a8bceb;
  text-align: center;
  color: #315bd2;
  margin: 30px 0;
  font-size: 14px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }
}

.xlselect {
  width: 120px;
  margin-right: 20px;
}

/deep/.el-upload--picture-card {
  background-color: transparent;
  border: none;
  width: auto;
  height: auto;
}

/deep/.el-upload__text {
  line-height: 1;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 78px;
  height: 78px;
}

.pushzizhi {
  .text {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-right: 12px;
  }

  .scimg {
    width: 26px;
    height: 26px;
    padding-top: 5px;
  }
}

.flexClass {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-left: 20px;
  padding-top: 2px;
}

.chengImg {
  font-size: 23px;
  color: #315bd2;
}
.example {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}
.example img {
  width: 60px;
  height: 60px;
  margin-left: 20px;
}
</style>
