<template>
	<div id="app">
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>

<script>
	export default {
		name: "App",
		provide() { //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
			return {
				reload: this.reload,
				isRouterAlive: true //控制视图是否显示的变量
			}
		},
		data() {
			return {
				isRouterAlive: true //控制视图是否显示的变量
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false; //先关闭，
				this.$nextTick(function() {
					this.isRouterAlive = true; //再打开
				})
			}
		}


	};
</script>

<style>
	body {
		padding: 0;
		margin: 0;
	}

	.copyright {
		margin-top: 150px;
		line-height: 67px;
		font-size: 14px;
		text-align: center;
		color: #666;
		background: #f8fafb;
		border: 1px solid #eaedf0;
	}
</style>
