<template>
  <div>
    <div v-for="(name, value, listindex) in formlist" :key="listindex">
      <!-- <span>{{name}} -{{value}} - {{listindex}}</span> -->
      <div style="display: flex">
        <div class="title" style="margin-top: 30px">{{payment_type_text(value)}}结算账户资料</div>
      </div>

      <div class="zizhipage" v-for="(item, index) in name" :key="index">
        <div class="zizhipage-title">
          {{payment_type_text(value)}}账号 {{ index + 1 }}
          <el-button
            size="small"
            v-if="!ifedit"
            class="editbutton"
            icon="el-icon-edit"
            @click="openEdit(value,item)"
          >修改</el-button>
        </div>

        <div class="zizhipage-titletwo">发票邮寄信息</div>
        <el-row style="border: none">
          <el-col :span="12">
            <el-form-item required label="发票抬头">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.invoice_title"
                  placeholder="需与营业执照上保持一致"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required label="邮编">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.invoice_postal"
                  placeholder="输入邮编"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="border: none">
          <el-col :span="12">
            <el-form-item required label="收件联系人">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.invoice_contacts"
                  placeholder="输入收件联系人"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required label="收件联系电话">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.invoice_mobile"
                  placeholder="输入收件联系电话"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="border: none">
          <el-col :span="12">
            <el-form-item required label="收件详细地址">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.invoice_addr"
                  placeholder="请输入收件详细地址"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="zizhipage-border"></div>
        <div class="zizhipage-titletwo">开票信息</div>
        <el-row style="border: none">
          <el-col :span="12">
            <el-form-item required label="纳税人识别号">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.taxpayer_id"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required label="企业注册地址">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.company_address"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="border: none">
          <el-col :span="12">
            <el-form-item required label="企业电话">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.company_tel"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required label="基本开户行账号">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.base_bank_account"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item required label="基本开户行详细名称">
              <div class="input">
                <el-input
                  :disabled="item.if_edit"
                  style="width: 80%"
                  v-model="item.base_bank_title"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required label="开票类型">
              <div class="input">
                <el-select
                  :disabled="item.if_edit"
                  v-model="item.billing_type"
                  placeholder="请选择开票类型"
                >
                  <el-option label="专用发票" value="专用发票" />
                  <el-option label="电子发票（普票）" value="电子发票（普票）" />
                </el-select>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="zizhipage-border"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BJiesuan",
  props: {
    formlist: Object,
    ifedit: {
      default: false,
      type: Boolean,
    },
  },
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {
    openEdit (value, item) {
      var obj = this.formlist;
      for (var i in obj) {
        if (i == value) {
          for (var k = 0; k < obj[i].length; k++) {
            if (obj[i][k]['payinfo_detail_id'] == item.payinfo_detail_id) {
              console.log(item.payinfo_detail_id)
              obj[i][k]['if_edit'] = !obj[i][k]['if_edit'];
            }
          }
        }

      }
      this.formlist = obj;
      this.$forceUpdate();
    },
    payment_type_text (value) {
      switch (value) {
        case 'WXPAY':
          value = "微信";
          break;
        case 'ALIPAY':
          value = "支付宝";
          break;
        case 'YSF':
          value = "银联";
          break;
        case 'WXLIFE':
          value = "微信生活";
          break;
        case 'ALILIFE':
          value = "支付宝生活";
          break;
        default:
          value = "";
          break;
      }
      return value;
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("../assets/css/biaoge.scss");
.zizhipage {
  border: 1px solid rgba(168, 188, 235, 1);
  border-bottom: none;
  margin-bottom: 20px;
  // border-left: none;
  .flexlist {
    &:last-child {
      border-bottom: none;
    }
    .input {
      &:last-child {
        border-right: none;
      }
    }
  }
  .zizhipage-title {
    background: #f6f9ff;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #315bd2;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }
  .zizhipage-titletwo {
    width: 124px;
    height: 40px;
    border: 1px solid #e3e4e7;
    color: #333333;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    line-height: 40px;
    text-align: center;
    margin: 20px;
  }
  .zizhipage-border {
    width: 96%;
    border: 1px solid #e3e4e7;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }
}
.title {
  width: 183px;
  padding: 17px 0;
  background: #f6f9ff;
  border: 1px solid #a8bceb;
  text-align: center;
  color: #315bd2;
  margin: 30px 0;
  font-size: 14px;
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
}

.xlselect {
  width: 120px;
  margin-right: 20px;
}
</style>