<template>
	<div style="margin-top: 100px">
		<headtop :activeIndex="activeIndex"></headtop>
		<div style="display: flex; margin: 0 15%">
			<!-- <el-button style="height: max-content; margin: auto 0" @click="backpage"
        >返回</el-button
      > -->
			<!-- <h3 style="text-align: center; width: 83%">基本信息</h3> -->
			<h3 style="text-align: center; width: 100%">基本信息</h3>
		</div>

		<el-form :rules="rule" :model="formlist" ref="formlistRef">
			<div class="waikuang">
				<el-row ref="form">
					<el-col :span="12">
						<el-form-item label="联系人姓名" prop="contacts_name"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.contacts_name" type="text"
									placeholder="请输入第一时间可以联系到的负责人"></el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="手机号码" prop="contacts_mobile">
							<div class="input">
								<el-input style="width: 80%" v-model.number="formlist.contacts_mobile" maxlength="11"
									placeholder="为及时取得联系，请填写手机号码"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="重要邮箱" prop="email">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.email" type="email"
									placeholder="强烈建议使用企业邮箱"></el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="客服电话" prop="kftel">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.kftel" placeholder="请输入"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row>
					<el-col :span="16">
						<el-form-item label="微信公众号名称" prop="wxmp_name"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.wxmp_name" type="text" placeholder="请输入">
								</el-input>
							</div>

						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="微信公众号账号" prop="wxmp_account"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.wxmp_account" type="text"
									placeholder="请输入"></el-input>
							</div>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="微信公众号密码" prop="wxmp_pwd"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.wxmp_pwd" type="password"
									autocomplete="new-password" show-password placeholder="请输入"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>


					<el-col :span="12">
						<el-form-item label="微信管理员姓名" prop="wxmp_contacts"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.wxmp_contacts" type="text"
									placeholder="请输入微信公众号管理员姓名"></el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="微信管理员电话" prop="wxmp_mobile"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.wxmp_mobile" maxlength="11"
									placeholder="请输入微信公众号管理员电话"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row> -->
				<el-row>
					<el-col :span="12">
						<el-form-item label="申请主体类别" prop="business_license_type"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.business_license_type" type="text"
									placeholder="一般为“法人企业”，具体与营业执照的主体类别为准"></el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所属行业" prop="industry"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.industry" type="text" placeholder="请输入">
								</el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<!-- <el-form-item label="公司名称" prop="customer_name"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.customer_name" type="text"
									placeholder="输入公司名称应与营业执照登记名称一致"></el-input>
							</div>
						</el-form-item> -->

						<el-form-item label="公司名称" prop="customer_name">
							<div class="input">
								<el-select v-model="formlist.customer_name" style="width: 240px" placeholder="请选择"
									auto-complete="off" @change="customer_name_change">
									<el-option v-for="(item,index) in companylist" :key="index" :label="item.name"
										:value="item.name"></el-option>
								</el-select>
							</div>
						</el-form-item>

					</el-col>
					<el-col :span="12">
						<el-form-item label="邮政编码" prop="customer_postal">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.customer_postal" type="tel"
									placeholder="请输入" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="16">
						<el-form-item label="注册地址" prop="business_license_addr"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input" style="width: 100%">
								<el-input style="width: 100%" v-model="formlist.business_license_addr" type="text"
									placeholder="注册地址需与营业执照登记住所一致"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="法人姓名" prop="legal_name"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.legal_name" type="text"
									placeholder="请输入"></el-input>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="法人身份证号码" prop="legal_id_code">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.legal_id_code" placeholder="请输入"
									maxlength="18"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<!--   prop="legal_id_valid"-->
						<el-form-item label="法人证件有效期" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input" style="align-items: center;">
								<el-date-picker :disabled="check_legal_id_valid_check()"
									v-model="formlist.legal_id_valid" type="date" placeholder="选择日期"
									value-format="yyyy-MM-dd">
								</el-date-picker>
								<el-checkbox v-model="formlist.legal_id_valid_check" style="margin-left: 10px;">长期有效
								</el-checkbox>
							</div>

						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="24">
						<el-form-item label="已选择的支付方式" required>
							<div class="input" style="width: 100%">
								<div class="listone" v-for="(item, index) in checkgroup" :key="index">
									<img src="../assets/records/error.png" class="errorImg" v-if="checkgroup.length>1"
										@click="delCheckGroup(index)" />
									{{ item }}
								</div>
							</div>
						</el-form-item>
					</el-col>
				</el-row>

				<!-- <el-row v-if="isShowZfb">
					<el-col :span="12">
						<el-form-item label="企业支付宝账号" prop="zfb_name"
							:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
							<div class="input">
								<el-input style="width: 80%" v-model="formlist.zfb_name" type="text"
									placeholder="请输入"></el-input>
							</div>
						</el-form-item>
					</el-col>
				</el-row> -->
			</div>
			<div class="bottom">
				<!-- <el-form-item> -->
				<el-tabs v-model="activeName" class="tabs" type="card">

					<el-tab-pane label="资质信息" name="first">
						<zizhipage :formlist="formlist" :ifedit="ifedit"></zizhipage>
					</el-tab-pane>
					<el-tab-pane label="收款账户信息" name="second"
						v-if="ylchosed==true|| weixinchosed==true|| weixinshchosed==true|| zfbchosed==true||zfbshchosed==true">
						<jiesuan :weixinchosed="weixinchosed" :zfbchosed="zfbchosed" :formlist="formlist.detail"
							:checkgroup="checkgroup" :weixinshchosed="weixinshchosed" :zfbshchosed="zfbshchosed"
							:ylchosed="ylchosed" :ifedit="ifedit" :rules="rule"></jiesuan>
					</el-tab-pane>
					<!-- <el-tab-pane label="发票信息" name="third">
            <fapiao
              :weixinchosed="weixinchosed"
              :zfbchosed="zfbchosed"
            ></fapiao>
          </el-tab-pane> -->
					<el-tab-pane label="资质上传" name="fourth">
						<pushzizhi :formlist="formlist" :ifedit="ifedit"></pushzizhi>
					</el-tab-pane>



					<el-tab-pane v-if="showOfficialAccount()" label="公众号信息" name="officialAccount">
						<officialAccount :weixinchosed="weixinchosed" :zfbchosed="zfbchosed" :formlist="formlist.wxinfo"
							:checkgroup="checkgroup" :weixinshchosed="weixinshchosed" :zfbshchosed="zfbshchosed"
							:ylchosed="ylchosed" :ifedit="ifedit"></officialAccount>
						<!-- :rules="rule" -->
					</el-tab-pane>




					<el-tab-pane v-if="showExamineFile()" label="审核材料" name="fifth">
						<examinefile :formlist="formlist" :ifedit="ifedit"></examinefile>
					</el-tab-pane>

					<el-tab-pane v-if="isShowZfb" label="支付宝信息" name="sixth">
						<zfbInfo :formlist="formlist" :ifedit="ifedit"></zfbInfo>
					</el-tab-pane>
				</el-tabs>
				<!-- </el-form-item> -->
			</div>
			<!-- 按钮 -->
			<el-form-item>
				<el-row class="buttonlist">
					<el-button type="info" class="leftbutton" @click="keepform" :disabled="baocun">临时保存</el-button>
					<el-button type="submit" class="rightbutton" :loading="ifdianji" @click="submitsh"
						:disabled="ifdianji">提交审核</el-button>
				</el-row>
			</el-form-item>
		</el-form>

	</div>
</template>

<script>
	import headtop from "../components/header";
	import zizhipage from "../components/zizhi";
	import jiesuan from "../components/jiesuan";
	// import fapiao from "../components/fapiao";
	import pushzizhi from "../components/pushzizhi";
	import examinefile from "../components/examinefile";
	import config from "../request/config";
	import Cookies from "js-cookie";
	import officialAccount from "../components/officialAccount";
	import zfbInfo from "../components/zfbInfo";
	import {
		getproduct
	} from "../request/api";
	export default {
		name: "JinjianSubmit",
		components: {
			headtop,
			zizhipage,
			jiesuan,
			pushzizhi,
			examinefile,
			officialAccount,
			zfbInfo
		},
		data() {
			return {
				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				fileheaders: {
					token: Cookies.get("tooken"),
				},
				uploadurl: config.baseUrl + "/index.php/api/index",

				input: "",
				activeName: "first",
				value1: "",
				activeIndex: "1",
				onceltwoplac: "为及时取得联系，请填写手机号码",
				onceltwolable: "手机号码",
				oncelplac: "请输入第一时间可以联系到的负责人",
				oncellable: "联系人姓名",
				checkgroup: [],
				weixinchosed: false,
				weixinshchosed: false,
				zfbchosed: false,
				zfbshchosed: false,
				ylchosed: false,
				formlist: {},
				ifedit: false,
				rule: {
					customer_postal: [{
							required: true,
							message: "邮政编码不能为空",
							trigger: ["blur", "change"],
						},
						{
							validator: this.customer_postalRegular,
							trigger: ["blur", "change"]
						}
					],

					contacts_mobile: [{
							required: true,
							message: "手机号码不能为空",
							trigger: ["blur", "change"],
						},
						{
							validator: this.validatePhone,
							trigger: ["blur", "change"]
						}
					],
					wxmp_mobile: [{
						required: true,
						message: " ",
						trigger: "blur",
					}, ],
					kftel: [{
							required: true,
							message: "客服电话不能为空",
							trigger: "blur",
						},
						{
							validator: this.kftelRegular,
							trigger: ["blur", "change"]
						},
					],
					legal_id_code: [{
							required: true,
							message: "身份证号码不能为空",
							trigger: "blur",
						},
						{
							validator: this.idcard,
							trigger: ["blur", "change"]
						},

					],
					email: [{
							required: true,
							message: "邮箱不能为空",
							trigger: "change"
						},
						{
							type: "email",
							message: "邮箱格式不正确",
							trigger: ["blur", "change"],
						},
					],
					bank_account: "",
					base_bank_account: "",
				},
				ifdianji: false,
				baocun: false,
				companylist: [],
				auth_file: [], //业务办理授权函
			};
		},
		created() {},
		mounted() {
			this.getpage();
			this.getcustomerinfo();
		},
		methods: {
			customer_postalRegular(rule, value, callback) {
				/*console.log(rule);
				console.log(value);
				console.log(callback);*/
				if (!value) {
					callback(new Error('邮政编码不能为空！'));
				}
				//使用正则表达式进行验证手机号码
				else if (!/^[0-9]{6}$/.test(value)) {
					callback(new Error('邮政编码不正确！'));
				} else {
					callback()
				}
			},

			kftelRegular(rule, value, callback) {
				/*console.log(rule);
				console.log(value);
				console.log(callback);*/
				if (!value) {
					callback(new Error('客服电话不能为空！'));
				}
				//使用正则表达式进行验证手机号码
				else if (!/^(([0-9]{3,4}-)?[0-9]{7,8}|(1\d{10}))|[1-9]\d*$/.test(value)) {
					callback(new Error('客服电话不正确！'));
				} else {
					callback()
				}
			},
			validatePhone(rule, value, callback) {
				/*console.log(rule);
				console.log(value);
				console.log(callback);*/
				if (!value) {
					callback(new Error('手机号不能为空！'));
				}
				//使用正则表达式进行验证手机号码
				else if (!/^1\d{10}$/.test(value)) {
					callback(new Error('手机号不正确！'));
				} else {
					callback()
				}
			},

			idcard(rule, value, callback) {
				// console.log("rule", rule)
				// console.log("value", value)
				// console.log("callback", callback)

				// 法人身份证号码legal_id_code



				if (!value) {
					if (rule.field == "legal_id_code") {
						return callback(new Error("身份证号码不能为空"));
					} else {
						return;
					}
				} else if (!/^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/.test(value) && !
					/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
						value)) {
					callback(new Error('身份证号不正确！'));
				} else {
					callback()
				}
				//}, 1000);
			},

			//2022-06-15 增加  集团公司 则选择自己或者子公司，其他则默认自己公司
			getcustomerinfo() {
				var param = {
					func: "getcustomerinfo",
					ctrl: "payinfo",
				}
				getproduct(param).then((res) => {
					if (res.code == 200) {
						this.companylist = res.data;

					}
				});
			},
			customer_name_change($event) {

				console.log($event, this.formlist)
				this.$forceUpdate()
			},
			delCheckGroup(index) {
				this.checkgroup.splice(index, 1)

				if (this.checkgroup.indexOf("微信支付(公众号、小程序)") != "-1") {
					this.weixinchosed = true;
				}
				if (this.checkgroup.indexOf("微信支付(公众号、小程序)") == "-1") {
					this.weixinchosed = false;
					delete this.formlist.detail['WXPAY']
				}
				if (this.checkgroup.indexOf("微信生活缴费") != "-1") {
					this.weixinshchosed = true;
				}
				if (this.checkgroup.indexOf("微信生活缴费") == "-1") {
					this.weixinshchosed = false;
					delete this.formlist.detail['WXLIFE']
				}
				if (this.checkgroup.indexOf("支付宝小程序") != "-1") {
					this.zfbchosed = true;
				}
				if (this.checkgroup.indexOf("支付宝小程序") == "-1") {
					this.zfbchosed = false;
					delete this.formlist.detail['ALIPAY']
				}
				if (this.checkgroup.indexOf("支付宝生活缴费") != "-1") {
					this.zfbshchosed = true;
				}
				if (this.checkgroup.indexOf("支付宝生活缴费") == "-1") {
					this.zfbshchosed = false;
					delete this.formlist.detail['ALILIFE']

				}
				if (this.checkgroup.indexOf("银联云闪付") != "-1") {
					this.ylchosed = true;
				}
				if (this.checkgroup.indexOf("银联云闪付") == "-1") {
					this.ylchosed = false;
					delete this.formlist.detail['YSF']

				}

				if (!this.formlist.detail['WXPAY'] && !this.formlist.detail['WXLIFE'] && !this.formlist.detail['ALIPAY'] &&
					!this.formlist.detail['ALILIFE'] && !this.formlist.detail['YSF']) {
					this.activeName = 'first'
				}




			},
			showOfficialAccount() {
				return this.checkgroup.includes('微信支付(公众号、小程序)')
			},
			showExamineFile() {
				return this.checkgroup.includes('微信生活缴费') || this.checkgroup.includes('支付宝生活缴费') || this.checkgroup
					.includes('银联云闪付')
			},
			//返回
			backpage() {
				this.$router.push({
					name: "home"
				});
			},
			check_legal_id_valid_check() {
				if (this.ifedit === true) {
					return true;
				}
				return this.formlist.legal_id_valid_check;
			},
			//保存草稿
			keepform() {
				console.log("没走这个吗")
				this.$refs.formlistRef.validate(async valid => {
					console.log(valid)
				
					// if (!valid) {

					// 	return false;
					// } else {
						if (this.baocun) return;
						this.baocun = true;
						this.ifdianji = true;
						var data = this.formlist;
						data.func = "createapply";
						data.ctrl = "payinfo";
						data.apply_status = 0;
						getproduct(data).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: "保存成功！",
									type: "success",
									offset: 100,
								});
								setTimeout(() => {
									this.$router.push({
										name: "records"
									});
								}, 2000);
							} else {
								this.baocun = false;
								this.ifdianji = false;
							}
						});
					// }
				})
			},
			//获取数据
			getpage() {
				var data = this.formlist;
				data.func = "formdata";
				data.ctrl = "payinfo";
				var listtwo = localStorage.getItem("checkboxGroup");
				listtwo = listtwo.split(",");
				var listthree = JSON.parse(localStorage.getItem("alllist"))
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.formlist = res.data;
						this.checkgroup = this.$route.params.checkboxGroup || listtwo;
						this.formlist.detail = this.$route.params.alllist || listthree;


						// if (!this.formlist.wxinfo) {
						// 	console.log("这个有值", this.formlist.wxinfo)
						// 	console.log("这个都没进来吗是不")
						// 	this.formlist.wxinfo=[]
						// 	this.formlist.wxinfo.push({
						// 		wxmp_name: "", //微信公众号名称
						// 		wxmp_account: "", //微信公众号账号
						// 		wxmp_pwd: "", //微信公众号密码
						// 		wxmp_contacts: "", //微信管理员姓名
						// 		wxmp_mobile: "", //微信管理员电话
						// 	})
						// } 
						console.log("这个是什么this.formlist.wxinfo", this.formlist.wxinfo)


						for (var j in this.formlist.detail) {
							this.formlist.detail[j][0].account_file = ""
							delete this.formlist.detail[j][0].account_file
							delete this.formlist.detail[j][0].account_file_fileinfo
						}
						if (res.data.fapiao) {
							for (var i in this.formlist.detail) {
								this.formlist.detail[i][0].taxpayer_id = res.data.fapiao[0].taxpayer_id
								this.formlist.detail[i][0].company_address = res.data.fapiao[0].company_address
								this.formlist.detail[i][0].company_tel = res.data.fapiao[0].company_tel
								this.formlist.detail[i][0].base_bank_account = res.data.fapiao[0].base_bank_account
								this.formlist.detail[i][0].base_bank_title = res.data.fapiao[0].base_bank_title
								this.formlist.detail[i][0].billing_type = res.data.fapiao[0].billing_type
							}
						}

						if (this.checkgroup.indexOf("微信支付(公众号、小程序)") != "-1") {
							this.weixinchosed = true;
						}
						if (this.checkgroup.indexOf("微信生活缴费") != "-1") {
							this.weixinshchosed = true;
						}
						if (this.checkgroup.indexOf("支付宝小程序") != "-1") {
							this.zfbchosed = true;
						}
						if (this.checkgroup.indexOf("支付宝生活缴费") != "-1") {
							this.zfbshchosed = true;
						}
						if (this.checkgroup.indexOf("银联云闪付") != "-1") {
							this.ylchosed = true;
						}


						if ('wxinfo' in this.formlist) {
							console.log("123")
							if (this.formlist.wxinfo.length == 0) {
								this.formlist.wxinfo.push({
									wxmp_name: "", //微信公众号名称
									wxmp_account: "", //微信公众号账号
									wxmp_pwd: "", //微信公众号密码
									wxmp_contacts: "", //微信管理员姓名
									wxmp_mobile: "", //微信管理员电话
								})
							}
						} else {
							this.formlist.wxinfo = []
							this.formlist.wxinfo.push({
								wxmp_name: "", //微信公众号名称
								wxmp_account: "", //微信公众号账号
								wxmp_pwd: "", //微信公众号密码
								wxmp_contacts: "", //微信管理员姓名
								wxmp_mobile: "", //微信管理员电话
							})
						}

						// this.auth_file=[];//业务办理授权函
						console.log("123123", this.formlist.auth_fileinfo)


						//业务办理授权函
						if (this.formlist.auth_fileinfo && this.formlist.auth_fileinfo.length != 0) {
							this.auth_file.push({
								'url': config.baseUrl + this.formlist.auth_fileinfo.file_path
							})
						} else {
							this.formlist.auth_fileinfo = []
						}



						//2022-06-15 修改
						this.formlist['customer_name'] = this.getCookie("customername");
					}
				});
			},
			//提交表单
			submitsh() {
				this.$refs.formlistRef.validate((valid) => {

					if (valid) {
						if (this.ifdianji) {
							return false;
						}
						if (!this.formlist.legal_id_valid && !this.formlist.legal_id_valid_check) {
							this.$message({
								message: "请选择时间或者长期有效！",
								type: "error",
								offset: 100,
							});
							return
						}
						if (this.isShowZfb) {
							if(!this.formlist.ali_account){
								this.$message({
									message: "请输入企业支付宝登录账号！",
									type: "error",
									offset: 100,
								});
								return
							}
						}


						this.ifdianji = true;
						this.baocun = true;

						var data = this.formlist;


						data.func = "createapply";
						data.ctrl = "payinfo";
						data.apply_status = 1;
						console.log("json参数", JSON.stringify(data))
						getproduct(data).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: "提交成功！",
									type: "success",
									offset: 100,
								});
								setTimeout(() => {
									this.$router.push({
										name: "records"
									});
								}, 2000);
							} else {
								this.ifdianji = false;
								this.baocun = false;
							}
						});
					} else {
					
						window.scrollTo(0,0)
						console.log('error submit!!');
						return false;
					}
				})
			},
		},
		computed:{
			isShowZfb(){
				// let tempValue = false;
				// for (let index = 0; index < this.checkgroup.length; index++) {
				// 	let element = this.checkgroup[index];
				// 	if(element=='支付宝小程序' ||element=='支付宝生活缴费'){
				// 		tempValue = true;
				// 	}
				// }
				let tempValue = this.checkgroup.includes('支付宝小程序') || this.checkgroup.includes('支付宝生活缴费');
				return tempValue;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin-bottom: 30px;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border-bottom: 1px solid #e4e7ed;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		border: 1px solid #315bd2;
		color: #315bd2;
	}

	/deep/.el-form-item {
		margin: 0;
	}

	/deep/.el-form-item__content {
		display: flex;
		line-height: 16px;
	}

	/deep/.el-form-item__label {
		width: 183px;
		// border-left: 1px solid rgba(168, 188, 235, 1);
		// border-right: 1px solid rgba(168, 188, 235, 1);
		line-height: 60px;
		// background: rgba(246, 249, 255, 1);
	}

	/deep/.el-row {
		border-bottom: 1px solid rgba(168, 188, 235, 1);
	}

	/deep/.el-form-item__error {
		top: 40%;
		left: 43%;
	}

	.waikuang {
		border: 1px solid rgba(168, 188, 235, 1);
		border-bottom: none;
		// border-left: none;
		width: 70%;
		margin: 0 15%;
		min-width: 1040px;

		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.bottom {
		width: 70%;
		margin: 10vh 15% 5vh;

		.tabs {
			border: none;
		}
	}

	.buttonlist {
		display: flex;
		justify-content: center;
		margin-bottom: 10vh;
		border: none;
		border-top: 1px solid #e3e4e7;
		padding-top: 30px;
		width: 100%;

		.leftbutton {
			background: #eeeeee;
			border: 1px solid #eeeeee;
			color: #000;
			padding: 10px 30px;
		}

		.rightbutton {
			background: #315bd2;
			border-radius: 2px;
			color: #fff;
			padding: 10px 30px;
		}
	}

	.listone {
		position: relative;
	}

	.errorImg {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 0;
		right: 0;
	}



	/deep/.el-form-item__label {
		line-height: 74px !important;
	}

	/deep/.el-upload--picture-card {
		line-height: 74px !important;
		width: 78px;
		height: 78px;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.fileFormat {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 36px;
		margin-left: 17px;
		width: 340px;
	}
</style>
