<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>待富通模板管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-table :data="list" class="elTable" style="width: 100%">
			<el-table-column label="姓名">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.name }}</span>
				</template>
			</el-table-column>

			<el-table-column label="手机号">
				<template slot-scope="scope">

					<span style="margin-left: 10px">{{ scope.row.mobile }}</span>
				</template>
			</el-table-column>
			<el-table-column label="职务">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.post }}</span>
				</template>
			</el-table-column>

			<el-table-column label="公司名">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.enterprise }}</span>

				</template>
			</el-table-column>
			<el-table-column label="审核状态">
				<template slot-scope="scope">
					<span style="margin-left: 10px" v-if="scope.row.type == 1">待审核</span>
					<span style="margin-left: 10px" v-if="scope.row.type == 2">审核成功 </span>
					<span style="margin-left: 10px" v-if="scope.row.type == -1">审核失败 </span>

				</template>
			</el-table-column>

			<el-table-column label="操作">
				<template slot-scope="scope" >
					<el-button v-if="scope.row.type == 1" size="mini" class="buttonOne" @click="adopt(scope.$index, scope.row)">通过
					</el-button>
					<el-button v-if="scope.row.type == 1" size="mini" class="buttonTwo" type="danger" @click="refuse(scope.$index, scope.row)">拒绝
					</el-button>
				</template>

			</el-table-column>
		</el-table>
		<div class="blockDiv">

			
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>您确定要拒绝吗?</span>
			
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleSure">确 定</el-button>
			</span>
		</el-dialog>



	</div>
</template>

<script>
import {
	getCompanyList
} from "../../request/api";
import Vue from 'vue'

import axios from 'axios'
Vue.prototype.$axios = axios
export default {
	name: "dispatch",
	data() {
		return {
			dialogVisible: false,
			currentPage1: 5,
			currentPage2: 5,
			currentPage3: 5,
			currentPage4: 4,
			list: [],
			rowValue: "",
		
		}
	},
	created() {
		this.customerid = this.getCookie("customerid")
		console.log("寄哪里")
		console.log("没走这个方法吗")
		this.getCompanyList()

	},

	methods: {
		createFun() {
			this.$router.push({
				name: "createContract"
			});
		},
		getCompanyList() {
			
			var data = {
				func: "examlist",
				ctrl: "Contacts",
				// customer_id: this.getCookie("customerid"),
				status:1,
			};
			if (data.func == 'examlist' && data.ctrl == 'Contacts') {
				console.log("没进来是不是")
				axios.defaults.headers.post['source'] = 'DFT';
			}


			console.log("看看传递的数据", data)
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					// this.ifused = res.data;
					this.list = res.data


					console.log("看看返回值", res)
				} else {
					console.log("看看返回值", res)
				}
			})
		},
		adopt(index, row) {
			console.log(index, row);
			var data = {
				func: "exampost",
				ctrl: "Contacts",
				status: 2,
				wx_id: row.wx_id,
				customer_id: this.customerid,
			};
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					this.$message({
						message: "通过成功",
						type: 'success'
					});

					this.getCompanyList()
					console.log("看看返回值", res)
				} else {
					console.log("看看返回值", res)
				}
			})

		},

		dialogVisibleSure() {
			console.log("拒绝",this.row)
			var data = {
				func: "exampost",
				ctrl: "Contacts",
				wx_id: this.rowValue.wx_id,
				status:-1,
				


			};
			console.log("看看传递的数据", data)
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					this.$message({
						message: "拒绝成功",
						type: 'success'
					});
					this.getCompanyList()


					console.log("看看返回值", res)
					this.dialogVisible=false
				} else {
					this.$message({
						message: "拒绝失败",
						type: 'error'
					});
					this.dialogVisible=false
					this.getCompanyList()
					console.log("看看返回值", res)
				}
			})
		},

		refuse(index, row) {
			

			console.log(index, row);
			this.dialogVisible = true

			this.rowValue = row



		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		}

	}
}
</script>

<style scoped>
.titleDiv {
	font-size: 20px;
	font-weight: bold;
	color: #0C0C0C;
	margin: 0 auto;
	margin-top: 30px;
	text-align: center;
}

.createLookDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-right: 24px;
	font-size: 14px;
	font-weight: bold;
	color: #F19944;
	margin-top: 20px;
}

.createLookDiv div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.createLookDiv div:nth-child(1) {
	/* border-right: 1px solid #c1c1c1; */
	padding-right: 15px;
}

.createLookDiv div:nth-child(2) {
	padding-left: 15px;
}

.createLookDiv div img {
	width: 26px;
	height: 20px;
	margin-right: 10px;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
	text-align: center;
}

.el-table th.el-table__cell.is-leaf {
	background-color: #1373C2;
	font-size: 16px;
	font-weight: bold;
	color: #F6F6F6;
	line-height: 30px;
}

.elTable {
	margin-top: 17px;
}

.el-table td.el-table__cell {
	font-weight: bold;
}

.blockDiv {
	margin-top: 32px;
}

.blockDiv .el-pagination {
	margin: 0 auto;
	text-align: center;
}

.buttonOne {

	height: 30px;
	border: 1px solid #1373C2;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	color: #1373C2;

}

.buttonTwo {

	height: 30px;
	background: #FFFFFF;
	border: 1px solid #FB0000;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	color: #FF0000;
}

.breadcrumbClass {
	height: 39px;
	width: 100%;
	margin-top: 8px;
	border-bottom: 1px solid #F0F1F5;
}

/deep/.el-table td,
/deep/.el-table th {
	text-align: center;
}

/deep/.el-table th {
	background-color: #1373C2;
	color: #fff;
}

</style>
