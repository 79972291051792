<template>
	<div style="margin-top: 100px" ref="wrap">
		
		<headtop :activeIndex="activeIndex"></headtop>
		<div class="box">
			<div class="nav">
				<!-- <div class="navBack" @click="goback">
          <i class="el-icon-arrow-left"></i>返回
        </div> -->
				<!-- <el-button style="height: max-content; margin: auto 0" @click="goback">返回</el-button> -->
				<span></span>
				<div class="navTitle">申请详情</div>
				<div style="width: 50px"></div>
			</div>
			<el-alert v-if="formlist.apply_message && typestatus == -1" :title="formlist.apply_message" type="error"
				show-icon :closable="false">
			</el-alert>
			<div class="content">
				<div class="contentTop">
					<span>基本信息</span>
					<el-button size="small" v-if="typestatus == -1" class="editbutton" icon="el-icon-edit"
						@click="openEdit()">修改</el-button>
				</div>
			</div>
			<!-- 表格 -->
			<el-form :rules="ruleForm" :model="formlist" ref="formlistRef">
				<div class="waikuang">
					<el-row ref="form">
						<el-col :span="12">
							<el-form-item label="联系人姓名">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.contacts_name"
										type="text" placeholder="请输入第一时间可以联系到的负责人"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号码" prop="contacts_mobile">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.contacts_mobile"
										type="tel" placeholder="为及时取得联系，请填写手机号码"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="重要邮箱" prop="email">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.email"
										type="email" placeholder="强烈建议使用企业邮箱"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="客服电话" prop="kftel">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.kftel" type="tel"
										placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="12">
							<el-form-item label="申请主体类别">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%"
										v-model="formlist.business_license_type" type="text"
										placeholder="一般为“法人企业”，具体与营业执照的主体类别为准"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所属行业">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.industry"
										type="text" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<!-- <el-form-item label="公司名称">
								<div class="input">
									<el-input :disabled="true" style="width: 80%" v-model="formlist.customer_name"
										type="text" placeholder="输入公司名称应与营业执照登记名称一致"></el-input>
								</div>
							</el-form-item> -->

							<el-form-item label="公司名称">
								<div class="input">
									<el-select v-model="formlist.customer_name" :disabled="ifedit" style="width: 240px"
										placeholder="请选择" auto-complete="off" @change="customer_name_change">
										<el-option v-for="(item,index) in companylist" :key="index" :label="item.name"
											:value="item.name"></el-option>
									</el-select>
								</div>
							</el-form-item>

						</el-col>
						<el-col :span="12">
							<el-form-item label="邮政编码" prop="customer_postal">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.customer_postal"
										type="number" placeholder="选填"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="16">
							<el-form-item label="注册地址">
								<div class="input" style="width: 100%">
									<el-input :disabled="ifedit" style="width: 100%"
										v-model="formlist.business_license_addr" type="text"
										placeholder="注册地址需与营业执照登记住所一致"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="法人姓名">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.legal_name"
										type="text" placeholder="请输入"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="法人身份证号码" prop="legal_id_code">
								<div class="input">
									<el-input :disabled="ifedit" style="width: 80%" v-model="formlist.legal_id_code"
										type="text" placeholder="请输入" maxlength="18"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="法人证件有效期">
								<div class="input" style="align-items: center;">
									<el-date-picker :disabled="check_legal_id_valid_check()"
										v-model="formlist.legal_id_valid" type="date" placeholder="选择日期"
										value-format="yyyy-MM-dd">
									</el-date-picker>
									<el-checkbox :disabled="ifedit" v-model="formlist.legal_id_valid_check"
										style="margin-left: 10px;">长期有效</el-checkbox>
								</div>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="24">

							<el-form-item label="已选择的支付方式">
								<div class="input" style="width: 100%">


									<div v-if="formlist.wxpay_status==1" v-show='weixinchosed' class="listone">
										微信支付(公众号、小程序)
										<img src="../assets/records/error.png"
											v-if="zfbchosed==true||ylchosed==true||weixinshchosed==true||zfbshchosed==true"
											class="errorImg" @click="delStatus(wxpay_status)" />
									</div>
									<div v-if="formlist.alipay_status==1" v-show="zfbchosed" class="listone"> 支付宝小程序
										<img v-if="weixinchosed==true||ylchosed==true||weixinshchosed==true||zfbshchosed==true"
											src="../assets/records/error.png" class="errorImg"
											@click="delStatus(alipay_status)" />
									</div>
									<div v-if="formlist.ysf_status==1" v-show="ylchosed" class="listone"> 云闪付
										<img src="../assets/records/error.png"
											v-if="weixinchosed==true||zfbchosed==true||weixinshchosed==true||zfbshchosed==true"
											class="errorImg" @click="delStatus(ysf_status)" />
									</div>
									<div v-if="formlist.wxlife_status==1" v-show="weixinshchosed" class="listone">
										微信生活缴费
										<img src="../assets/records/error.png" class="errorImg"
											v-if="weixinchosed==true||zfbchosed==true||ylchosed==true||zfbshchosed==true"
											@click="delStatus(wxlife_status)" />
									</div>
									<div v-if="formlist.alilife_status==1" v-show="zfbshchosed" class="listone"> 支付宝生活缴费
										<img src="../assets/records/error.png"
											v-if="weixinchosed==true||zfbchosed==true||ylchosed==true||weixinshchosed==true"
											class="errorImg" @click="delStatus(alilife_status)" />
									</div>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<div class="bottom">
					<el-tabs v-model="activeName" class="tabs" type="card">
						<el-tab-pane label="资质信息" name="first">
							<zizhipage :formlist="formlist" :ifedit="ifedit"></zizhipage>
						</el-tab-pane>
						<el-tab-pane label="结算信息" name="second">
							<!-- <jiesuan :weixinchosed="false" :zfbchosed="false" :formlist="formlist.detail"
								:weixinshchosed="false" :zfbshchosed="false" :ylchosed="false" :ifedit="ifedit">
							</jiesuan> -->
							<jiesuan :weixinchosed="weixinchosed" :zfbchosed="zfbchosed" :formlist="formlist.detail"
								:checkgroup="checkgroup" :weixinshchosed="weixinshchosed" :zfbshchosed="zfbshchosed"
								:ylchosed="ylchosed" :ifedit="ifedit" :rules="rule"></jiesuan>
						</el-tab-pane>
						<!-- <el-tab-pane label="发票信息" name="third">
            <fapiao
              :weixinchosed="weixinchosed"
              :zfbchosed="zfbchosed"
            ></fapiao>
          </el-tab-pane> -->
						<el-tab-pane label="资质上传" name="fourth">
							<pushzizhi :formlist="formlist" :ifedit="ifedit"></pushzizhi>
						</el-tab-pane>
						<el-tab-pane v-if="showExamineFile()" label="审核材料" name="fifth">
							<examinefile :formlist="formlist" :ifedit="ifedit"></examinefile>
						</el-tab-pane>

						<el-tab-pane v-if="showOfficialAccount()" label="公众号信息" name="officialAccount">
							<officialAccount :weixinchosed="weixinchosed" :zfbchosed="zfbchosed"
								:formlist="formlist.wxinfo" :checkgroup="checkgroup" :weixinshchosed="weixinshchosed"
								:zfbshchosed="zfbshchosed" :ylchosed="ylchosed" :ifedit="ifedit"></officialAccount>
							<!-- :rules="rule" -->
						</el-tab-pane>

						<el-tab-pane v-if="zfbchosed || zfbshchosed" label="支付宝信息" name="sixth">
							<zfbInfo :formlist="formlist" :ifedit="ifedit"></zfbInfo>
						</el-tab-pane>
					</el-tabs>
				</div>

				<!-- 按钮 -->
				<el-form-item v-if="ifedit == false">
					<el-row class="buttonlist">
						<!-- v-if="typestatus==0" -->
						<el-button type="info" class="leftbutton" @click="keepform" :disabled="baocun">临时保存</el-button>
						<el-button type="submit" class="rightbutton" @click="submitechange" :disabled="ifdianji">提交修改
						</el-button>

					</el-row>
				</el-form-item>
			</el-form>
		</div>
		<!-- <el-alert
      class="errormessage"
      v-if="formlist.apply_message"
      :title="formlist.apply_message"
      type="error"
      show-icon
      :closable="false"
    >
    </el-alert> -->

	</div>
</template>

<script>
	import headtop from "../components/header";
	import zizhipage from "../components/zizhi";
	import jiesuan from "../components/jiesuan";
	import pushzizhi from "../components/pushzizhi";
	import examinefile from "../components/examinefile";
	import config from "../request/config";
	import Cookies from "js-cookie";
	import officialAccount from "../components/officialAccount";
	import zfbInfo from "../components/zfbInfo";
	import {
		getproduct
	} from "../request/api";
	export default {
		name: "RecordDetail",
		components: {
			headtop,
			zizhipage,
			jiesuan,
			pushzizhi,
			examinefile,
			officialAccount,
			zfbInfo
		},
		data() {
			return {

				filedata: {
					ctrl: "file",
					func: "save",
					type: "file",
				},
				ruleForm: {
					customer_postal: [{
							required: true,
							message: "邮政编码不能为空",
							trigger: ["blur", "change"],
						},
						{
							validator: this.customer_postalRegular,
							trigger: ["blur", "change"]
						}
					],


					contacts_mobile: [{
							required: true,
							message: "手机号码不能为空",
							trigger: ["blur", "change"],
						},
						{
							validator: this.validatePhone,
							trigger: ["blur", "change"]
						}
					],
					wxmp_mobile: [{
						required: true,
						message: " ",
						trigger: "blur",
					}, ],
					kftel: [{
							required: true,
							message: "客服电话不能为空",
							trigger: "blur",
						},
						{
							validator: this.kftelRegular,
							trigger: ["blur", "change"]
						},
					],
					legal_id_code: [{
							required: true,
							message: "身份证号码不能为空",
							trigger: "blur",
						},
						{
							validator: this.idcard,
							trigger: ["blur", "change"]
						},

					],
					email: [{
							required: true,
							message: "邮箱不能为空",
							trigger: "change"
						},
						{
							type: "email",
							message: "邮箱格式不正确",
							trigger: ["blur", "change"],
						},
					],
					bank_account: "",
					base_bank_account: "",
				},

				fileheaders: {
					token: Cookies.get("tooken"),
				},
				uploadurl: config.baseUrl + "/index.php/api/index",
				wxpay_status: "wxpay_status",
				alipay_status: "alipay_status",
				ysf_status: "ysf_status",
				wxlife_status: "wxlife_status",
				alilife_status: "alilife_status",
				formlist: {},
				input: "",
				activeName: "first",
				value1: "",
				activeIndex: "2",
				typestatus: "",
				ifedit: true,
				backsin: false,
				ifdianji: false,
				baocun: false,
				payStatus: 0,
				weixinchosed: false,
				weixinshchosed: false,
				zfbchosed: false,
				zfbshchosed: false,
				ylchosed: false,
				rule: {
					contacts_mobile: [{
						required: true,
						message: " ",
						trigger: "blur",
					}, ],
					wxmp_mobile: [{
						required: true,
						message: " ",
						trigger: "blur",
					}, ],
					kftel: [{
						required: true,
						message: " ",
						trigger: "blur",
					}, ],
					legal_id_code: [{
						required: true,
						message: " ",
						trigger: "blur",
					}, ],
					email: [{
							required: true,
							message: " ",
							trigger: "change"
						},
						{
							type: "email",
							message: " ",
							trigger: ["blur", "change"],
						},
					],
					bank_account: "",
					base_bank_account: "",
				},
				companylist: [],
			};
		},
		beforeRouteEnter(to, from, next) {
			localStorage.setItem("routename", from.name);
			next();
		},
		created() {
			if (localStorage.getItem("routename") == "alreadyauditing") {
				//如过是从待处理里进来的  设置返回参数
				this.backsin = true;
			}
		},
		mounted() {

			this.typestatus = this.$route.query.status;
			if (this.typestatus == 0) {
				this.ifedit = false;
			}
			this.getpage();
			this.getcustomerinfo();

		},
		methods: {
			customer_postalRegular(rule, value, callback) {
				/*console.log(rule);
				console.log(value);
				console.log(callback);*/
				if (!value) {
					callback(new Error('邮政编码不能为空！'));
				}
				//使用正则表达式进行验证手机号码
				else if (!/^[0-9]{6}$/.test(value)) {
					callback(new Error('邮政编码不正确！'));
				} else {
					callback()
				}
			},

			kftelRegular(rule, value, callback) {
				/*console.log(rule);
				console.log(value);
				console.log(callback);*/
				if (!value) {
					callback(new Error('客服电话不能为空！'));
				}
				//使用正则表达式进行验证手机号码
				else if (!/^(([0-9]{3,4}-)?[0-9]{7,8}|(1\d{10}))|[1-9]\d$/.test(value)) {
					callback(new Error('客服电话不正确！'));
				} else {
					callback()
				}
			},
			validatePhone(rule, value, callback) {
				/*console.log(rule);
				console.log(value);
				console.log(callback);*/
				if (!value) {
					callback(new Error('手机号不能为空！'));
				}
				//使用正则表达式进行验证手机号码
				else if (!/^1\d{10}$/.test(value)) {
					callback(new Error('手机号不正确！'));
				} else {
					callback()
				}
			},

			idcard(rule, value, callback) {
				// console.log("rule", rule)
				// console.log("value", value)
				// console.log("callback", callback)

				// 法人身份证号码legal_id_code



				if (!value) {
					if (rule.field == "legal_id_code") {
						return callback(new Error("身份证号码不能为空"));
					} else {
						return;
					}
				} else if (!/^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/.test(value) && !
					/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
						value)) {
					callback(new Error('身份证号不正确！'));
				} else {
					callback()
				}
				//}, 1000);
			},

			showOfficialAccount() {
				return this.formlist.wxpay_status == 1
			},
			//2022-06-15 增加  集团公司 则选择自己或者子公司，其他则默认自己公司
			getcustomerinfo() {
				var param = {
					func: "getcustomerinfo",
					ctrl: "payinfo",
				}
				getproduct(param).then((res) => {
					if (res.code == 200) {
						this.companylist = res.data;
						var arr = this.companylist.map(item => item.name);
						if (arr.indexOf(this.formlist['customer_name']) === -1) {
							this.formlist['customer_name'] = this.getCookie("customername");
							this.$forceUpdate()
						}
					}
				});
			},
			customer_name_change($event) {
				console.log($event, this.formlist)
				this.$forceUpdate()
			},
			delStatus(status) {
				console.log(status, this.formlist.status)

				if (status == "wxpay_status") {
					this.weixinchosed = false
					delete this.formlist.detail['WXPAY']
				}
				if (status == "alipay_status") {
					this.zfbchosed = false
					delete this.formlist.detail['ALIPAY']
				}
				if (status == "wxlife_status") {
					this.weixinshchosed = false
					delete this.formlist.detail['WXLIFE']
				}
				if (status == "ysf_status") {
					this.ylchosed = false
					delete this.formlist.detail['YSF']
				}
				if (status == "alilife_status") {
					this.zfbshchosed = false
					delete this.formlist.detail['ALILIFE']

				}

			},
			showExamineFile() {
				return this.formlist.ysf_status == 1 || this.formlist.wxlife_status == 1 || this.formlist.alilife_status ==
					1
			},
			check_legal_id_valid_check() {
				if (this.ifedit === true) {
					return true;
				}
				return this.formlist.legal_id_valid_check;
			},
			getpage() {
				var data = {
					func: "formdata",
					ctrl: "payinfo",
					payinfo_id: this.$route.query.payinfoid,
					InstanceID: this.$route.query.InstanceID,
				};
				if (!data.payinfo_id && !data.InstanceID) {
					this.$router.go(-1);
				}
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.formlist = res.data;

						if (this.formlist.detail['ALIPAY']) {
							this.zfbchosed = true
						}
						if (this.formlist.detail['WXPAY']) {
							this.weixinchosed = true
						}
						if (this.formlist.detail['YSF']) {
							this.ylchosed = true
						}
						if (this.formlist.detail['WXLIFE']) {
							this.weixinshchosed = true
						}
						if (this.formlist.detail['ALILIFE']) {
							this.zfbshchosed = true
						}
						if ('wxinfo' in this.formlist) {
							console.log("123")
							if (this.formlist.wxinfo.length == 0) {
								this.formlist.wxinfo.push({
									wxmp_name: "", //微信公众号名称
									wxmp_account: "", //微信公众号账号
									wxmp_pwd: "", //微信公众号密码
									wxmp_contacts: "", //微信管理员姓名
									wxmp_mobile: "", //微信管理员电话
								})
							}

						} else {
							this.formlist.wxinfo = []
							this.formlist.wxinfo.push({
								wxmp_name: "", //微信公众号名称
								wxmp_account: "", //微信公众号账号
								wxmp_pwd: "", //微信公众号密码
								wxmp_contacts: "", //微信管理员姓名
								wxmp_mobile: "", //微信管理员电话
							})
						}
						this.auth_file = []
						//业务办理授权函
						if (this.formlist.auth_fileinfo) {
							this.auth_file.push({
								'url': config.baseUrl + this.formlist.auth_fileinfo.file_path
							})
						} else {
							this.formlist.auth_fileinfo = []
						}
						console.log("this.auth_file", this.auth_file)

						var arr = this.companylist.map(item => item.name);
						if (arr.indexOf(this.formlist['customer_name']) === -1) {
							this.formlist['customer_name'] = this.getCookie("customername");
							this.$forceUpdate()
						}

					}
				});
			},
			//保存草稿
			keepform() {
				this.$refs.formlistRef.validate(async valid => {
					console.log(valid)
					// if (!valid) {

					// 	return false;
					// } else {
					if (this.baocun) return;
					this.baocun = true;
					this.ifdianji = true;
					var data = this.formlist;
					data.func = "createapply";
					data.ctrl = "payinfo";
					data.apply_status = 0;
					getproduct(data).then((res) => {
						if (res.code == 200) {
							this.$message({
								message: "修改成功！",
								type: "success",
								offset: 100,
							});
							setTimeout(() => {
								this.$router.go(-1);
							}, 2000);
						} else {
							this.baocun = false;
							this.ifdianji = false;
						}
					});
					// }
				})
			},
			submitechange() {
				this.$refs.formlistRef.validate((valid) => {

					if (valid) {


						if (this.ifdianji) {



							return false;
						}
						this.ifdianji = true;
						this.baocun = true;
						var data = this.formlist;
						// data.auth_file="4619"
						data.func = "createapply";
						data.ctrl = "payinfo";
						data.apply_status = 1;
						getproduct(data).then((res) => {
							console.log(res);
							if (res.code == 200) {
								this.$message({
									message: "修改成功！",
									type: "success",
									offset: 100,
								});
								setTimeout(() => {
									// this.$router.push({ name: "records" });
									this.$router.go(-1);
								}, 2000);
							} else {
								this.baocun = false;
								this.ifdianji = false;
							}
						});
					} else {

						// this.$refs.wrap.scrollTo(0, 0)
						this.$refs.wrap.scrollTop = 0
						window.scrollTo(0,0)
						console.log('error submit!!123123');
						return false;
					}
				})
			},
			goback() {
				if (this.backsin) {
					this.$router.push({
						name: "alreadyauditing"
					});
				} else {
					this.$router.go(-1);
				}

			},
			openEdit() {
				if (this.ifedit) {
					this.ifedit = false
				} else {
					this.getpage();
					this.ifedit = true
				}

			}
		},
	};
</script>

<style lang="scss" scoped>
	@import url("../assets/css/biaoge.scss");

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin-bottom: 30px;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border-bottom: 1px solid #e4e7ed;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		border: 1px solid #315bd2;
		color: #315bd2;
	}

	/deep/.el-form-item {
		margin: 0;
	}

	/deep/.el-form-item__content {
		display: flex;
		line-height: 16px;
	}

	/deep/.el-form-item__label {
		width: 183px;
		// border-left: 1px solid rgba(168, 188, 235, 1);
		// border-right: 1px solid rgba(168, 188, 235, 1);
		line-height: 60px;
		// background: rgba(246, 249, 255, 1);
	}

	/deep/.el-row {
		border-bottom: 1px solid rgba(168, 188, 235, 1);
	}

	.box {
		width: 74%;
		margin: 0 13%;

		.nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 18px 0;
			border-bottom: 1px solid #e0e0e0;

			.navBack {
				color: #666666;
				font-size: 16px;
			}

			.navTitle {
				color: #333333;
				font-size: 20px;
				font-weight: 600;
			}
		}

		.content {
			margin-top: 40px;

			.contentTop {
				display: flex;
				justify-content: space-between;

				span {
					font-size: 24px;
					color: #333333;
					font-weight: 600;
				}

				.editbutton {
					border: 1px solid #315bd2;
					color: #315bd2;
				}
			}
		}
	}

	.waikuang {
		border: 1px solid rgba(168, 188, 235, 1);
		margin-top: 30px;
		border-bottom: none;

		// border-left: none;
		.flexlist {
			&:last-child {
				border-bottom: none;
			}

			.input {
				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.bottom {
		margin: 30px 0;

		.tabs {
			border: none;
		}
	}

	.buttonlist {
		display: flex;
		justify-content: center;
		margin-bottom: 10vh;
		border: none;
		border-top: 1px solid #e3e4e7;
		padding-top: 30px;
		width: 100%;

		.leftbutton {
			background: #eeeeee;
			border: 1px solid #eeeeee;
			color: #000;
			padding: 10px 30px;
		}

		.rightbutton {
			background: #315bd2;
			border-radius: 2px;
			color: #fff;
			padding: 10px 30px;
		}
	}

	.errormessage {

		width: max-content;
		margin: 0 13%;
		margin-bottom: 30px;
	}

	.listone {
		position: relative;
	}

	.errorImg {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 0;
		right: 0;
	}

	.table-view-itemView {
		border-bottom: 1px solid #CFCFCF;
		height: 116px;
		padding-left: 69px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		// margin-right: 75px;
	}

	.table-div-item-label {
		// margin-right: 59px;
		width: 135px;
		font-size: 14px;
		color: #606266;
	}

	/deep/.el-form-item__label {
		line-height: 74px !important;
	}

	/deep/.el-upload--picture-card {
		line-height: 74px !important;
		width: 78px;
		height: 78px;
	}

	/deep/.el-upload__text {
		line-height: 1;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 78px;
		height: 78px;
	}

	.fileFormat {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 36px;
		margin-left: 17px;
		width: 340px;
	}

	/deep/.el-form-item__error {
		top: 40%;
		left: 43%;
	}
</style>
