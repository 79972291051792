<template>
    <div style="margin-top: 100px">
        <headtop :activeIndex="activeIndex" title="热付通SAAS营业执照变更"></headtop>
        <div class="box">
            <el-button class="backbut" @click="backpage">返回</el-button>
            <div class="nav">
                <span></span>
                <div class="navTitle">营业执照变更</div>
                <div style="width: 50px"></div>
            </div>

            <div style="margin-top: 10px;" v-if="Corplist.weiZ">
                <el-row>
                    <bexaminefile @chinaumsFile="chinaumsFile" @ysepayFile="ysepayFile" :ifedit="ifedit"></bexaminefile>
                </el-row>
            </div>
            <div class="content">


                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="140px"
                    class="demo-ruleForm">
                    <el-form-item label="已选择的支付方式" prop="corp">
                        <div class="corpList">
                            <div class="corpList_item" v-for="(item, index) in list" :key="index">{{ item }}</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="法人姓名" prop="legal_name">
                        <el-input v-model="ruleForm.legal_name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="法人身份证号" prop="legal_id_code">
                        <el-input v-model="ruleForm.legal_id_code" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="法人身份证有效期">
                        <!--  <el-col :span="12">
                            <el-form-item prop="legal_id_data1">
                                <el-date-picker type="date" placeholder="身份证开始时间" v-model="ruleForm.legal_id_data1"
                                    style="width: 100%;"></el-date-picker>
                            </el-form-item>
                        </el-col> -->
                        <el-form-item prop="legal_id_valid">
                            <el-date-picker type="date" placeholder="法人身份证有效期（长期不填写）" v-model="ruleForm.legal_id_valid"
                                style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-form-item>



                    <el-form-item label="身份证正反面" class="card">
                        <div class="cardUpload">
                            <span>
                                <el-upload :class="{ hide: legalIdfrontImageUpload }" ref="legalIdfrontUpload"
                                    :action="uploadurl" :data="filedata1" :headers="fileheaders" list-type="picture-card"
                                    :on-success="onlegalIdfront" :file-list="legalIdfront" :on-change="legalIdfrontImage">
                                    身份证正面
                                    <div slot="file" slot-scope="{file}">
                                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                        <span class="el-upload-list__item-actions">
                                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview()">
                                                <i class="el-icon-zoom-in"></i>
                                            </span>
                                            <span v-if="!disabled" class="el-upload-list__item-delete"
                                                @click="legalIdfrontRemove(file)">
                                                <i class="el-icon-delete"></i>
                                            </span>
                                        </span>
                                    </div>
                                </el-upload>
                            </span>
                            <span>
                                <el-upload :class="{ hide: legalIdbackUpload }" ref="legalIdbackUpload" :action="uploadurl"
                                    :data="filedata2" :headers="fileheaders" list-type="picture-card"
                                    :on-success="onlegalIdback" :file-list="legalIdback" :on-change="legalIdbackImage">
                                    身份证反面
                                    <div slot="file" slot-scope="{file}">
                                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                        <span class="el-upload-list__item-actions">
                                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview()">
                                                <i class="el-icon-zoom-in"></i>
                                            </span>
                                            <span v-if="!disabled" class="el-upload-list__item-delete"
                                                @click="legalIdbackRemove(file)">
                                                <i class="el-icon-delete"></i>
                                            </span>
                                        </span>
                                    </div>
                                </el-upload>
                            </span>

                        </div>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>

                    <el-form-item label="营业执照注册号" prop="business_licence_code">
                        <el-input v-model="ruleForm.business_licence_code" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照有效期" prop="business_licence_valid">
                        <!-- <el-col :span="12">
                            <el-form-item prop="business_licence_data1">
                                <el-date-picker type="date" placeholder="营业执照开始时间" v-model="ruleForm.business_licence_data1"
                                    style="width: 100%;"></el-date-picker>
                            </el-form-item>
                        </el-col> -->

                        <el-form-item prop="business_licence_valid">
                            <el-date-picker type="date" placeholder="营业执照结束时间（长期不填写）"
                                v-model="ruleForm.business_licence_valid" style="width: 100%;"></el-date-picker>
                        </el-form-item>

                    </el-form-item>
                    <el-form-item label="营业执照经营内容" prop="business_licence_cont">
                        <el-input v-model="ruleForm.business_licence_cont" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照注册资金" prop="business_licence_fund">
                        <el-input v-model="ruleForm.business_licence_fund" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="支付宝账号" prop="ali_account" v-if="Corplist.zhiS || Corplist.zhiX">
                        <el-input v-model="ruleForm.ali_account" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="营业执照">
                        <el-upload class="upload-demo" :headers="fileheaders" :action="uploadurl" :limit="1"
                            :data="filedata3" :on-success="onbusinessLicenceFile" :on-remove="handleRemove"
                            :file-list="businessLicenceFile" list-type="picture">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <!--   <div slot="tip" class="el-upload__tip">只能上传一个jpg/png文件</div> -->
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="变更授权函" v-if="Corplist.weiS || Corplist.zhiS">
                        <el-upload class="upload-demo" :action="uploadurl" :headers="fileheaders" :limit="1"
                            :data="filedata4" :on-success="onchangeList" :on-remove="handleRemove" :file-list="changeList"
                            list-type="picture">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <!--  <div slot="tip" class="el-upload__tip">只能上传一个jpg/png文件</div> -->
                        </el-upload>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">确认提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import headtop from "../components/header";
import bexaminefile from "../components/corp_file.vue";
import Cookies from "js-cookie";
import config from "../request/config"
import {
    getproduct
} from "../request/api";
export default {
    name: "RecordDetail",
    components: {
        headtop, bexaminefile
    },
    data() {
        var validatorCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('法人身份证号'));
            } else if (!/^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/.test(value) && !
                /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                    value)) {
                callback(new Error('身份证号不正确！'));
            } else {
                callback();
            }
        };
        return {
            list: [],
            Corplist: {},
            activeIndex: "2",
            PaymentMethod: "",
            ruleForm: {
                payinfo_id: '',
                legal_name: '',
                legal_id_code: '',
                legal_id_valid: "",
                legal_idfront_file: '',
                legal_idback_file: '',
                business_licence_code: '',
                business_licence_cont: '',
                business_licence_fund: '',
                business_licence_file: '',
                ali_account: "",
                empower_file: "",
                chinaums_file: "",
                ysepay_file: "",
            },
            uploadurl: config.baseUrl + "/index.php/api/index",
            fileheaders: {
                token: Cookies.get("tooken"),
            },
            filedata1: {
                ctrl: "file",
                func: "save",
                type: "file",
                serviceFileName: "身份证正面",
            },
            filedata2: {
                ctrl: "file",
                func: "save",
                type: "file",
                serviceFileName: "身份证反面",
            },
            filedata3: {
                ctrl: "file",
                func: "save",
                type: "file",
                serviceFileName: "营业执照",
            },
            filedata4: {
                ctrl: "file",
                func: "save",
                type: "file",
                serviceFileName: "变更授权函",
            },
            rules: {
                legal_name: [{ required: true, message: '请输入法人姓名', trigger: 'blur' },],
                legal_id_code: [{ required: true, message: '请输入法人身份证号', trigger: 'blur' },
                { validator: validatorCode, trigger: 'blur' }],
                business_licence_code: [{ required: true, message: '请输入营业执照注册号', trigger: 'blur' },],
                business_licence_cont: [{ required: true, message: '请输入营业执照经营内容', trigger: 'blur' },],
                business_licence_fund: [{ required: true, message: '请输入营业执照注册资金', trigger: 'blur' },],

            },
            businessLicenceFile: {},
            legalIdfront: {},
            legalIdback: {},
            changeList: [],
            legalIdfrontImageUpload: false,
            legalIdbackUpload: false,
            formlist: {},
            formlistXin: {},
            ifedit: true,
            imageUrl: '',
            dialogImageUrl: "",
            dialogVisible: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        localStorage.setItem("routename", from.name);
        next();
    },
    created() {
        this.list = JSON.parse(this.$route.query.list)
        this.list.map((item) => {
            console.log(item);
            if (item == '微信支付(公众号、小程序)') {
                this.Corplist.weiZ = item
            } else if (item == '微信生活缴费') {
                this.Corplist.weiS = item
            } else if (item == "支付宝生活缴费") {
                this.Corplist.zhiS = item
            } else if (item == "支付宝小程序") {
                this.Corplist.zhiX = item
            } else if (item == "云闪付") {
                this.Corplist.yun = item
            }
        })
    },
    mounted() {

    },
    methods: {
        backpage() {
            this.$router.push({ name: "receipt" });
        },
        newDate(time) {
            if (time) {
                var date = new Date(time)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                var d = date.getDate()
                d = d < 10 ? '0' + d : d
                return y + '-' + m + '-' + d
            } else {
                return ""
            }

        },
        submitForm(formName) {
            let that = this
            let data = {
                ...this.ruleForm,
                ctrl: "payinfo",
                func: "licenceedit",
                payinfo_id: that.$route.query.payinfo_id
            }
            data.legal_id_valid = that.newDate(data.legal_id_valid)
            data.business_licence_valid = that.newDate(data.business_licence_valid)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(data)
                    if (!data.legal_idfront_file) {
                        this.$message({
                            type: "error",
                            message: "请上传身份证正面",
                            offset: 120,
                        });
                        return false;

                    }
                    if (!data.legal_idback_file) {

                        this.$message({
                            type: "error",
                            message: "请上传身份证反面",
                            offset: 120,
                        });
                        return false;
                    }
                    if (that.Corplist.weiZ && !data.chinaums_file) {
                        this.$message({
                            type: "error",
                            message: "请上传银联商户信息变更单",
                            offset: 120,
                        });
                        return false
                    }
                    if (that.Corplist.weiZ && !data.ysepay_file) {
                        this.$message({
                            type: "error",
                            message: "请上传变更表——商户盖章即可",
                            offset: 120,
                        });
                        return false
                    }
                    if (that.Corplist.zhiS && !data.ysepay_file) {
                        this.$message({
                            type: "error",
                            message: "请输入支付宝账号",
                            offset: 120,
                        });
                        return false
                    }
                    if (that.Corplist.zhiS && !data.empower_file) {
                        this.$message({
                            type: "error",
                            message: "请上传变更授权函",
                            offset: 120,
                        });
                        return false
                    }
                    if (that.Corplist.weiS && !data.empower_file) {
                        this.$message({
                            type: "error",
                            message: "请上传变更授权函",
                            offset: 120,
                        });
                        return false
                    }
                    console.log(123)

                    getproduct(data).then((res) => {
                        if (res.code == 200) {
                            console.log(res)
                            that.$router.go(-1)
                        }
                    });

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        chinaumsFile(e) {
            console.log(e)
            this.ruleForm.chinaums_file = e
        },
        ysepayFile(e) {
            console.log(e)
            this.ruleForm.ysepay_file = e
        },
        handlePictureCardPreview(file) {
            console.log(file)
            this.dialogImageUrl = config.baseUrl + file.file_path;
            this.dialogVisible = true;
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            let uploadFiles = this.$refs.pictureUpload.uploadFiles
            for (var i = 0; i < uploadFiles.length; i++) {
                if (uploadFiles[i]['url'] == file.url) {
                    uploadFiles.splice(i, 1)
                }
            }
        },
        legalIdfrontRemove(file) {
            let uploadFiles = this.$refs.legalIdfrontUpload.uploadFiles
            console.log(uploadFiles)
            for (var i = 0; i < uploadFiles.length; i++) {
                if (uploadFiles[i]['url'] == file.url) {
                    uploadFiles.splice(i, 1)
                }
            }

            setTimeout(() => {
                this.legalIdfrontImageUpload = false
            }, 2000)

        },
        legalIdbackRemove(file) {
            let uploadFiles = this.$refs.legalIdbackUpload.uploadFiles
            for (var i = 0; i < uploadFiles.length; i++) {
                if (uploadFiles[i]['url'] == file.url) {
                    uploadFiles.splice(i, 1)
                }
            }
            setTimeout(() => {
                this.legalIdbackUpload = false
            }, 2000)

        },
        onbusinessLicenceFile(response, file, fileList) {
            console.log(response, file, fileList);
            this.businessLicenceFile = file
            this.ruleForm.business_licence_file = response.data.file_id
        },
        onchangeList(response, file, fileList) {
            console.log(response, file, fileList);
            this.changeList = file
            this.ruleForm.empower_file = response.data.file_id
        },
        onlegalIdfront(response, file, fileList) {
            console.log(response, file, fileList);
            this.ruleForm.legal_idfront_file = response.data.file_id
        },
        onlegalIdback(response, file, fileList) {
            console.log(response, file, fileList);
            this.ruleForm.legal_idback_file = response.data.file_id
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log(this.imageUrl)
        },
        legalIdfrontImage(file, fileList) {
            console.log(file, fileList);
            this.legalIdfrontImageUpload = fileList.length == 1;
            this.legalIdfront = fileList
        },
        legalIdbackImage(file, fileList) {
            console.log(file, fileList);
            this.legalIdbackUpload = fileList.length == 1;
            this.legalIdback = fileList
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;

        }
    }
};
</script>

<style lang="scss" scoped>
@import url("../assets/css/biaoge.scss");

/deep/.el-row {
    border-bottom: 1px solid rgba(168, 188, 235, 1);
}

.box {
    width: 74%;
    margin: 0 13%;

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
        border-bottom: 1px solid #333333;

        .navBack {
            color: #666666;
            font-size: 16px;
        }

        .navTitle {
            color: #333333;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .content {
        margin-top: 40px;

        .corpList {
            display: flex;

            .corpList_item {
                color: #409EFF;
                padding: 0 20px;
                border: 1px solid #409EFF;
                border-radius: 5px;
                margin-right: 10px;
            }
        }

        .demo-ruleForm {

            .card {

                .cardUpload {
                    display: flex;
                }

                /deep/ .hide .el-upload--picture-card {
                    display: none !important;
                }

                /deep/.el-upload--picture-card {
                    width: 265px;
                    height: 165px;
                    line-height: 165px;
                    margin-right: 20px;
                }

                /deep/.el-upload-list--picture-card .el-upload-list__item {
                    width: 265px;
                    height: 165px;
                }

            }
        }
    }
}
</style>
