<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>合同模板管理</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- <div class="titleDiv">
			合同模板管理
		</div> -->
		<div class="createLookDiv">
			<div @click="createFun">
				<!-- <img src="../../assets/home/choseicompany.png"> -->
				<img src="../../assets/company/upload.png">
				<span>创建合同模板</span>
			</div>
			<!-- <div>
				<img src="../../assets/home/choseicompany.png">
				<span>查看已禁用的合同模板</span>
			</div> -->
		</div>



		<el-table :data="list" class="elTable" style="width: 100%">
			<el-table-column label="合同模板编号">
				<template slot-scope="scope">
					<!-- <i class="el-icon-time"></i> -->
					<span style="margin-left: 10px">{{ scope.row.C_TempletID.substr(scope.row.C_TempletID.indexOf("_") + 1)
					}}</span>
				</template>
			</el-table-column>

			<el-table-column label="合同模板标题">
				<template slot-scope="scope">
					<!-- <i class="el-icon-time"></i> -->
					<span style="margin-left: 10px">{{ scope.row.C_TempletName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="合同模板状态">
				<template slot-scope="scope">
					<!-- <i class="el-icon-time"></i> -->
					<span style="margin-left: 10px" v-if="scope.row.I_State == 0">已启用</span>
					<span style="margin-left: 10px" v-else>禁用</span>
				</template>
			</el-table-column>

			<el-table-column label="创建时间">
				<template slot-scope="scope">
					<!-- <i class="el-icon-time"></i> -->
					<span style="margin-left: 10px">{{ scope.row.D_CreateDate }}</span>

				</template>
			</el-table-column>

			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button size="mini" class="buttonOne" @click="handleEdit(scope.$index, scope.row)">下载模板
					</el-button>
					<!-- <el-button size="mini" class="buttonTwo" type="danger"
						@click="handleDelete(scope.$index, scope.row)">禁用</el-button> -->
					<el-button size="mini" class="buttonTwo" type="danger"
						@click="handleDelete(scope.$index, scope.row)">修改</el-button>
				</template>

			</el-table-column>
		</el-table>
		<div class="blockDiv">

			<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[100, 200, 300, 400]" :page-size="100"
				layout="total, sizes, prev, pager, next, jumper" :total="400">
			</el-pagination> -->
		</div>

		<el-dialog title="修改合同" :visible.sync="dialogtwo" style="width:100%;height:100%">
			<div class="contractView">
				<div class="managementDiv-companyTitleDiv maginTopDiv" v-if="customerid != 679">
					<div class="managementDiv-companyTitleDiv-nameDiv">业务类型：
					</div>
					<el-input placeholder="请填写业务类型" v-model="BusinessType" class="managementDiv-companyTitleDiv-input">
					</el-input>
				</div>
				<div class="managementDiv-companyTitleDiv maginTopDiv" v-if="customerid != 679">
					<div class="managementDiv-companyTitleDiv-nameDiv">用户类型：
					</div>
					<el-input placeholder="请填写用户类型" v-model="UserType" class="managementDiv-companyTitleDiv-input">
					</el-input>
				</div>
				<div class="managementDiv-companyTitleDiv maginTopDiv" v-if="customerid != 679">
					<div class="managementDiv-companyTitleDiv-nameDiv">
						标签：</div>

					<el-input placeholder="请填写标签" v-model="Labels" class="managementDiv-companyTitleDiv-input"
						style="width: 200px;">
					</el-input>

					<el-button @click="LabelsSure">确认</el-button>
				</div>
				<div class="tagsClass" v-if="customerid != 679">
					<el-tag v-for="tag in tags" :key="tag" closable @close="handleClose(tag)">
						{{ tag }}
					</el-tag>
				</div>

				<el-radio-group class="radioView" v-model="radioValue">
					<el-radio label="1">禁用</el-radio>
					<el-radio label="0">启用</el-radio>
				</el-radio-group>


				<el-button @click="sureClick" type="primary" class="sureClass">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	getCompanyList
} from "../../request/api";
import Vue from 'vue'
// import {
// 	getCompanyList
// } from "../../request/api";
import axios from 'axios'
Vue.prototype.$axios = axios
export default {
	data() {
		return {
			dialogtwo: false,
			currentPage1: 5,
			currentPage2: 5,
			currentPage3: 5,
			currentPage4: 4,
			list: [],
			BusinessType: "",
			UserType: "",
			Labels: "",
			tags: [],
			indexValue: "",
			rowValue: "",
			radioValue: '0',
		}
	},
	created() {
		this.customerid = this.getCookie("customerid")
		this.getCompanyList()
	},
	methods: {
		handleClose(tag) {
			this.tags.splice(this.tags.indexOf(tag), 1);
		},
		LabelsSure() {
			if (this.Labels) {
				this.tags.push(this.Labels)
				this.Labels = ""
			}


		},
		createFun() {
			this.$router.push({
				name: "createContract"
			});
		},
		getCompanyList() {
			var data = {
				func: "QueryContractTemplets",
				ctrl: "FadadaApi",
				customer_id: this.customerid,
			};
			console.log("看看传递的数据", data)
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					// this.ifused = res.data;
					this.list = res.data
					console.log("看看返回值", res)
				} else {
					console.log("看看返回值", res)
				}
			})
		},
		handleEdit(index, row) {
			console.log(index, row);
			if (row.url) {

				window.open(row.url)




			} else {
				this.$message({
					message: "该合同没有模板",
					type: 'error'
				});
			}
		},
		sureClick() {
			console.log()
			if (this.Labels) {
				this.$message({
					message: "标签输入框里不为空，请点击【确认】按钮后再提交",
					type: 'error',
					offset: 80
				});
			} else {
				var data = {
					func: "ChangeTempletState",
					ctrl: "FadadaApi",
					customer_id: this.customerid,
					TempNo: this.rowValue.C_TempletID.substr(this.rowValue.C_TempletID.indexOf("_") + 1),
					BusinessType: this.BusinessType,
					UserType: this.UserType,
					Labels: this.tags.join(','),
					State: this.radioValue
				};
				console.log("看看传递的数据", data)
				getCompanyList(data).then((res) => {
					if (res.code == 200) {
						// this.ifused = res.data;
						this.list = res.data
						this.$message({
							message: "操作成功",
							type: 'success',
							offset: 80
						});
						this.dialogtwo = false
						this.getCompanyList()


						console.log("看看返回值", res)
					} else {
						this.$message({
							message: "操作失败",
							type: 'error',
							offset: 80

						});
						this.getCompanyList()
						console.log("看看返回值", res)
					}
				})
			}

		},

		handleDelete(index, row) {
			console.log(index, row);
			this.dialogtwo = true
			this.indexValue = index
			this.rowValue = row
			this.BusinessType = row.BusinessType;
			this.UserType = row.UserType;
			this.tags = row.Labels.split(',');
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		}

	}
}
</script>

<style scoped>
.titleDiv {
	font-size: 20px;
	font-weight: bold;
	color: #0C0C0C;
	margin: 0 auto;
	margin-top: 30px;
	text-align: center;
}

.createLookDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-right: 24px;
	font-size: 14px;
	font-weight: bold;
	color: #F19944;
	margin-top: 20px;
}

.createLookDiv div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.createLookDiv div:nth-child(1) {
	/* border-right: 1px solid #c1c1c1; */
	padding-right: 15px;
}

.createLookDiv div:nth-child(2) {
	padding-left: 15px;
}

.createLookDiv div img {
	width: 26px;
	height: 20px;
	margin-right: 10px;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
	text-align: center;
}

.el-table th.el-table__cell.is-leaf {
	background-color: #1373C2;
	font-size: 16px;
	font-weight: bold;
	color: #F6F6F6;
	line-height: 30px;
}

.elTable {
	margin-top: 17px;
}

.el-table td.el-table__cell {
	font-weight: bold;
}

.blockDiv {
	margin-top: 32px;
}

.blockDiv .el-pagination {
	margin: 0 auto;
	text-align: center;
}

.buttonOne {

	height: 30px;
	border: 1px solid #1373C2;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	color: #1373C2;

}

.buttonTwo {

	height: 30px;
	background: #FFFFFF;
	border: 1px solid #FB0000;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	color: #FF0000;
}

.breadcrumbClass {
	height: 39px;
	width: 100%;
	margin-top: 8px;
	border-bottom: 1px solid #F0F1F5;
}

/deep/.el-table td,
/deep/.el-table th {
	text-align: center;
}

/deep/.el-table th {
	background-color: #1373C2;
	color: #fff;
}

.managementDiv-companyTitleDiv {
	/* width: 677px; */
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: auto;


}

.managementDiv-companyTitleDiv-nameDiv {
	font-size: 16px;

	font-weight: bold;
	color: #000000;
}

.managementDiv-companyTitleDiv-input {
	width: 300px;
	margin-left: 35px;

}

/deep/.el-dialog {
	width: 700px;
	min-height: 400px;
}

.sureClass {
	margin-top: 10px;
	padding: auto 80px;
}

.contractView {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.maginTopDiv {
	margin-top: 23px;
}

.tagsClass {
	display: flex;
	flex-direction: row;
	/* 	justify-content: flex-start; */
	/* 	align-items: center; */
	flex-wrap: wrap;
	margin-left: 36%;
	width: 64%;
}

.radioView {
	margin-top: 20px;
}
</style>
