<template>
	<div style="margin-top: 100px">
		<headtop :activeIndex="activeIndex"></headtop>
		<div class="tools-bar">
			<!-- <el-button type="default" @click="backpage">返回</el-button> -->
			<span></span>
			<h1 class="biaoti">选择要开通的支付方式</h1>
			<div class="rt">
				<el-button type="primary" icon="el-icon-s-promotion" @click="$router.push({ name: 'home' })">提交申请
				</el-button>
				<el-button type="default" icon="el-icon-s-custom" @click="$router.push({ name: 'records' })">申请记录
				</el-button>
			</div>
		</div>
		<div class="list">
			<el-checkbox-group v-model="checkboxGroup1">
				<div class="felxlist">
					<div class="listdefaule" :class="checkboxGroup1.indexOf('微信支付(公众号、小程序)') != -1 ? 'activecox' : ''">
						<img src="../assets/home/used.png" class="usedimg" v-if="ifused.WXPAY > 0" />

						<el-checkbox label="微信支付(公众号、小程序)">
							<img class="imglogo" src="../assets/home/weixinone.png" />
							<p class="title">微信支付(公众号、小程序)</p>
							<div class="xian"></div>
							<p class="text">基于微信公众号和微信小程序</p>
							<p class="text">实现用户缴费</p>
							<!-- <div class="godetail">
                <div class="godetailtext">查看流程</div>
                <img class="godetaillogo" src="../assets/home/go.png" />
              </div> -->
						</el-checkbox>
					</div>
					<div class="listdefaule" :class="
              checkboxGroup1.indexOf('支付宝小程序') != -1 ? 'activecox' : ''
            ">
						<img src="../assets/home/used.png" class="usedimg" v-if="ifused.ALIPAY > 0" />
						<el-checkbox label="支付宝小程序">
							<img class="imglogo" src="../assets/home/zfb.png" />
							<p class="title">支付宝小程序</p>
							<div class="xian"></div>
							<p class="text">基于支付宝小程序</p>
							<p class="text">实现用户缴费</p>
							<!-- <div class="godetail">
                <div class="godetailtext">查看流程</div>
                <img class="godetaillogo" src="../assets/home/go.png" />
              </div> -->
						</el-checkbox>
					</div>
					<div style="filter: grayscale(100%)" class="listdefaule" :class="
              checkboxGroup1.indexOf('银联云闪付') != -1 ? 'activecox' : ''
            ">
						<img src="../assets/home/used.png" class="usedimg" v-if="ifused.YSF > 0" />
						<el-checkbox label="银联云闪付" disabled>
							<img class="imglogo" src="../assets/home/yl.png" />
							<p class="title">银联云闪付</p>
							<div class="xian"></div>
							<p class="text">基于银联云闪付APP</p>
							<p class="text">实现用户缴费</p>
							<!-- <div class="godetail">
                <div class="godetailtext">查看流程</div>
                <img class="godetaillogo" src="../assets/home/go.png" /></div
            > -->
						</el-checkbox>
					</div>
				</div>
				<div class="felxlist">
					<div class="listdefaule" :class="
              checkboxGroup1.indexOf('微信生活缴费') != -1 ? 'activecox' : ''
            ">
						<img src="../assets/home/used.png" class="usedimg" v-if="ifused.WXLIFE > 0" />
						<el-checkbox label="微信生活缴费"><img class="imglogo" src="../assets/home/weixintwo.png" />
							<p class="title">微信生活缴费</p>
							<div class="xian"></div>
							<p class="text">基于微信的生活缴费模块</p>
							<p class="text">实现用户缴费</p>
							<!-- <div class="godetail">
                <div class="godetailtext">查看流程</div>
                <img class="godetaillogo" src="../assets/home/go.png" /></div
            > -->
						</el-checkbox>
					</div>
					<div class="listdefaule" :class="
              checkboxGroup1.indexOf('支付宝生活缴费') != -1 ? 'activecox' : ''
            ">
						<img src="../assets/home/used.png" class="usedimg" v-if="ifused.ALILIFE > 0" />
						<el-checkbox label="支付宝生活缴费"><img class="imglogo" src="../assets/home/zfb.png" />
							<p class="title">支付宝生活缴费</p>
							<div class="xian"></div>
							<p class="text">基于支付宝的生活缴费模块</p>
							<p class="text">实现用户缴费</p>
							<!-- <div class="godetail">
                <div class="godetailtext">查看流程</div>
                <img class="godetaillogo" src="../assets/home/go.png" /></div
            > -->
						</el-checkbox>
					</div>
					<!-- <div
            class="listdefaule"
            :class="checkboxGroup1.indexOf('POS刷卡') != -1 ? 'activecox' : ''"
          >
            <el-checkbox label="POS刷卡"
              ><img class="imglogo" src="../assets/home/pos.png" />
              <p class="title">POS刷卡</p>
              <div class="xian"></div>
              <p class="text">基于移动设备POS机</p>
              <p class="text">实现用户缴费</p>
              <div class="godetail">
                <div class="godetailtext">查看流程</div>
                <img class="godetaillogo" src="../assets/home/go.png" /></div
            ></el-checkbox>
          </div> -->
				</div>
			</el-checkbox-group>
		</div>
		<el-button type="primary" class="nextbutton" @click="gonext">下一步</el-button>
		<div class="copyright">Copyright©2021 -2025 热付通SASS平台</div>
	</div>
</template>

<script>
	import headtop from "../components/header";
	import {
		getproduct
	} from "../request/api";
	export default {
		name: "Resgin",
		components: {
			headtop,
		},
		data() {
			return {
				checkboxGroup1: [],
				checklable: "",
				activeIndex: "1",
				alllist: {},
				ifused: {},
			};
		},
		beforeCreate() {
			document
				.querySelector("html")
				.setAttribute("style", "background-color:#F6F9FF;");
		},
		beforeDestroy() {
			document.querySelector("html").removeAttribute("style");
		},
		mounted() {
			this.getpage();
		},
		methods: {
			backpage() {
				this.$router.push({
					name: "product"
				});
			},
			//获取页面
			getpage() {
				var data = {
					func: "completecount",
					ctrl: "payinfo",
				};
				getproduct(data).then((res) => {
					if (res.code == 200) {
						this.ifused = res.data;
					}
				});
			},
			//下一步
			gonext() {
				if (this.checkboxGroup1.length == 0) {
					this.$message({
						type: "warning",
						message: "请选择",
						offset: 100,
					});
					return;
				}
				for (var i = 0; i < this.checkboxGroup1.length; i++) {
					var alltestlist = {
						payment_type: "",
						province: "",
						city: "",
						bank_title: "",
						bank_account: "",
						bank_name: "",
						bank_code: "",
						taxpayer_id: "",
						company_address: "",
						company_tel: "",
						base_bank_account: "",
						base_bank_title: "",
						invoice_title: "",
						invoice_postal: "",
						invoice_contacts: "",
						invoice_mobile: "",
						invoice_addr: "",
					};
					switch (this.checkboxGroup1[i]) {
						case "微信支付(公众号、小程序)":
							alltestlist.payment_type = "WXPAY";
							var wxlist = [];
							wxlist.push(alltestlist);
							this.alllist["WXPAY"] = wxlist;
							break;
						case "支付宝小程序":
							alltestlist.payment_type = "ALIPAY";
							var zfblist = [];
							zfblist.push(alltestlist);
							this.alllist["ALIPAY"] = zfblist;
							break;
						case "银联云闪付":
							alltestlist.payment_type = "YSF";
							var ysflist = [];
							ysflist.push(alltestlist);
							this.alllist["YSF"] = ysflist;
							break;
						case "微信生活缴费":
							alltestlist.payment_type = "WXLIFE";
							var wxlifelist = [];
							wxlifelist.push(alltestlist);
							this.alllist["WXLIFE"] = wxlifelist;
							break;
						case "支付宝生活缴费":
							alltestlist.payment_type = "ALILIFE";
							var zfblifelist = [];
							zfblifelist.push(alltestlist);
							this.alllist["ALILIFE"] = zfblifelist;
							break;
					}
				}
				localStorage.setItem("checkboxGroup", this.checkboxGroup1);
				localStorage.setItem("alllist", JSON.stringify(this.alllist));
				this.$router.push({
					name: "submit",
					params: {
						checkboxGroup: this.checkboxGroup1,
						alllist: this.alllist
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: rgba(49, 91, 210, 1);
		border-color: rgba(49, 91, 210, 1);
	}

	/deep/.el-checkbox {
		width: 100%;
	}

	/deep/.el-checkbox__input {
		position: absolute;
	}

	/deep/.el-checkbox__label {
		display: block;
		margin-top: 30px;
	}

	.biaoti {
		position: absolute;
		top: 30px;
		left: 50%;
		margin-left: -250px;
		color: rgba(102, 102, 102, 1);
		text-align: center;
		font-weight: 400;
		width: 500px;
	}

	.list {
		width: 75%;
		margin: 5vh 12.5% 8vh;
	}

	.felxlist {
		display: flex;
		// justify-content: space-between;

		margin-bottom: 40px;

		.activecox {
			border: 1px solid rgba(49, 91, 210, 1) !important;
		}

		.listdefaule {
			background: #ffffff;
			box-shadow: 0px 2px 10px 4px rgba(49, 91, 210, 0.1);
			border: 1px solid transparent;
			border-radius: 4px;
			padding: 10px;
			width: 30%;
			position: relative;
			padding-bottom: 35px;
			box-sizing: border-box;

			.usedimg {
				width: 72px;
				height: 72px;
				position: absolute;
				right: 0;
				top: 0;
			}

			.imglogo {
				width: 64px;
				height: 64px;
				display: block;
				margin: auto;
			}

			.title {
				font-size: 20px;
				font-weight: 500;
				color: #333333;
				text-align: center;
				margin: 10px 0 0;
			}

			.xian {
				width: 16px;
				height: 4px;
				background: #e0e0e0;
				border-radius: 2px;
				margin: 20px auto;
			}

			.text {
				font-size: 16px;
				color: #666666;
				letter-spacing: 1px;
				text-align: center;
				margin: 0;
			}

			.godetail {
				display: flex;
				justify-content: flex-end;
				margin: 30px 0 0;

				.godetailtext {
					font-size: 14px;
					color: #315bd2;
				}

				.godetaillogo {
					width: 16px;
					height: 16px;
					display: block;
					margin: auto 0;
				}
			}
		}

		.listdefaule+.listdefaule {
			margin-left: 5%;
		}
	}

	.nextbutton {
		display: block;
		margin: auto;
		width: 350px;
		background: rgba(49, 91, 210, 1);
		color: #fff;
		font-size: 20px;
	}

	.tools-bar {
		position: relative;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		padding-top: 42px;
		width: 75%;

		/deep/ .el-button {
			padding: 20px;
			font-size: 18px;
			border-radius: 5px;

			&.el-button--default {
				background-color: #e1e1e1;
			}

			&.el-button--primary {
				background-color: #246fec;
			}
		}
	}
</style>
